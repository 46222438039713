import React from "react";
import {Button, Checkbox, Dropdown, Icon,  Label, Table} from "semantic-ui-react";
import axios from "axios";
import Cookies from "js-cookie";
import GroupPermissionForObject from "./GroupPermissionForObject";
import {stringify} from "wkt";
const config = require('../config');
const hostBackend = config.HOSTBackend;
const portBackend = config.PORTBackend;
const protocolBackend = config.ProtocolBackend;
const prefixBackend = config.PrefixBackend;
var urlBackend;
if (portBackend === "" || portBackend === undefined){
    urlBackend=protocolBackend+'://'+hostBackend+prefixBackend;
}
else{
    urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
}



class LoaderToDB extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            array: [{key:"Basins",text:"Basins",value:"Basins"},{key:"SubBasins",text:"SubBasins",value:"SubBasins"},{key:"Licenses",text:"Licenses",value:"Licenses"},{key:"Fields",text:"Fields",value:"Fields"},{key:"TectonicMap",text:"Tectonic Map",value:"TectonicMap"},{key:"GeologicMap",text:"Geologic Map",value:"GeologicMap"},{key:"Geo_Objects",text:"Geo Objects",value:"Geo_Objects"},{key:"Seismics",text:"Seismics",value:"Seismics"},{key:"Wells",text:"Wells",value:"Wells"},{key:"Protected_Areas",text:"Protected Areas",value:"Protected_Areas"}],
            list:[],
            proplist:this.props.list,
            proparray:[],
            name:this.props.list,
            keyprop:null,
            PermissionDisplay:"none",
            groupPermArray:this.props.groupPermArray,
            groupPermArrayBasic:this.props.groupPermArrayBasic,
            display:"none",
            display2:"none",
            myobject:false,
            resultlist:[],
            resultquary:[],
            rowlist:"",
            layer:"licenses",
            index:0,
            selectValue:"----"
        };
        this.ObjectToString = this.ObjectToString.bind(this);
        this.makeRequestsFromArray = this.makeRequestsFromArray.bind(this);
        this.makeArrayFromJson = this.makeArrayFromJson.bind(this);
        this.cancel = this.cancel.bind(this);
    }
    ObjectToString(object) {
        if (object !== null || object !== undefined) {
            let string ='';
            //console.log(object)
            let obj = Object.getOwnPropertyNames(object);
            for (let i = 0; i < obj.length;i++){
                if (object[obj[i]] instanceof Object)
                    string =string + obj[i] + ' : [ ' + this.ObjectToString(object[obj[i]]) + ' ] \n' ;
                else
                    string =string + obj[i] + ' : ' + object[obj[i]] + ' \n'
            }
            return string ;
        }
        else
            return "No availible Properties";
    }
    makeRequestsFromArray(arr,arr2) {
        let index = 0;
        let resultarray = [];
        let leng = arr.length-1;
        let that = this;
        this.props.loadingopen();
        function request() {
            //console.log(index, arr[index],leng,arr,arr2,resultarray)
            //console.log(arr[index].quary)
            if (index <= arr.length && arr[index] !== undefined){
                return axios.post(urlBackend+'/postQuery', {query: arr[index].quary})
                    .then((res) => {
                        console.log(res);
                        index++;
                        if (index === arr.length)
                            that.props.loadingclose()
                        if (index > arr.length) {
                            console.log("Error")
                            that.props.loadingclose()
                            return that.setState({resultlist:resultarray});
                        }
                        else{
                            console.log("Success")
                            let temp ="" ;
                            let triger = false;
                            if (res.data[0].get_similar_geo_objects_with_params.similar_by_params !== null){
                                temp = temp + " Has conflicted properties : \n" + that.ObjectToString(res.data[0].get_similar_geo_objects_with_params.similar_by_params[0])
                                triger = true;
                            }
                            if (res.data[0].get_similar_geo_objects_with_params.similar_by_geometry !== null){
                                temp = "\n"+ temp + " Has conflicted geometries : \n" + that.ObjectToString(res.data[0].get_similar_geo_objects_with_params.similar_by_geometry[0])
                                triger = true;
                            }
                            if (triger === false)
                                temp = "Has no similar geometry and properties"
                            //resultarray.push(res.data);
                            console.log(resultarray)
                            console.log({index:index-1,result:temp,name:arr2[index-1][Object.getOwnPropertyNames(arr2[index-1])[0]],prop:arr2[index-1],geom:arr[index-1].geom})
                            resultarray.push({index:index-1,result:temp,name:arr2[index-1][Object.getOwnPropertyNames(arr2[index-1])[0]],prop:arr2[index-1],geom:arr[index-1].geom,collapse:true})
                            that.setState({resultlist:resultarray});
                            return request();
                        }
                    }).catch((error) => {
                        //console.log(error)
                        index++;
                        resultarray.push(error)
                        if (index >= arr.length) {
                            that.props.loadingclose()
                            console.log('done',error);
                        }
                        return request();
                    });
                //}
            }
        }
        //console.log(index,"when??")
        return request();
    }
    makeArrayFromJson(value,lic_nbr,layer) {
        console.log(layer);
        if (layer !== undefined || (layer === "Licenses" && lic_nbr !== undefined && lic_nbr !== null)) {
            let properties = [];
            let prop = [];
            let name = '';
            for (let i = 0; i < this.props.result.length; i++) {
                if (this.props.result[i].name === value && this.props.result[i].type === 'geojson') {
                    properties = Object.getOwnPropertyNames(this.props.result[i].data.features[0].properties)
                    name = properties[0];
                    for (let j = 0; j < properties.length; j++) {
                        prop.push({key: properties[j], text: properties[j], value: properties[j]})
                    }
                    const {stringify} = require('wkt');
                    let resultToDisplay = [];
                    let data = this.props.result[i].data;
                    let request = [];
                    let PropList = [];
                    //console.log("File length =", data.features.length)
                    let res2 = [];
                    for (let h = 0; h < data.features.length; h++) {
                        let reqBody = "";
                        let quarygeom = "";
                        let reqIndex = h;
                        if (data.features[h].geometry !== null) {
                            let geometry = data.features[h].geometry;
                            geometry = stringify(geometry);
                            //console.log(h !== 59,"id = ",h,data.features[h].properties,geometry.length)
                            if (geometry !== 'MULTIPOLYGON ()') {
                                res2.push(h);
                                let quary = "";
                                if ( layer === "Licenses"){
                                    //quary = "SELECT public.get_similar_geo_objects_with_params(ST_SetSRID(ST_Force2D(ST_GeomFromText('" + geometry + "')),4326), 'licenses', '{\"lic_nbr\":\"" + data.features[h].properties[lic_nbr] + "\"}'::json)";
                                    quary = {type:"get_similar_geo_objects_with_params",select_type:"get_similar_geo_objects_with_params",
                                        select_params:{geometry:geometry,layer:layer.toLowerCase(),params:'{"lic_nbr":"' + data.features[h].properties[lic_nbr] + '"}'}}
                                    console.log(quary,data.features[h].properties[lic_nbr]);
                                    quarygeom = "ST_Transform(ST_SetSRID(ST_Force2D(ST_GeomFromText('" + geometry + "')),4326),3857)";
                                }
                                else {
                                    //quary = "SELECT public.get_similar_geo_objects_with_params(ST_SetSRID(ST_Force2D(ST_GeomFromText('" + geometry + "')),4326), '"+layer.toLowerCase()+"', '{}'::json)";
                                    quary = {type:"get_similar_geo_objects_with_params",select_type:"get_similar_geo_objects_with_params",
                                        select_params:{geometry:geometry,layer:layer.toLowerCase(),params:'{}'}}
                                    quarygeom = "ST_Transform(ST_SetSRID(ST_Force2D(ST_GeomFromText('" + geometry + "')),4326),3857)";
                                }
                                console.log(quary)
                                //quary = "SELECT public.get_similar_geo_objects(ST_SetSRID(ST_GeomFromText('"+geometry+"'),4326), 'basins') ";
                                reqBody = quary;
                                //console.log(data.features[h].properties)
                                PropList.push(data.features[h].properties);
                            } else
                                PropList.push(null)
                            request.push({index: reqIndex, quary: reqBody, geom: quarygeom});
                        }
                        else {
                            PropList.push(null)
                            request.push({index:reqIndex,quary:reqBody, geom: quarygeom});
                        }
                    }
                    //request = request.slice(0, -1)
                    console.log(request);
                    prop.push({key: "---", text: "---", value: "---"});
                    properties = prop;
                    resultToDisplay.push({quarylist: request, properties: PropList})
                    console.log(resultToDisplay)

                    //this.props.loader(prop,resultToDisplay);toDB
                    return resultToDisplay;
                    //makeRequestsFromArray(request).then(r => console.log(r));
                }
            }
        }
    }
    isDate(dateStr) {
        return !isNaN(new Date(dateStr).getDate());
    }
    cancel ()  {
        this.setState({
            array: [{key:"Basins",text:"Basins",value:"Basins"},{key:"SubBasins",text:"SubBasins",value:"SubBasins"},{key:"Licenses",text:"Licenses",value:"Licenses"},{key:"Fields",text:"Fields",value:"Fields"},{key:"TectonicMap",text:"Tectonic Map",value:"TectonicMap"},{key:"GeologicMap",text:"Geologic Map",value:"GeologicMap"},{key:"Geo_Objects",text:"Geo Objects",value:"Geo_Objects"},{key:"Seismics",text:"Seismics",value:"Seismics"},{key:"Wells",text:"Wells",value:"Wells"},{key:"Protected_Areas",text:"Protected Areas",value:"Protected_Areas"}],
            list:[],
            proplist:[],
            proparray:[],
            groupPermArray:[],
            groupPermArrayBasic:[],
            name:[],
            object_types:[],
            outcrop:[],
            keyprop:null,
            display:"none",
            display2:"none",
            resultlist:[],
            resultquary:[],
            rowlist:"",
            layer:"licenses",
            selectValue:"----"
        });
        this.props.loaderhandle([])
    }
    render() {
        let quarylist =[];
        let quarylist2 = [];
        let outcrop = [];
        let profiles = [];
        let object_type = [];
        console.log(this.state.groupPermArray,this.state.groupPermArrayBasic)
        let rowlist = "(";
        if (Cookies.get("id") !== undefined)
            var authorized = true;
        else
            var authorized = false;
        for ( let j = 0 ; j < this.state.list.length;j++) {
            if ( this.state.proparray[j] !== null && this.state.proparray[j] !== undefined && this.state.proparray[j] !== "" ) {
                rowlist = rowlist + this.state.list[j] + ","
            }

        }
        console.log(this.state.resultlist)
        return(
            <div className="loader" style={{
                display:this.props.display,
            }}>
                <Button basic size={'mini'} onClick={()=>{this.cancel()}} icon><i className="close icon"/></Button>
                <br/>
                <Checkbox
                    slider
                    size={'mini'}
                    label={"Only User Objects"}
                    className={'checkbox'}
                    checked={this.state.myObject}
                    onChange={ () => {
                        this.setState({myObject:!this.state.myObject});
                    }}
                />
                <br/>
                <GroupPermissionForObject csstop={null} arrayChange={(array)=>{this.setState({groupPermArray:array})}} permArrayBasic={this.state.groupPermArrayBasic} permArray={this.state.groupPermArray} id={null} close={()=>{this.setState({PermissionDisplay:"none"})}} display={this.props.display} />
                <br/>
                {"Select Layer:"}
                <Dropdown
                    options={this.state.array}
                    value={this.state.selectValue}
                    onChange={(e,val) => {

                        //console.log(e,val,this.state.name,this.props.list,this.props)
                        let properties=[];
                        let prop = [];
                        let name = '';
                        console.log(this.props.result)
                        for ( let i = 0 ;i < this.props.result.length;i++){
                            console.log(this.props.result[i].name,this.props.list,this.props.result[i].type)
                            if(this.props.result[i].name === this.props.list && this.props.result[i].type === 'geojson') {
                                properties = Object.getOwnPropertyNames(this.props.result[i].data.features[0].properties)
                                name = properties[0];
                                for(let j = 0; j<properties.length;j++) {
                                    prop.push({key:properties[j],text:properties[j],value:properties[j]})
                                }
                                prop.push({key:"---",text:"---",value:"---"});
                            }
                        }
                        console.log(prop);
                        let resp;
                        let col;
                        let req_columns;
                        console.log("before",val.value)
                        switch (val.value) {
                            case "Basins":
                                console.log(val.value,prop)
                                req_columns = ["basin_name","basin_type_id","basin_subtype_id","basin_class_id","basin_fluid_type_id","basin_age_id","year","description"];
                                this.setState({name:this.props.list,list:req_columns,proplist:prop,layer:"basins",selectValue:val.value,display:"block"})
                                break;
                            case "GeologicMap":
                                console.log(val.value,prop)
                                req_columns = ["original_name","info_year","info_source_text","geo_index","confidence_degree_id","geologicmap_name"];
                                this.setState({name:this.props.list,list:req_columns,proplist:prop,layer:"geologicmap",selectValue:val.value,display:"block"})
                                break;
                            case "TectonicMap":
                                console.log(val.value,prop)
                                req_columns = ["info_year","info_source_text","confidence_degree_id"];
                                this.setState({name:this.props.list,list:req_columns,proplist:prop,layer:"tectonicmap",selectValue:val.value,display:"block"})
                                break;
                            case "SubBasins":
                                console.log(val.value,prop)
                                req_columns = ["year","source","confidence_degree_id","basin_fluid_type_id","subbasin_name","classified_name","description"];
                                this.setState({name:this.props.list,list:req_columns,proplist:prop,layer:"subbasins",selectValue:val.value,display:"block"})
                                break;
                            case "Licenses":
                                console.log(val.value,prop)
                                req_columns =[ "lic_nbr","licenses_name","operator_name","year","source","confidence_degree_id","license_type","license_status","license_obligation","issue_date","expiry_date","description"];
                                this.setState({name:this.props.list,list:req_columns,proplist:prop,layer:"licenses",selectValue:val.value,display:"block"})
                                break;
                            case "Fields":
                                console.log(val.value,prop)
                                req_columns = ["fields_name","year","source","field_class","field_location","field_type","development_stage","discovery_method","field_terrain","discovery_year","shutdown_year","start_production_year","planed_start_production_year","max_elevation","max_water_depth","description"];
                                this.setState({name:this.props.list,list:req_columns,proplist:prop,layer:"fields",selectValue:val.value,display:"block"})
                                break;
                            case "Geo_Objects":
                                console.log(val.value,prop)
                                req_columns = ["object_name","object_type","outcrop","geo_position","reference","straton","description"];
                                this.setState({name:this.props.list,list:req_columns,proplist:prop,layer:"geo_objects",selectValue:val.value,display:"block"})
                                break;
                            case "Seismics":
                                console.log(val.value,prop)
                                req_columns=["s_lin_name", "s_lin_nbr","domain_id",'seismic_type_id','signal_source_id','field_work_year','processing_year','field_operator_name' , 'processing_company_name' , 'rgf_nbr' , 'rgf_report_year' , "rgf_link"]
                                this.setState({name:this.props.list,list:req_columns,proplist:prop,layer:"seismics",selectValue:val.value,display:"block"})
                                break;
                            case "Wells":
                                console.log(val.value,prop)
                                req_columns=["well_name", "well_stock_id", "alternative_names", "uwi" , "pad", "slot", "target_reservoir_id" , "well_type_id" , "current_status_id" , "status_start_date", "fluid_type_id" , "well_head_altitude", "kb_altitude", "td_m", "data_source", "description", "spud_date"	, "Total_length_md"	, "water_depth"]
                                this.setState({name:this.props.list,list:req_columns,proplist:prop,layer:"wells",selectValue:val.value,display:"block"})
                                break;
                            case "Protected_Areas":
                                console.log(val.value,prop)
                                req_columns=["name","protected_area_type_id","protected_area_profile_id","protected_area_significance_id","description", "source", "profile_create_date", "area"]
                                this.setState({name:this.props.list,list:req_columns,proplist:prop,layer:"protected_areas",selectValue:val.value,display:"block"})
                                break;
                            default:
                                console.log(val.value,prop)
                                resp = "basins_infos";
                                col = "basins_infos_id";
                                break;
                        }
                        console.log("req_col",req_columns)
                    }}
                    //value={this.state.selected}
                    selection
                    placeholder={"Choose layer to insert"} />
                <br/>
                {"Fields marked with an asterisk (*) are required. "}
                <br/>
                {this.state.list.map((name,index) => {

                    console.log(this.state.list,name,index,this.state.proplist)
                    if (name === "lic_nbr") {
                        return(
                            <span>
                            <Label basic size={'medium'}>{" * "}{name}{" * "}</Label>
                                <Dropdown
                                    clearable
                                    options={this.state.proplist}
                                    onChange={(e,val) => {
                                        //console.log(name,val.value,index)
                                        let array = this.state.proparray;
                                        //let rowlist = "(";
                                        array[index] =val.value;
                                        this.setState({proparray:array,keyprop:name})
                                    }}
                                    selection
                                    placeholder={"Choose property"} />
                            <br/>
                            </span>
                        )
                    }
                    else
                        return(
                            <span>
                            <Label basic size={'medium'}>{name}</Label>
                                <Dropdown
                                    clearable
                                    options={this.state.proplist}
                                    onChange={(e,val) => {
                                        //console.log(name,val.value,index)
                                        let array = this.state.proparray;
                                        //let rowlist = "(";
                                        array[index] =val.value;
                                        this.setState({proparray:array,rowlist:rowlist})
                                    }}
                                    selection
                                    placeholder={"Choose property"} />
                            <br/>
                            </span>
                        )
                })}
                <div style={{display:this.state.display}}>
                    <Button onClick={(e) => {
                        let resultlist = this.makeArrayFromJson(this.props.list,this.state.keyprop,this.state.selectValue);
                        //console.log(resultlist,this.state.proparray);
                        let request = resultlist[0].quarylist;
                        let prop = resultlist[0].properties;

                        //console.log(this.props);
                        this.makeRequestsFromArray(request,prop).then(r => console.log(r,this.state));

                        this.setState({display2:"block"})
                    }}>{"Send"}</Button>
                </div>
                <div style={{display:this.state.display2}} >
                    {"Result List:"}
                    <Table compact celled definition>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell />
                                <Table.HeaderCell>Index</Table.HeaderCell>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Result</Table.HeaderCell>
                                <Table.HeaderCell>Properies</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.resultlist.map(({index,result,name,prop,geom,collapse}) => {
                                console.log(index,result,name,prop,geom,this.state.resultlist)
                                if ( index !== undefined) {

                                    let triger = false;
                                    let properties ;
                                    if ( result === "Has no similar geometry and properties") {
                                        triger = true
                                    }
                                    if ( result !== null)
                                        result = ' result : ' + result + '\n';
                                    else
                                        result = ' result : Match not found ' + '\n'
                                    if ( prop !== null)
                                        properties = ' properties : ' + this.ObjectToString(prop) + '\n'
                                    else
                                        properties = + ' properties : Not found' + '\n'
                                    //console.log(index,result,name,prop)
                                    let nametrigger = true;
                                    let nbrtrigger = true;
                                    for ( let j = 0 ; j < this.state.list.length;j++) {
                                        if (this.state.list[j] === "lic_nbr" || this.state.list[j] === "licenses_name") {
                                            if ( (this.state.proparray[j] === null || this.state.proparray[j] === undefined || this.state.proparray[j] === "" ) && this.state.list[j] === "lic_nbr" )
                                            {
                                                nbrtrigger=false;
                                            }
                                            if ( (this.state.proparray[j] === null || this.state.proparray[j] === undefined || this.state.proparray[j] === "" ) && this.state.list[j] === "licenses_name" )
                                            {
                                                nametrigger = false;
                                            }
                                        }
                                    }
                                    if (!nametrigger && !nbrtrigger){
                                        triger = false
                                        result = ' result : ' + 'has no key properties.' + '\n';
                                    }
                                    if ( triger ) {
                                        //console.log(prop,this.state.list,this.state.proparray)
                                        let quary = "";
                                        let valuelist = "("
                                        let quary2 = '';
                                            quary2 = "(" +geom +"," ;
                                        for ( let j = 0 ; j < this.state.list.length;j++) {
                                            if ((this.state.list[j] === "lic_nbr" || this.state.list[j] === "licenses_name") && (this.state.proparray[j] !== null && this.state.proparray[j] !== undefined && this.state.proparray[j] !== "" )) {
                                                if ( (prop[this.state.proparray[j]] !== null && prop[this.state.proparray[j]] !== undefined && prop[this.state.proparray[j]] !== "" ) && this.state.list[j] === "lic_nbr" )
                                                {
                                                    //console.log("nbr with null",this.state.list[j],prop[this.state.proparray[j]])
                                                    //quary2 = quary2 + "'" + prop[this.state.proparray[j]] + "',"

                                                }
                                                else if ( this.state.list[j] === "lic_nbr"){
                                                    //console.log("nbr with null",this.state.list[j],prop[this.state.proparray[j]])
                                                    //quary2 = quary2 + "' ',"
                                                    nbrtrigger=false;
                                                }
                                                if ( (prop[this.state.proparray[j]] !== null && prop[this.state.proparray[j]] !== undefined && prop[this.state.proparray[j]] !== "" ) && this.state.list[j] === "licenses_name" )
                                                {
                                                    //console.log("name not null",this.state.list[j],prop[this.state.proparray[j]])
                                                    //quary2 = quary2 + "'" + prop[this.state.proparray[j]] + "',"

                                                }
                                                else if ( this.state.list[j] === "licenses_name"){
                                                    //console.log("name with null",this.state.list[j],prop[this.state.proparray[j]])
                                                    //quary2 = quary2 + "' ',"
                                                    nametrigger = false;
                                                }
                                            }
                                        }
                                        for ( let j = 0 ; j < this.state.list.length;j++) {
                                            if ( this.state.proparray[j] !== null && this.state.proparray[j] !== undefined && this.state.proparray[j] !== "" ) {
                                                console.log(this.state.list[j])
                                                if (this.state.list[j] === "issue_date" || this.state.list[j] === "profile_create_date" || this.state.list[j] === "expiry_date" || this.state.list[j] === "status_start_date" || this.state.list[j] === "spud_date") {
                                                    if (prop[this.state.proparray[j]] === null)
                                                        valuelist = valuelist + "" + prop[this.state.proparray[j]] + ",";
                                                    else {
                                                        let temp = prop[this.state.proparray[j]].toString();
                                                        if (temp.includes("."))
                                                            valuelist = valuelist + "to_date('" + prop[this.state.proparray[j]] + "', 'DD.MM.YYYY'),";
                                                        else if (temp.includes("-")){
                                                            if (temp.split('-')[0].length > 3)
                                                                valuelist = valuelist + "to_date('" + prop[this.state.proparray[j]] + "', 'YYYY-MM-DD'),";
                                                            else
                                                                valuelist = valuelist + "to_date('" + prop[this.state.proparray[j]] + "', 'DD-MM-YYYY'),";
                                                        }
                                                        else if (temp.includes("/"))
                                                            valuelist = valuelist + "to_date('" + prop[this.state.proparray[j]] + "', 'DD/MM/YYYY'),";
                                                        else if (this.isDate(temp)){
                                                            valuelist = valuelist + "cast('" + prop[this.state.proparray[j]] + "' as date),";
                                                        }
                                                        else {
                                                            valuelist = valuelist + "NULL,";
                                                        }

                                                    }
                                                }
                                                else if (this.state.list[j] === "well_name" || this.state.list[j] === "lic_nbr" || this.state.list[j] === "licenses_name" || this.state.list[j]==="basin_name"|| this.state.list[j]==="s_lin_name"|| this.state.list[j]==="s_lin_nbr" || this.state.list[j]==="name"){
                                                    console.log(prop[this.state.proparray[j]]);
                                                    if (prop[this.state.proparray[j]] === null || typeof(prop[this.state.proparray[j]]) === 'number')
                                                        valuelist = valuelist +"" + prop[this.state.proparray[j]] + ",";
                                                    else
                                                        valuelist = valuelist +"'" + prop[this.state.proparray[j]].replace(/'/g, "") + "',";
                                                }
                                                else if ( this.state.list[j] === "uwi" || this.state.list[j] === "alternative_names" || this.state.list[j] === "field_operator_name" || this.state.list[j] === "processing_company_name" || this.state.list[j] === "rgf_nbr" || this.state.list[j] === "rgf_link" || this.state.list[j] === "source" || this.state.list[j] === "operator_name" || this.state.list[j] === "description" || this.state.list[j] === "original_name" || this.state.list[j] === "info_source_text" || this.state.list[j] === "geo_index" || this.state.list[j] === "geologicmap_name" || this.state.list[j] === "subbasin_name" || this.state.list[j] === "classified_name"  || this.state.list[j] === "fields_name" || this.state.list[j] === "s_lin_name" || this.state.list[j] === "object_name" || this.state.list[j] === "reference" ) {
                                                    console.log(prop[this.state.proparray[j]])
                                                    if (prop[this.state.proparray[j]] === null)
                                                        valuelist = valuelist +"" + prop[this.state.proparray[j]] + ",";
                                                    else
                                                        valuelist = valuelist +"'" + prop[this.state.proparray[j]].replace(/'/g, "") + "',";
                                                }
                                                else if ( this.state.list[j] === "basin_type_id" ) {
                                                    if (Number.isInteger(prop[this.state.proparray[j]]))
                                                        valuelist = valuelist + "(SELECT f_basin_type_id FROM basin_type WHERE f_basin_type_id"+prop[this.state.proparray[j]]+"),"
                                                    else
                                                        valuelist = valuelist + "(SELECT f_basin_type_id FROM basin_type WHERE LOWER(f_basin_type_name)='"+prop[this.state.proparray[j]].toLowerCase()+"'),"
                                                }
                                                else if ( this.state.list[j] === "basin_subtype_id" ) {
                                                    if (Number.isInteger(prop[this.state.proparray[j]]))
                                                        valuelist = valuelist + "(SELECT f_basin_subtype_id FROM basin_subtype WHERE f_basin_subtype_id="+prop[this.state.proparray[j]]+"),"
                                                    else
                                                        valuelist = valuelist + "(SELECT f_basin_subtype_id FROM basin_subtype WHERE LOWER(f_basin_subtype_name)='"+prop[this.state.proparray[j]].toLowerCase()+"'),"
                                                }
                                                else if ( this.state.list[j] === "basin_class_id" ) {
                                                    if (Number.isInteger(prop[this.state.proparray[j]]))
                                                        valuelist = valuelist + "(SELECT f_basin_class_id FROM basin_class WHERE f_basin_class_id="+prop[this.state.proparray[j]]+"),"
                                                    else
                                                        valuelist = valuelist + "(SELECT f_basin_class_id FROM basin_class WHERE LOWER(f_basin_class_name)='"+prop[this.state.proparray[j]].toLowerCase()+"'),"
                                                }
                                                else if ( this.state.list[j] === "basin_fluid_type_id" ) {
                                                    if (Number.isInteger(prop[this.state.proparray[j]]))
                                                        valuelist = valuelist + "(SELECT f_basin_fluide_type_id FROM basin_fluid_type WHERE f_basin_fluide_type_id="+prop[this.state.proparray[j]]+"),"
                                                    else
                                                        valuelist = valuelist + "(SELECT f_basin_fluide_type_id FROM basin_fluid_type WHERE LOWER(f_basin_fluide_type_name)='"+prop[this.state.proparray[j]].toLowerCase()+"'),"
                                                }
                                                else if ( this.state.list[j] === "basin_age_id" ) {
                                                    if (Number.isInteger(prop[this.state.proparray[j]]))
                                                        valuelist = valuelist + "(SELECT f_basin_age_id FROM basin_age WHERE f_basin_age_id="+prop[this.state.proparray[j]]+"),"
                                                    else
                                                        valuelist = valuelist + "(SELECT f_basin_age_id FROM basin_age WHERE LOWER(f_basin_age_name)='"+prop[this.state.proparray[j]].toLowerCase()+"'),"
                                                }
                                                else if ( this.state.list[j] ==="well_type_id"  ) {
                                                    console.log(Number.isInteger(parseInt(prop[this.state.proparray[j]])), parseInt(prop[this.state.proparray[j]]), Number.isInteger(1), Number.isInteger(parseInt('1')))
                                                    if (Number.isInteger(parseInt(prop[this.state.proparray[j]])))
                                                        valuelist = valuelist + "(select code from universal_dict where dict_code = 'well_type' and code = " + prop[this.state.proparray[j]] + "),"
                                                    else{
                                                        let dictString = prop[this.state.proparray[j]];
                                                        if ( prop[this.state.proparray[j]] !== null && prop[this.state.proparray[j]] !== undefined)
                                                            dictString=prop[this.state.proparray[j]].toLowerCase()
                                                        valuelist = valuelist + "(select code from universal_dict where dict_code = 'well_type' and LOWER(name_ru) =  '" + dictString + "'),"

                                                    }
                                                }
                                                else if ( this.state.list[j] ==="outcrop"   ) {
                                                    console.log(Number.isInteger(parseInt(prop[this.state.proparray[j]])), parseInt(prop[this.state.proparray[j]]), Number.isInteger(1), Number.isInteger(parseInt('1')))
                                                    outcrop[index] = prop[this.state.proparray[j]].replaceAll(" ","").split(',')
                                                }
                                                else if ( this.state.list[j] ==="object_type"   ) {
                                                    console.log(Number.isInteger(parseInt(prop[this.state.proparray[j]])), parseInt(prop[this.state.proparray[j]]), Number.isInteger(1), Number.isInteger(parseInt('1')))
                                                    object_type[index] = prop[this.state.proparray[j]].replaceAll(" ","").split(',')
                                                }
                                                else if(this.state.list[j] === "protected_area_profile_id"){
                                                    profiles[index] = prop[this.state.proparray[j]].replaceAll(" ","").split(',')
                                                }
                                                else if(this.state.list[j] === "protected_area_significance_id"){
                                                    if (Number.isInteger(parseInt(prop[this.state.proparray[j]])))
                                                        valuelist = valuelist + "(select code from universal_dict where dict_code = 'protected_area_significance' and code = " + prop[this.state.proparray[j]] + "),"
                                                    else{
                                                        let dictString = prop[this.state.proparray[j]];
                                                        if ( prop[this.state.proparray[j]] !== null && prop[this.state.proparray[j]] !== undefined)
                                                            dictString=prop[this.state.proparray[j]].toLowerCase()
                                                        valuelist = valuelist + "(select code from universal_dict where dict_code = 'protected_area_significance' and LOWER(name_ru) =  '" + dictString + "'),"

                                                    }
                                                }
                                                else if(this.state.list[j] === "protected_area_type_id"){
                                                    if (Number.isInteger(parseInt(prop[this.state.proparray[j]])))
                                                        valuelist = valuelist + "(select code from universal_dict where dict_code = 'protected_area' and code = " + prop[this.state.proparray[j]] + "),"
                                                    else{
                                                        let dictString = prop[this.state.proparray[j]];
                                                        if ( prop[this.state.proparray[j]] !== null && prop[this.state.proparray[j]] !== undefined)
                                                            dictString=prop[this.state.proparray[j]].toLowerCase()
                                                        valuelist = valuelist + "(select code from universal_dict where dict_code = 'protected_area' and LOWER(name_ru) =  '" + dictString + "'),"

                                                    }
                                                }
                                                else if (this.state.list[j] === "field_terrain"  || this.state.list[j] === "field_location" || this.state.list[j] === "fluid_type_id" || this.state.list[j] === "current_status_id" || this.state.list[j] === "target_reservoir_id" || this.state.list[j] === "well_stock_id" || this.state.list[j] ==="well_type" || this.state.list[j] === "signal_source_id" || this.state.list[j] === "seismic_type_id" || this.state.list[j] === "domain_id" || this.state.list[j] === "development_stage" || this.state.list[j] === "descovery_method" || this.state.list[j] === "field_class" || this.state.list[j] === "field_type" || this.state.list[j] === "location" || this.state.list[j] === "terrain" || this.state.list[j] === "license_status" ||  this.state.list[j] === "license_type" || this.state.list[j] === "protected_area_profile_id" || this.state.list[j] === "protected_area_type_id" || this.state.list[j] === "confidence_degree_id" ) {
                                                    console.log(Number.isInteger(parseInt(prop[this.state.proparray[j]])), parseInt(prop[this.state.proparray[j]]), Number.isInteger(1), Number.isInteger(parseInt('1')))
                                                    if (Number.isInteger(parseInt(prop[this.state.proparray[j]])))
                                                        valuelist = valuelist + "(select code from universal_dict where dict_code = '" + this.state.list[j] + "' and code = " + prop[this.state.proparray[j]] + "),"
                                                    else{
                                                        let dictString = prop[this.state.proparray[j]];
                                                        if ( prop[this.state.proparray[j]] !== null && prop[this.state.proparray[j]] !== undefined)
                                                            dictString=prop[this.state.proparray[j]].toLowerCase()
                                                        valuelist = valuelist + "(select code from universal_dict where dict_code = '" + this.state.list[j] + "' and LOWER(name_ru) =  '" + dictString + "'),"

                                                    }
                                                }
                                                else
                                                    valuelist = valuelist + prop[this.state.proparray[j]] + ",";
                                            }
                                        }

                                        if (this.state.myObject)
                                            quary2 = quary2 + Cookies.get("id") + ",'USER'),"
                                        else
                                            quary2 = quary2 + Cookies.get("id") + "),"

                                        if (this.state.myObject)
                                            valuelist =valuelist+ Cookies.get("id") + ",0,'USER' "
                                        else
                                            valuelist =valuelist+ Cookies.get("id") + ",0"
                                        if (this.state.layer.toLowerCase() === "seismics")
                                            valuelist = valuelist + ',( ST_Length(('+geom+')::geography)/1000) '
                                        quarylist.push(valuelist)
                                        quarylist2.push(quary2)
                                        let prop_display = properties;
                                        if (collapse)
                                            prop_display = prop_display.substring(0, 30) + ' ... click to expand'

                                        return(
                                            <Table.Row positive>
                                                <Table.Cell collapsing>
                                                    <Checkbox slider />
                                                </Table.Cell>
                                                <Table.Cell>{index}</Table.Cell>
                                                <Table.Cell>{name}</Table.Cell>
                                                <Table.Cell>{result}</Table.Cell>
                                                <Table.Cell onClick={()=>{
                                                    console.log("Click on cell")
                                                    let resultlistcollapse = this.state.resultlist;
                                                    resultlistcollapse[index].collapse = !collapse;
                                                    this.setState({index:this.state.index++,resultlist:resultlistcollapse})
                                                }}>{prop_display}</Table.Cell>
                                            </Table.Row>
                                        )}
                                    else {
                                        let prop_display = properties;
                                        if (collapse)
                                            prop_display = prop_display.substring(0, 30) + ' ... click to expand'
                                        return(
                                            <Table.Row negative>
                                                <Table.Cell collapsing>
                                                    <Checkbox slider />
                                                </Table.Cell>
                                                <Table.Cell>{index}</Table.Cell>
                                                <Table.Cell>{name}</Table.Cell>
                                                <Table.Cell >{result}</Table.Cell>
                                                <Table.Cell onClick={()=>{
                                                    console.log("Click on cell")
                                                    let resultlistcollapse = this.state.resultlist;
                                                    resultlistcollapse[index].collapse = !collapse;
                                                    this.setState({index:this.state.index++,resultlist:resultlistcollapse})
                                                }}>{prop_display}</Table.Cell>
                                            </Table.Row>
                                        )}
                                }
                            })}
                        </Table.Body>
                        <Table.Footer style={{position: 'sticky', insetBlockEnd: '0'}} fullWidth>
                            <Table.Row>
                                <Table.HeaderCell />
                                <Table.HeaderCell colSpan='4'>
                                    <Button
                                        floated='right'
                                        icon
                                        labelPosition='left'
                                        onClick={() => {

                                            let row2 = rowlist ;
                                            if (this.state.myObject)
                                                row2 = row2 + "author_id,data_availability_id,view_state"
                                            else
                                                row2 = row2 + "author_id,data_availability_id"

                                            if( this.state.layer.toLowerCase() === 'seismics')
                                                row2 = row2 +',length_2d'
                                            row2 = row2  +","+this.state.layer+"_id)"
                                            //let quary = "INSERT INTO " + this.state.layer + "_infos " + row2 +","+this.state.layer+"_id) VALUES  ";
                                            //let quary2;
                                            let rowGeometry = ''
                                            if (this.state.myObject)
                                                rowGeometry = "(wkb_geometry,user_id,view_state)";
                                            else
                                                rowGeometry = "(wkb_geometry,user_id)";
                                            //quary2 = "INSERT INTO " + this.state.layer + " (wkb_geometry,user_id,view_state) VALUES " ;
                                            //quary2 = "INSERT INTO " + this.state.layer + " (wkb_geometry,user_id) VALUES " ;

                                            //console.log(quary,quary2)
                                            let valuesGeometry = ''
                                            for ( let i = 0 ; i < quarylist2.length;i++) {
                                                valuesGeometry = valuesGeometry + " " +quarylist2[i]
                                            }

                                            valuesGeometry = valuesGeometry.substring(0, valuesGeometry.length - 1) ;
                                            //console.log(quary2)
                                            axios.post(urlBackend+'/postQuery', {query: {type:"insert_from_geojson_geometry",select_type:"insert_geometry",select_params:{
                                                        layer:this.state.layer,
                                                        rows:rowGeometry,
                                                        values:valuesGeometry
                                                    }}})
                                                .then((res) => {
                                                    let valuesInfos ='';
                                                    let insertPermissions = ''

                                                    for ( let i = 0 ; i < quarylist.length;i++) {
                                                        valuesInfos  = valuesInfos  + " " +quarylist[i] +"," +res.data[i].qc_id + "),"
                                                    }
                                                    let permissions = [];

                                                        for (let i=0; i< this.state.groupPermArrayBasic.length;i++){
                                                            //console.log(this.state.groupPermArrayBasic[i] === null , i, typeof this.state.groupPermArrayBasic[i].permissions,id,this.state.groupPermArray[i].id)
                                                            if ( this.state.groupPermArrayBasic[i].permissions === null && this.state.groupPermArray[i].permissions !== null){
                                                                //insert
                                                                let permissionsString = "insert into acl (f_acl_group_id, f_object_id, f_layer_id, f_acl_perm_type_id) values "
                                                                for ( let k = 0 ; k <res.data.length; k++){
                                                                    let permCode = null;
                                                                    if ( this.state.groupPermArray[i].permissions === 'read')
                                                                        permCode = 1;
                                                                    if ( this.state.groupPermArray[i].permissions === 'change')
                                                                        permCode = 2;
                                                                    permissionsString =permissionsString+ "("+this.state.groupPermArray[i].id+" ," + res.data[k].qc_id+", ( select f_layer_id from layer where f_layer_code = '"+this.state.layer.toLowerCase()+"' ) ,"+permCode+" ) ,"
                                                                }
                                                                permissions.push({string:permissionsString.substring(0, permissionsString.length - 1)})
                                                                //let PermQuary = {type:"insert_Groups_Permission",select_type:"insert_Groups_Permission",select_params:{object_id:id,group_id:this.state.groupPermArray[i].id}}
                                                            }
                                                        }
                                                    let outcrop_query = 'insert into geo_objects_outcrop (geo_objects_id,universal_dict_id,author_id) values ';
                                                    let object_type_query = 'insert into geo_objects_object_type (geo_objects_id,universal_dict_id,author_id) values ';
                                                    let profiles_query = 'insert into protected_areas_profile (f_protected_areas_id,profile_id) values ';
                                                    for ( let k = 0 ; k <res.data.length;k++){
                                                        console.log( outcrop.lenght,object_type.lenght,profiles.length)
                                                        if (outcrop.lenght > 0){
                                                            for (let j = 0 ;j< outcrop[k].length;j++){
                                                                outcrop_query = outcrop_query + '( '+res.data[k].qc_id+',( select universal_dict_id from universal_dict \n' +
                                                                    'where dict_code = \'outcrop\' \n' +
                                                                    'AND ( case when NOT \''+outcrop[k][j]+'\' ~ \'^\\d+(\\.\\d+)?$\' \n' +
                                                                    '\t then lower(name_ru) = lower(\''+outcrop[k][j]+'\') \n' +
                                                                    '\t else universal_dict_id = NULLIF(regexp_replace(\''+outcrop[k][j]+'\', \'\\D\',\'\',\'g\'),\'\')::int end ) limit 1), '+Cookies.get("id")+' ),'
                                                            }
                                                            permissions.push({string:outcrop_query.substring(0, outcrop_query.length - 1)})
                                                        }
                                                        if (object_type.lenght > 0){
                                                            for (let j = 0 ;j< object_type[k].length;j++){
                                                                object_type_query = object_type_query + '( '+res.data[k].qc_id+',( select universal_dict_id from universal_dict \n' +
                                                                    'where dict_code = \'object_type\' \n' +
                                                                    'AND ( case when NOT \''+object_type[k][j]+'\' ~ \'^\\d+(\\.\\d+)?$\' \n' +
                                                                    '\t then lower(name_ru) = lower(\''+object_type[k][j]+'\') \n' +
                                                                    '\t else universal_dict_id = NULLIF(regexp_replace(\''+object_type[k][j]+'\', \'\\D\',\'\',\'g\'),\'\')::int end ) limit 1), '+Cookies.get("id")+' ),'
                                                            }
                                                            permissions.push({string:object_type_query.substring(0, object_type_query.length - 1)})
                                                        }
                                                        if (profiles.lenght > 0){
                                                            for (let j = 0 ;j< profiles[k].length;j++){
                                                                profiles = profiles + '( '+res.data[k].qc_id+',( select code from universal_dict \n' +
                                                                    'where dict_code = \'protected_area_profile\' \n' +
                                                                    'AND ( case when NOT \''+profiles[k][j]+'\' ~ \'^\\d+(\\.\\d+)?$\' \n' +
                                                                    '\t then lower(name_ru) = lower(\''+profiles[k][j]+'\') \n' +
                                                                    '\t else universal_dict_id = NULLIF(regexp_replace(\''+profiles[k][j]+'\', \'\\D\',\'\',\'g\'),\'\')::int end ) limit 1) ),'
                                                            }
                                                            permissions.push({string:profiles_query.substring(0, profiles_query.length - 1)})
                                                        }
                                                    }
                                                    row2 = row2.replace(',object_type,outcrop','')
                                                    row2 = row2.replace(',protected_area_profile_id','')
                                                    row2 = row2.replace('field_location','location')
                                                    row2 = row2.replace('field_terrain','terrain')
                                                    valuesInfos  = valuesInfos.substring(0, valuesInfos.length - 1) ;
                                                        console.log(outcrop,object_type,res.data)
                                                    //console.log(quary)
                                                    axios.post(urlBackend+'/postQuery', {query: {type:"insert_from_geojson_infos",select_type:"insert_all_transactions",select_params:{
                                                                layer:this.state.layer,
                                                                rows:row2,
                                                                values:valuesInfos,
                                                                permissions:permissions,
                                                            }}})
                                                        .then((res2) => {
                                                            //console.log(res)
                                                            this.props.alert("success","Geojson Successfully uploaded to DB.")
                                                            this.cancel()
                                                        }).catch((error) => {
                                                            let listID = '{';
                                                            for (let i = 0; i < res.data.length; i++) {
                                                                listID =listID + res.data[i].qc_id + ','
                                                            }
                                                            listID = listID.substring(0, listID.length - 1) + '}'
                                                        axios.post(urlBackend+'/postQuery', {query: {type:"insert_from_geojson_geometry",select_type:"delete_geometry",select_params:{
                                                                    layer:this.state.layer,
                                                                    ids:listID,
                                                                    layer_id:this.state.layer+'_id'
                                                                }}})
                                                            .then((res) => {})
                                                            .catch((error) => {console.log(error);})
                                                        console.log(listID)
                                                        console.log(error)
                                                        this.props.alert("error","Error while uploading Geojson to DB.")
                                                        this.cancel()
                                                    });
                                                }).catch((error) => {
                                                console.log(error)
                                                this.props.alert("error","Error while uploading Geojson to DB.")
                                                this.cancel()
                                            });
                                        }}
                                        primary
                                        size='small'
                                    >
                                        <Icon name='plus' /> Add to DataBase
                                    </Button>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </div>
            </div>
        )}

}

export default LoaderToDB;