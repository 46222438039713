import React from "react";
import {Button, Dropdown, Icon, Input, Label} from "semantic-ui-react";
import axios from "axios";
import Cookies from "js-cookie";
import Slide from "@mui/material/Slide";
import {age_period2 as age_period, age_word, map_type_word} from "../VariableInitialize"
const config = require('../config');
const hostBackend = config.HOSTBackend;
const portBackend = config.PORTBackend;
const protocolBackend = config.ProtocolBackend;
const prefixBackend = config.PrefixBackend;
var urlBackend;
if (portBackend === "" || portBackend === undefined){
    urlBackend=protocolBackend+'://'+hostBackend+prefixBackend;
}
else{
    urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
class UpdateGeoTiff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editObjectID:null,
            display:"none",
            publicationType:"",
            publicationTags:[],
            url:"",
            type:"",
            name:"",
            age_from:"",
            age_to:"",
            mapType:"",
            tags:[],
            author:"",
            doi:"",
            year:null,
            publicationError:"",
            PublicationFiles:null,
            addDisplay:"none",
            publicationText:"Files to Upload: ",
            exist:false,
            open:false,
            fileName:"",
        }
    };
    updateForm() {
        //this.props.editTags
        //this.props.editType
        //for (let i = 0; i < this.props.word.length; i++){
        //    if ( this.props.word[i].text === this.props.editType)
        //        type_id = this.props.word[i].value;
        //}
        console.log(this.props.editID,this.props.editPositionTop,this.props.editUrl,this.props.type,this.props.name,this.props.age_from,this.props.age_to)
        this.setState({
            editObjectID:this.props.editID,
            url:this.props.editUrl,
            type:this.props.type,
            name:this.props.name,
            age_from:this.props.age_from,
            age_to:this.props.age_to,
            edit_position:this.props.editPositionTop,
        })

    }
    componentDidUpdate(prevProps) {
        console.log(this.props.editID,prevProps.editID)
        if(this.props.editID!== prevProps.editID) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
        {
            this.updateForm();
        }
    }
    render() {
        const { t } = this.props;

        //let infos = document.getElementById('infos')
        //console.log(infos.body.scrollTop)
        let display = "none";
        if ( this.props.display)
            display = "block";
        return(
            <div style={{display:this.props.display,position: 'absolute',backgroundColor:"#FFFFFF",border: "thick double #000000", right:"3px", top:this.props.editPositionTop+"px", zIndex:'99999999'}} >
                <Button basic size={'mini'} onClick={()=>this.props.close()} icon><i className="close icon"/></Button>
                <br/>
                {this.state.publicationError}
                <br/>
                <div>
                    <span >{"Имя:"}<Input value={this.state.name}  onChange={(e,val) =>{
                        this.setState({name:val.value})
                    }}/></span>
                    <br/>
                    <span >{"Тип:"}
                        <Dropdown
                            style={{zIndex:999999999,overflow: 'inherit'}}
                            options={map_type_word}
                            value={this.state.type}
                            onChange={(e,val) => {
                                this.setState({type:val.value})
                            }}
                            selection
                            placeholder={"Choose layer to insert"} /></span>
                    <br/>
                    <span >{"Возраст От:"}<Dropdown id={"ChronoDropDown1"}  value={this.state.age_from} options={age_period.reverse()}
                                                    onChange={(e,val) =>{

                                                        //let res = val.value.split(" ");
                                                       // console.log(val,res[1])
                                                       // let obj_value = parseFloat(res[0]);
                                                        this.setState({age_from:val.value});
                    }}
                                                    fluid selection placeholder="Выбирите начальный период"
                    /></span>
                    <br/>
                    <span >{"Возраст До:"}<Dropdown id={"ChronoDropDown2"} value={this.state.age_to} options={age_period} onChange={(e,val) =>{
                                                        //let res = val.value.split(" ");
                                                        // let obj_value = parseFloat(res[0]);
                                                        this.setState({age_to:val.value});
                    }}
                                                    fluid selection placeholder="Выбирите конечный период"
                    /></span>
                    <br/>
                    <span >{"Ссылка:"}<Input value={this.state.url}  onChange={(e,val) =>{
                        this.setState({url:val.value})
                    }}/></span>
                    <br/>
                </div>
                <Button basic onClick={()=>{
                    if ( this.state.name ){
                        const dataform = new FormData();
                        console.log(this.state.PublicationFiles);
                        //console.log(dataform,this.state.selectedFile);
                        console.log(dataform)
                        let name = this.state.name
                        let age_to = this.state.age_to
                        let age_from = this.state.age_from
                        let url = this.state.url
                        let type = this.state.type
                        let that = this;
                        console.log(name,age_from,age_to,url,type,this.props.editID)
                        axios.post(urlBackend+'/postQuery', {query:{
                                type:"update_geotiff",
                                select_type:"update_geotiff",
                                select_params:{
                                    name: this.state.name ,
                                    url: this.state.url ,
                                    type:this.state.type  ,
                                    age_from:this.state.age_from,
                                    age_to:this.state.age_to,
                                    id: this.props.editID,}}})
                            .then((res) => {
                                console.log(res)
                                this.props.handleRedo()
                                this.props.close()
                            })
                            .catch((error) => {console.log(error)})



                        //this.setState({url:urlBackend+'/displayimages?file='+res.data.filename});

                    }else{
                        this.setState({publicationError:"Fill required fields"})
                    }
                }}>{"Save"}</Button>
            </div>
        )}}
export default UpdateGeoTiff;
