import ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';
import React, {Component} from 'react';
import {BrowserRouter as Router,Route,Routes} from "react-router-dom";
import MapGL,{ Source, Layer, FeatureState,NavigationControl,ScaleControl,Image } from '@urbica/react-map-gl';
import LoadingScreen from './Components/LoadingScreen'
import { withTranslation } from 'react-i18next';
import { hydrateRoot } from 'react-dom/client';
import i18n from "./i18n";
import { Threebox } from 'threebox-plugin';
import 'mapbox-gl/dist/mapbox-gl.css';
import Papa from "papaparse";
import $ from "jquery";
import { RulerControl } from 'mapbox-gl-controls';
import CookieConsent from "react-cookie-consent";
import {co2_emissions_min,co2_emissions_max,only_my} from "./VariableInitialize"
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import Gradient from "javascript-color-gradient";
import Draggable from 'react-draggable';
import "react-datepicker/dist/react-datepicker.css";
import "./App.css";
import {BrowserView, MobileView, isMobile, isBrowser} from "react-device-detect";
import * as turf from '@turf/turf'
import FilterRangeSlider from "./Components/FilterRangeSlider";
import Draw from '@urbica/react-map-gl-draw';
import Map, {FullscreenControl, GeolocateControl, NavigationControl as NavigationControlNew, ScaleControl as ScaleControlNew, UseControl} from 'react-map-gl/maplibre';
import WelcomeMessage from "./Components/WelcomeMessage";
import WelcomeGuide from "./Components/WelcomeGuide";
import Activation from "./Components/Activation";
import AlertMessage from "./Components/AlertMessage";
import GeoReference from "./Components/GeoReference";
import GeoTiffLoader from "./Components/GeoTiffLoader";
import FeatureInfos from "./Components/FeatureInfos";
import WikimapiaApi from "./Components/WikimapiaApi";
import Help from "./Components/Help";
import LoaderToDB from "./Components/LoaderToDB";
import Profile from "./Components/Profile";
import PointCreate from "./Components/PointCreate";
import FitLayer from "./Components/FitLayer";
import FeaturePopup from "./Components/FeauturePopup";
import "./styles.css";
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import {  Button, List,Input,Label,Checkbox,Icon,Popup } from 'semantic-ui-react'
import {getUserAgent, meters2degress, normalize, contains, getUniqueFeatures, arrayRemove} from './functions';
import axios from 'axios';
import AppMenu, {ImageDisplay, ShapeDisplay, WikiDisplay,LoginDisplay,RegisterDisplay,LayerDisplay,SearchDisplay,FilterDisplay,AboutDisplay} from "./Components/appMenu";
import Singin from "./Components/singin";
import Cookies from 'js-cookie'
import gif from "./images/ES_3857(2).png"
import 'semantic-ui-css/semantic.min.css';
import Warning from "./Components/Warning";
import FilterItemList from "./Components/FilterItemList";
import LayerMenu from "./Components/LayerMenu";
import UpdateGeometry from "./Components/UpdateGeometry";
import Reserves from "./Components/Reserves";
import Kern from "./Components/Kern";
import LasTool from "./Components/LasTool";
import MapReserves from "./Components/MapReserves";
import Dropzone from "react-dropzone";
import FloatWindow from "./Components/FloatWindow";
import LibraryTool from "./Components/LibraryTool";
import MyGeoMap from "./Components/MyGeoMap";
import LoadFeatureOnStart from "./Components/LoadFeatureOnStart";
import LoadLayerOnStart from "./Components/LoadLayerOnStart";
import LoadOnStart from "./Components/LoadOnStart";
import LoadInstrumentOnStart from "./Components/LoadInstrumentOnStart";
import LoadLoginOnStart from "./Components/LoadLoginOnStart";
import LoadLayerMenuOnStart from "./Components/LoadLayerMenuOnStart";
import LoadProfileOnStart from "./Components/LoadProfileOnStart";
const GeoReferenceTranlate = withTranslation()(GeoReference)
const ReservesTranslate = withTranslation()(Reserves)

const ver = "0.5.2";
const { NominatimJS } = require('@owsas/nominatim-js');
    var routerInfosTrigger = false;
var image = false;
var browser = '';
var lat,lng;
const styles = require('./styles2');
var index =0;
var authorized = Cookies.get("id") !== undefined;
var config = require('./config');
const hostMartin = config.HOSTMartin;
const hostBackend = config.HOSTBackend;
const portBackend = config.PORTBackend;
const portMartin = config.PORTMartin;
const regURL = config.regURL;
const formatMartin = config.FormatMartin
const protocolMartin = config.ProtocolMartin;
const protocolBackend = config.ProtocolBackend;
const prefixMartin = config.PrefixMartin;
const prefixBackend = config.PrefixBackend;
//const geoReferenceUrl = config.georefReproject;
const georefApi = config.georefApi;
const georefReproject = config.georefReproject;
var urlMartin;

if (portMartin=== "" || portMartin === undefined)
    urlMartin = protocolMartin+'://'+hostMartin+prefixMartin;
else
    urlMartin = protocolMartin+'://'+hostMartin+':'+portMartin+prefixMartin;

var urlBackend;
if (portBackend === "" || portBackend === undefined)
    urlBackend = protocolBackend+'://'+hostBackend+prefixBackend;
else
    urlBackend = protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;

if (localStorage.getItem('ver') !== ver) {
    localStorage.removeItem('Data1');
    localStorage.removeItem('Data2');
    localStorage.removeItem('presetData');
    localStorage.clear();
}
function setRouterInfosTrigger (state) {
    routerInfosTrigger = state
}
var data = (localStorage.getItem('Data1') === null) ?
    [
    {name:"Basins",data:urlMartin+'/public.basinstest/{z}/{x}/{y}'+formatMartin,color:['get','color'],id:1,beforeid:2,visibility:"none",sourcelayer:'public.basinstest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null ,transparent:false,source:'public.basins',layers:[],colordefault:['get','color'],popup:true},
    {name:"GeologicMap",data:urlMartin+'/public.geologicmaptest/{z}/{x}/{y}'+formatMartin,color:['get','color'],id:2,beforeid:3,visibility:"none",sourcelayer:'public.geologicmaptest',opacity:0.8,type:'fill',text:false,border:['get','border_color'],hover:false,paint:null,transparent:false,source:'public.geologicmap',layers:[],colordefault:['get','color'],popup:true},
    {name:"TectonicMap",data:urlMartin+'/public.tectonicmaptest/{z}/{x}/{y}'+formatMartin,color:"#D2691E",id:3,beforeid:4,visibility:"none",sourcelayer:'public.tectonicmaptest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.tectonicmap',layers:[],colordefault:"#D2691E",popup:true},
    {name:"SubBasins",data:urlMartin+'/public.subbasinstest/{z}/{x}/{y}'+formatMartin,color:"#FFFFFF",id:4,beforeid:5,visibility:"none",sourcelayer:'public.subbasinstest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.subbasins',layers:[],colordefault:"#FFFFFF",popup:true},
    {name:"Regions",data:urlMartin+'/public.regionstest/{z}/{x}/{y}'+formatMartin,color:"#00218B",id:5,beforeid:6,visibility:"none",sourcelayer:'public.regionstest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:true,source:'public.regions',layers:[],colordefault:"#00218B",popup:true},
        {name:"co2_emissions",data:urlMartin+'/public.co2_emissionstest/{z}/{x}/{y}'+formatMartin,color:['get','color'],id:5,beforeid:6,visibility:"none",sourcelayer:'public.co2_emissionstest',opacity:0.8,type:'circle',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.co2_emissionstest',layers:[],colordefault:['get','color'],popup:true},
        {name:"co2_world_sources",data:urlMartin+'/public.co2_world_sources/{z}/{x}/{y}'+formatMartin,color:['get','color'],id:5,beforeid:6,visibility:"none",sourcelayer:'public.co2_world_sources',opacity:0.8,type:'circle',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.co2_world_sources',layers:[],colordefault:['get','color'],popup:true},
        {name:"infra_obj",data:urlMartin+'/public.infra_obj/{z}/{x}/{y}'+formatMartin,color:['get','color'],id:5,beforeid:6,visibility:"none",sourcelayer:'public.infra_obj',opacity:0.8,type:'circle',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.infra_obj',layers:[],colordefault:['get','color'],popup:true},

        {name:"MSU_Branches",data:urlMartin+'/public.msu_branchestest/{z}/{x}/{y}'+formatMartin,color:"#000000",id:5,beforeid:6,visibility:"none",sourcelayer:'public.msu_branchestest',opacity:0.8,type:'circle',text:false,border:"#000000",hover:false,paint:null,transparent:true,source:'public.msu_branchestest',layers:[],colordefault:"#00218B",popup:true},
        {name:"Licenses",data:urlMartin+'/public.licensestest/{z}/{x}/{y}'+formatMartin,color:['get','color'],id:6,beforeid:7,visibility:"none",sourcelayer:'public.licensestest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.licenses',layers:[],colordefault:['get','color'],popup:true},
    {name:"Fields",data:urlMartin+'/public.fieldstest/{z}/{x}/{y}'+formatMartin,color:['get','color'],id:7,beforeid:8,visibility:"none",sourcelayer:'public.fieldstest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.fields',layers:[],colordefault:['get','color'],popup:true},
    {name:"GeologicObjects",data:urlMartin+'/public.geoobjecttest/{z}/{x}/{y}'+formatMartin,color:"#00218B",id:8,beforeid:9,visibility:"none",sourcelayer:'public.geoobjecttest',opacity:0.8,type:'circle',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.geo_objects',layers:[],colordefault:"#00218B",popup:true},
        {name:"Seismics",data:urlMartin+'/public.seismicstest/{z}/{x}/{y}'+formatMartin,color:"#000000",id:7,beforeid:8,visibility:"none",sourcelayer:'public.seismicstest',opacity:0.8,type:'line',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.seismics',layers:[],colordefault:"#000000",popup:true},
        {name:"Wells",data:urlMartin+'/public.wellstest/{z}/{x}/{y}'+formatMartin,color:"#000000",id:8,beforeid:9,visibility:"none",sourcelayer:'public.wellstest',opacity:0.8,type:'circle',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.wells',layers:[],colordefault:"#000000",popup:true},
    {name:"ProtectedAreas",data:urlMartin+'/public.protectedareastest/{z}/{x}/{y}'+formatMartin,color:"#cddcad",id:9,beforeid:10,visibility:"none",sourcelayer:'public.protectedareastest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.protectedareastest',layers:[],colordefault:"#cddcad",popup:true},
        {name:"Info",data:'https://openinframap.org/tiles/{z}/{x}/{y}.pbf',color:['get','color'],id:9,beforeid:10,visibility:"none",sourcelayer:'public.test',opacity:0.8,type:'multilayer',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.b',layers:
            [{idlayer: "power_line",color:"#ff8c00",size:1,type:"line"},
                {idlayer: "power_tower",color:"#ff8c00",size:1,type:"circle"},
                {idlayer: "power_substation",color:"#cd6600",size:1,type:"line"},
                {idlayer: "power_substation_point",color:"#cd6600",size:1,type:"circle"},
                {idlayer: "power_plant",color:"#c9713b",size:1,type:"line"},
                {idlayer: "power_plant_point",color:"#c9713b",size:1,type:"circle"},
                {idlayer: "power_generator",color:"#446b75",size:1,type:"circle"},
                {idlayer: "power_generator_area",color:"#446b75",size:1,type:"area"},
                {idlayer: "power_heatmap_solar",color:"#593872",size:0.5,type:"circle"},
                {idlayer: "power_transformer",color:"#ba9bac",size:1,type:"circle"},
                {idlayer: "power_compensator",color:"#c99e66",size:1,type:"circle"},
                {idlayer: "power_switch",color:"#5b6c60",size:1,type:"circle"},
                {idlayer: "telecoms_communication_line",color:"#bf91ff",size:1,type:"line"},
                {idlayer: "telecoms_data_center",color:"#bf91ff",size:1,type:"area"},
                {idlayer: "telecoms_mast",color:"#9874cc",size:1,type:"circle"},
                {idlayer: "petroleum_pipeline",color:"#006E00",size:2,type:"line"},
                {idlayer: "petroleum_well",color:"#313036",size:2,type:"circle"},
                {idlayer: "petroleum_site",color:"#eee6b8",size:2,type:"circle"},
                {idlayer: "water_pipeline",color:"#00ceff",size:2,type:"line"}]},
    {name:"GoogleSat",data:'https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',color:"#FFFFFF",id:9,beforeid:10,visibility:"none",sourcelayer:'public.i',opacity:0.8,type:'raster',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.i',layers:[],colordefault:"",popup:true},
    {name:"Blackmarble",data:'https://s2maps-tiles.eu/wms?bbox={bbox-epsg-3857}&format=image/jpeg&service=WMS&version=1.1.1&STYLES=default&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=blackmarble_3857',color:"#FFFFFF",id:9,beforeid:10,visibility:"none",sourcelayer:'public.i',opacity:0.8,type:'wms',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.i',layers:[],colordefault:"",popup:true},
    {name:"BingAerial",data:'https://ecn.t3.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=1',color:"#FFFFFF",id:9,beforeid:10,visibility:"none",sourcelayer:'public.i',opacity:0.8,type:'raster',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.i',layers:[],colordefault:"",popup:true},
    {name:"ArcGISTopo",data:'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',color:"#FFFFFF",id:9,beforeid:10,visibility:"none",sourcelayer:'public.i',opacity:0.8,type:'raster',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.i',layers:[],colordefault:"",popup:true},
    ] : JSON.parse(localStorage.getItem('Data1'));
var data2 = (localStorage.getItem('Data2') === null) ?
     [
    {name:"BasinsText",data:urlMartin+'/public.mvtbasinstext/{z}/{x}/{y}'+formatMartin,color:"#00218B",id:20,beforeid:false,visibility:"visible",sourcelayer:'public.mvtbasinstext',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
    {name:"GeologicMapText",data:urlMartin+'/public.mvtgeologicmaptext/{z}/{x}/{y}'+formatMartin,color:"#00218B",id:21,beforeid:false,visibility:"visible",sourcelayer:'public.mvtgeologicmaptext',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
    {name:"TectonicMapText",data:urlMartin+'/public.mvttectonicmaptext/{z}/{x}/{y}'+formatMartin,color:"#00218B",id:22,beforeid:false,visibility:"visible",sourcelayer:'public.mvttectonicmaptext',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
    {name:"SubBasinsText",data:urlMartin+'/public.mvtsubbasinstext/{z}/{x}/{y}'+formatMartin,color:"#00218B",id:23,beforeid:false,visibility:"visible",sourcelayer:'public.mvtsubbasinstext',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
    {name:"RegionsText",data:urlMartin+'/public.regionscenter/{z}/{x}/{y}'+formatMartin,color:"#00218B",id:24,beforeid:false,visibility:"visible",sourcelayer:'public.regionscenter',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
    {name:"MSU_BranchesText",data:urlMartin+'/public.mvtmsu_branchestext/{z}/{x}/{y}'+formatMartin,color:"#00218B",id:36,beforeid:false,visibility:"visible",sourcelayer:'public.mvtmsu_branchestext',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
    {name:"co2_emissionsText",data:urlMartin+'/public.mvtco2_emissionstext/{z}/{x}/{y}'+formatMartin,color:"#00218B",id:37,beforeid:false,visibility:"visible",sourcelayer:'public.mvtco2_emissionstext',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
         {name:"infra_objText",data:urlMartin+'/public.mvtinfra_objtext/{z}/{x}/{y}'+formatMartin,color:"#00218B",id:37,beforeid:false,visibility:"visible",sourcelayer:'public.mvtinfra_objtext',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
         {name:"co2_world_sourcesText",data:urlMartin+'/public.mvtco2_world_sources_text/{z}/{x}/{y}'+formatMartin,color:"#00218B",id:38,beforeid:false,visibility:"visible",sourcelayer:'public.mvtco2_world_sources_text',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
    {name:"LicensesText",data:urlMartin+'/public.mvtlicensestext/{z}/{x}/{y}'+formatMartin,color:"#00218B",id:25,beforeid:false,visibility:"visible",sourcelayer:'public.mvtlicensestext',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
    {name:"SeismicsText",data:urlMartin+'/public.mvtseismicstext/{z}/{x}/{y}'+formatMartin,color:"#00218B",id:35,beforeid:false,visibility:"visible",sourcelayer:'public.mvtseismicstext',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
    {name:"WellsText",data:urlMartin+'/public.mvtwellstext/{z}/{x}/{y}'+formatMartin,color:"#00218B",id:33,beforeid:false,visibility:"visible",sourcelayer:'public.mvtwellstext',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
    {name:"ProtectedAreasText",data:urlMartin+'/public.mvtprotectedareastext/{z}/{x}/{y}'+formatMartin,color:"#00218b",id:34,beforeid:false,visibility:"visible",sourcelayer:'public.mvtprotectedareastext',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
    {name:"FieldsText",data:urlMartin+'/public.mvtfieldstext/{z}/{x}/{y}'+formatMartin,color:"#00218B",id:26,beforeid:false,visibility:"visible",sourcelayer:'public.mvtfieldstext',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
    {name:"GeologicObjectsText",data:urlMartin+'/public.mvtgeologicobjectstext/{z}/{x}/{y}'+formatMartin,color:"#00218B",id:27,beforeid:false,visibility:"visible",sourcelayer:'public.mvtgeologicobjectstext',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
    {name:"InfoText",data:urlMartin+'/public.regionscenter/{z}/{x}/{y}.pbf',color:"#00218B",id:28,beforeid:false,visibility:"none",sourcelayer:'public.regionscenter',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
    {name:"GoogleSatText",data:urlMartin+'/public.regionscenter/{z}/{x}/{y}'+formatMartin,color:"#00218B",id:29,beforeid:false,visibility:"none",sourcelayer:'public.regionscenter',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
    {name:"BlackmarbleText",data:urlMartin+'/public.regionscenter/{z}/{x}/{y}'+formatMartin,color:"#00218B",id:30,beforeid:false,visibility:"none",sourcelayer:'public.regionscenter',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
    {name:"BingAerialText",data:urlMartin+'/public.regionscenter/{z}/{x}/{y}'+formatMartin,color:"#00218B",id:31,beforeid:false,visibility:"none",sourcelayer:'public.regionscenter',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
    {name:"ArcGISTopoText",data:urlMartin+'/public.regionscenter/{z}/{x}/{y}'+formatMartin,color:"#00218B",id:32,beforeid:false,visibility:"none",sourcelayer:'public.regionscenter',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
] : JSON.parse(localStorage.getItem('Data2'))
var presetdata = (localStorage.getItem('presetData') === null) ?
 [
    {name:"Info",data:'https://openinframap.org/tiles/{z}/{x}/{y}.pbf',color:['get','color'],id:1,beforeid:2,visibility:"none",sourcelayer:'public.test',opacity:0.8,type:'multilayer',text:false,border:"#000000",hover:false,paint:null,transparent:false,layers:
            [{idlayer: "power_line",color:"#ff8c00",size:1,type:"line"},
                {idlayer: "power_tower",color:"#ff8c00",size:1,type:"circle"},
                {idlayer: "power_substation",color:"#cd6600",size:1,type:"line"},
                {idlayer: "power_substation_point",color:"#cd6600",size:1,type:"circle"},
                {idlayer: "power_plant",color:"#c9713b",size:1,type:"line"},
                {idlayer: "power_plant_point",color:"#c9713b",size:1,type:"circle"},
                {idlayer: "power_generator",color:"#446b75",size:1,type:"circle"},
                {idlayer: "power_generator_area",color:"#446b75",size:1,type:"area"},
                {idlayer: "power_heatmap_solar",color:"#593872",size:0.5,type:"circle"},
                {idlayer: "power_transformer",color:"#ba9bac",size:1,type:"circle"},
                {idlayer: "power_compensator",color:"#c99e66",size:1,type:"circle"},
                {idlayer: "power_switch",color:"#5b6c60",size:1,type:"circle"},
                {idlayer: "telecoms_communication_line",color:"#bf91ff",size:1,type:"line"},
                {idlayer: "telecoms_data_center",color:"#bf91ff",size:1,type:"area"},
                {idlayer: "telecoms_mast",color:"#9874cc",size:1,type:"circle"},
                {idlayer: "petroleum_pipeline",color:"#006E00",size:2,type:"line"},
                {idlayer: "petroleum_well",color:"#313036",size:2,type:"circle"},
                {idlayer: "petroleum_site",color:"#eee6b8",size:2,type:"circle"},
                {idlayer: "water_pipeline",color:"#00ceff",size:2,type:"line"}]},
     {name:"GeologicObjects",data:urlMartin+'/public.geoobjecttest/{z}/{x}/{y}'+formatMartin,color:"#00218B",id:0,beforeid:1,visibility:"none",sourcelayer:'public.geoobjecttest',opacity:0.8,type:'circle',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.geo_objects',layers:[],colordefault:"#00218B",popup:true},
     {name:"Seismics",data:urlMartin+'/public.seismicstest/{z}/{x}/{y}'+formatMartin,color:"#000000",id:7,beforeid:8,visibility:"none",sourcelayer:'public.seismicstest',opacity:0.8,type:'line',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.wells',layers:[],colordefault:"#000000",popup:true},
     {name:"infra_obj",data:urlMartin+'/public.infra_obj/{z}/{x}/{y}'+formatMartin,color:['get','color'],id:5,beforeid:6,visibility:"none",sourcelayer:'public.infra_obj',opacity:0.8,type:'circle',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.infra_obj',layers:[],colordefault:['get','color'],popup:true},
     {name:"Wells",data:urlMartin+'/public.wellstest/{z}/{x}/{y}'+formatMartin,color:"#000000",id:7,beforeid:8,visibility:"none",sourcelayer:'public.wellstest',opacity:0.8,type:'circle',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.wells',layers:[],colordefault:"#000000",popup:true},
     {name:"co2_emissions",data:urlMartin+'/public.co2_emissionstest/{z}/{x}/{y}'+formatMartin,color:['get','color'],id:5,beforeid:6,visibility:"none",sourcelayer:'public.co2_emissionstest',opacity:0.8,type:'circle',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.co2_emissionstest',layers:[],colordefault:['get','color'],popup:true},
     {name:"co2_world_sources",data:urlMartin+'/public.co2_world_sources/{z}/{x}/{y}'+formatMartin,color:['get','color'],id:5,beforeid:6,visibility:"none",sourcelayer:'public.co2_world_sources',opacity:0.8,type:'circle',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.co2_world_sources',layers:[],colordefault:['get','color'],popup:true},
     {name:"Fields",data:urlMartin+'/public.fieldstest/{z}/{x}/{y}'+formatMartin,color:['get','color'],id:3,beforeid:4,visibility:"none",sourcelayer:'public.fieldstest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.fields',layers:[],colordefault:['get','color'],popup:true},
    {name:"Licenses",data:urlMartin+'/public.licensestest/{z}/{x}/{y}'+formatMartin,color:['get','color'],id:2,beforeid:3,visibility:"none",sourcelayer:'public.licensestest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.licenses',layers:[],colordefault:['get','color'],popup:true},
    {name:"TectonicMap",data:urlMartin+'/public.tectonicmaptest/{z}/{x}/{y}'+formatMartin,color:"#D2691E",id:3,beforeid:4,visibility:"none",sourcelayer:'public.tectonicmaptest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.tectonicmap',layers:[],colordefault:"#D2691E",popup:true},
    {name:"SubBasins",data:urlMartin+'/public.subbasinstest/{z}/{x}/{y}'+formatMartin,color:"#FFFFFF",id:4,beforeid:5,visibility:"none",sourcelayer:'public.subbasinstest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.subbasins',layers:[],colordefault:"#FFFFFF",popup:true},
    {name:"Basins",data:urlMartin+'/public.basinstest/{z}/{x}/{y}'+formatMartin,color:['get','color'],id:5,beforeid:6,visibility:"none",sourcelayer:'public.basinstest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.basins',layers:[],colordefault:['get','color'],popup:true},
    {name:"Regions",data:urlMartin+'/public.regionstest/{z}/{x}/{y}'+formatMartin,color:"#00218B",id:6,beforeid:7,visibility:"none",sourcelayer:'public.regionstest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:true,source:'public.regions',layers:[],colordefault:"#00218B",popup:true},
     {name:"MSU_Branches",data:urlMartin+'/public.msu_branchestest/{z}/{x}/{y}'+formatMartin,color:"#000000",id:5,beforeid:6,visibility:"none",sourcelayer:'public.msu_branchestest',opacity:0.8,type:'circle',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.msu_branchestest',layers:[],colordefault:"#000000",popup:true},
     {name:"GeologicMap",data:urlMartin+'/public.geologicmaptest/{z}/{x}/{y}'+formatMartin,color:['get','color'],id:7,beforeid:8,visibility:"none",sourcelayer:'public.geologicmaptest',opacity:0.8,type:'fill',text:false,border:['get','border_color'],hover:false,paint:null,transparent:false,source:'public.geologicmaptest',layers:[],colordefault:['get','color'],popup:true},
    {name:"ProtectedAreas",data:urlMartin+'/public.protectedareastest/{z}/{x}/{y}'+formatMartin,color:"#cddcad",id:8,beforeid:9,visibility:"none",sourcelayer:'public.protectedareastest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.protectedareastest',layers:[],colordefault:"#cddcad",popup:true},
    {name:"GoogleSat",data:'https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',color:"#FFFFFF",id:9,beforeid:10,visibility:"none",sourcelayer:'public.i',opacity:0.8,type:'raster',text:false,border:"#000000",hover:true,paint:null,transparent:false,source:'public.i',layers:[],colordefault:"",popup:true},
    {name:"Blackmarble",data:'https://s2maps-tiles.eu/wms?bbox={bbox-epsg-3857}&format=image/jpeg&service=WMS&version=1.1.1&STYLES=default&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=blackmarble_3857',color:"#FFFFFF",id:9,beforeid:10,visibility:"none",sourcelayer:'public.i',opacity:0.8,type:'wms',text:false,border:"#000000",hover:true,paint:null,transparent:false,source:'public.i',layers:[],colordefault:"",popup:true},
    {name:"BingAerial",data:'https://ecn.t3.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=1',color:"#FFFFFF",id:9,beforeid:10,visibility:"none",sourcelayer:'public.i',opacity:0.8,type:'raster',text:false,border:"#000000",hover:true,paint:null,source:'public.i',transparent:false,layers:[],colordefault:"",popup:true},
    {name:"ArcGISTopo",data:'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',color:"#FFFFFF",id:9,beforeid:10,visibility:"none",sourcelayer:'public.i',opacity:0.8,type:'raster',text:false,border:"#000000",hover:true,paint:null,source:'public.i',transparent:false,layers:[],colordefault:"",popup:true},
] : JSON.parse(localStorage.getItem('presetData'))
var dataname = [{index:0,name:"Basins"},{index:1,name:"GeologicMap"},{index:2,name:"TectonicMap"},{index:3,name:"SubBasins"},{index:4,name:"Regions"},{index:5,name:"Licenses"},{index:6,name:"Fields"},{index:7,name:"GeologicObjects"},{index:8,name:"Wells"},{index:9,name:"ProtectedAreas"},{index:10,name:"Seismics"},{index:11,name:"MSU_Branches"},{index:12,name:"co2_emissions"},{index:13,name:"co2_world_sources"}];
const explordataname = [{index:14,name:"Wells"},{inded:16,name:"Seismics"},{index:0,name:"Fields"},{index:5,name:"Licenses"},{index:3,name:"SubBasins"},{index:1,name:"Basins"}];
const geodataname = [{index:1,name:"GeologicObjects"},{index:4,name:"TectonicMap"},{index:8,name:"GeologicMap"}];
const infdataname = [{index:9,name:"Info"},{index:19,name:"infra_obj"}];
const regdataname = [{index:4,name:"Regions"},{index:5,name:"MSU_Branches"}];
const ecodataname = [{index:15,name:"ProtectedAreas"},{index:17,name:"co2_emissions"},{index:18,name:"co2_world_sources"}];
var userdataname = [];
const layoutdataname = [{index:10,name:"GoogleSat"},{index:11,name:"Blackmarble"},{index:12,name:"BingAerial"},{index:13,name:"ArcGISTopo"}];
var datasetn = 1;
const nodes = [{
    value: 'Платформ',
    label: 'Платформ',
    children: [
        { value: 'Внутриплатформенный (интракратонный)', label: 'Внутриплатформенный (интракратонный)',children:[
            {value:"Синеклизный",label:"Синеклизный"}, {value:"Рифтовый (Внутриплатформенный)",label:"Рифтовый (Внутриплатформенный)"}] },
        { value: 'Окраинно-платформенный (перикратонный)', label: 'Окраинно-платформенный (перикратонный)' ,children:[
            {value:"Перикратонно-орогенный",label:"Перикратонно-орогенный"}, {value:"Перикратонный",label:"Перикратонный"}] },
        { value: 'Пассивных окраин (перикратонно-океанический)', label: 'Пассивных окраин (перикратонно-океанический)' ,children:[
            {value:"Периокеанический",label:"Периокеанический"}, {value:"Рифтовый (Пассивных окраин)",label:"Рифтовый (Пассивных окраин)"}] },
    ],
    },
    {
        value: 'Подвижных поясов',
        label: 'Подвижных поясов',
        children: [
            { value: 'Островодужный', label: 'Островодужный' ,children:[
                    {value:"Преддуговой",label:"Преддуговой"}, {value:"Междуговой",label:"Междуговой"}, {value:"Задуговой",label:"Задуговой"}] },
            { value: 'Орогенный', label: 'Орогенный' ,children:[
                    {value:"Межконтинентально-орогенный",label:"Межконтинентально-орогенный"}, {value:"Периконтенентально-океанический орогенный",label:"Периконтенентально-океанический орогенный"}, {value:"Внутриконтенентально-орогенный",label:"Внутриконтенентально-орогенный"}, {value:"Периконтинентально-Орогенный",label:"Периконтинентально-Орогенный"}, {value:"Окраинно-континентально-орогенный",label:"Окраинно-континентально-орогенный"}] },
        ],
    }];

async function requast(quary){
    return axios.post(urlBackend+'/postQuery', {query:quary}).then(response => {let data = response.data;data = data.push({key:"",text:"------",value:""}); return response.data})
}
async function requast2(quary){
    return axios.post(urlBackend+'/postQuery', {query:quary}).then(response => {return response.data})
}
var tags_word;var world_source_reservoir;var world_source_storage;var world_source_status;var world_source_formation;var co2_subcategories_word;var co2_categories_word;var basin_class_word;var basin_subtype_word;var basin_type_word;var basin_fluid_word;var field_class_word;var signal_source_word;var seismic_type_word;var domain_word;var field_type_word;var well_type_word;var well_stock_word;var well_status_word;var object_type_word;var outcrop_word;var licenses_type_word;var development_stage_word;var confidence_degree_word;var licenses_status_word;var location_word;var discovery_method_word;var protected_areas_word;var terrain_word;var basin_age_word;var map_type_word;var publication_type_word;var age_from_word;var age_to_word ;
//;
var domain_word2;var co2_legend;var seismic_type_word2;var signal_source_word2;var basin_fluid_word2;var field_class_word2;var field_type_word2;var object_type_word2;var outcrop_word2;var confidence_degree_word2;var licenses_type_word2;var licenses_status_word2;var location_word2;var terrain_word2;var protected_areas_word2;var protected_areas_profile_word2;var discovery_method_word2;var basin_age_word2;var licensecolor ;
// check if dict was updated
axios.post(urlBackend+'/postQuery', {query:{type:"user_activity",select_type:"user_activity",select_params:{user_id:Cookies.get("id"),url:window.location.href}}}).then(result => {console.log("User_activity")}).catch(err =>{console.log(err)})
var update_dict=  axios.post(urlBackend+'/postQuery', {query:{type:"check_dict",select_type:"check_dict"}}).then(result => {
    console.log(result.data[0].relfilenode,localStorage.getItem('Dict_ver'));
    update_dict= result.data[0].relfilenode
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('tags_word')=== null)
        tags_word =requast({type:"get_publications_tags",select_type:"dropdown"}).then(result => {console.log(tags_word);localStorage.setItem('tags_word', JSON.stringify(result));tags_word= result});
    else
        tags_word =JSON.parse(localStorage.getItem('tags_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('basin_class_word')=== null)
        basin_class_word =requast({type:"basin_class",select_type:"dropdown"}).then(result => {console.log(basin_class_word);localStorage.setItem('basin_class_word', JSON.stringify(result));basin_class_word= result});
    else
        basin_class_word =JSON.parse(localStorage.getItem('basin_class_word'));
    console.log(basin_class_word)
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('basin_subtype_word')=== null)
        basin_subtype_word = requast({type:"basin_subtype",select_type:"dropdown"}).then(result => {localStorage.setItem('basin_subtype_word', JSON.stringify(result));basin_subtype_word= result});
    else
        basin_subtype_word =JSON.parse(localStorage.getItem('basin_subtype_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('basin_type_word')=== null)
        basin_type_word = requast({type:"basin_type",select_type:"dropdown"}).then(result => {localStorage.setItem('basin_type_word', JSON.stringify(result));basin_type_word= result});
    else
        basin_type_word =JSON.parse(localStorage.getItem('basin_type_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('basin_fluid_word')=== null)
        basin_fluid_word = requast({type:"basin_fluid_type",select_type:"dropdown"}).then(result => {localStorage.setItem('asin_fluid_word', JSON.stringify(result));basin_fluid_word= result});
    else
        basin_fluid_word =JSON.parse(localStorage.getItem('basin_fluid_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('field_class_word')=== null)
        field_class_word = requast({type:"universal_dict",select_type:"dropdown",select_params:"field_class"}).then(result => {localStorage.setItem('field_class_word', JSON.stringify(result));field_class_word= result});
    else
        field_class_word =JSON.parse(localStorage.getItem('field_class_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('signal_source_word')=== null)
        signal_source_word = requast( {type:"universal_dict",select_type:"dropdown",select_params:'seismic_signal_source'}).then(result => {localStorage.setItem('signal_source_word', JSON.stringify(result));signal_source_word= result});
    else
        signal_source_word =JSON.parse(localStorage.getItem('signal_source_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('seismic_type_word')=== null)
        seismic_type_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'seismic_type'}).then(result => {localStorage.setItem('seismic_type_word', JSON.stringify(result));seismic_type_word= result});
    else
        seismic_type_word =JSON.parse(localStorage.getItem('seismic_type_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('domain_word ')=== null)
        domain_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'seismic_domain'}).then(result => {localStorage.setItem('domain_word', JSON.stringify(result));domain_word= result});
    else
        domain_word  =JSON.parse(localStorage.getItem('domain_word '));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('field_type_word')=== null)
        field_type_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'field_type'}).then(result => {localStorage.setItem('field_type_word', JSON.stringify(result));field_type_word= result});
    else
        field_type_word =JSON.parse(localStorage.getItem('field_type_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('well_type_word')=== null)
        well_type_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'well_type'}).then(result => {localStorage.setItem('well_type_word', JSON.stringify(result));well_type_word= result});
    else
        well_type_word =JSON.parse(localStorage.getItem('well_type_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('well_stock_word')=== null)
        well_stock_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'well_stock'}).then(result => {localStorage.setItem('well_stock_word', JSON.stringify(result));well_stock_word= result});
    else
        well_stock_word =JSON.parse(localStorage.getItem('well_stock_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('well_status_word')=== null)
        well_status_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'well_status'}).then(result => {localStorage.setItem('well_status_word', JSON.stringify(result));well_status_word= result});
    else
        well_status_word =JSON.parse(localStorage.getItem('well_status_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('object_type_word')=== null)
        object_type_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'object_type'}).then(result => {localStorage.setItem('object_type_word', JSON.stringify(result));object_type_word= result});
    else
        object_type_word =JSON.parse(localStorage.getItem('object_type_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('outcrop_word')=== null)
        outcrop_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'outcrop'}).then(result => {localStorage.setItem('outcrop_word', JSON.stringify(result));outcrop_word= result});
    else
        outcrop_word =JSON.parse(localStorage.getItem('outcrop_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('licenses_type_word')=== null)
        licenses_type_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'license_type'}).then(result => {localStorage.setItem('licenses_type_word', JSON.stringify(result));licenses_type_word= result});
    else
        licenses_type_word =JSON.parse(localStorage.getItem('co2_categories'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('co2_categories')=== null)
        co2_categories_word = requast({type:"get_co2_emissions_values",select_type:"get_categories"}).then(result => {localStorage.setItem('co2_categories', JSON.stringify(result));co2_categories_word= result;co2_emissionsFilter[1].word = result;infra_objFilter[1].word = result});
    else
        co2_categories_word =JSON.parse(localStorage.getItem('co2_categories'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('co2_subcategories')=== null)
        co2_subcategories_word = requast({type:"get_co2_emissions_values",select_type:"get_subcategories"}).then(result => {localStorage.setItem('co2_subcategories', JSON.stringify(result));co2_subcategories_word= result;co2_emissionsFilter[2].word = result;infra_objFilter[2].word = result});
    else
        co2_subcategories_word =JSON.parse(localStorage.getItem('co2_subcategories'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('development_stage_word')=== null)
        development_stage_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'development_stage'}).then(result => {localStorage.setItem('development_stage_word', JSON.stringify(result));development_stage_word= result});
    else
        development_stage_word =JSON.parse(localStorage.getItem('development_stage_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('confidence_degree_word')=== null)
        confidence_degree_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'confidence_degree'}).then(result => {localStorage.setItem('confidence_degree_word', JSON.stringify(result));confidence_degree_word= result});
    else
        confidence_degree_word =JSON.parse(localStorage.getItem('confidence_degree_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('licenses_status_word')=== null)
        licenses_status_word = requast({ type:"universal_dict",select_type:"dropdown",select_params:'license_status'}).then(result => {localStorage.setItem('licenses_status_word', JSON.stringify(result));licenses_status_word= result});
    else
        licenses_status_word =JSON.parse(localStorage.getItem('licenses_status_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('location_word')=== null)
        location_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'field_location'}).then(result => {localStorage.setItem('location_word', JSON.stringify(result));location_word= result});
    else
        location_word =JSON.parse(localStorage.getItem('location_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('discovery_method_word')=== null)
        discovery_method_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'discovery_method'}).then(result => {localStorage.setItem('discovery_method_word', JSON.stringify(result));discovery_method_word= result});
    else
        discovery_method_word =JSON.parse(localStorage.getItem('discovery_method_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('protected_areas_word')=== null)
        protected_areas_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'protected_area'}).then(result => {localStorage.setItem('protected_areas_word', JSON.stringify(result));protected_areas_word= result});
    else
        protected_areas_word =JSON.parse(localStorage.getItem('protected_areas_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('terrain_word')=== null)
        terrain_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'field_terrain'}).then(result => {localStorage.setItem('terrain_word', JSON.stringify(result));terrain_word= result});
    else
        terrain_word =JSON.parse(localStorage.getItem('terrain_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('basin_age_word')=== null)
        basin_age_word = requast({type:"basin_age",select_type:"dropdown"}).then(result => {localStorage.setItem('basin_age_word', JSON.stringify(result));console.log(result);basin_age_word= result});
    else
        basin_age_word =JSON.parse(localStorage.getItem('basin_age_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('map_type_word')=== null)
        map_type_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'map_type'}).then(result => {localStorage.setItem('map_type_word', JSON.stringify(result));map_type_word= result});
    else
        map_type_word =JSON.parse(localStorage.getItem('map_type_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('publication_type_word')=== null)
        publication_type_word = requast({type:"universal_dict",select_type:"dropdown2",select_params:'publication_type'}).then(result => {localStorage.setItem('publication_type_word', JSON.stringify(result));publication_type_word= result});
    else
        publication_type_word =JSON.parse(localStorage.getItem('publication_type_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('age_from_word')=== null)
        age_from_word = requast({type:"chrono_strat",select_type:"dropdown",select_params:'age_from'}).then(result => {localStorage.setItem('age_from_word', JSON.stringify(result));age_from_word= result});
    else
        age_from_word =JSON.parse(localStorage.getItem('age_from_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('age_to_word')=== null)
        age_to_word = requast({type:"chrono_strat",select_type:"dropdown",select_params:'age_to'}).then(result => {localStorage.setItem('age_to_word', JSON.stringify(result));age_to_word= result});
    else
        age_to_word =JSON.parse(localStorage.getItem('age_to_word'));
        //
        //
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('domain_word2')=== null)
        domain_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'seismic_domain'}).then(result => {localStorage.setItem('domain_word2', JSON.stringify(result));domain_word2= result;SeismicsFilter[1].word = domain_word2 ;});
    else{
        domain_word2 =JSON.parse(localStorage.getItem('domain_word2'));
        SeismicsFilter[1].word =JSON.parse(localStorage.getItem('domain_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('seismic_type_word2')=== null)
        seismic_type_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'seismic_type'}).then(result => {localStorage.setItem('seismic_type_word2', JSON.stringify(result));seismic_type_word2= result;SeismicsFilter[2].word = seismic_type_word2 ;});
    else{
        seismic_type_word2 =JSON.parse(localStorage.getItem('seismic_type_word2'));
        SeismicsFilter[2].word =JSON.parse(localStorage.getItem('seismic_type_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('signal_source_word2')=== null)
        signal_source_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'seismic_signal_source'}).then(result => {localStorage.setItem('signal_source_word2', JSON.stringify(result));signal_source_word2= result;SeismicsFilter[3].word = signal_source_word2 ;});
    else{
        signal_source_word2 =JSON.parse(localStorage.getItem('signal_source_word2'));
        SeismicsFilter[3].word = JSON.parse(localStorage.getItem('signal_source_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('basin_fluid_word2')=== null)
        basin_fluid_word2 = requast2({type:"basin_fluid_type",select_type:"filter"}).then(result => {localStorage.setItem('basin_fluid_word2', JSON.stringify(result));basin_fluid_word2= result;BasinsFilter[5].word =basin_fluid_word2;SubBasinsFilter[5].word =basin_fluid_word2;});
    else{
        basin_fluid_word2 =JSON.parse(localStorage.getItem('basin_fluid_word2'));
        BasinsFilter[5].word =JSON.parse(localStorage.getItem('basin_fluid_word2'));
        SubBasinsFilter[5].word =JSON.parse(localStorage.getItem('basin_fluid_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('field_class_word2')=== null)
        field_class_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'field_class'}).then(result => {localStorage.setItem('field_class_word2', JSON.stringify(result));field_class_word2= result;FieldsFilter[1].word =field_class_word2;});
    else{
        field_class_word2 =JSON.parse(localStorage.getItem('field_class_word2'));
        FieldsFilter[1].word =JSON.parse(localStorage.getItem('field_class_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('field_type_word2')=== null)
        field_type_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'field_type'}).then(result => { console.log(result);localStorage.setItem('field_type_word2', JSON.stringify(result));field_type_word2= result;FieldsFilter[0].word =field_type_word2;});
    else{
        field_type_word2 =JSON.parse(localStorage.getItem('field_type_word2'));
        FieldsFilter[0].word = JSON.parse(localStorage.getItem('field_type_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('object_type_word2')=== null)
        object_type_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'object_type'}).then(result => {localStorage.setItem('object_type_word2', JSON.stringify(result));object_type_word2= result;GeologicObjectsFilter[1].word =object_type_word2;});
    else{
        object_type_word2 =JSON.parse(localStorage.getItem('object_type_word2'));
        GeologicObjectsFilter[1].word =JSON.parse(localStorage.getItem('object_type_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('outcrop_word2')=== null)
        outcrop_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'outcrop'}).then(result => {localStorage.setItem('outcrop_word2', JSON.stringify(result));outcrop_word2= result;GeologicObjectsFilter[2].word =outcrop_word2;});
    else{
        outcrop_word2 =JSON.parse(localStorage.getItem('outcrop_word2'));
        GeologicObjectsFilter[2].word =JSON.parse(localStorage.getItem('outcrop_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('confidence_degree_word2')=== null)
        confidence_degree_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'confidence_degree'}).then(result => {localStorage.setItem('confidence_degree_word2', JSON.stringify(result));confidence_degree_word2= result;LicensesFilter[8].word =confidence_degree_word2;});
    else{
        confidence_degree_word2 =JSON.parse(localStorage.getItem('confidence_degree_word2'));
        LicensesFilter[8].word =JSON.parse(localStorage.getItem('object_type_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('licenses_type_word2')=== null)
        licenses_type_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'license_type'}).then(result => {console.log(result);localStorage.setItem('licenses_type_word2', JSON.stringify(result));licenses_type_word2= result;LicensesFilter[4].word =licenses_type_word2;});
    else{
        licenses_type_word2 =JSON.parse(localStorage.getItem('licenses_type_word2'));
        console.log(licenses_type_word2);
        LicensesFilter[4].word =JSON.parse(localStorage.getItem('licenses_type_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('licenses_status_word2')=== null)
        var licenses_status_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'license_status'}).then(result => {localStorage.setItem('licenses_status_word2', JSON.stringify(result));licenses_status_word2= result;LicensesFilter[0].word =licenses_status_word2;});
    else{
        licenses_status_word2 =JSON.parse(localStorage.getItem('licenses_status_word2'));
        LicensesFilter[0].word =JSON.parse(localStorage.getItem('licenses_status_word2'));
    }

    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('location_word2')=== null)
        location_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'field_location'}).then(result => {localStorage.setItem('location_word2', JSON.stringify(result));location_word2= result;FieldsFilter[4].word =location_word2;});
    else{
        location_word2 =JSON.parse(localStorage.getItem('location_word2'));
        FieldsFilter[4].word =JSON.parse(localStorage.getItem('location_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('terrain_word2')=== null)
        terrain_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'field_terrain'}).then(result => {localStorage.setItem('terrain_word2', JSON.stringify(result));terrain_word2= result;FieldsFilter[5].word =terrain_word2;});
    else{
        terrain_word2 =JSON.parse(localStorage.getItem('terrain_word2'));
        FieldsFilter[5].word =JSON.parse(localStorage.getItem('terrain_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('protected_areas_word2')=== null)
        protected_areas_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'protected_area'}).then(result => {localStorage.setItem('protected_areas_word2', JSON.stringify(result));protected_areas_word2= result;ProtectedAreasFilter[1].word =protected_areas_word2;});
    else{
        protected_areas_word2 =JSON.parse(localStorage.getItem('protected_areas_word2'));
        ProtectedAreasFilter[1].word =JSON.parse(localStorage.getItem('protected_areas_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('protected_areas_profile_word2')=== null)
        protected_areas_profile_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'protected_area_profile_profile'}).then(result => {localStorage.setItem('protected_areas_profile_word2', JSON.stringify(result));protected_areas_profile_word2= result;ProtectedAreasFilter[2].word =protected_areas_profile_word2;});
    else{
        protected_areas_profile_word2 =JSON.parse(localStorage.getItem('protected_areas_profile_word2'));
        ProtectedAreasFilter[2].word =JSON.parse(localStorage.getItem('protected_areas_profile_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('discovery_method_word2')=== null)
        discovery_method_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'discovery_method'}).then(result => {localStorage.setItem('discovery_method_word2', JSON.stringify(result));discovery_method_word2= result;FieldsFilter[9].word =discovery_method_word2;});
    else{
        discovery_method_word2 =JSON.parse(localStorage.getItem('discovery_method_word2'));
        FieldsFilter[9].word =JSON.parse(localStorage.getItem('discovery_method_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('basin_age_word2')=== null)
        basin_age_word2 = requast2({type:"basin_age",select_type:"filter"}).then(result => {localStorage.setItem('basin_age_word2', JSON.stringify(result));basin_age_word2= result;BasinsFilter[3].word =basin_age_word2;SubBasinsFilter[3].word =basin_age_word2;});
    else{
        basin_age_word2 =JSON.parse(localStorage.getItem('basin_age_word2'));
        BasinsFilter[3].word =JSON.parse(localStorage.getItem('basin_age_word2'));
        SubBasinsFilter[3].word =JSON.parse(localStorage.getItem('basin_age_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('field_type_word2')=== null)
        field_type_word2 = requast2({type:"universal_dict",select_type:"color",select_params:'field_type'}).then(result => {result.push({name:null,color:null});localStorage.setItem('field_type_word2', JSON.stringify(result));field_type_word2= result;LegendLayer[2].data = field_type_word2 ;});
    else{
        field_type_word2 =JSON.parse(localStorage.getItem('field_type_word2'));
        FieldsFilter[0].word =JSON.parse(localStorage.getItem('discovery_method_word2'));
        LegendLayer[2].data =JSON.parse(localStorage.getItem('field_type_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('licensecolor')=== null)
        licensecolor = requast2({type:"universal_dict",select_type:"color",select_params:'license_status'}).then(result => {result.push({name:null,color:null});localStorage.setItem('licensecolor', JSON.stringify(result));licensecolor= result;LegendLayer[3].data = licensecolor ;});
    else{
        licensecolor =JSON.parse(localStorage.getItem('licensecolor'));
        LegendLayer[3].data =JSON.parse(localStorage.getItem('licensecolor'));
    }
    if(update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('co2_legend')===null)
        co2_legend = requast2({type:"get_co2_orgs_infos",select_type:"get_legend"}).then(result =>{console.log(result);localStorage.setItem('co2_legend', JSON.stringify(result));co2_legend=result;LegendLayer[6].data = co2_legend});
    else {
        co2_legend = JSON.parse(localStorage.getItem('co2_legend'));
        LegendLayer[6].data =JSON.parse(localStorage.getItem('co2_legend'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('world_source_storage')=== null)
        world_source_storage = requast2({type:"universal_dict",select_type:"filter",select_params:'storage_type'}).then(result => {localStorage.setItem('world_source_storage', JSON.stringify(result));world_source_storage= result;co2_world_sourcesFilter[2].word =world_source_storage;});
    else{
        world_source_storage =JSON.parse(localStorage.getItem('world_source_storage'));
        co2_world_sourcesFilter[2].word =JSON.parse(localStorage.getItem('world_source_storage'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('world_source_status')=== null)
        world_source_status = requast2({type:"universal_dict",select_type:"filter",select_params:'world_sources_status'}).then(result => {localStorage.setItem('world_source_status', JSON.stringify(result));world_source_status= result;co2_world_sourcesFilter[3].word =world_source_status;});
    else{
        world_source_status =JSON.parse(localStorage.getItem('world_source_status'));
        co2_world_sourcesFilter[3].word =JSON.parse(localStorage.getItem('world_source_status'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('world_source_formation')=== null)
        world_source_formation = requast2({type:"universal_dict",select_type:"filter",select_params:'world_source_formation'}).then(result => {localStorage.setItem('world_source_formation', JSON.stringify(result));world_source_formation= result;co2_world_sourcesFilter[4].word =world_source_formation;});
    else{
        world_source_formation =JSON.parse(localStorage.getItem('world_source_formation'));
        co2_world_sourcesFilter[4].word =JSON.parse(localStorage.getItem('world_source_formation'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('world_source_reservoir')=== null)
        world_source_reservoir = requast2({type:"litho_type",select_type:"filter",select_params:'world_source_reservoir'}).then(result => {localStorage.setItem('world_source_reservoir', JSON.stringify(result));world_source_reservoir= result;co2_world_sourcesFilter[5].word =world_source_reservoir;});
    else{
        world_source_reservoir =JSON.parse(localStorage.getItem('world_source_reservoir'));
        co2_world_sourcesFilter[5].word =JSON.parse(localStorage.getItem('world_source_reservoir'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver'))
        localStorage.setItem('Dict_ver', update_dict)
});

var age_word = [];
var age_period = [];
if (Cookies.get("id") !== undefined)
    axios.post(urlBackend+'/postQuery', {query:{type:"get_Groups_Permission",select_type:"get_Groups_Permission_Without_Object",select_params:{user_id:Cookies.get("id")}}})
        .then((res) => {
            let groupArray = [];
            for (let i = 0;i< res.data[0].get_permission_groups.length;i++){
                groupArray.push(res.data[0].get_permission_groups[i].name);
            }
            Cookies.set("groups", groupArray);
        }).catch((error) => {
        console.log(error)
    });
axios.post(urlBackend+'/postQuery', {query:{type:"chrono_strat",select_type:"filter",select_params:'age_from'}})
    .then((res) => {
        age_word = res.data;
        let temp = [];
        for (let i = 0 ; i < res.data.length;i++){
            temp.push({key:i,text:res.data[i].object_index,value:res.data[i].age_from + " " +res.data[i].object_index})
            age_period.push({key:i,text:res.data[i].object_index,value:res.data[i].age_to + " " +res.data[i].object_index});
        }
        BasinsFilter[4].word = temp;
        GeologicMapFilter[2].word = temp;
        GeologicObjectsFilter[5].word = temp;
        FieldsFilter[12].word = temp;
    }).catch((error) => {
    console.log(error)
});


axios.post(urlBackend+'/postQuery', {query:{type:"universal_dict",select_params:"well_type"}})
    .then((res) => {
        console.log(res)
    }).catch((error) => {
    console.log(error)
});
var WTI ;var XBR ;var maxAuctionValue;var maxWinValue;var avgWinValue;var avgAuctionValue;var histogramValue ;var histogramValue2 ;var minGradient;var maxGradient;
var colorGradient1="#FFFFFF";
var colorGradient2="#FFFFFF";

axios.post(urlBackend+'/postQuery', {query:{type:"quotes",select_type:"quotes",select_params:'WTI/USD'}} )
    .then((res) => {
        console.log(res)
        WTI= "WTI/USD : " + res.data.price
    }).catch((error) => {
    console.log(error)
});axios.post(urlBackend+'/postQuery', {query:{type:"quotes",select_type:"quotes",select_params:'XBR/USD'}} )
    .then((res) => {
        console.log(res)
        XBR= "XBR/USD : " + res.data.price
    }).catch((error) => {
        console.log(error)
    });
axios.post(urlBackend+'/postQuery', {query:{type:"get_graph_data_test",select_type:"get_graph",select_params:{
            layer_name: 'v_licenses',
            column_name:'paid_value',
            value_column_name:'',
            dictionary_table:'',
            dictionary_code:'paid_value',
            parent_object_field:'',
            need_all_data:'False',
            not_count:'False'
        }}} )
    .then((res) => {
        console.log(res)
        histogramValue = res.data[0];
    }).catch((error) => {
    console.log(error)
});
axios.post(urlBackend+'/postQuery', {query:{type:"get_graph_data_test",select_type:"get_graph",select_params:{
            layer_name: 'v_licenses',
            column_name:'auction_value',
            value_column_name:'',
            dictionary_table:'',
            dictionary_code:'auction_value',
            parent_object_field:'',
            need_all_data:'False',
            not_count:'False'
        }}} )
    .then((res) => {
        console.log(res)
        histogramValue2 = res.data[0];
    }).catch((error) => {
    console.log(error)
});
axios.post(urlBackend+'/postQuery', {query:{type:"v_licenses",select_type:"get_minmax",select_params:{}}} )
    .then((res) => {
        console.log(res)
        maxAuctionValue = res.data[0].auction;
        maxWinValue = res.data[0].paid;
        avgWinValue =res.data[0].avgpaid;
        avgAuctionValue =res.data[0].avgauction;
    }).catch((error) => {
    console.log(error)
});

const GuestText = ['OriginalName'];
const ProtectedAreasText = ['OriginalName','Тип']
const WellsText = ['OriginalName'];
const SeismicsText = ['OriginalName'];
const BasinsText = ['OriginalName','qc_id','Площадь' ,'Классификация','Тип','Подтип','Класс','Возраст','Разведанные_ресурсы_нефти','Разведанные_ресурсы_конденсата', 'Разведанные_ресурсы_газа', 'Неразведанные_ресурсы_нефти', 'Неразведанные_ресурсы_конденсата', 'Неразведанные_ресурсы_газа'];
const SubBasinsText = ['OriginalName','Площадь', 'Классификация_по_полезным_ископае', 'Lithology',  'Main_reservoir', 'Main_source_rock', 'Разведанные_ресурсы_нефти',  'Разведанные_ресурсы_конденсата', 'Разведанные_ресурсы_газа', 'Неразведанные_ресурсы_нефти',  'Неразведанные_ресурсы_конденсата', 'Неразведанные_ресурсы_газа'];
const FieldsText = ["OriginalName","Год","Класс","Location","Тип","Terrain","ShutdownYear","StartProductionYear","PlanedStartProductionYear","DiscoveryMethod","MaxElevation","MaxWaterDepth"];
const LicensesText = ['Имя_лицензии','Лицензионный_номер','Год','Источник','Степень_уверенности', 'Тип_лицензии', 'Статус_лицензии','Лицензионные_обязательства', 'Дата_выдачи', 'Дата_окончания','Оператор','Платеж_победителя','Начальная_цена_аукциона'];
const GeologicMapText = ['Geo_Index', 'Geo_compos'];
const RegionsText = ["OriginalName"];
const MSU_BranchesText = ["OriginalName"]
const co2_emissionsText = ["OriginalName"]
const infra_objText = ['OriginalName'];
const co2_world_sourcesText = ["OriginalName"]
const TectonicMapText = ['Структура', 'Порядок','Морфогенетический_тип','Тип'];
const GeoObjectText = ['object_name','object_type','geo_index', 'outcrop'];
const WellsFilter = [{
    name:'OriginalName',type:"text",display:"none",nulldisplay:"none"},
    {name:'FilterByGeometry',type:"geometry",display:"none",nulldisplay:"none"}];
const SeismicsFilter = [{
    name:'OriginalName',type:"text",display:"none",nulldisplay:"none"},
    {name:'domain',type:"word",word:domain_word2,display:"none",nulldisplay:"none"},
    {name:'seismic_type',word:seismic_type_word2,type:"word",display:"none",nulldisplay:"none"},
    {name:'signal_source',word:signal_source_word2,type:"word",display:"none",nulldisplay:"none"},
    {name:'field_work_year',type:"number",display:"none",nulldisplay:"none"},
    {name:'processing_year',type:"number",display:"none",nulldisplay:"none"},
    {name:'FilterByGeometry',type:"geometry",display:"none",nulldisplay:"none"}];
const BasinsFilter = [{
    name:'OriginalName',type:"text",display:"none",nulldisplay:"none"},
    {name:'Классификация',type:"word",word:nodes,display:"none",nulldisplay:"none"},
    {name:'Год',type:"number",display:"none",nulldisplay:"none"} ,
    {name:'Возраст',type:"word",word:basin_age_word2,display:"none",nulldisplay:"none"},
    {name:'Стратиграфия',type:"chrono",word:age_word,display:"none",nulldisplay:"none"},
    {name:'Тип полезных ископаемых',type:"word",word:age_word,display:"none",nulldisplay:"none"}];
const SubBasinsFilter = [{name:'OriginalName',type:"text",display:"none",nulldisplay:"none"},
    {name:'Год',type:"number",display:"none",nulldisplay:"none"} ,
    {name:'Классификация',type:"word",word:nodes,display:"none",nulldisplay:"none"},
    {name:'Возраст',type:"word",word:basin_age_word2,display:"none",nulldisplay:"none"},
    {name:'Стратиграфия',type:"chrono",word:age_word,display:"none",nulldisplay:"none"},
    {name:'Тип полезных ископаемых',type:"word",word:age_word,display:"none",nulldisplay:"none"},
    {name:'FilterByGeometry',type:"geometry",display:"none",nulldisplay:"none"}];
const FieldsFilter = [
    {name:'Тип',type:"word",word:field_type_word2,display:"none",nulldisplay:"none"},
    {name:'Классификация',type:"word",word:field_class_word2,display:"none",nulldisplay:"none"},
    {name:'OriginalName',type:"text",display:"none",nulldisplay:"none"},
    {name:'Год открытия',type:"number",display:"none",nulldisplay:"none"},
    {name:'Расположение',type:"word",word:location_word2,display:"none",nulldisplay:"none"} ,
    {name:'Местность',type:"word",word:terrain_word2,display:"none",nulldisplay:"none"},
    {name:'Год закрытия',type:"number",display:"none",nulldisplay:"none"},
    {name:'Год начала производства',type:"number",display:"none",nulldisplay:"none"},
    {name:'Планируемый год начала производства',type:"number",display:"none",nulldisplay:"none"},
    {name:'Метод открытия',type:"word",word:discovery_method_word2,display:"none",nulldisplay:"none"},
    {name:'Максимальная высота',type:"number",display:"none",nulldisplay:"none"},
    {name:'Максимальная глубина воды',type:"number",display:"none",nulldisplay:"none"},
    {name:'FilterByAge',type:"age",display:"none",nulldisplay:"none"},
    {name:'FilterByReserves',type:"reserves",display:"none",nulldisplay:"none"},
    {name:'FilterByGeometry',type:"geometry",display:"none",nulldisplay:"none"}];
const LicensesFilter = [
    {name:'Статус лицензии',type:"word",word:licenses_status_word2,display:"none",nulldisplay:"none"},
    {name:'Оператор',type:"text",display:"none",nulldisplay:"none"},
    {name:'OriginalName',type:"text",display:"none",nulldisplay:"none"},
    {name:'Лицензионный номер',type:"text",display:"none",nulldisplay:"none"},
    {name:'Тип лицензии',type:"word",word:licenses_type_word2,display:"none",nulldisplay:"none"} ,
    {name:'Дата выдачи лицензии',type:"date",display:"none",nulldisplay:"none"},
    {name:'Дата окончания лицензи',type:"date",display:"none",nulldisplay:"none"},
    {name:"Источник",type:"text",display:"none",nulldisplay:"none"},
    {name:'Дата Аукциона',type:"date",display:"none",nulldisplay:"none"},
    {name:'Лицензионные обязательства',type:"text",display:"none",nulldisplay:"none"},
    {name:'Год',type:"number",display:"none",nulldisplay:"none"},
    {name:'Платеж победителя',type:"number",display:"none",nulldisplay:"none"},
    {name:'Начальная цена аукциона',type:"number",display:"none",nulldisplay:"none"},
    {name:'FilterByGeometry',type:"geometry",display:"none",nulldisplay:"none"},
    {name:'FilterByInteraction',type:"interaction",display:"none",nulldisplay:"none"}];
const GeologicMapFilter = [{
    name:'OriginalName',type:"text",display:"none",nulldisplay:"none"},
    {name:'Compas',type:"text",display:"none",nulldisplay:"none"},
    {name:'Стратиграфия',type:"chrono",word:age_word,display:"none",nulldisplay:"none"},
    {name:'FilterByGeometry',type:"geometry",display:"none",nulldisplay:"none"}];
const GeologicObjectsFilter = [{
    name:'OriginalName',type:"text",display:"none",nulldisplay:"none"},
    {name:'ObjectType',type:"word",word:object_type_word2,display:"none",nulldisplay:"none"},
    {name:'Outcrop',type:"word",word:outcrop_word2,display:"none",nulldisplay:"none"} ,
    {name:'GeoPosition',type:"text",display:"none",nulldisplay:"none"},
    {name:'Reference',type:"text",display:"none",nulldisplay:"none"},
    {name:'Стратиграфия',type:"chrono",word:age_word,display:"none",nulldisplay:"none"},
    {name:'Straton',type:"text",display:"none",nulldisplay:"none"},
    {name:'ShortDesc',type:"text",display:"none",nulldisplay:"none"},
    {name:'FilterByGeometry',type:"geometry",display:"none",nulldisplay:"none"}];
const TectonicMapFilter = [{
    name:'OriginalName'},{name:'FilterByGeometry',type:"geometry",display:"none",nulldisplay:"none"},
    {name:'FilterByGeometry',type:"geometry",display:"none",nulldisplay:"none"}];
const ProtectedAreasFilter = [{
    name:'OriginalName',type:"text",display:"none",nulldisplay:"none"},
    {name:'ProtectedAreasType',type:"word",word:protected_areas_word2,display:"none",nulldisplay:"none"},
    {name:'ProtectedAreasProfile',type:"word",word:protected_areas_profile_word2,display:"none",nulldisplay:"none"},
    {name:'FilterByGeometry',type:"geometry",display:"none",nulldisplay:"none"}];
const RegionsFilter = [
    {name:'OriginalName',type:"text",display:"none",nulldisplay:"none"},
    {name:'Административный округ',type:"text",display:"none",nulldisplay:"none"}];
const MSU_BranchesFilter = [
    {name:'OriginalName',type:"text",display:"none",nulldisplay:"none"}]
const co2_world_sourcesFilter = [{name:'OriginalName',type:"text",display:"none",nulldisplay:"none"},{name:"nominal_co2_injection",type:"number",display:"none",nulldisplay:"none"},
    {name:"storage_type",word:world_source_storage,type:"word",display:"none",nulldisplay:"none"},
    {name:"status",word:world_source_status,type:"word",display:"none",nulldisplay:"none"},
    {name:"formation",word:world_source_formation,type:"word",display:"none",nulldisplay:"none"},
    {name:"reservoir_type",word:world_source_reservoir,type:"word",display:"none",nulldisplay:"none"},
    {name:"project_start_year",type:"number",display:"none",nulldisplay:"none"},
    {name:"project_stop_year",type:"number",display:"none",nulldisplay:"none"},
    {name:"operator",type:"text",display:"none",nulldisplay:"none"}]
const infra_objFilter = [
    {name:'OriginalName',type:"text",display:"none",nulldisplay:"none"},
    {name:'Category',type:"word",word:co2_categories_word,display:"none",nulldisplay:"none"},
    {name:'Subcategory',word:co2_subcategories_word,type:"word",display:"none",nulldisplay:"none"},
    {name:"Company",type:"text",display:"none",nulldisplay:"none"},
    {name:'emissions_value',type:"number",display:"none",nulldisplay:"none"}
]
const co2_emissionsFilter=[
    {name:'OriginalName',type:"text",display:"none",nulldisplay:"none"},
    {name:'Category',type:"word",word:co2_categories_word,display:"none",nulldisplay:"none"},
    {name:'Subcategory',word:co2_subcategories_word,type:"word",display:"none",nulldisplay:"none"},
    {name:"Company",type:"text",display:"none",nulldisplay:"none"},
    {name:'emissions_value',type:"number",display:"none",nulldisplay:"none"}]
const BasinsFilterData = ['OriginalName','Классификация','Год','Возраст','Стратиграфия','Тип полезных ископаемых'];
const WellsFilterData = ['OriginalName','FilterByGeometry'];
const SeismicsFilterData = ['OriginalName','domain','seismic_type','signal_source','field_work_year','processing_year','FilterByGeometry'];
const ProtectedAreasData = ['OriginalName','ProtectedAreasType','ProtectedAreasProfile','FilterByGeometry']
const FieldsFilterData = [
    'Тип',
    'Классификация',
    'OriginalName',
    'Год открытия',
    'Расположение',
    'Местность',
    'Год закрытия',
    'Год начала производства',
    'Планируемый год начала производства',
    'Метод открытия',
    "Максимальная высота",
    "Максимальная глубина воды",
    "FilterByAge",
    "FilterByReserves",
    'FilterByGeometry'];
const LicensesFilterData = [
    'Статус лицензии',
    'Оператор',
    'OriginalName',
    'Лицензионный номер',
    'Тип лицензии',
    'Дата выдачи лицензии',
    'Дата окончания лицензи',
    "Источник",
    "Дата Аукциона",
    "Лицензионные обязательства",
    "Год",
    "Платеж победителя",
    "Начальная цена аукциона",
    'FilterByGeometry',
    'FilterByInteraction'];
const GeologicMapFilterData = [
    'OriginalName',
    'Compas',
    'Стратиграфия',
    'FilterByGeometry'];
const TectonicMapFilterData = [
    'OriginalName',
    'FilterByGeometry'];
const GeologicObjectsFilterData = [
    'OriginalName',
    'ObjectType',
    'Outcrop',
    'GeoPosition',
    'Reference',
    'Стратиграфия',
    'Straton',
    'ShortDesc',
    'FilterByGeometry'];
const RegionsFilterData = [
    'OriginalName',
    'Административный округ'];
const MSU_BranchesFilterData = ['OriginalName']
const co2_emissionsFilterData = ['OriginalName',
    'Category',
    'Subcategory',
    'Company',
    "emissions_value"]
const infra_objFilterData = ['OriginalName',
    'Category',
    'Subcategory',
    'Company',
    "emissions_value"]
const SubBasinsFilterData = ['OriginalName',
    'Год',
    'Классификация',
    'Возраст',
    'Стратиграфия',
    'Тип полезных ископаемых',
    'FilterByGeometry'];
const co2_world_sourcesFilterData = ['OriginalName','nominal_co2_injection','storage_type','status','formation','reservoir_type','project_start_year','project_stop_year','operator']
const LegendLayer = [{name:"Basins",data:false},{name:"SubBasins",data:false},{name:"Fields",data:false},{name:"Licenses",data:false},{name:"GeologicObjects",data:false},{name:"ProtectedAreas",data:false},{name:"co2_emissions",data:false},{name:"co2_world_sources",data:false},{name:'infra_obj',data:false}];
const FilterLayerData = [{name:"Basins",data:BasinsFilter,list:BasinsFilterData},{name:"GeologicMap",data:GeologicMapFilter,list:GeologicMapFilterData},{name:"TectonicMap",data:TectonicMapFilter,list:TectonicMapFilterData},{name:"SubBasins",data:SubBasinsFilter,list:SubBasinsFilterData},{name:"Regions",data:RegionsFilter,list:RegionsFilterData},{name:"GeologicObjects",data:GeologicObjectsFilter,list:GeologicObjectsFilterData},{name:"Licenses",data:LicensesFilter,list:LicensesFilterData},{name:"Fields",data:FieldsFilter,list:FieldsFilterData},{name:"ProtectedAreas",data:ProtectedAreasFilter,list:ProtectedAreasData},{name:"Seismics",data:SeismicsFilter,list:SeismicsFilterData},{name:"MSU_Branches",data:MSU_BranchesFilter,list:MSU_BranchesFilterData},{name:"co2_emissions",data:co2_emissionsFilter,list:co2_emissionsFilterData},{name:"co2_world_sources",data:co2_world_sourcesFilter,list:co2_world_sourcesFilterData},{name:'infra_obj',data:infra_objFilter,list:infra_objFilterData}]
var Filters = [{name:"Basins",switch:false,filters:
        [{name:"OriginalName",type:"text",filter:null,notnull:null,DBname:"OriginalName"},
            {name:"Класс",type:"dict",filter:null,notnull:null,DBname:"Класс"},
            {name:"Год",type:"number",filter:null,notnull:null,DBname:"Год"},

            {name:"Возраст",type:"dict",filter:null,notnull:null,DBname:"Возраст"},
            {name:"Стратиграфия",type:"age",filter:null,notnull:null,DBname:"Начало"},
            {name:"Тип полезных ископаемых",type:"dict",filter:null,notnull:null,DBname:"Fluid"}]},
    {name:"SubBasins",switch:false,filters:
            [{name:"OriginalName",type:"text",filter:null,notnull:null,DBname:"OriginalName"},
                {name:"Год",type:"number",filter:null,notnull:null,DBname:"Год"},
                {name:"Класс",type:"dict",filter:null,notnull:null,DBname:"Класс"},
                {name:"Возраст",type:"dict",filter:null,notnull:null,DBname:"Возраст"},
                {name:"Стратиграфия",type:"age",filter:null,notnull:null,DBname:"Начало"},
                {name:"Тип полезных ископаемых",type:"dict",filter:null,notnull:null,DBname:"Fluid"},
                {name:"FilterByGeometry",type:"id",filter:null,notnull:null,DBname:"id"}]},
    {name:"Regions",switch:false,filters:
            [{name:"OriginalName",type:"text",filter:null,notnull:null,DBname:"OriginalName"},
                {name:"Административный округ",type:"text",filter:null,notnull:null,DBname:"Администрати вныйОкруг"}]},
    {name:"Fields",switch:false,filters:
            [{name:"Тип",type:"dict",filter:null,notnull:null,DBname:"Тип"},
                {name:"Класс",type:"dict",filter:null,notnull:null,DBname:"Класс"},
                {name:"OriginalName",type:"text",filter:null,notnull:null,DBname:"OriginalName"},
                {name:"Год открытия",type:"number",filter:null,notnull:null,DBname:"DiscoveryYear"},
                {name:"Расположение",type:"dict",filter:null,notnull:null,DBname:"Location"},
                {name:"Местность",type:"dict",filter:null,notnull:null,DBname:"Terrain"},
                {name:"Год закрытия",type:"number",filter:null,notnull:null,DBname:"ShutdownYear"},
                {name:"Год начала производства",type:"number",filter:null,notnull:null,DBname:"StartProductionYear"},
                {name:"Планируемый год начала производства",type:"number",filter:null,notnull:null,DBname:"PlanedStartProductionYear"},
                {name:"Метод открытия",type:"dict",filter:null,notnull:null,DBname:"DiscoveryMethod"},
                {name:"Максимальная высота",type:"number",filter:null,notnull:null,DBname:"MaxElevation"},
                {name:"Максимальная глубина воды",type:"number",filter:null,notnull:null,DBname:"MaxWaterDepth"},
                {name:"FilterByAge",type:"id",filter:null,notnull:null,DBname:"id"},
                {name:"FilterByReserves",type:"id",filter:null,notnull:null,DBname:"id"},
                {name:"FilterByGeometry",type:"id",filter:null,notnull:null,DBname:"id"}]},
    {name:"Licenses",switch:false,filters:
            [{name:"Статус лицензии",type:"dict",filter:["Действующая","Аукцион","Планируемый аукцион","Инвестиционные возможности"],notnull:null,DBname:"Статус лицензии"},
                {name:"Оператор",type:"text",filter:null,notnull:null,DBname:"Оператор"},
                {name:"OriginalName",type:"text",filter:null,notnull:null,DBname:"OriginalName"},
                {name:"Лицензионный номер",type:"text",filter:null,notnull:null,DBname:"ЛицензионныйНомер"},
                {name:"Тип лицензии",type:"dict",filter:null,notnull:null,DBname:"Тип лицензии"},
                {name:"Дата выдачи лицензии",type:"number",filter:null,notnull:null,DBname:"IssueDate"},
                {name:"Дата окончания лицензи",type:"number",filter:null,notnull:null,DBname:"ExparyYear"},
                {name:"Источник",type:"text",filter:null,notnull:null,DBname:"Источник"},
                {name:"Дата Аукциона",type:"number",filter:null,notnull:null,DBname:"AuctionDate"},
                {name:"Лицензионные обязательства",type:"dict",filter:null,notnull:null,DBname:"Лицензионные обязательства"},
                {name:"Год",type:"number",filter:null,notnull:null,DBname:"Год"},
                {name:"Платеж победителя",type:"number",filter:null,notnull:null,DBname:"paid_value"},
                {name:"Начальная цена аукциона",type:"number",filter:null,notnull:null,DBname:"auction_value"},
                {name:"FilterByGeometry",type:"id",filter:null,notnull:null,DBname:"id"},
                {name:"FilterByInteraction",type:"id",filter:null,notnull:null,DBname:"id",reverse:false},
            ]},
    {name:"GeologicObjects",switch:false,filters:
            [{name:"OriginalName",type:"text",filter:null,notnull:null,DBname:"OriginalName"},
                {name:"ObjectType",type:"dict",filter:null,notnull:null,DBname:"ObjectType"},
                {name:"Outcrop",type:"dict",filter:null,notnull:null,DBname:"outcrop"},
                {name:"GeoPosition",type:"text",filter:null,notnull:null,DBname:"GeoPosition"},
                {name:"Reference",type:"text",filter:null,notnull:null,DBname:"Reference"},
                {name:"Стратиграфия",type:"age",filter:null,notnull:null,DBname:"Начало"},
                {name:"Straton",type:"text",filter:null,notnull:null,DBname:"Straton"},
                {name:"ShortDesc",type:"text",filter:null,notnull:null,DBname:"ShortDesc"},
                {name:"FilterByGeometry",type:"id",filter:null,notnull:null,DBname:"id"}]},
    {name:"TectonicMap",switch:false,filters:
            [{name:"OriginalName",type:"text",filter:null,notnull:null,DBname:"OriginalName"},
                {name:"FilterByGeometry",type:"id",filter:null,notnull:null,DBname:"id"}]},
    {name:"GeologicMap",switch:false,filters:
            [{name:"OriginalName",type:"text",filter:null,notnull:null,DBname:"OriginalName"},
                {name:'Compas',type:"text",filter:null,notnull:null,DBname:"Compas"},
                {name:'Стратиграфия',type:"age",filter:null,notnull:null,DBname:"Начало"},
                {name:"FilterByGeometry",type:"id",filter:null,notnull:null,DBname:"id"}]},
    {name:"Wells",switch:false,filters:
            [{name:"OriginalName",type:"text",filter:null,notnull:null,DBname:"OriginalName"},
                {name:"FilterByGeometry",type:"id",filter:null,notnull:null,DBname:"id"}]},
    {name:"MSU_Branches",switch:false,filters:
            [{name:"OriginalName",type:"text",filter:null,notnull:null,DBname: "OriginalName"}]},
    {name:"co2_emissions",switch:false,filters:
            [{name:"OriginalName",type:"text",filter:null,notnull: null,DBname:"OriginalName"},
                {name:"Category",type:"dict",filter:null,notnull: null,DBname:"category"},
                {name:"Subcategory",type:"dict",filter:null,notnull: null,DBname:"subcategory"},
                {name:"Company",type:"text",filter:null,notnull: null,DBname:"company"},
                {name:"emissions_value",type:"number",filter:null,notnull: null,DBname:"emissions_value"}]},
    {name:"co2_world_sources",switch:false,filters:
            [{name:"OriginalName",type:"text",filter:null,notnull:null,DBname:"Originalname"},
                {name:"nominal_co2_injection",type:"number",filter:null,notnull:null,DBname: "nominal_co2_injection"},
                {name:"storage_type",type:"dict",filter:null,notnull:null,DBname: "storage_type_id"},
                {name:"status",type:"dict",filter:null,notnull:null,DBname: "status_id"},
                {name:"formation",type:"dict",filter:null,notnull:null,DBname: "formation_id"},
                {name:"reservoir_type",type:"dict",filter:null,notnull: null,DBname: "reservoir_type_id"},
                {name:"project_start_year",type:"number",filter:null,notnull:null,DBname: "project_start_year"},
                {name:"project_stop_year",type:"number",filter:null,notnull:null,DBname: "project_stop_year"},
                {name:"operator",type:"text",filter:null,notnull:null,DBname: "operator_str"}]},
    {name:'infra_obj',switch: false,filters:
            [{name:"OriginalName",type:"text",filter:null,notnull: null,DBname:"OriginalName"},
                {name:"Category",type:"dict",filter:null,notnull: null,DBname:"category"},
                {name:"Subcategory",type:"dict",filter:null,notnull: null,DBname:"subcategory"},
                {name:"Company",type:"text",filter:null,notnull: null,DBname:"company"},
                {name:"emissions_value",type:"number",filter:null,notnull: null,DBname:"emissions_value"}]},
    {name:"Seismics",switch:false,filters:
            [{name:"OriginalName",type:"text",filter:null,notnull:null,DBname:"OriginalName"},
                {name:"domain",type:"dict",filter:null,notnull:null,DBname:"domain"},
                {name:"seismic_type",type:"dict",filter:null,notnull:null,DBname:"seismic_type"},
                {name:"signal_source",type:"text",filter:null,notnull:null,DBname:"signal_source"},
                {name:"field_work_year",type:"number",filter:null,notnull:null,DBname:"field_work_year"},
                {name:"processing_year",type:"number",filter:null,notnull:null,DBname:"processing_year"},
                {name:"FilterByGeometry",type:"id",filter:null,notnull:null,DBname:"id"}]},
    {name:"ProtectedAreas",switch:false,filters:
            [{name:"OriginalName",type:"text",filter:null,notnull:null,DBname:"OriginalName"},
                {name:"ProtectedAreasType",type:"dict",filter:null,notnull:null,DBname:"protectedareastype"},
                {name:"ProtectedAreasProfile",type:"dict",filter:null,notnull:null,DBname:"protectedareasprofile"},
                {name:"FilterByGeometry",type:"id",filter:null,notnull:null,DBname:"id"}]}];
var result = [];
const TextData = authorized ?
     [{name:"co2_world_sources",data:co2_world_sourcesText},{name:"co2_emissions",data:co2_emissionsText},{name:'infra_obj',data:infra_objText},{name:"MSU_Branches",data:MSU_BranchesText},{name:"Wells",data:WellsText},{name:"Seismics",data:SeismicsText},{name:"Basins",data:BasinsText},{name:"GeologicMap",data:GeologicMapText},{name:"TectonicMap",data:TectonicMapText},{name:"SubBasins",data:SubBasinsText},{name:"Regions",data:RegionsText},{name:"GeologicObjects",data:GeoObjectText},{name:"Licenses",data:LicensesText},{name:"Fields",data:FieldsText},{name:"ProtectedAreas",data:ProtectedAreasText}]
:
    [{name:"co2_world_sources",data:co2_world_sourcesText},{name:"co2_emissions",data:co2_emissionsText},{name:'infra_obj',data:infra_objText},{name:"MSU_Branches",data:MSU_BranchesText},{name:"Wells",data:WellsText},{name:"Seismics",data:SeismicsText},{name:"Basins",data:GuestText},{name:"GeologicMap",data:GuestText},{name:"TectonicMap",data:GuestText},{name:"SubBasins",data:GuestText},{name:"Regions",data:GuestText},{name:"GeologicObjects",data:GuestText},{name:"Licenses",data:GuestText},{name:"Fields",data:GuestText}];
var LayerOptions=[{name:"Basins",data:'none',data2:"none",id:0},{name:"GeologicMap",data:'none',data2:"none",id:1},{name:"TectonicMap",data:'none',data2:"none",id:2},{name:"SubBasins",data:'none',data2:"none",id:3},{name:"Regions",data:'none',data2:"none",id:4},{name:"Licenses",data:'none',data2:"none",id:5},{name:"Fields",data:'none',data2:"none",id:6},{name:"GeologicObjects",data:'none',data2:"none",id:7},{name:"Wells",data:'none',data2:"none",id:8},{name:"ProtectedAreas",data:'none',data2:"none",id:9},{name:"Seismics",data:"none",data2:"none",id:10},{name:"MSU_Branches",data:"none",data2:"none",id:11},{name:"co2_emissions",data:"none",data2:"none",id:12},{name:"co2_world_sources",data:"none",data2:"none",id:13},{name:"infra_obj",data:"none",data2:"none",id:14}];
var UserOptions=[];
var name ;
const MAPBOX_TOKEN = '29401193d04a48ad85fc942a38f09631'; // Set your mapbox token here
navigator.saysWho = getUserAgent();
console.log(navigator.saysWho)
/*
setTimeout(()=> {
        result.unshift({name:"wms"+data.length+1,data:"https://s2maps-tiles.eu/wms?bbox={bbox-epsg-3857}&format=image/jpeg&service=WMS&version=1.1.1&STYLES=default&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=blackmarble_3857",color:"#FF00FF",id:data.length+1,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'wms',text:false,border:null,hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
        LayerOptions.unshift({name:"wms"+data.length+1,data:'none',data2:"none",id:data.length+1});
        UserOptions.unshift({name:"wms"+data.length+1,data:'none',data2:"none",id:20+data.length+1});
        userdataname.unshift({index:2+userdataname.length,name:"wms"+data.length,type:"WmsLayer"});
        data.unshift({name:"wms"+data.length+1,data:"https://s2maps-tiles.eu/wms?bbox={bbox-epsg-3857}&format=image/jpeg&service=WMS&version=1.1.1&STYLES=default&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=blackmarble_3857",color:"#FF00FF",id:data.length+1,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'wms',text:false,border:null,hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
        presetdata.unshift({name:"wms"+data.length+1,data:"https://s2maps-tiles.eu/wms?bbox={bbox-epsg-3857}&format=image/jpeg&service=WMS&version=1.1.1&STYLES=default&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=blackmarble_3857",color:"#FF00FF",id:data.length+1,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'wms',text:false,border:null,hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
        dataname.unshift({index:dataname.length,name:"wms"+data.length,type:"WmsLayer"});
        data2.unshift({name:"wms"+data.length+1+"Text",data:urlMartin+'/public.basinscenter/{z}/{x}/{y}.pbf',color:"#00218B",id:data2[data2.length-1].id+"tif",beforeid:false,visibility:"none",sourcelayer:'public.basinscenter',opacity:0.8,type:'symbol',layoutname:'OriginalName'})

    }
    ,5000);
 */
function setResult(newResult) {
    result = newResult;
    //result.reverse();
    datasetn = 1;
}
function setData2(newResult) {
    data2 = newResult;
}
function setLayerOptions(newResult) {
    LayerOptions = newResult;
}
function setMinGradient(newResult) {
    minGradient = newResult;
}
function setMaxGradient(newResult) {
    maxGradient = newResult;
}
function setDataname(newResult) {
    dataname = newResult;
}
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //flytomap
            flyCount:0,
            flyCoordinates:[],
            flyType:null,
            //hover
            hoveredID: null,
            hoveredSourceLayer: null,
            hoveredSourceID: null,
            hoveredArray:[],
            hoveredClickArray:[],
            hoveredClickArea:null,
            //variable
            features: [],
            search: [],
            count: 0,
            area:'',
            geocodingInput:"",
            geocodingResult:[],
            geocodingList:[],
            list: [],
            searchList:[],
            displayList:[],
            checkList:[],
            checkboxList:[],
            filterPropsList:[{ empty:"table" }],
            fulterPropsLayer:"",
            inputValue: '',
            itemSelected: null,
            redactDisplay: 'none',
            dataForRedact: null,
            dataReady:'false',
            filterListY:"300px",
            filterListButtonX:"",
            filterListButtonY:"",
            filterListDragging:false,
            data:null,
            inc:0,
            drawState:false,
            auth:true,
            username:'',
            user_id:"?user_id="+  (Cookies.get("id")?Cookies.get("id"):0),
            id:'',
            currentCenter:{},
            wmsInput:"",
            // map
            filterDisplay:this.props.filter,
            filterPropsDisplay:"none",
            layerDisplay: "none",
            searchDisplay: SearchDisplay,
            loginDisplay:"none",
            registerDisplay:RegisterDisplay,
            imageDisplay:"none",
            shapeDisplay:ShapeDisplay,
            aboutDisplay:AboutDisplay,
            linesDisplay:false,
            kernDisplay:"none",
            LibraryDisplay:"none",
            publicationCount:0,
            publication:[],
            wikiDisplay:WikiDisplay,
            interactionLayers:[],
            helpDisplay:"none",
            mapReservesDisplay:"none",
            mapReservesIds:null,
            lasDisplay:'none',
            lasURL:null,
            bindDisplay:"block",
            bindWidth:"400",
            bindHeight:"200",
            welcomeDisplay:"block",
            loaderDisplay:"none",
            georefDisplay:"none",
            georefClear:false,
            georefMode:false,
            georefPoints:[],
            georef_delete_index: null,
            georef_delete: false,
            georefURL:"",
            georefBbox:[],
            georefOpacity:1,
            tiffLoaderDisplay:"none",
            tiffLoaderName:"",
            pointDisplay:"none",
            profileDisplay:"none",
            maintainDisplay:"none",
            routeDisplay:"none",
            reservesDisplay:"none",
            Model3dDisplay:"none",
            url3d:null,
            // Search Analogs
            AnalogData:null,
            AnalogCategories:null,
            AnalogDict:null,
            AnalogDictNames:null,
            AnalogDisplay:"none",
            //
            routeMenu:[],
            loaderProp:[],
            loaderResultList:[],
            calcDisplay:"none",
            viewport: {
                latitude: 67,
                longitude: 97,
                zoom:2.3
            },
            // editor
            //selectedMode: EditorModes.READ_ONLY,
            //LandingScreen
            landing:true,
            //LoadingScreen
            loading:false,
            // Popup
            popupDisplay:'none',
            x:300,
            y:400,
            textPopup:'',
            clickID:"",
            //Redaction
            redactionID:'',
            redactionSourceLayer:'',
            redactionCoord:[],
            redactionName:"",
            myObject:false,
            objID:null,
            groupPermArray:[],
            groupPermArrayBasic:[],
            //infos
            infosDisplay:'none',
            infosPerm:'edit',
            infosFeature:[],
            infosQuery:'',
            infosQuery2:'',
            infosName:'',
            infosDesc:'',
            infoLayer:'',
            infosEditorName:'',
            infosAuthorID:null,
            infosUserObject:false,
            //image
            selectedFile: null,
            image:gif,
            imageBbox:[8157717.8530999999493361,6046875.2785999998450279,17219361.2809999994933605,13419923.0031000003218651],
            imageerror:"",
            imageVisible:"none",
            imageOpacity:0.50,
            //shape
            shapeerror:"",
            shapeFile:null,
            shapeResult:[],
            type:'',
            //features-choose
            featuresDisplay:"none",
            featuresList:[],
            //
            geojsonFile:null,
            renderSwitch:true,
            update:false,
            //profile
            profileName:"",
            profileEmail:"",
            //lasso
            lassoCoordinates:[],
            lassoVisibility:"none",
            lassoList:[],
            lassoDisplay:"none",
            lassoIds:[],
            lassoFilter:false,
            lassoActive:false,
            CoordinateNetActive:false,
            userActive:false,
            //mapcontextmenu
            mapMenuDisplay:"none",
            mapMenuSearchDisplay:"none",
            mapMenuSearchResult:{},
            mapMenuPosition:{x:0,y:0},
            mapMenuPoint:{lat:0,lng:0},
            //area
            areaActive:false,
            areaDisplay:"none",
            areaCoordinates:[],
            areaVisibility:"none",
            //router
            routerViewportTrigger:false,
            routerInfosTrigger:false,
            routerAboutTrigger:false,
            firstRender:true,
            updateDate:null,
            //alert
            alertDisplay:false,
            alertState:"success",
            alertValue:"",
            //lang
            language:"ru",
            languageArray:["ru","en"],
            //co2_emissions
            emissionCenter:null,
        };
        this.LoadUpdate = this.LoadUpdate.bind(this)
        this.getLibraryPublication = this.getLibraryPublication.bind(this);
        this.handlerPopup = this.handlerPopup.bind(this);
        this.handleClosePopup = this.handleClosePopup.bind(this);
        this.onGuestInfos = this.onGuestInfos.bind(this);
        this.handlerSingin = this.handlerSingin.bind(this);
        this.handlerAbout =this.handlerAbout.bind(this);
        this.handlerHoverClick = this.handlerHoverClick.bind(this);
        this.fitLayer =this.fitLayer.bind(this);
        this.onFeaturestable =this.onFeaturestable.bind(this);
        this.draw_graticule = this.draw_graticule.bind(this);
    }
    onLoadingOpen = () => {
        this.setState({loading:true});
    }
    onLoadingClose = () => {
        this.setState({loading:false})
    }
    setInteractionLayers = (layer)=>{
        let layers = this.state.interactionLayers;
        if (this.state.interactionLayers.includes(layer))
            layers = layers.filter(item => item !== layer)
        else
            layers.push(layer)
        console.log(layers)
        this.setState({interactionLayers:layers})
    }
    setInteractionLayersID = (id,source)=>{
        let layer = ''
        for (let i = 0 ; i < result.length;i++){
            if (source === result[i].id)
                layer = result[i].name
        }
        for ( let i = 0 ;i < Filters.length ; i++){
            if (Filters[i].name === layer){
                for (let j = 0 ; j < Filters[i].filters.length; j++){
                    if (Filters[i].filters[j].name === 'FilterByInteraction' ){
                        if (Filters[i].filters[j].filter === null){
                            Filters[i].filters[j].filter = [id]
                        }else{
                            Filters[i].filters[j].filter.push(id)
                        }
                    }
                }
            }
        }
    }
    handlerHoverClick = (id,source,sourcelayer,geometry) => {
        console.log(id,source,sourcelayer)
        let arr = this.state.hoveredClickArray;
        let value = {id:id,source:source,sourcelayer:sourcelayer,geometry:geometry}
        if (contains(arr,value) === false){
            arr.push(value);
        }
        else
            arr.splice(contains(arr,value),1);
        let fullArea=0;
        for ( let i = 0;i<arr.length;i++){
            //console.log(arr[i].geometry);
            var polygon = turf.polygon(arr[i].geometry.coordinates);
            var area = turf.area(polygon);
            if ( area !== null && area !== undefined)
                fullArea = fullArea+area;
            //console.log(area,fullArea)
        }
        //console.log(fullArea);
        if (fullArea === 0)
            fullArea = null;
        else
            fullArea = fullArea/1000000;
        //console.log(fullArea);
        this.setState({hoveredClickArray:arr,hoveredClickArea:fullArea});
    };
    handlerPopup = (layer,layer_id,name) => {
        console.log(layer);
        let id = 0;
        if(Cookies.get('id') !== undefined)
            id = Cookies.get('id');
        let query =' SELECT (CASE WHEN user_id = '+id+' THEN true ELSE false END) AS users_obj FROM '+layer+' WHERE '+layer+'_id = '+layer_id;
        //console.log(query);
        axios.post(urlBackend+'/postQuery', {query: {type:"get_geom",select_type:"is_user_object",select_params:{
                    object_id: layer_id,
                    layer:layer,
                    layer_id: layer+'_id',
                    id:id
                }}})
            .then((res) => {
                console.log(res)
                //
                this.setState({popupDisplay:'none',myObject:res.data[0].users_obj})
            }).catch((error) => {
            //console.log(error,query)
        });
        axios.post(urlBackend+'/postQuery', {query: this.state.dataReady})
            .then((res) => {
                console.log(res);
                this.onRedactChange(res.data[0].jsonb_build_object);
                let array = res.data[0].jsonb_build_object;
                console.log(array);
                let FinalGeom = "";
                let coordinates = array.features[0].geometry.coordinates.slice();
                if (array.features[0].geometry.type === "Polygon"){
                    for (let i = 0 ; i < coordinates[0].length;i++){
                        coordinates[0][i] = coordinates[0][i][0] + " " + coordinates[0][i][1];
                    }
                    FinalGeom = "POLYGON(("+coordinates.toString()+"))";
                }
                else if(array.features[0].geometry.type === 'Point') {
                    FinalGeom = "POINT("+coordinates[0].toString()+" "+coordinates[1].toString()+")"
                }
                else if(array.features[0].geometry.type === 'LineString') {
                    let linestring = "";
                    for ( let i = 0 ;i<coordinates.length;i++){
                        linestring = linestring +" " + + coordinates[i][0] + " " + coordinates[i][1]+ ","
                    }
                    FinalGeom = "LINESTRING("+linestring.substring(0, linestring.length - 1)+")";
                }
                console.log(FinalGeom)
                console.log(JSON.stringify(array));
                this.setState({drawState:true,objID:layer_id,redactionName:name,redactionID:layer_id,redactionSourceLayer:layer,data:array,redactionCoord:FinalGeom});
                this.handleCloseInfos();
                //this.onDrawSelectionChange({features:array.features , points: [], type: "draw.selectionchange", target: ''});
            }).catch((error) => {
            console.log(error)
        });
        //this.setState({popupDisplay:'none',dataForRedact:this.state.data})
    };
    handlerSingin = () => {
        this.setState({auth:true,username:Cookies.get("username")})
        this.onLayerChange2();
    };
    handlerLanguage = (lang) => {
        i18n.changeLanguage(lang);
        this.setState({language:lang});
    };
    handleClosePopup = () => {
        this.setState({popupDisplay:'none'})
    };
    handlerAbout = () => {
        // console.log(ImageDisplay());
        this.setState({aboutDisplay:AboutDisplay()})
    };
    lineshandle = () => {
        this.setState({linesDisplay:!this.state.linesDisplay})
    }
    handleCloseWelcome = () => {
        this.setState({aboutDisplay:AboutDisplay("none")})
    };
    handleCloseHelp = () => {
        this.setState({helpDisplay:"none"})
    };
    //mapReservesDisplay
    handleCloseMapReserves = () => {
        this.setState({mapReservesDisplay:"none"})
    };
    handleMapReserves = (ids) => {
        if (this.state.mapReservesDisplay === "none")
            this.setState({mapReservesDisplay:"block",mapReservesIds:ids})
        else
            this.setState({mapReservesDisplay:"none",mapReservesIds:null})
    };

    handleCloseInfos = () => {
        this.setState({infosDisplay:'none'})
    };
    handleAlert = (state,message) => {
        this.setState({alertDisplay:true,
            alertState:state,
            alertValue:message})
    };
    handleAlertClose = () => {
        this.setState({alertDisplay:false,
            alertState:"success",
            alertValue:""})
    };
    handleInfosPopup = (state,userInfos,query,query2) => {
        console.log(state,userInfos,this.state.infosQuery,this.state.infosQuery2);
        let infosQuery = this.state.infosQuery
        let infosQuery2 = this.state.infosQuery2
        if(query)
            infosQuery = query
        if(query2)
            infosQuery2 = query2
        if (state === true) {
            //console.log("in state === true")
           this.setState({infosFeature:[{basin_name:""}],infosName:"",infosDesc:"",infosEditorName:"",infosAuthorID:null});
        }
        else{
            let update_date = null;
            let tempdata = {};
            let query ;
            if (userInfos)
                query = infosQuery;
            else
                query = infosQuery2;
            axios.post(urlBackend+'/postQuery', {query: query})
                .then((res) => {
                    console.log(res.data[0])
                    if (res.data.length > 1)
                        res.data = [res.data[0]];
                    if (res.data[0] !== undefined) {
                        console.log(res.data[0].update_date)
                        tempdata = JSON.parse(JSON.stringify(res))
                        if ( res.data[0].update_date !== undefined && res.data[0].update_date !== null){
                            update_date = tempdata.data[0].update_date;
                            delete res.data[0]["update_date"];
                        }
                        console.log(update_date,tempdata)
                        if ( res.data[0].description === undefined || res.data[0].description === null)
                            res.data[0].description = "";
                        if (res.data[0].basin_name !== undefined){
                                let keys = Object.keys(res.data[0])
                                let infos_key = keys[0];
                                let PermQuary = {type:"get_Groups_Permission",select_type:"get_Groups_Permission_For_Object",select_params:{object_id:res.data[0].basins_id,user_id:Cookies.get("id"),layer_id:infos_key.split('_')[0]}}
                                axios.post(urlBackend+'/postQuery', {query: PermQuary})
                                    .then((res2) => {
                                        console.log(res2,res2.data[0],res.data[0])
                                        console.log(res2.data.length)
                                        let perm = '' ;
                                        if (res2.data[0] !== undefined) {
                                            for (let i = 0; i < res2.data.length;i++) {
                                                console.log(res2.data[i].f_acl_perm_types_code,res2.data[i].f_acl_perm_types_code !== null)
                                                if (res2.data[i].f_acl_perm_types_code !== null){
                                                    if (res2.data[i].f_acl_perm_types_code.replaceAll(' ','') === "change") {
                                                        perm = res2.data[i].f_acl_perm_types_code
                                                    }   else if(res2.data[i].f_acl_perm_types_code.replaceAll(' ','') === "read" && !(perm.replaceAll(' ','') === "change" )) {
                                                        perm = res2.data[i].f_acl_perm_types_code
                                                    }
                                                    console.log(perm,res2.data[i].f_acl_perm_types_code.replaceAll(' ',''),res2.data[i].f_acl_perm_types_code.replaceAll(' ','') === "change",res2.data[i].f_acl_perm_types_code.replaceAll(' ','') === "read")
                                                }

                                            }
                                        }

                                        console.log(perm)
                                        axios.post(urlBackend+'/postQuery', {query: {type:"get_Infos_Author",select_type:"get_Infos_Author",select_params:{infos_id:res.data[0][infos_key],column:infos_key,layer:infos_key.split('_')[0]}}})
                                            .then((res3) => {
                                                console.log(res3,(Number.parseInt(res3.data[0].user_id) === Number.parseInt(Cookies.get("id"))),(Number.parseInt(res3.data[0].author_id) === Number.parseInt(Cookies.get("id"))))
                                                if (Number.parseInt(res3.data[0].author_id) === Number.parseInt(Cookies.get("id"))) {
                                                    if (res2.data[0] !== undefined)
                                                        this.setState({infosPerm:perm,updateDate:update_date,infosFeature:res.data,infosDisplay:'block',infosName:res.data[0].basin_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username,infosAuthorID:true})
                                                    else
                                                        this.setState({infosPerm:'',updateDate:update_date,infosFeature:res.data,infosDisplay:'block',infosName:res.data[0].basin_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username,infosAuthorID:res3.data[0].author_id})
                                                }else if (Number.parseInt(res3.data[0].user_id) === Number.parseInt(Cookies.get("id"))){
                                                    if (res2.data[0] !== undefined)
                                                        this.setState({infosPerm:perm,updateDate:update_date,infosFeature:res.data,infosDisplay:'block',infosName:res.data[0].basin_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username,infosAuthorID:true})
                                                    else
                                                        this.setState({infosPerm:'',updateDate:update_date,infosFeature:res.data,infosDisplay:'block',infosName:res.data[0].basin_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username,infosAuthorID:res3.data[0].author_id})
                                                }
                                                else {
                                                    if (res2.data[0] !== undefined)
                                                        this.setState({infosPerm:perm,updateDate:update_date,infosFeature:res.data,infosDisplay:'block',infosName:res.data[0].basin_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username,infosAuthorID:false})
                                                    else
                                                        this.setState({infosPerm:'',updateDate:update_date,infosFeature:res.data,infosDisplay:'block',infosName:res.data[0].basin_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username,infosAuthorID:res3.data[0].author_id})

                                                }

                                            })
                                            .catch((error)=>{
                                                console.log(error)
                                            })
                                        })
                                    .catch((error)=>{console.log(error)})
                        }
                        else if (res.data[0].subbasin_name !== undefined){
                            this.setState({infosFeature:res.data,updateDate:update_date,infosDisplay:'block',infosName:res.data[0].subbasin_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username});
                        }else if (res.data[0].fields_name !== undefined){
                            let keys = Object.keys(res.data[0])
                            let infos_key = keys[0];
                            let PermQuary = {type:"get_Groups_Permission",select_type:"get_Groups_Permission_For_Object",select_params:{object_id:res.data[0].fields_id,user_id:Cookies.get("id"),layer_id:infos_key.split('_')[0]}}
                            axios.post(urlBackend+'/postQuery', {query: PermQuary})
                                .then((res2) => {
                                    console.log(res2,res2.data[0],res.data[0])
                                    console.log(res2.data.length)
                                    let perm = '' ;
                                    if (res2.data[0] !== undefined) {
                                        for (let i = 0; i < res2.data.length;i++) {
                                            console.log(res2.data[i].f_acl_perm_types_code,res2.data[i].f_acl_perm_types_code !== null)
                                            if (res2.data[i].f_acl_perm_types_code !== null){
                                                if (res2.data[i].f_acl_perm_types_code.replaceAll(' ','') === "change") {
                                                    perm = res2.data[i].f_acl_perm_types_code
                                                }   else if(res2.data[i].f_acl_perm_types_code.replaceAll(' ','') === "read" && !(perm.replaceAll(' ','') === "change" )) {
                                                    perm = res2.data[i].f_acl_perm_types_code
                                                }
                                                console.log(perm,res2.data[i].f_acl_perm_types_code.replaceAll(' ',''),res2.data[i].f_acl_perm_types_code.replaceAll(' ','') === "change",res2.data[i].f_acl_perm_types_code.replaceAll(' ','') === "read")
                                            }

                                        }
                                    }

                                    console.log(perm)
                                    axios.post(urlBackend+'/postQuery', {query: {type:"get_Infos_Author",select_type:"get_Infos_Author",select_params:{infos_id:res.data[0][infos_key],column:infos_key,layer:infos_key.split('_')[0]}}})
                                        .then((res3) => {
                                            console.log(res3,(Number.parseInt(res3.data[0].user_id) === Number.parseInt(Cookies.get("id"))),(Number.parseInt(res3.data[0].author_id) === Number.parseInt(Cookies.get("id"))))
                                            if (Number.parseInt(res3.data[0].author_id) === Number.parseInt(Cookies.get("id"))) {
                                                if (res2.data[0] !== undefined)
                                                    this.setState({infosPerm:perm,updateDate:update_date,infosFeature:res.data,infosDisplay:'block',infosName:res.data[0].fields_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username,infosAuthorID:true})
                                                else
                                                    this.setState({infosPerm:'',updateDate:update_date,infosFeature:res.data,infosDisplay:'block',infosName:res.data[0].fields_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username,infosAuthorID:res3.data[0].author_id})
                                            }else if (Number.parseInt(res3.data[0].user_id) === Number.parseInt(Cookies.get("id"))){
                                                if (res2.data[0] !== undefined)
                                                    this.setState({infosPerm:perm,updateDate:update_date,infosFeature:res.data,infosDisplay:'block',infosName:res.data[0].fields_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username,infosAuthorID:true})
                                                else
                                                    this.setState({infosPerm:'',updateDate:update_date,infosFeature:res.data,infosDisplay:'block',infosName:res.data[0].fields_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username,infosAuthorID:res3.data[0].author_id})
                                            }
                                            else {
                                                if (res2.data[0] !== undefined)
                                                    this.setState({infosPerm:perm,updateDate:update_date,infosFeature:res.data,infosDisplay:'block',infosName:res.data[0].fields_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username,infosAuthorID:false})
                                                else
                                                    this.setState({infosPerm:'',updateDate:update_date,infosFeature:res.data,infosDisplay:'block',infosName:res.data[0].fields_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username,infosAuthorID:res3.data[0].author_id})

                                            }

                                        })
                                        .catch((error)=>{
                                            console.log(error)
                                        })
                                })
                                .catch((error)=>{console.log(error)})
                            //this.setState({infosFeature:res.data,infosDisplay:'block',infosName:res.data[0].fields_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username});
                        }else if (res.data[0].info_structure !== undefined){
                            this.setState({infosFeature:res.data,updateDate:update_date,infosDisplay:'block',infosName:res.data[0].info_structure,infosDesc:res.data[0].description,infosEditorName:res.data[0].username});
                        }else if (res.data[0].s_lin_name !== undefined){
                            this.setState({infosFeature:res.data,updateDate:update_date,infosDisplay:'block',infosName:res.data[0].s_lin_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username});
                        }else if (res.data[0].region_name !== undefined){
                            this.setState({infosFeature:res.data,updateDate:update_date,infosDisplay:'block',infosName:res.data[0].region_name,infosDesc:"",infosEditorName:res.data[0].username});
                        }else if (res.data[0].licenses_name !== undefined){
                            this.setState({infosFeature:res.data,updateDate:update_date,infosDisplay:'block',infosName:res.data[0].licenses_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username});
                        }else if (res.data[0].object_name !== undefined){
                            this.setState({infosFeature:res.data,updateDate:update_date,infosDisplay:'block',infosName:res.data[0].object_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username});
                        }else if (res.data[0].f_co2_org_infos_name_rus !== undefined){
                            this.setState({infosFeature:res.data,updateDate:update_date,infosDisplay:'block',infosName:res.data[0].f_co2_org_infos_name_rus,infosDesc:res.data[0].description,infosEditorName:res.data[0].username});
                        }else if (res.data[0].f_infra_obj_infos_name_rus !== undefined){
                            this.setState({infosFeature:res.data,updateDate:update_date,infosDisplay:'block',infosName:res.data[0].f_infra_obj_infos_name_rus,infosDesc:res.data[0].description,infosEditorName:res.data[0].username});
                        }else if (res.data[0].name_ru !== undefined){
                            this.setState({infosFeature:res.data,updateDate:update_date,infosDisplay:'block',infosName:res.data[0].name_ru,infosDesc:"",infosEditorName:res.data[0].username});
                        }else if (res.data[0].well_name !== undefined){
                            let keys = Object.keys(res.data[0])
                            let infos_key = keys[0];
                            let PermQuary = {type:"get_Groups_Permission",select_type:"get_Groups_Permission_For_Object",select_params:{object_id:res.data[0].wells_id,user_id:Cookies.get("id"),layer_id:infos_key.split('_')[0]}}
                            axios.post(urlBackend+'/postQuery', {query: PermQuary})
                                .then((res2) => {
                                    console.log(res2,res2.data[0],res.data[0])
                                    console.log(res2.data.length)
                                    let perm = '' ;
                                    if (res2.data[0] !== undefined) {
                                        for (let i = 0; i < res2.data.length;i++) {
                                            console.log(res2.data[i].f_acl_perm_types_code,res2.data[i].f_acl_perm_types_code !== null)
                                            if (res2.data[i].f_acl_perm_types_code !== null){
                                                if (res2.data[i].f_acl_perm_types_code.replaceAll(' ','') === "change") {
                                                    perm = res2.data[i].f_acl_perm_types_code
                                                }   else if(res2.data[i].f_acl_perm_types_code.replaceAll(' ','') === "read" && !(perm.replaceAll(' ','') === "change" )) {
                                                    perm = res2.data[i].f_acl_perm_types_code
                                                }
                                                console.log(perm,res2.data[i].f_acl_perm_types_code.replaceAll(' ',''),res2.data[i].f_acl_perm_types_code.replaceAll(' ','') === "change",res2.data[i].f_acl_perm_types_code.replaceAll(' ','') === "read")
                                            }

                                        }
                                    }
                                    console.log(perm)
                                    axios.post(urlBackend+'/postQuery', {query: {type:"get_Infos_Author",select_type:"get_Infos_Author",select_params:{infos_id:res.data[0][infos_key],column:infos_key,layer:infos_key.split('_')[0]}}})
                                        .then((res3) => {
                                            console.log(res3,(Number.parseInt(res3.data[0].user_id) === Number.parseInt(Cookies.get("id"))),(Number.parseInt(res3.data[0].author_id) === Number.parseInt(Cookies.get("id"))))
                                            if (Number.parseInt(res3.data[0].author_id) === Number.parseInt(Cookies.get("id"))) {
                                                if (res2.data[0] !== undefined)
                                                    this.setState({infosPerm:perm,updateDate:update_date,infosFeature:res.data,infosDisplay:'block',infosName:res.data[0].well_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username,infosAuthorID:true})
                                                else
                                                    this.setState({infosPerm:'',updateDate:update_date,infosFeature:res.data,infosDisplay:'block',infosName:res.data[0].well_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username,infosAuthorID:res3.data[0].author_id})
                                            }else if (Number.parseInt(res3.data[0].user_id) === Number.parseInt(Cookies.get("id"))){
                                                if (res2.data[0] !== undefined)
                                                    this.setState({infosPerm:perm,updateDate:update_date,infosFeature:res.data,infosDisplay:'block',infosName:res.data[0].well_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username,infosAuthorID:true})
                                                else
                                                    this.setState({infosPerm:'',updateDate:update_date,infosFeature:res.data,infosDisplay:'block',infosName:res.data[0].well_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username,infosAuthorID:res3.data[0].author_id})
                                            }
                                            else {
                                                if (res2.data[0] !== undefined)
                                                    this.setState({infosPerm:perm,updateDate:update_date,infosFeature:res.data,infosDisplay:'block',infosName:res.data[0].well_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username,infosAuthorID:false})
                                                else
                                                    this.setState({infosPerm:'',updateDate:update_date,infosFeature:res.data,infosDisplay:'block',infosName:res.data[0].well_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username,infosAuthorID:res3.data[0].author_id})

                                            }

                                        })
                                        .catch((error)=>{
                                            console.log(error)
                                        })
                                })
                                .catch((error)=>{console.log(error)})
                                //this.setState({infosFeature:res.data,infosDisplay:'block',infosName:res.data[0].well_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username});
                        }else if (res.data[0].original_name !== undefined){
                            this.setState({infosFeature:res.data,updateDate:update_date,infosDisplay:'block',infosName:res.data[0].original_name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username});
                        } else if (res.data[0].name !== undefined){
                            this.setState({infosFeature:res.data,updateDate:update_date,infosDisplay:'block',infosName:res.data[0].name,infosDesc:res.data[0].description,infosEditorName:res.data[0].username});
                        } else{
                            this.setState({infosFeature:res.data,updateDate:update_date,infosDisplay:'block',infosName:"",infosDesc:"",infosEditorName:res.data[0].username});}
                    }

                }).catch((error) => {
                console.log(error,"Something wrong",this.state.infosQuery)
            });
        }
    };
    onClickGeoJson = (event) => {
        let id = event.features[0].id;
    };
    onFeatureListClose = () => {
        this.setState({featuresDisplay:'none'});
    };
    onGuestInfos = () => {
        LoginDisplay('block');
        this.setState({popupDisplay:'none',loginDisplay:'block'})
    };
    onFeatureListClick = (event,data) => {
        //console.log(data.content[5])
        let proxy = {};
        proxy.features = [data.content[5]];
        proxy.point = {x:this.state.x,y:this.state.y};
        //console.log(proxy);
        this.onClick(proxy);
    };
    onClick = (event) => {
        console.log(event)
        var elem2 = document.getElementsByClassName("infos");
        //console.log(event)
        if ( isMobile){
           // elem2.style.left = '30%';
            //elem2.style.width = '25%'
        }
        if (event.features.length>0 && !this.state.drawState){
            let id = event.features[0].id;
            console.log(event.features[0],event.features[0].id);
            let sourceLayer = event.features[0].sourceLayer;
            console.log(sourceLayer,event,event.features,event.features[0]);
            if (sourceLayer === "public.geoobjecttest")
                sourceLayer = "public.geo_objectstest"
            if (sourceLayer === "public.co2_emissionstest"){
                sourceLayer = "public.co2_orgstest"
            }
            if (sourceLayer === "public.protectedareastest")
                sourceLayer = "public.protected_areastest"
            let source = sourceLayer.substring(7,sourceLayer.length).replace('test','');
            let hoveredID = event.features[0].id;
            let hoveredSourceLayer = event.features[0].source;
            let hoveredSourceID = event.features[0].sourceLayer;
            let description = event.features[0].properties.description;
            let req_columns = "*";


            if ( Cookies.get('id') !== undefined) {
                let query = {type:"get_user_infos",select_type:"get_user_infos",select_params:{
                        object_id: source + "_id",
                        infos_id:source + "_infos" + "_id",
                        infos:source + "_infos",
                        user:Cookies.get('id'),
                        id:id,
                    }}
                if (sourceLayer === "public.co2_orgstest"){
                    query = {type:"get_user_infos",select_type:"get_user_infos",select_params:{
                            object_id:"f_"+ source + "_id",
                            infos_id:"f_co2_org_infos_id",
                            infos:source + "_infos",
                            user:Cookies.get('id'),
                            id:id,
                        }}
                }
                if (sourceLayer === "public.msu_branchestest"){
                    query = {type:"get_user_infos",select_type:"get_user_infos",select_params:{
                            object_id:"f_msu_branches_id",
                            infos_id:"f_msu_branches_infos_id",
                            infos:source + "_infos",
                            user:Cookies.get('id'),
                            id:id,
                        }}
                }
                if (sourceLayer === "public.infra_obj"){
                    query = {type:"get_user_infos",select_type:"get_user_infos",select_params:{
                            object_id:"f_infra_obj_id",
                            infos_id:"f_infra_obj_infos_id",
                            infos:"infra_obj_infos",
                            user:Cookies.get('id'),
                            id:id,
                        }}
                }
                if (sourceLayer === "public.co2_world_sources"){
                    query = {type:"get_user_infos",select_type:"get_user_infos",select_params:{
                            object_id:"f_"+ source + "_id",
                            infos_id:"f_co2_world_sources_infos_id",
                            infos:source + "_infos",
                            user:Cookies.get('id'),
                            id:id,
                        }}
                }
                axios.post(urlBackend+'/postQuery', {query:query})
                    .then((res) => {
                        console.log(res.data[0])
                        if (res.data[0] !== undefined)
                        if ( res.data[0] !== undefined && res.data[0].status === "TRUE")
                        {
                            let query = {type:'get_'+source + "_infos",select_type:'get_infos2',select_params:{req_column:req_columns,layer:source + "_infos",column :source + "_id",object_id:id,infos_id:res.data[0].id}}
                            let query2 = {type:'get_'+source + "_infos",select_type:'get_infos2',select_params:{req_column:req_columns,layer:source + "_infos",column :source + "_id",object_id:id,infos_id:res.data[0].id}}
                            if (res.data[0].userobject) {
                                query2 = {type:'get_'+source + "_infos",select_type:'get_infos',select_params:{req_column:req_columns,layer:source + "_infos",column :source + "_id",object_id:id,infos_id:res.data[0].id}}

                            }
                            console.log(query,query2)
                            if ( this.state.drawState === false){
                                let disp = "block";
                                if(Cookies.get('id') !== undefined)
                                    disp = "none"
                                if (source === "geo_objects")
                                    this.setState({dataReady:{type:"get_geom",select_type:"get_geom",select_params:{
                                                object_id: sourceLayer.substring(7,sourceLayer.length).replace('test','')+"_id",
                                                layer:sourceLayer.substring(7,sourceLayer.length).replace('test',''),
                                                id:id,
                                            }},x:event.point.x,y:event.point.y,infosPerm:"edit",infosUserObject:res.data[0].userobject,textPopup:event.features[0].properties.original_name,popupDisplay:disp,infosQuery2:query2,infosQuery:query,infoLayer:source,infosDesc:description,  hoveredID,hoveredSourceLayer,hoveredSourceID,clickID:id,infosFeature:[{basin_name:""}],infosAuthorID:null,infosName:"",infosEditorName:""});
                                else
                                    this.setState({dataReady:{type:"get_geom",select_type:"get_geom",select_params:{
                                                object_id: sourceLayer.substring(7,sourceLayer.length).replace('test','')+"_id",
                                                layer:sourceLayer.substring(7,sourceLayer.length).replace('test',''),
                                                id:id,
                                            }},x:event.point.x,y:event.point.y,infosPerm:"edit",infosUserObject:res.data[0].userobject,textPopup:event.features[0].properties.OriginalName,popupDisplay:disp,infosQuery2:query2,infosQuery:query,infoLayer:source,infosDesc:description,  hoveredID,hoveredSourceLayer,hoveredSourceID,clickID:id,infosFeature:[{basin_name:""}],infosAuthorID:null,infosName:"",infosEditorName:""}
                                        , () => {
                                        console.log('after setstate');
                                    })
                                if(Cookies.get('id') !== undefined) {
                                    console.log("infos")
                                    if (res.data[0].userobject){

                                        setTimeout(()=>{this.handleInfosPopup(null,true,query,query2);},500)
                                    }

                                    else
                                        setTimeout(()=>{this.handleInfosPopup(null,null,query,query2);},500)

                                }
                            }
                        }
                        else {
                            let query = {type:'get_'+source + "_infos",select_type:'get_infos2',select_params:{req_column:req_columns,layer:source + "_infos",column :source + "_id",object_id:id}}
                            let query2 = {type:'get_'+source + "_infos",select_type:'get_infos2',select_params:{req_column:req_columns,layer:source + "_infos",column :source + "_id",object_id:id}}
                            //let query = "Select "+req_columns+" From "+source + "_infos"+" Where "+col+"=" + id + " and view_state is null";

                            //this.setState({infosQuery:query,clickID:id,infosDesc:"",objID:id});
                            console.log(this.state.drawState,query,query2,Cookies.get('id'))
                            if ( this.state.drawState === false){
                                console.log("no draw state")
                                let disp = "block";
                                if(Cookies.get('id') !== undefined)
                                    disp = "none"
                                console.log("after timeout",source)
                                if (source === "geo_objects" || source ==='co2_orgs'){
                                    console.log("infos popup1")
                                    this.setState({dataReady:{type:"get_geom",select_type:"get_geom",select_params:{
                                                object_id: sourceLayer.substring(7,sourceLayer.length).replace('test','')+"_id",
                                                layer:sourceLayer.substring(7,sourceLayer.length).replace('test',''),
                                                id:id,
                                            }},x:event.point.x,y:event.point.y,infosPerm:"edit",infosUserObject:res.data[0].userobject,textPopup:event.features[0].properties.original_name,popupDisplay:disp,infosQuery2:query2,infosQuery:query,infoLayer:source,infosDesc:description,  hoveredID,hoveredSourceLayer,hoveredSourceID,clickID:id,infosFeature:[{basin_name:""}],infosName:"",infosEditorName:"",infosAuthorID:null});
                                    console.log(" after set state")
                                }
                                else{
                                    console.log("infos popup1")
                                    this.setState({dataReady:{type:"get_geom",select_type:"get_geom",select_params:{
                                                object_id: sourceLayer.substring(7,sourceLayer.length).replace('test','')+"_id",
                                                layer:sourceLayer.substring(7,sourceLayer.length).replace('test',''),
                                                id:id,
                                            }},x:event.point.x,y:event.point.y,infosPerm:"edit",infosUserObject:res.data[0].userobject,textPopup:event.features[0].properties.OriginalName,popupDisplay:disp,infosQuery2:query2,infosQuery:query,infoLayer:source,infosDesc:description,  hoveredID,hoveredSourceLayer,hoveredSourceID,clickID:id,infosFeature:[{basin_name:""}],infosName:"",infosEditorName:"",infosAuthorID:null}
                                        , () => {
                                            console.log('after setstate');
                                        })
                                    console.log(" after set state")
                                }
                                console.log(" after set state")
                                    if(Cookies.get('id') !== undefined){
                                    console.log("infos popup")
                                    this.handleInfosPopup(null,null,query,query2)
                                }

                            }
                        }
                        //user_status = res.data;
                    }).catch((error) => {
                });
            }else{
                let disp = "block";
                if(Cookies.get('id') !== undefined)
                    disp = "none"
                this.setState({x:event.point.x,y:event.point.y,infosPerm:"edit",textPopup:event.features[0].properties.OriginalName,popupDisplay:disp,infoLayer:source,infosDesc:description,clickID:id});
            }
        }
    };

    onProfileChange = (x,y) => {
        this.setState({profileDisplay:"none"})
    };
    onLayerChange2 = () => {
        var user_id;
        if (Cookies.get("id") !== undefined){
            if (only_my === false)
                user_id = "?user_id="+  Cookies.get("id");
            else
                user_id = "?user_id="+  Cookies.get("id") + "&only_my=true"  ;               }
        else {
            user_id = "?user_id=0";}
        this.setState({user_id:user_id})
    };
    onLayerMove = () => {
        setTimeout(() => {
            /*const map = this.reactMap.getMap();
            let sources = map.style.sourceCaches;
            console.log(result,dataname)
            for (let i = dataname.length-1;i>=0;i--){
                for (let j = 0; j < result.length; j++) {
                    if (result[j].name === dataname[i].name){
                        if (result[j].visibility === 'visible'){
                            console.log(result[j].id)
                            map.moveLayer(result[j].id,null)
                            this.drawLayerOrder();
                        }

                    }
                }
            }*/
        },100);
    };
    onMobile = (word) => {
        if (word === "Login")
            if (this.state.loginDisplay === "none")
                this.setState({loginDisplay:"block"})
            else
                this.setState({loginDisplay:"none"})
        else if (word === "Register")
            window.open(regURL, "_blank")
        else if (word === "Reset")
        {
            localStorage.clear();
            window.location.reload();
        }
        else if (word === "About")
            if (this.state.aboutDisplay === "none")
                this.setState({inc:this.state.inc+1,aboutDisplay:"block",hoveredID: null, hoveredSourceLayer: null, hoveredSourceID: null})
            else
                this.setState({inc:this.state.inc+1,aboutDisplay:"none",hoveredID: null, hoveredSourceLayer: null, hoveredSourceID: null})
        this.setState({inc:this.state.inc+1,hoveredID: null, hoveredSourceLayer: null, hoveredSourceID: null})

    };
    onFeaturestableComplete =  (FilterPropsList) => {
        console.log(FilterPropsList)
        let filterPropsDisplay="block";
        this.setState({filterPropsDisplay:filterPropsDisplay,filterPropsList:FilterPropsList})
    }
    onFeaturestable = (layerID) => {
        this.setState({filterPropsLayer:layerID})
    }
    onLayerChange = () => {
        this.onLayerChange2()
        this.setState({inc:this.state.inc+1});
    };
    onHover = event => {
        //console.log(event)
        if (event.features.length > 0) {
            if (event.features[0].id !== this.state.hoveredID){
            const hoveredID = event.features[0].id;
            const hoveredSourceLayer = event.features[0].source;
            const hoveredSourceID = event.features[0].sourceLayer;
            if (hoveredID !== this.state.hoveredID)
                for (let i = 0 ; i < result.length;i++){
                    if (result[i].sourcelayer === hoveredSourceID)
                        if (result[i].hover === true)
                            this.setState({ hoveredID,hoveredSourceLayer,hoveredSourceID });
                }
            }
        }
    };
    onLeave = () => {
        if (this.state.hoveredID) {
            this.setState({ hoveredID: null,hoveredSourceLayer:null,hoveredSourceID:null });
        }
    };
    onRedactChange = event => {
        console.log(event)
        if (event.features.length >0 && Cookies.get("id") !== undefined){
            let type =  event.features[0].geometry.type;
            let coordinates = event.features[0].geometry.coordinates.slice();
            let coordinates2 = coordinates.slice();
            let coordinates3 = event.features[0].geometry;

            if ( type === "MultiPolygon") {
                let newcoord =[];
                let newcoord2 = [];
                let newcoord3 = [];
                for (let j = 0 ; j< coordinates2[0].length;j++){
                    newcoord3 = [];
                    for ( let k = 0 ;k<coordinates2[0][j].length;k++){
                        newcoord3.push(coordinates2[0][j][k][0] + " " + coordinates2[0][j][k][1]) ;
                    }
                    newcoord2.push(newcoord3);
                }
                for ( let j = 0 ; j < newcoord2.length;j++){
                    newcoord = newcoord+ "("+newcoord2[j].toString() + "),"
                }
                this.setState({dataForRedact:{"type":"FeatureCollection","features":event.features},redactionCoord:"MULTIPOLYGON(("+newcoord.slice(0, -1)+"))",redactDisplay:"block"})
            }
            else if ( type === "Polygon") {
                for (let i = 0 ; i < coordinates[0].length;i++){
                    coordinates[0][i] = coordinates[0][i][0] + " " + coordinates[0][i][1];
                }
                let array2 = [[]];
                let length = event.features[0].geometry.coordinates[0].length;
                for (let i = 0;i < length;i++) {
                    array2[0][i] = event.features[0].geometry.coordinates[0][length - (i + 1)]
                }
                    let res3 = [];
                    for (let i = 0;i < coordinates2[0].length;i++) {
                        let res = coordinates2[0][i].split(" ");
                        res3.push([parseFloat(res[0]),parseFloat(res[1])]);
                    }
                let string = JSON.stringify(array2);
                let data2 = JSON.stringify(event.features);
                let data ='{"type":"FeatureCollection","features":[{"id":"292c5122dcd98a1db4d6526e7adb14e3","type":"Feature","properties":{},"geometry":{"coordinates":['+JSON.stringify(res3)+'],"type":"Polygon"}}]}';
                data = JSON.parse(data);
                this.setState({dataForRedact:data,redactionCoord:"POLYGON(("+coordinates.toString()+"))",redactDisplay:"block"})
            }
            else if(type === 'Point') {
                let data ='{"type":"FeatureCollection","features":[{"id":"292c5122dcd98a1db4d6526e7adb14e3","type":"Feature","properties":{},"geometry":{"coordinates":['+coordinates[0]+','+coordinates[1]+'],"type":"Point"}}]}'
                data = JSON.parse(data);
                this.setState({dataForRedact:data,redactionCoord:"POINT("+coordinates[0].toString()+" "+coordinates[1].toString()+")",redactDisplay:"block"})
            }
            else if(type === 'LineString') {
                let data ='{"type":"FeatureCollection","features":[{"id":"292c5122dcd98a1db4d6526e7adb14e3","type":"Feature","properties":{},"geometry":{"coordinates":'+JSON.stringify(coordinates)+',"type":"LineString"}}]}';
                let linestring = "";
                for ( let i = 0 ;i<coordinates2.length;i++){
                    linestring = linestring +" " + + coordinates2[i][0] + " " + coordinates2[i][1]+ ","
                }
            let data2 = JSON.parse(data)
                this.setState({dataForRedact:JSON.parse(data),redactionCoord:"LINESTRING("+linestring.substring(0, linestring.length - 1)+")",redactDisplay:"block"})
            }
    }
    };
    deletehandle = (layer,layer_id) => {
        if (Cookies.get("id") !== undefined) {
            let query = {type:"delete_object", select_type:"delete", select_params:{layer:layer,object_id:this.state.clickID,user_id:Cookies.get("id")}}
            axios.post(urlBackend+'/postQuery', {query:query})
                .then((res) => {
                    this.onLayerChange2();
                    this.handleAlert("success","Object successfully updated.")

                }).catch((error) => {
                    console.log(error)
                    this.handleAlert("error","Error while deleting object.")
            });
            this.handleCloseInfos();

        }
    };
    layershandle = () => {
        if ( this.state.layerDisplay === "none")
            this.setState({layerDisplay: "block"})
        else
            this.setState({layerDisplay:"none"})
    };
    searchhandle = () => {
        this.setState({searchDisplay: SearchDisplay})
    };
    imagehandle = ()=>{
        if (this.state.imageDisplay === "none")
            this.setState({imageDisplay:"block"})
        else
            this.setState({imageDisplay:"none"})
    };
    shapehandle = ()=>{
        this.setState({shapeDisplay:ShapeDisplay()})
    };
    loaderhandle = (value)=>{
        let PermQuary = {type:"get_Groups_Permission",select_type:"get_Groups_Permission_Without_Object",select_params:{user_id:Cookies.get("id")}}
        axios.post(urlBackend+'/postQuery', {query: PermQuary})
            .then((res) => {
                if (this.state.loaderDisplay === "none")
                    this.setState({loaderDisplay:"block",loaderProp:value,groupPermArray:res.data[0].get_permission_groups,groupPermArrayBasic:JSON.parse(JSON.stringify(res.data[0].get_permission_groups))})
                else
                    this.setState({loaderDisplay:"none",loaderProp:value,groupPermArray:res.data[0].get_permission_groups,groupPermArrayBasic:JSON.parse(JSON.stringify(res.data[0].get_permission_groups))})

            })
            .catch((error)=>{console.log(error)})
    };
    tiffloaderhandle = (value)=>{
        if (this.state.tiffLoaderDisplay=== "none" )
            this.setState({tiffLoaderDisplay:"block",tiffLoaderName:value})
        else
            this.setState({tiffLoaderDisplay:"none",tiffLoaderName:""})
    };
    tiffloaderdisplay = ()=>{
        if (this.state.tiffLoaderDisplay=== "none")
            this.setState({tiffLoaderDisplay:"block"})
        else
            this.setState({tiffLoaderDisplay:"none",tiffLoaderName:""})
    };
    loaderdisplay = ()=>{
        let PermQuary = {type:"get_Groups_Permission",select_type:"get_Groups_Permission_Without_Object",select_params:{user_id:Cookies.get("id")}}
        axios.post(urlBackend+'/postQuery', {query: PermQuary})
            .then((res) => {
                if (this.state.loaderDisplay === "none")
                    this.setState({loaderDisplay:"block",groupPermArray:res.data[0].get_permission_groups,groupPermArrayBasic:JSON.parse(JSON.stringify(res.data[0].get_permission_groups))})
                else
                    this.setState({loaderDisplay:"none",groupPermArray:res.data[0].get_permission_groups,groupPermArrayBasic:JSON.parse(JSON.stringify(res.data[0].get_permission_groups))})

            })
            .catch((error)=>{console.log(error)})

    };
    kernhandle = ()=>{
        if (this.state.kernDisplay === "none")
            this.setState({kernDisplay:"block"})
        else
            this.setState({kernDisplay:"none"})
    };
    wikihandle = ()=>{
        this.setState({wikiDisplay:WikiDisplay()})
    };
    landinghandle = () =>{
        this.setState({landing:!this.state.landing})
    }
    helphandle = ()=>{
        if (this.state.helpDisplay === "none")
            this.setState({helpDisplay:"block"})
        else
            this.setState({helpDisplay:"none"})
    };
    lashandle = ()=>{
        if (this.state.lasDisplay === "none")
            this.setState({lasDisplay:"block"})
        else
            this.setState({lasDisplay:"none"})
    };
    lasHandler = (fileurl)=>{
        console.log(fileurl)
        this.setState({lasDisplay:"block",lasURL:fileurl})
    }
    reserveshandle = ()=>{
        if (this.state.reservesDisplay === "none")
            this.setState({reservesDisplay:"block"})
        else
            this.setState({reservesDisplay:"none"})
    };
    model3dhandle = () =>{
        if (this.state.Model3dDisplay === "none")
            this.setState({Model3dDisplay:"block",url3d:null})
        else
            this.setState({Model3dDisplay:"none",url3d:null})
    }
    model3dhandleinfos = (url) =>{
        this.setState({Model3dDisplay:"block",url3d:url})
    }
    analogshandle = () =>{
        if (this.state.AnalogDisplay === "none"){
            axios.post(urlBackend+'/postQuery', {query:{type:'get_reserves_filter',select_type:'get_reserve_params_by_categories',select_params:{}}})
                .then((res) => {
                    axios.post(urlBackend+'/postQuery', {query:{type:'get_reserves_filter',select_type:'get_reserve_sediment_accumulation',select_params:{}}})
                        .then((res2) => {
                            console.log(res,res.data[0].json_build_object,res2)
                            let categories_json= res.data[0].json_build_object;
                            let dicts_names = Object.keys(res2.data[0]);
                            let dicts = res2.data[0]
                            let categories_keys = Object.keys(categories_json);
                            console.log(categories_json,categories_keys)
                            //console.log(res_data,dicts)
                            this.setState({AnalogDisplay:"block",AnalogData:categories_json, AnalogCategories:categories_keys, AnalogDict:dicts, AnalogDictNames:dicts_names,})
                        })
                        .catch((err) => {console.log(err);})


                })
                .catch((err) => {console.log(err);})

        }
        else
            this.setState({AnalogDisplay:"none",AnalogData:null, AnalogCategories:null, AnalogDict:null, AnalogDictNames:null,})
    }
    authhandle = () => {
        if (Cookies.get("id")!==undefined)
            this.setState({auth: true});
        else
            this.setState({auth: false})
        this.onLayerChange2()
    };
    filterhandle = () => {
        this.setState({filterDisplay:(this.state.filterDisplay === "block" ? "none" : "block")})
    };
    loginhandle = () => {
        this.setState({loginDisplay:(this.state.loginDisplay === "block" ? "none" : "block")})
    };
    searchclickhandle  = () => {
        const map = this.reactMap.getMap();
        map.zoomTo(1, { duration: 1500 });
        let newArray = this.state.checkboxList;
        if (newArray.length > 0) {
            let featuresJoined = turf.union.apply(this, newArray);
            let bbox = turf.bbox(featuresJoined);
            map.fitBounds(bbox, {
                padding: 20
            });
        }
    };
    onLassoState = (state) => {
        this.setState({userActive:state})
    };
    onLassoListClose = () => {
        this.setState({lassoCoordinates:[],
            lassoVisibility:"none",
            lassoList:[],
            lassoDisplay:"none",
            lassoIds:[],
            lassoFilter:false})
        this.onLayerChange();
    };
    onLassoListClick = (event,data) => {
        let proxy = {};
        proxy.features = [data.content[5]];
        proxy.point = {x:this.state.x,y:this.state.y};
        proxy.features[0].properties= {OriginalName:name};
        this.onClick(proxy);
    };
    onLassoListFilter = () => {
        let arrayID = [];
        if ( this.state.lassoFilter){
            this.setState({lassoFilter:false})
        }
        else{
        for (let i =0 ; i <this.state.lassoList.length;i++){
            arrayID.push(this.state.lassoList[i].id)
        }
        this.setState({lassoIds:arrayID, lassoFilter:true})
        }
        this.onLayerChange();
    };
    checkboxhandle  = (event,data) => {
        let arr = this.state.checkboxList;
        let hoverarr = this.state.hoveredArray;
        let checkarr = this.state.checkList;
        if (data.checked === true) {
            arr.push(data.value);
            hoverarr.push({id:data.value.id,source:data.value.source,sourceLayer:data.value.sourceLayer});
            checkarr.push({id:data.value,name:data.value.OriginalName})
        }
        else if (contains(arr,data.value) !== false) {
            arr.splice(contains(arr,data.value),1);
            hoverarr.splice(contains(arr,data.value),1);
            checkarr.splice(contains(arr,data.value),1);
        }
        console.log(hoverarr)
        this.setState({checkboxList:arr,hoveredArray:hoverarr,checkList:checkarr});
    };
    mapgetcenter  = () => {
        const map = this.reactMap.getMap();
        const center = map.getCenter();
        this.setState({currentCenter:center});
    };
    displayApiResult = (geojson) => {
        const map = this.reactMap.getMap();
        if (map.getSource("wikimapia")) {
            map.getSource('wikimapia').setData(geojson);
        }
        else {
            map.addSource('wikimapia', {
                'type': 'geojson',
                'data': geojson
            });

// Add a new layer to visualize the polygon.
            map.addLayer({
                'id': 'wikimapia',
                'type': 'fill',
                'source': 'wikimapia', // reference the data source
                'layout': {},
                'paint': {
                    'fill-color': '#ff0a00', // blue color fill
                    'fill-opacity': 0.8
                }
            });
        }
        let popup2 = new mapboxgl.Popup();
        map.on('click', "wikimapia", function(e) {
            popup2.remove();
                var description = JSON.stringify(e.features[0].properties);
                description = description.replace("{","");
                description = description.replace("}","");
                description = description.replace(/,/g, '<br/>');
                popup2
                    .setLngLat(e.lngLat)
                    .setHTML(description)
                    .addTo(map);
        });
    };
    searchinputhandle= (text) => {
        let searcharray = [];
        var elem = 100;
        if (document.getElementById("search") !== undefined){
            elem = document.getElementById("search").offsetLeft;
        }
        if ( document.getElementsByClassName("map-overlay")[0] !== undefined)
            var elem2 = document.getElementsByClassName("map-overlay")[0];
        else
            var elem2 = document.getElementsByClassName("map-overlay-mobile")[0];

        elem2.style.left = elem+'px';
        if ( isMobile)
            elem2.style.width = '50%'
        if (text.length > 2) {
            let list = this.state.searchList;
            let visiblelayers = [];
            for(let i = 0 ; i < result.length;i++){
                if ((result[i].visibility === 'visible' && result[i].name !== "Info" && result[i].type !== "geojson" && result[i].type !== "image")||(result[i].name === "Basins" || result[i].name === "Fields" || result[i].name === "Licenses")){
                    switch (result[i].name){
                        case "Basins":
                            visiblelayers.push("v_basins")
                            break;
                        case "SubBasins":
                            visiblelayers.push("v_subbasins")
                            break;
                        case "Licenses":
                            visiblelayers.push("v_licenses")
                            break;
                        case "Fields":
                            visiblelayers.push("v_fields")
                            break;
                        case "Wells" :
                            visiblelayers.push("v_wells")
                            break;
                        case "GeologicObjects":
                            visiblelayers.push("v_geo_objects")
                            break;
                        case "TectonicMap" :
                            visiblelayers.push("v_tectonicmap")
                            break;
                        case "GeologicMap":
                            visiblelayers.push("v_geologicmap")
                            break;
                        case "ProtectedAreas" :
                            visiblelayers.push("v_protected_areas");
                            break;
                        case "Regions" :
                            visiblelayers.push("v_regions");
                            break;
                    }
                }
            }
            let query2 = ""
            if (text !== undefined)
                query2 = {type: "search", select_type: "search_new",select_params:{layers:visiblelayers,search:text.toLowerCase(),user_id:Cookies.get("id")}}
            axios.post(urlBackend+'/postQuery', {query: query2})
                .then((res) => {
                    console.log(res.data)
                    console.log(res.data[0])
                    console.log(res.data[0].search_from_layers_with_perm)
                    for( let i = 0 ; i < res.data[0].search_from_layers_with_perm.length;i++){
                        console.log(res.data[0].search_from_layers_with_perm[i])
                        if ( res.data[0].search_from_layers_with_perm[i].found_value !== null && res.data[0].search_from_layers_with_perm[i].geom !== null && res.data[0].search_from_layers_with_perm[i].optional !== null && res.data[0].search_from_layers_with_perm[i].order !== null && res.data[0].search_from_layers_with_perm[i].qc_id !== null && res.data[0].search_from_layers_with_perm[i].similarity !== null && res.data[0].search_from_layers_with_perm[i].sourceid !== null && res.data[0].search_from_layers_with_perm[i].sourcetable !== null){
                            console.log("after skip",res.data[0].search_from_layers_with_perm[i])
                            for (let j = 0 ; j < result.length; j++){
                                console.log(result[j].name.toLowerCase() , res.data[0].search_from_layers_with_perm[i].sourcetable)
                                if (result[j].name.toLowerCase() === res.data[0].search_from_layers_with_perm[i].sourcetable){
                                    console.log("match",result[j].name.toLowerCase() , res.data[0].search_from_layers_with_perm[i].sourcetable)
                                    res.data[0].search_from_layers_with_perm[i].sourceLayer = result[j].sourcelayer;
                                    res.data[0].search_from_layers_with_perm[i].source = result[j].id;
                                    res.data[0].search_from_layers_with_perm[i].sourceid = result[j].id;
                                }
                            }
                            //res.data[0].search_from_layers_with_perm[i].sourceLayer = 'public.'+res.data[i].sourcetable;
                            //.data[0].search_from_layers_with_perm[i].source =res.data[i].sourceid;
                            res.data[0].search_from_layers_with_perm[i].coordinates = JSON.parse(res.data[0].search_from_layers_with_perm[i].geom).coordinates;
                            res.data[0].search_from_layers_with_perm[i].id = res.data[0].search_from_layers_with_perm[i].qc_id;
                            res.data[0].search_from_layers_with_perm[i].geometry = JSON.parse(res.data[0].search_from_layers_with_perm[i].geom);
                            res.data[0].search_from_layers_with_perm[i].type = res.data[0].search_from_layers_with_perm[i].geometry.type;
                            console.log(res.data[0].search_from_layers_with_perm[i])
                            searcharray.push({
                                name: res.data[0].search_from_layers_with_perm[i].found_value,
                                id: res.data[0].search_from_layers_with_perm[i].qc_id,
                                source: res.data[0].search_from_layers_with_perm[i].sourceid,
                                sourceLayer: res.data[0].search_from_layers_with_perm[i].sourceLayer,
                                coordinates: res.data[0].search_from_layers_with_perm[i].coordinates,
                                optional:res.data[0].search_from_layers_with_perm[i].optional,
                                feature:res.data[0].search_from_layers_with_perm[i]
                            });}
                    }
                    searcharray = Array.from(new Set(searcharray.map(JSON.stringify))).map(JSON.parse);
                    if (searcharray.length > 10)
                        searcharray = searcharray.slice(0, 9);
                    console.log(searcharray);
                    this.setState({searchDisplay:'block',list: searcharray, checkboxList: []});

                }).catch((error) => {
                console.log(error)
            });


        }
        else
        {
            this.setState({list:[], checkboxList: [],searchDisplay:'none'});
        }

    };
    searchfocushandle= () => {
        this.setState({searchDisplay:'inline-block'});
    };
    searchblur2handle= (e) => {
        var a = document.getElementById("search-input");// a = HTMLCollection
        if (a.value !="") {
            a.value = "";
        }
        this.setState({
            searchDisplay:'none',list:[]
        });
    };
    searchblurhandle= (e,enter) => {
        console.log(e,enter)
        var a = e;
        if (a.length > 2){
            if (enter) {
                console.log(a,e)
                axios.get('https://nominatim.openstreetmap.org/search?format=json&q='+encodeURIComponent(a))
                    .then((res)=>{
                        console.log(res)
                        let results = res.data;
                        let list =  [];
                        for (let i = 0; i < results.length; i++) {
                            let osm_coords = [[[Number.parseFloat(results[i].boundingbox[2]), Number.parseFloat(results[i].boundingbox[1])],
                                [Number.parseFloat(results[i].boundingbox[3]), Number.parseFloat(results[i].boundingbox[1])],
                                [Number.parseFloat(results[i].boundingbox[3]), Number.parseFloat(results[i].boundingbox[0])],
                                [Number.parseFloat(results[i].boundingbox[2]), Number.parseFloat(results[i].boundingbox[0])]]]
                            console.log(results[i],osm_coords)
                            let center = [(Number.parseFloat(results[i].boundingbox[3]) + Number.parseFloat(results[i].boundingbox[2]))/2,(Number.parseFloat(results[i].boundingbox[0]) + Number.parseFloat(results[i].boundingbox[1]))/2]
                            console.log(center)
                            list.push({
                                coordinates: osm_coords,
                                center:center,
                                feature: {
                                    coordinates: osm_coords,
                                    geometry: {
                                        type: "Polygon",
                                        coordinates: osm_coords
                                    },
                                    id: results[i].place_id,
                                    qc_id: results[i].place_id,
                                    optional: results[i].type,
                                    center:center,
                                    originalname: results[i].display_name,
                                    source: "OSM " + results[i].type,
                                    sourceLayer: "OSM " + results[i].type,
                                    sourceid: "OSM " + results[i].type,
                                    sourcetable: "OSM " + results[i].type,
                                    type: "Polygon"
                                },
                                id: results[i].place_id,
                                name: results[i].display_name,
                                optional: results[i].type,
                                optional2: "OSM " + results[i].type,
                                source: "OSM " + results[i].type,
                                sourceLayer: "OSM " + results[i].type
                            })
                        }
                    console.log(list);
                    //this.setState({searchDisplay: 'block', list: [ ...list], checkboxList: []});
                    if (list.length > 0) {
                        let newlist = [];
                        let arraydata = {content: []};
                        arraydata.content.push(list[0].name);
                        arraydata.content.push(list[0].id);
                        arraydata.content.push(list[0].source);
                        arraydata.content.push(list[0].sourceLayer);
                        arraydata.content.push(list[0].coordinates);
                        arraydata.content.push(list[0].feature);
                        arraydata.content.push(true);
                        newlist.push(list[0]);
                        //his.setState({list:list});
                        let searchlist = this.state.searchList;
                        let visiblelayers = [];
                        for(let i = 0 ; i < result.length;i++){
                            if ((result[i].visibility === 'visible' && result[i].name !== "Info" && result[i].type !== "geojson" && result[i].type !== "image")||(result[i].name === "Basins" || result[i].name === "Fields" || result[i].name === "Licenses")){
                                switch (result[i].name){
                                    case "Basins":
                                        visiblelayers.push("v_basins")
                                        break;
                                    case "SubBasins":
                                        visiblelayers.push("v_subbasins")
                                        break;
                                    case "Licenses":
                                        visiblelayers.push("v_licenses")
                                        break;
                                    case "Fields":
                                        visiblelayers.push("v_fields")
                                        break;
                                    case "Wells" :
                                        visiblelayers.push("v_wells")
                                        break;
                                    case "GeologicObjects":
                                        visiblelayers.push("v_geo_objects")
                                        break;
                                    case "TectonicMap" :
                                        visiblelayers.push("v_tectonicmap")
                                        break;
                                    case "GeologicMap":
                                        visiblelayers.push("v_geologicmap")
                                        break;
                                    case "ProtectedAreas" :
                                        visiblelayers.push("v_protected_areas");
                                        break;
                                    case "Regions" :
                                        visiblelayers.push("v_regions");
                                        break;
                                }
                            }
                        }

                        let query2 = {type: "search", select_type: "search_new",select_params:{layers:visiblelayers,search:a,user_id:Cookies.get("id")}}
                        console.log(query2)
                        axios.post(urlBackend + '/postQuery', {query: query2})
                            .then((res) => {
                                let searcharray = [];
                                for( let i = 0 ; i < res.data[0].search_from_layers_with_perm.length;i++){
                                    console.log(res.data[0].search_from_layers_with_perm[i])
                                    if ( res.data[0].search_from_layers_with_perm[i].found_value !== null && res.data[0].search_from_layers_with_perm[i].geom !== null && res.data[0].search_from_layers_with_perm[i].optional !== null && res.data[0].search_from_layers_with_perm[i].order !== null && res.data[0].search_from_layers_with_perm[i].qc_id !== null && res.data[0].search_from_layers_with_perm[i].similarity !== null && res.data[0].search_from_layers_with_perm[i].sourceid !== null && res.data[0].search_from_layers_with_perm[i].sourcetable !== null){
                                        console.log("after skip",res.data[0].search_from_layers_with_perm[i])
                                        for (let j = 0 ; j < result.length; j++){
                                            console.log(result[j].name.toLowerCase() , res.data[0].search_from_layers_with_perm[i].sourcetable)
                                            if (result[j].name.toLowerCase() === res.data[0].search_from_layers_with_perm[i].sourcetable){
                                                console.log("match",result[j].name.toLowerCase() , res.data[0].search_from_layers_with_perm[i].sourcetable)
                                                res.data[0].search_from_layers_with_perm[i].sourceLayer = result[j].sourcelayer;
                                                res.data[0].search_from_layers_with_perm[i].source = result[j].id;
                                                res.data[0].search_from_layers_with_perm[i].sourceid = result[j].id;
                                            }
                                        }
                                        //res.data[0].search_from_layers_with_perm[i].sourceLayer = 'public.'+res.data[i].sourcetable;
                                        //.data[0].search_from_layers_with_perm[i].source =res.data[i].sourceid;
                                        res.data[0].search_from_layers_with_perm[i].coordinates = JSON.parse(res.data[0].search_from_layers_with_perm[i].geom).coordinates;
                                        res.data[0].search_from_layers_with_perm[i].id = res.data[0].search_from_layers_with_perm[i].qc_id;
                                        res.data[0].search_from_layers_with_perm[i].geometry = JSON.parse(res.data[0].search_from_layers_with_perm[i].geom);
                                        res.data[0].search_from_layers_with_perm[i].type = res.data[0].search_from_layers_with_perm[i].geometry.type;
                                        console.log(res.data[0].search_from_layers_with_perm[i])
                                        searcharray.push({
                                            name: res.data[0].search_from_layers_with_perm[i].found_value,
                                            id: res.data[0].search_from_layers_with_perm[i].qc_id,
                                            source: res.data[0].search_from_layers_with_perm[i].sourceid,
                                            sourceLayer: res.data[0].search_from_layers_with_perm[i].sourceLayer,
                                            coordinates: res.data[0].search_from_layers_with_perm[i].coordinates,
                                            optional:res.data[0].search_from_layers_with_perm[i].optional,
                                            feature:res.data[0].search_from_layers_with_perm[i]
                                        });}
                                }
                                searcharray = Array.from(new Set(searcharray.map(JSON.stringify))).map(JSON.parse);
                                if (searcharray.length > 10)
                                    searcharray = searcharray.slice(0, 9);
                                console.log(searcharray);
                                this.setState({searchDisplay:'block',list: [...searcharray, ...list], checkboxList: []});
                            }).catch((error) => {
                            console.log(error)
                        });
                        //this.itemSelection(e,arraydata)
                    }
                })
                    .catch((error) => {console.log(error)});
            } else {
                let list = this.state.searchList;
                let visiblelayers = [];
                for(let i = 0 ; i < result.length;i++){
                    if ((result[i].visibility === 'visible' && result[i].name !== "Info" && result[i].type !== "geojson" && result[i].type !== "image")||(result[i].name === "Basins" || result[i].name === "Fields" || result[i].name === "Licenses")){
                        switch (result[i].name){
                            case "Basins":
                                visiblelayers.push("v_basins")
                                break;
                            case "SubBasins":
                                visiblelayers.push("v_subbasins")
                                break;
                            case "Licenses":
                                visiblelayers.push("v_licenses")
                                break;
                            case "Fields":
                                visiblelayers.push("v_fields")
                                break;
                            case "Wells" :
                                visiblelayers.push("v_wells")
                                break;
                            case "GeologicObjects":
                                visiblelayers.push("v_geo_objects")
                                break;
                            case "TectonicMap" :
                                visiblelayers.push("v_tectonicmap")
                                break;
                            case "GeologicMap":
                                visiblelayers.push("v_geologicmap")
                                break;
                            case "ProtectedAreas" :
                                visiblelayers.push("v_protected_areas");
                                break;
                            case "Regions" :
                                visiblelayers.push("v_regions");
                                break;
                        }
                    }
                }

                let query2 = {type: "search", select_type: "search_new",select_params:{layers:visiblelayers,search:a,user_id:Cookies.get("id")}}
                console.log(query2,a,a.value,visiblelayers)
                axios.post(urlBackend+'/postQuery', {query: query2})
                    .then((res) => {
                        console.log("RESPONCE",res,JSON.parse(res.data[0].geom).coordinates);
                        let searcharray = [];
                        for( let i = 0 ; i < res.data[0].search_from_layers_with_perm.length;i++){
                            console.log(res.data[0].search_from_layers_with_perm[i])
                            if ( res.data[0].search_from_layers_with_perm[i].found_value !== null && res.data[0].search_from_layers_with_perm[i].geom !== null && res.data[0].search_from_layers_with_perm[i].optional !== null && res.data[0].search_from_layers_with_perm[i].order !== null && res.data[0].search_from_layers_with_perm[i].qc_id !== null && res.data[0].search_from_layers_with_perm[i].similarity !== null && res.data[0].search_from_layers_with_perm[i].sourceid !== null && res.data[0].search_from_layers_with_perm[i].sourcetable !== null){
                                console.log("after skip",res.data[0].search_from_layers_with_perm[i])
                                for (let j = 0 ; j < result.length; j++){
                                    console.log(result[j].name.toLowerCase() , res.data[0].search_from_layers_with_perm[i].sourcetable)
                                    if (result[j].name.toLowerCase() === res.data[0].search_from_layers_with_perm[i].sourcetable){
                                        console.log("match",result[j].name.toLowerCase() , res.data[0].search_from_layers_with_perm[i].sourcetable)
                                        res.data[0].search_from_layers_with_perm[i].sourceLayer = result[j].sourcelayer;
                                        res.data[0].search_from_layers_with_perm[i].source = result[j].id;
                                        res.data[0].search_from_layers_with_perm[i].sourceid = result[j].id;
                                    }
                                }
                                //res.data[0].search_from_layers_with_perm[i].sourceLayer = 'public.'+res.data[i].sourcetable;
                                //.data[0].search_from_layers_with_perm[i].source =res.data[i].sourceid;
                                res.data[0].search_from_layers_with_perm[i].coordinates = JSON.parse(res.data[0].search_from_layers_with_perm[i].geom).coordinates;
                                res.data[0].search_from_layers_with_perm[i].id = res.data[0].search_from_layers_with_perm[i].qc_id;
                                res.data[0].search_from_layers_with_perm[i].geometry = JSON.parse(res.data[0].search_from_layers_with_perm[i].geom);
                                res.data[0].search_from_layers_with_perm[i].type = res.data[0].search_from_layers_with_perm[i].geometry.type;
                                console.log(res.data[0].search_from_layers_with_perm[i])
                                searcharray.push({
                                    name: res.data[0].search_from_layers_with_perm[i].found_value,
                                    id: res.data[0].search_from_layers_with_perm[i].qc_id,
                                    source: res.data[0].search_from_layers_with_perm[i].sourceid,
                                    sourceLayer: res.data[0].search_from_layers_with_perm[i].sourceLayer,
                                    coordinates: res.data[0].search_from_layers_with_perm[i].coordinates,
                                    optional:res.data[0].search_from_layers_with_perm[i].optional,
                                    feature:res.data[0].search_from_layers_with_perm[i]
                                });}
                        }
                        searcharray = Array.from(new Set(searcharray.map(JSON.stringify))).map(JSON.parse);
                        if (searcharray.length > 10)
                            searcharray = searcharray.slice(0, 9);
                        console.log(searcharray);
                        this.setState({searchDisplay:'block',list: searcharray, checkboxList: []});
                    }).catch((error) => {
                    console.log(error)
                });
            }
        }

        };
    registerhandle = () => {
        //window.open(regURL, "_blank")
    };
    itemSelection = (evt,data) => {
        console.log(data)
        const hoveredID = data.content[1];
        const hoveredSourceLayer = data.content[2];
        const hoveredSourceID = data.content[3]+"test";
        let layerName =  hoveredSourceLayer.replace(/[0-9]/g, '');
        for ( let i = 0 ; i < result.length;i++){
            if(result[i].name === layerName) {
                result[i].visibility = 'visible';
                for (let j = 0; j < data2.length; j++) {
                    if (data2[j].name === layerName+"Text") {
                        data2[j].enable = true;
                    }
                }

            }
        }
        //const map = this.reactMap.getMap();
        //map.zoomTo(1, { duration: 1500 });
        if( data.content[5].geometry.type === "Point")
        {
           // map.flyTo({
            //    center: [
            //        Number(data.content[5].geometry.coordinates[0]) ,
            //        Number(data.content[5].geometry.coordinates[1])
            //    ],
            //    zoom: 11,
            //    essential: true
            //});
            this.setState({flyCount:this.state.flyCount+1,
                flyCoordinates:{center: [
                    Number(data.content[5].geometry.coordinates[0]) ,
                    Number(data.content[5].geometry.coordinates[1])
                ]},
                flyType:"bbox"})
        }
        else {
            if (data.content[5].source.includes("OSM")){
                let source_data = { "type": "FeatureCollection",
                    "features": [
                        { "type": "Feature",
                            "geometry": {"type": "Point", "coordinates": data.content[5].center},
                            "properties": {"prop0": "value0"}
                        },
                    ]
                }
                console.log(data.content[5],source_data)
                //map.getSource('search_point').setData(source_data);
                //map.setLayoutProperty('search_point', 'visibility', 'visible');
                //let bbox = turf.bbox(data.content[5].geometry);
                //map.fitBounds(bbox, {padding: 1})
                this.setState({flyCount:this.state.flyCount+1,
                    flyCoordinates:data.content[5].geometry,
                    flyType:"bbox"})
            }
            else {
                this.setState({flyCount:this.state.flyCount+1,
                    flyCoordinates:data.content[5].geometry,
                    flyType:"bbox"})
                //let bbox = turf.bbox();
                //map.fitBounds(bbox, {padding: 100})
            }
        }

        if (hoveredID !== this.state.hoveredID) {
            this.setState({ hoveredID,hoveredSourceLayer,hoveredSourceID });
        }
        var a = document.getElementById("search-input");
        for (var i = 0; i < a.length; i++) {
            a[i].value = data.content[0];
        }
        if (data.content[6] === undefined)
        this.setState({
            itemSelected: data
        })
        else{
            this.setState({
                searchDisplay:'none',
                itemSelected: data
            })
        }
    };
    onMapFly=(geom,flytype)=>{
        this.setState({flyCount:this.state.flyCount+1,
        flyCoordinates:geom,
        flyType:flytype})

    }
    zoomin =  (layer,id,name) =>{
        console.log(layer,id,name)
        axios.post(urlBackend+'/postQuery', {query:{type:"get_loading_data",select_type:"get_data",select_params:{
                    object_id:id,
                    layer:layer,
                    source:layer,
                }}})
            .then((res) => {
                if(  JSON.parse(res.data[0].geom).type === "Point")
                {
                    this.onMapFly({center: [
                            Number(JSON.parse(res.data[0].geom).coordinates[0]) ,
                            Number(JSON.parse(res.data[0].geom).coordinates[1])
                        ]},"fly")

                }
                else {
                    this.onMapFly(JSON.parse(res.data[0].geom),"bbox")

                }

            }).catch((error) => {
            console.log(error)
        });
}
    onImageChange=files=>{
        console.log(files[0])
        let file = files[0];
        let name = files[0].name;
        if (file.type === "text/csv"){
            console.log(file.type)
            this.setState({geojsonFile:file,shapeerror:'',type:'csv',filename:name});

        }
        else if (file.type === "image/tiff") {
            console.log(file.type)
            var link = URL.createObjectURL(file);
            this.setState({
                selectedFile: files[0],
                loaded: 0,
                imageerror:"",
                image:link,
                type:'tiff',filename:name
            })
        }
        else if (file.name.includes(".zip")){
            console.log(file.type)
            this.setState({shapeFile:file,shapeerror:'',type:'zip',filename:name});
        }
        else if (file.name.includes(".geojson")) {
            console.log(file.type)
            this.setState({geojsonFile:file,shapeerror:'',type:'geojson',filename:name});
        }
        else if (file.name.includes(".gpx")) {
            console.log(file.type)
            this.setState({geojsonFile:file,shapeerror:'',type:'gpx',filename:name});
        }
        else if (file.name.includes(".kmz")) {
            console.log(file.type)
            this.setState({geojsonFile:file,shapeerror:'',type:'kmz',filename:name});
        }
        else if (file.name.includes(".kml")) {
            console.log(file.type)
            this.setState({geojsonFile:file,shapeerror:'',type:'kml',filename:name});
        }
        else if (file.name.includes(".geojson")) {
            console.log(file.type)
            this.setState({geojsonFile:file,shapeerror:'',type:'geojson',filename:name});
        }
        else if (file.name.includes(".geojson")) {
            console.log(file.type)
            this.setState({geojsonFile:file,shapeerror:'',type:'geojson',filename:name});
        }
        else
        {
            this.setState({imageerror:"Not Supported file type. (upload GeoTiff,Zip,Geojson",type:''})
        }
        setTimeout(()=>{this.onClickImage()},100)

    };
    onCapillaryClick = (layer,id) =>{
        let hoverarr = [];
        for (let i = 0; i < result.length;i++){
            if (result[i].name === layer){
                result[i].visibility="visible";
                hoverarr.push({id:id,source:result[i].id,sourceLayer: result[i].sourcelayer});
            }
        }
        axios.post(urlBackend+'/postQuery', {query: {type:"capillary", select_type:"get_geom",select_params:{wells_id: id}}})
            .then((response) =>{
                console.log(response.data)
                if (response.data.length > 0){
                    for (let i = 0; i < result.length;i++){
                        if (result[i].name === "Fields"){
                            result[i].visibility="visible";
                            for (let j = 0; j <response.data.length;j++) {
                                hoverarr.push({id:response.data[j].fields_id,source:result[i].id,sourceLayer: result[i].sourcelayer});
                            }
                        }
                    }
                    let geomArray = []
                    for (let h = 0; h < response.data.length; h++){
                        geomArray.push({
                            "type": "Feature",
                            "properties": {},
                            "geometry": JSON.parse(response.data[h].geom)
                        });
                    }
                    //let featuresJoined = turf.union.apply(this, geomArray);
                    //let bbox = turf.bbox(featuresJoined);
                    this.setState({flyCount:this.state.flyCount+1,
                        flyCoordinates:{
                            type: 'FeatureCollection',
                            features: geomArray
                        },
                        flyType:"bbox"})
                }
                this.setState({hoveredArray:hoverarr});
            })
            .catch((error) => {console.log(error)})

    };
    onGraphClick=(layer,id,shift)=>{
        console.log(layer,id,shift)
        let layerID = "";
        for ( let i = 0 ; i < result.length;i++){
            if(result[i].name !== undefined && layer!==undefined)
            if ( result[i].name.toLowerCase() === layer.toLowerCase()){
                 result[i].visibility="visible";
                    if (id[0] !== undefined){
                        layerID=[result[i].id.toString()];
                        let hoverarr = [];
                        for ( let j = 0 ; j < id.length;j++)
                            hoverarr.push({id:id[j],source:result[i].id,sourceLayer: result[i].sourcelayer});
                        if ( id.length > 1 && shift)
                        {
                            axios.post(urlBackend+'/postQuery', {query:{type:"getGeometry",select_type:"multiple",select_params:{array:"["+id.join()+"]",layer:layer}}})
                                .then((res) => {
                                    let geomArray = []
                                    for (let h = 0; h < res.data.length; h++){
                                        geomArray.push({
                                            "type": "Feature",
                                            "properties": {},
                                            "geometry": JSON.parse(res.data[h].geom)
                                        });
                                    }
                                    //let featuresJoined = turf.union.apply(this, geomArray);
                                    //let bbox = turf.bbox(featuresJoined);
                                    this.setState({flyCount:this.state.flyCount+1,
                                        flyCoordinates:{
                                            type: 'FeatureCollection',
                                            features: geomArray
                                        },
                                        flyType:"bbox"})

                                }).catch((error) => {
                            });
                        }
                        this.setState({hoveredArray:hoverarr});
                    }
                    else {
                        layerID=[result[i].id.toString()];
                        const hoveredID = id;
                        const hoveredSourceLayer = result[i].id;
                        const hoveredSourceID = result[i].sourcelayer;
                        if (shift)
                            axios.post(urlBackend+'/postQuery', {query:{type:"getGeometry",select_type:"single",select_params:{array:id,layer:layer}}})
                                .then((res) => {
                                    console.log(res)
                                }).catch((error) => {
                                console.log(error)
                            });
                        if (hoveredID !== this.state.hoveredID) {
                            this.setState({ hoveredID,hoveredSourceLayer,hoveredSourceID });
                        }
                    }
            }
        }
    }
    drawReset=()=>{
        this.setState({dataForRedact:JSON.parse('{"type":"FeatureCollection","features":[]}'),redactDisplay:'none',drawState:false,myObject:false,data:null,
            clickID:null,
            redactionSourceLayer:"",
            objID:null});
    };
    updateGeometry=(features)=>{
        this.setState({redactDisplay:'block',drawState:true,
            });
    }
    updategeometryReset=()=>{
        this.setState({redactDisplay:'none',drawState:false,myObject:false,
            clickID:null,
            redactionSourceLayer:"",
            objID:null});
    };
    updategeometryReset2=()=>{
        this.setState({redactDisplay:'none',drawState:false,});
    };

    drawLayerOrder = ()=>{
        if (this.reactMap !== null) {
            const map = this.reactMap.getMap();
            let layer = map.getStyle().layers;
            if (map !== undefined){
                if(typeof map.getLayer('power_line') !== 'undefined') {map.moveLayer('power_line',null)}
                if(typeof map.getLayer('power_tower') !== 'undefined') {map.moveLayer('power_tower', null)}
                if(typeof map.getLayer('power_substation') !== 'undefined') {map.moveLayer('power_substation', null)}
                if(typeof map.getLayer('power_substation_point') !== 'undefined') {map.moveLayer('power_substation_point',null)}
                if(typeof map.getLayer('power_plant') !== 'undefined') {map.moveLayer('power_plant', null)}
                if(typeof map.getLayer('power_plant_point') !== 'undefined') {map.moveLayer('power_plant_point',null)}
                if(typeof map.getLayer('power_generator') !== 'undefined') {map.moveLayer('power_generator',null)}
                if(typeof map.getLayer('power_generator_area') !== 'undefined') {map.moveLayer('power_generator_area',null)}
                if(typeof map.getLayer('power_heatmap_solar') !== 'undefined') {map.moveLayer('power_heatmap_solar', null)}
                if(typeof map.getLayer('power_transformer') !== 'undefined') {map.moveLayer('power_transformer', null)}
                if(typeof map.getLayer('power_compensator') !== 'undefined') {map.moveLayer('power_compensator', null)}
                if(typeof map.getLayer('power_switch') !== 'undefined') {map.moveLayer('power_switch', null)}
                if(typeof map.getLayer('telecoms_communication_line') !== 'undefined') {map.moveLayer('telecoms_communication_line',null)}
                if(typeof map.getLayer('telecoms_data_center') !== 'undefined') {map.moveLayer('telecoms_data_center', null)}
                if(typeof map.getLayer('telecoms_mast') !== 'undefined') {map.moveLayer('telecoms_mast', null)}
                if(typeof map.getLayer('petroleum_pipeline') !== 'undefined') {map.moveLayer('petroleum_pipeline', null)}
                if(typeof map.getLayer('petroleum_well') !== 'undefined') {map.moveLayer('petroleum_well', null)}
                if(typeof map.getLayer('petroleum_site') !== 'undefined') {map.moveLayer('petroleum_site', null)}
                if(typeof map.getLayer('water_pipeline') !== 'undefined') {map.moveLayer('water_pipeline',null)}
                if(typeof map.getLayer('gl-draw-polygon-fill-inactive.cold') !== 'undefined') {map.moveLayer('gl-draw-polygon-fill-inactive.cold',null)}
                if(typeof map.getLayer('gl-draw-polygon-fill-active.cold') !== 'undefined') {map.moveLayer('gl-draw-polygon-fill-active.cold', null)}
                if(typeof map.getLayer('gl-draw-polygon-midpoint.cold') !== 'undefined') {map.moveLayer('gl-draw-polygon-midpoint.cold', null)}
                if(typeof map.getLayer('gl-draw-polygon-stroke-inactive.cold') !== 'undefined') {map.moveLayer('gl-draw-polygon-stroke-inactive.cold',null)}
                if(typeof map.getLayer('gl-draw-polygon-stroke-active.cold') !== 'undefined') {map.moveLayer('gl-draw-polygon-stroke-active.cold',null)}
                if(typeof map.getLayer('gl-draw-line-inactive.cold') !== 'undefined') {map.moveLayer('gl-draw-line-inactive.cold',null)}
                if(typeof map.getLayer('gl-draw-line-active.cold') !== 'undefined') {map.moveLayer('gl-draw-line-active.cold', null)}
                if(typeof map.getLayer('gl-draw-polygon-and-line-vertex-stroke-inactive.cold') !== 'undefined') {map.moveLayer('gl-draw-polygon-and-line-vertex-stroke-inactive.cold', null)}
                if(typeof map.getLayer('gl-draw-polygon-and-line-vertex-inactive.cold') !== 'undefined') {map.moveLayer('gl-draw-polygon-and-line-vertex-inactive.cold', null)}
                if(typeof map.getLayer('gl-draw-point-point-stroke-inactive.cold') !== 'undefined') {map.moveLayer('gl-draw-point-point-stroke-inactive.cold', null)}
                if(typeof map.getLayer('gl-draw-point-inactive.cold') !== 'undefined') {map.moveLayer('gl-draw-point-inactive.cold', null)}
                if(typeof map.getLayer('gl-draw-point-stroke-active.cold') !== 'undefined') {map.moveLayer('gl-draw-point-stroke-active.cold', null)}
                if(typeof map.getLayer('gl-draw-point-active.cold') !== 'undefined') {map.moveLayer('gl-draw-point-active.cold', null)}
                if(typeof map.getLayer('gl-draw-polygon-fill-static.cold') !== 'undefined') {map.moveLayer('gl-draw-polygon-fill-static.cold',null)}
                if(typeof map.getLayer('gl-draw-polygon-stroke-static.cold') !== 'undefined') {map.moveLayer('gl-draw-polygon-stroke-static.cold', null)}
                if(typeof map.getLayer('gl-draw-line-static.cold') !== 'undefined') {map.moveLayer('gl-draw-line-static.cold', null)}
                if(typeof map.getLayer('gl-draw-point-static.cold') !== 'undefined') {map.moveLayer('gl-draw-point-static.cold', null)}
                if(typeof map.getLayer('gl-draw-polygon-fill-inactive.hot') !== 'undefined') {map.moveLayer('gl-draw-polygon-fill-inactive.hot',null)}
                if(typeof map.getLayer('gl-draw-polygon-fill-active.hot') !== 'undefined') {map.moveLayer('gl-draw-polygon-fill-active.hot', null)}
                if(typeof map.getLayer('gl-draw-polygon-midpoint.hot') !== 'undefined') {map.moveLayer('gl-draw-polygon-midpoint.hot', null)}
                if(typeof map.getLayer('gl-draw-polygon-stroke-inactive.hot') !== 'undefined') {map.moveLayer('gl-draw-polygon-stroke-inactive.hot',null)}
                if(typeof map.getLayer('gl-draw-polygon-stroke-active.hot') !== 'undefined') {map.moveLayer('gl-draw-polygon-stroke-active.hot',  null)}
                if(typeof map.getLayer('gl-draw-line-inactive.hot') !== 'undefined') {map.moveLayer('gl-draw-line-inactive.hot', null)}
                if(typeof map.getLayer('gl-draw-line-active.hot') !== 'undefined') {map.moveLayer('gl-draw-line-active.hot', null)}
                if(typeof map.getLayer('gl-draw-polygon-and-line-vertex-stroke-inactive.hot') !== 'undefined') {map.moveLayer('gl-draw-polygon-and-line-vertex-stroke-inactive.hot',null)}
                if(typeof map.getLayer('gl-draw-polygon-and-line-vertex-inactive.hot') !== 'undefined') {map.moveLayer('gl-draw-polygon-and-line-vertex-inactive.hot', null)}
                if(typeof map.getLayer('gl-draw-point-point-stroke-inactive.hot') !== 'undefined') {map.moveLayer('gl-draw-point-point-stroke-inactive.hot', null)}
                if(typeof map.getLayer('gl-draw-point-inactive.hot') !== 'undefined') {map.moveLayer('gl-draw-point-inactive.hot', null)}
                if(typeof map.getLayer('gl-draw-point-stroke-active.ho') !== 'undefined') {map.moveLayer('gl-draw-point-stroke-active.hot', null)}
                if(typeof map.getLayer('gl-draw-point-active.hot') !== 'undefined') {map.moveLayer('gl-draw-point-active.hot', null)}
                if(typeof map.getLayer('gl-draw-polygon-fill-static.hot') !== 'undefined') {map.moveLayer('gl-draw-polygon-fill-static.hot',null)}
                if(typeof map.getLayer('gl-draw-polygon-stroke-static.hot') !== 'undefined') {map.moveLayer('gl-draw-polygon-stroke-static.hot',null)}
                if(typeof map.getLayer('gl-draw-line-static.hot') !== 'undefined') {map.moveLayer('gl-draw-line-static.hot', null)}
                if(typeof map.getLayer('gl-draw-point-static.hot') !== 'undefined') {map.moveLayer('gl-draw-point-static.hot',null)}
            }
        }
    }
    onWmsInput=(e,input)=>{
        if (input.value.includes("?"))
            this.setState({wmsInput:input.value})
        else{
            var additionalString;
            axios.get(input.value+'?service=WMS&version=1.3.0&request=GetCapabilities')
                .then((res) => {
                    var convert = require('xml-js');
                    var result1 = JSON.parse(convert.xml2json(res.data, {compact: true, spaces: 4}));
                    console.log(result1,result1.WMS_Capabilities.Capability.Layer.Layer[1].Name,result1.WMS_Capabilities.Capability.Layer.Layer[1].BoundingBox[4]._attributes);
                    additionalString = "?bbox=" + result1.WMS_Capabilities.Capability.Layer.Layer[1].BoundingBox[4]._attributes.minx + "," + result1.WMS_Capabilities.Capability.Layer.Layer[1].BoundingBox[4]._attributes.miny + "," + result1.WMS_Capabilities.Capability.Layer.Layer[1].BoundingBox[4]._attributes.maxx + "," + result1.WMS_Capabilities.Capability.Layer.Layer[1].BoundingBox[4]._attributes.maxy + "&format=" + result1.WMS_Capabilities.Capability.Layer.Layer[1].Style.LegendURL.Format._text + "&service=WMS&version="+result1.WMS_Capabilities._attributes.version+"&STYLES=default&request=GetMap&crs="+result1.WMS_Capabilities.Capability.Layer.Layer[1].BoundingBox[4]._attributes.CRS+"&transparent=true&width="+result1.WMS_Capabilities.Capability.Layer.Layer[1].Style.LegendURL._attributes.width+"&height="+result1.WMS_Capabilities.Capability.Layer.Layer[1].Style.LegendURL._attributes.height+"&layers="+result1.WMS_Capabilities.Capability.Layer.Layer[1].Name._text;
                    this.setState({wmsInput:input.value+additionalString})
                }).catch((error) => {
                console.log(error)
            });

        }

    };
    refreshResult=(layer = "Basins")=> {
        setTimeout(()=>{
            console.log("basins switch")
            for (let i = 0; i < result.length; i++) {
                console.log(result[i].name === layer,result[i].name , layer)
                if (result[i].name === layer) {
                    if (result[i].visibility === 'none') {
                        console.log("basins switch tornedoff")
                        result[i].visibility = 'visible';

                        for (let j = 0; j < data2.length; j++) {
                            if (data2[j].name === layer + "Text") {
                                data2[j].enable = true;
                            }
                        }
                        this.onLayerChange()
                        setTimeout(()=>{
                            result[i].visibility = 'none';
                            for (let j = 0; j < data2.length; j++) {
                                if (data2[j].name === layer + "Text") {
                                    data2[j].enable = false;
                                }
                            }
                            this.onLayerChange()
                        },250)


                    }else{
                        console.log("basins switch tornedon")
                        result[i].visibility = 'none';
                        for (let j = 0; j < data2.length; j++) {
                            if (data2[j].name === layer + "Text") {
                                data2[j].enable = false;
                            }
                        }
                        this.onLayerChange()
                        setTimeout(()=> {
                            result[i].visibility = 'visible';
                            for (let j = 0; j < data2.length; j++) {
                                if (data2[j].name === layer + "Text") {
                                    data2[j].enable = true;
                                }
                            }
                            this.onLayerChange()
                        },250)
                    }
                }
            }
        },10)

    }
    disableOnLogoutResult=(layer = "infra_obj")=> {
        setTimeout(()=>{
            console.log("basins switch")
            for (let i = 0; i < result.length; i++) {
                console.log(result[i].name === layer,result[i].name , layer)
                if (result[i].name === layer) {
                    result[i].visibility = 'none';
                    for (let j = 0; j < data2.length; j++) {
                        if (data2[j].name === layer + "Text") {
                            data2[j].enable = false;
                        }
                    }
                    this.onLayerChange()
                }
            }
        },10)

    }
    onWmsButton=()=>{
        if ( this.state.wmsInput !== ""){
            result.push({name:"wms"+this.state.inc,data:this.state.wmsInput,color:"#FF00FF",id:data.length+1,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'wms',text:false,border:null,hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
            LayerOptions.push({name:"wms"+this.state.inc,data:'none',data2:"none",id:data.length+1});
            UserOptions.push({name:"wms"+this.state.inc,data:'none',data2:"none",id:20+data.length+1});
            userdataname.push({index:2+userdataname.length,name:"wms"+this.state.inc,type:"WmsLayer"});
            data.push({name:"wms"+this.state.inc,data:this.state.wmsInput,color:"#FF00FF",id:data.length+1,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'wms',text:false,border:null,hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
            presetdata.push({name:"wms"+this.state.inc,data:this.state.wmsInput,color:"#FF00FF",id:data.length+1,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'wms',text:false,border:null,hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
            dataname.push({index:dataname.length,name:"wms"+this.state.inc,type:"WmsLayer"});
            data2.push({name:"wms"+this.state.inc+"Text",data:urlMartin+'/public.basinscenter/{z}/{x}/{y}.pbf',color:"#00218B",id:data2[data2.length-1].id+"tif",beforeid:false,visibility:"none",sourcelayer:'public.basinscenter',opacity:0.8,type:'symbol',layoutname:'OriginalName'})
            this.refreshResult()
        }

    };

    onClickImage=()=> {
        ShapeDisplay ();
        const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, {type: contentType});
            return blob;
        };
        console.log(this.state.selectedFile)
        if (this.state.type === 'tiff') {
            const dataform = new FormData();
            dataform.append('my_file', this.state.selectedFile);
            let name = this.state.selectedFile.name;
            let newname = true;
            let g = 1;
            while (newname){
                for (let j = 0 ; j< result.length;j++){
                    if ( name === result[j].name){
                        name = name+"("+g+")";
                        j = 0;
                        g++;
                    }
                    if ( j === result.length-1)
                        newname = false;
                }
            }
            this.onLoadingOpen()
            axios.post(georefReproject, dataform,{
            }).then(res => { // then print response status
                const blob = b64toBlob(res.data.image, 'image/png');
                const blobUrl = URL.createObjectURL(blob);
                let bbox = res.data.bbox;
                let coord = [meters2degress(bbox[0][0],bbox[0][1]),meters2degress(bbox[1][0],bbox[1][1]),meters2degress(bbox[2][0],bbox[2][1]),meters2degress(bbox[3][0],bbox[3][1])];
                index++;
                result.splice(4, 0,{name:name,data:blobUrl,color:"#FF00FF",id:data.length+1,beforeid:data.length+2,visibility:"visible",sourcelayer:blob,opacity:0.8,type:'image',text:false,border:coord,hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                LayerOptions.splice(4, 0,{name:name,data:'none',data2:"none",id:data.length+1});
                UserOptions.splice(4, 0,{name:name,data:'none',data2:"none",id:20+data.length+1});
                userdataname.splice(4, 0,{index:2+userdataname.length,name:name,type:"ImageLayer"});
                data.splice(4, 0,{name:name,data:blobUrl,color:"#FF00FF",id:data.length+1,beforeid:data.length+2,visibility:"visible",sourcelayer:blob,opacity:0.8,type:'image',text:false,border:coord,hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                presetdata.splice(4, 0,{name:name,data:blobUrl,color:"#FF00FF",id:data.length+1,beforeid:data.length+2,visibility:"visible",sourcelayer:blob,opacity:0.8,type:'image',text:false,border:coord,hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                dataname.splice(4, 0,{index:dataname.length,name:name,type:"ImageLayer"});
                data2.splice(4, 0,{name:name+"Text",data:urlMartin+'/public.basinscenter/{z}/{x}/{y}.pbf',color:"#00218B",id:data2[data2.length-1].id+"tif",beforeid:false,visibility:"none",sourcelayer:'public.basinscenter',opacity:0.8,type:'symbol',layoutname:'OriginalName'})
                this.setState({imageDisplay:'none',inc:this.state.inc++});
                this.handleAlert("success","GeoTiff Successfully uploaded.")
                this.onLoadingClose();
                this.refreshResult()
            }).catch((error) => {
                console.log(error)
                this.onLoadingClose()
                this.handleAlert("error","Error while uploading GeoTiff .")
            });
        }
        else if(this.state.type === 'gpx') {
            const dataform = new FormData();
            dataform.append('file', this.state.geojsonFile);
            console.log(this.state.geojsonFile)
            dataform.append('token',Cookies.get('token'));
            let name = this.state.geojsonFile.name;
            let newname = true;
            let g = 1;
            while (newname){
                for (let j = 0 ; j< result.length;j++){
                    if ( name === result[j].name){
                        name = name+"("+g+")";
                        j = 0;
                        g++;
                    }
                    if ( j === result.length-1)
                        newname = false;
                }
            }
            //checkGpx
            axios.post(urlBackend +'/checkGpx', dataform,{
            }).then(res => { // then print response status
                console.log(res)
                result.push({name:name,data:res.data,color:"#FF00FF",id:data.length+name,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'geojson',text:false,border:"none",hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                LayerOptions.push({name:name,data:'none',data2:"none",id:data.length+1});
                UserOptions.push({name:name,data:'none',data2:"none",id:20+data.length+1});
                userdataname.push({index:2+userdataname.length,name:name,type:"GeojsonLayer"});
                data.push({name:name,data:res.data,color:"#FF00FF",id:data.length+name,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'geojson',text:false,border:"none",hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                presetdata.push({name:name,data:res.data,color:"#FF00FF",id:data.length+name,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'geojson',text:false,border:"none",hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                dataname.push({index:dataname.length,name:name,type:"GeojsonLayer"});
                data2.push({name:name+"Text",data:urlMartin+'/public.basinscenter/{z}/{x}/{y}.pbf',color:"#00218B",id:data2[data2.length-1].id+2,beforeid:false,visibility:"none",sourcelayer:'public.basinscenter',opacity:0.8,type:'symbol',layoutname:'OriginalName'})
                this.setState({shapeResult:res.data,imageDisplay:'none',inc:this.state.inc++})
                this.onLoadingClose()
                this.refreshResult()

            }).catch((error) => {
                console.log(error)
                this.onLoadingClose()
                this.handleAlert("error","Error while uploading GPX .")
            });
        }
        else if(this.state.type === 'kmz') {
            const dataform = new FormData();
            dataform.append('file', this.state.geojsonFile);
            console.log(this.state.geojsonFile)
            dataform.append('token',Cookies.get('token'));
            let name = this.state.geojsonFile.name;
            let newname = true;
            let g = 1;
            while (newname){
                for (let j = 0 ; j< result.length;j++){
                    if ( name === result[j].name){
                        name = name+"("+g+")";
                        j = 0;
                        g++;
                    }
                    if ( j === result.length-1)
                        newname = false;
                }
            }
            //checkGpx
            axios.post(urlBackend +'/checkKmz', dataform,{
            }).then(res => { // then print response status
                console.log(res)
                result.push({name:name,data:res.data,color:"#FF00FF",id:data.length+name,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'geojson',text:false,border:"none",hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                LayerOptions.push({name:name,data:'none',data2:"none",id:data.length+1});
                UserOptions.push({name:name,data:'none',data2:"none",id:20+data.length+1});
                userdataname.push({index:2+userdataname.length,name:name,type:"GeojsonLayer"});
                data.push({name:name,data:res.data,color:"#FF00FF",id:data.length+name,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'geojson',text:false,border:"none",hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                presetdata.push({name:name,data:res.data,color:"#FF00FF",id:data.length+name,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'geojson',text:false,border:"none",hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                dataname.push({index:dataname.length,name:name,type:"GeojsonLayer"});
                data2.push({name:name+"Text",data:urlMartin+'/public.basinscenter/{z}/{x}/{y}.pbf',color:"#00218B",id:data2[data2.length-1].id+2,beforeid:false,visibility:"none",sourcelayer:'public.basinscenter',opacity:0.8,type:'symbol',layoutname:'OriginalName'})
                this.setState({shapeResult:res.data,imageDisplay:'none',inc:this.state.inc++})
                this.onLoadingClose()
                this.refreshResult()
            }).catch((error) => {
                console.log(error)
                this.onLoadingClose()
                this.handleAlert("error","Error while uploading KMZ .")
            });
        }
        else if(this.state.type === 'kml') {
            const dataform = new FormData();
            dataform.append('file', this.state.geojsonFile);
            console.log(this.state.geojsonFile)
            dataform.append('token',Cookies.get('token'));
            let name = this.state.geojsonFile.name;
            let newname = true;
            let g = 1;
            while (newname){
                for (let j = 0 ; j< result.length;j++){
                    if ( name === result[j].name){
                        name = name+"("+g+")";
                        j = 0;
                        g++;
                    }
                    if ( j === result.length-1)
                        newname = false;
                }
            }
            //checkGpx
            axios.post(urlBackend +'/checkKml', dataform,{
            }).then(res => { // then print response status
                console.log(res)
                result.push({name:name,data:res.data,color:"#FF00FF",id:data.length+name,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'geojson',text:false,border:"none",hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                LayerOptions.push({name:name,data:'none',data2:"none",id:data.length+1});
                UserOptions.push({name:name,data:'none',data2:"none",id:20+data.length+1});
                userdataname.push({index:2+userdataname.length,name:name,type:"GeojsonLayer"});
                data.push({name:name,data:res.data,color:"#FF00FF",id:data.length+name,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'geojson',text:false,border:"none",hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                presetdata.push({name:name,data:res.data,color:"#FF00FF",id:data.length+name,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'geojson',text:false,border:"none",hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                dataname.push({index:dataname.length,name:name,type:"GeojsonLayer"});
                data2.push({name:name+"Text",data:urlMartin+'/public.basinscenter/{z}/{x}/{y}.pbf',color:"#00218B",id:data2[data2.length-1].id+2,beforeid:false,visibility:"none",sourcelayer:'public.basinscenter',opacity:0.8,type:'symbol',layoutname:'OriginalName'})
                this.setState({shapeResult:res.data,imageDisplay:'none',inc:this.state.inc++})
                this.onLoadingClose()
                this.refreshResult()
            }).catch((error) => {
                console.log(error)
                this.onLoadingClose()
                this.handleAlert("error","Error while uploading KML .")
            });
        }
        else if(this.state.type === 'zip') {

            const dataform = new FormData();
            dataform.append('file', this.state.shapeFile);
            dataform.append('token',Cookies.get('token'));
            axios.post(urlBackend+'/getZippedFileNames', dataform)
                .then(nameArray => {
                    console.log(nameArray)
                    let jpg = false;
                    let jpgName = ''
                    let aux = false;
                    let shp = false;
                    let png = false;
                    for (let i = 0; i < nameArray.data.length; i++){
                        if (nameArray.data[i].name !==undefined){
                            if (nameArray.data[i].name.toLowerCase().includes('.jpg') && !nameArray.data[i].name.toLowerCase().includes('.aux.xml')){
                                jpg = true;
                                jpgName = nameArray.data[i].name.toLowerCase().replace('.jpg', '');
                            }
                            if (nameArray.data[i].name.toLowerCase().includes('.png') && !nameArray.data[i].name.toLowerCase().includes('.aux.xml')){
                                png = true;
                                jpgName = nameArray.data[i].name.toLowerCase().replace('.png', '');
                            }
                            if (nameArray.data[i].name.toLowerCase().includes('.aux.xml'))
                                aux = true;
                            if (nameArray.data[i].name.toLowerCase().includes('.shp'))
                                shp = true;
                        }

                    }
                    if ((jpg||png) && aux){
                        const dataform2 = new FormData();
                        dataform2.append('my_file', this.state.shapeFile);
                        if (jpg){
                            dataform2.append('my_type', '.jpg');
                        }
                        else {
                            dataform2.append('my_type', '.png');
                        }
                        this.onLoadingOpen()
                        axios.post(georefApi+'/zipReproject', dataform2).then((res)  => {
                            let reader = new FileReader();
                            var that = this;
                            const blob2 = b64toBlob(res.data.image, 'image/png');
                            let bbox = res.data.bbox;
                            var blobUrl2 = URL.createObjectURL(blob2);
                            let coord = [meters2degress(bbox[0][0],bbox[0][1]),meters2degress(bbox[1][0],bbox[1][1]),meters2degress(bbox[2][0],bbox[2][1]),meters2degress(bbox[3][0],bbox[3][1])];
                            let name = jpgName;
                            let newname = true;
                            let g = 1;
                            while (newname){
                                for (let j = 0 ; j< result.length;j++){
                                    if ( name === result[j].name){
                                        name = name+"("+g+")";
                                        j = 0;
                                        g++;
                                    }
                                    if ( j === result.length-1)
                                        newname = false;
                                }
                            }
                            index++;
                            result.splice(4, 0,{name:name,data:blobUrl2,color:"#FF00FF",id:data.length+1,beforeid:data.length+2,visibility:"visible",sourcelayer:blob2,opacity:0.8,type:'image',text:false,border:coord,hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                            LayerOptions.splice(4, 0,{name:name,data:'none',data2:"none",id:data.length+1});
                            UserOptions.splice(4, 0,{name:name,data:'none',data2:"none",id:20+data.length+1});
                            userdataname.splice(4, 0,{index:2+userdataname.length,name:name,type:"ImageLayer"});
                            data.splice(4, 0,{name:name,data:blobUrl2,color:"#FF00FF",id:data.length+1,beforeid:data.length+2,visibility:"visible",sourcelayer:blob2,opacity:0.8,type:'image',text:false,border:coord,hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                            presetdata.splice(4, 0,{name:name,data:blobUrl2,color:"#FF00FF",id:data.length+1,beforeid:data.length+2,visibility:"visible",sourcelayer:blob2,opacity:0.8,type:'image',text:false,border:coord,hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                            dataname.splice(4, 0,{index:dataname.length,name:name,type:"ImageLayer"});
                            data2.splice(4, 0,{name:name+"Text",data:urlMartin+'/public.basinscenter/{z}/{x}/{y}.pbf',color:"#00218B",id:data2[data2.length-1].id+"tif",beforeid:false,visibility:"none",sourcelayer:'public.basinscenter',opacity:0.8,type:'symbol',layoutname:'OriginalName'})
                            that.setState({imageDisplay:'none',inc:that.state.inc++});
                            that.handleAlert("success","JPG with binding Successfully uploaded.")
                            that.onLoadingClose();
                            this.refreshResult()
                        }).catch((err)=>{
                            this.onLoadingClose();
                            if (err.response && err.response.data && err.response.data.detail.includes('north up'))
                                this.handleAlert("error","Error while uploading jpg with binding . File have no projection .");
                            else
                                this.handleAlert("error","Error while uploading jpg with binding .");
                        })
                    }
                    else if (shp){
                        let name = this.state.shapeFile.name;
                        let newname = true;
                        let g = 1;
                        while (newname){
                            for (let j = 0 ; j< result.length;j++){
                                if ( name === result[j].name){
                                    name = name+"("+g+")";
                                    j = 0;
                                    g++;
                                }
                                if ( j === result.length-1)
                                    newname = false;
                            }
                        }
                        this.onLoadingOpen()
                        axios.post(urlBackend+'/uploadShape4', dataform).then(res => {
                            console.log(res)
                            if (res.data.error !== undefined){
                                axios.post(urlBackend+'/uploadShape', dataform).then(res => {
                                    result.push({name:name,data:res.data,color:['get','color'],id:data.length+name,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'geojson',text:false,border:"none",hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                                    LayerOptions.push({name:name,data:'none',data2:"none",id:data.length+1});
                                    UserOptions.push({name:name,data:'none',data2:"none",id:20+data.length+1});
                                    userdataname.push({index:2+userdataname.length,name:name,type:"GeojsonLayer"});
                                    data.push({name:name,data:res.data,color:['get','color'],id:data.length+name,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'geojson',text:false,border:"none",hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                                    presetdata.push({name:name,data:res.data,color:"#FF00FF",id:data.length+name,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'geojson',text:false,border:"none",hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                                    dataname.push({index:dataname.length,name:name,type:"GeojsonLayer"});
                                    data2.push({name:name+"Text",data:urlMartin+'/public.basinscenter/{z}/{x}/{y}.pbf',color:"#00218B",id:data2[data2.length-1].id+2,beforeid:false,visibility:"none",sourcelayer:'public.basinscenter',opacity:0.8,type:'symbol',layoutname:'OriginalName'})
                                    this.setState({shapeResult:res.data,imageDisplay:'none',inc:this.state.inc++})
                                    this.handleAlert("error","ShapeFile can be displayed in wrong projection .")
                                    this.onLoadingClose()
                                    this.refreshResult()
                                }).catch((error) => {
                                    this.handleAlert("error","Error while uploading ShapeFile .")
                                    this.onLoadingClose()
                                    console.log(error)

                                });
                            }
                            else {
                                result.push({name:name,data:res.data,color:['get','color'],id:data.length+name,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'geojson',text:false,border:"none",hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                                LayerOptions.push({name:name,data:'none',data2:"none",id:data.length+1});
                                UserOptions.push({name:name,data:'none',data2:"none",id:20+data.length+1});
                                userdataname.push({index:2+userdataname.length,name:name,type:"GeojsonLayer"});
                                data.push({name:name,data:res.data,color:['get','color'],id:data.length+name,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'geojson',text:false,border:"none",hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                                presetdata.push({name:name,data:res.data,color:"#FF00FF",id:data.length+name,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'geojson',text:false,border:"none",hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                                dataname.push({index:dataname.length,name:name,type:"GeojsonLayer"});
                                data2.push({name:name+"Text",data:urlMartin+'/public.basinscenter/{z}/{x}/{y}.pbf',color:"#00218B",id:data2[data2.length-1].id+2,beforeid:false,visibility:"none",sourcelayer:'public.basinscenter',opacity:0.8,type:'symbol',layoutname:'OriginalName'})
                                this.setState({shapeResult:res.data,imageDisplay:'none',inc:this.state.inc++})
                                this.onLoadingClose()
                                this.refreshResult()
                                this.handleAlert("success","ShapeFile Successfully uploaded.")
                            }
                        }).catch((error) => {
                            this.handleAlert("error","Error while uploading ShapeFile .")
                            this.onLoadingClose()
                            console.log(error)
                        });
                    }
                    else {
                        this.handleAlert("error",".ShapeFile or JPG with binding not found in archive .")
                    }
                })
                .catch(err => {console.log(err) })

        }
        else if(this.state.type ==="csv") {
           let name = "temp";
            const dataform = new FormData();
            dataform.append('file',this.state.geojsonFile);
            let tempname = this.state.geojsonFile.name;
            let that = this;
            let newname = true;
            let g = 1;
            while (newname){
                for (let j = 0 ; j< result.length;j++){
                    if ( tempname === result[j].name){
                        tempname = name+"("+g+")";
                        j = 0;
                        g++;
                    }
                    if ( j === result.length-1)
                        newname = false;
                }
            }
            name = tempname;
            console.log(dataform,name,this.state.geojsonFile)
            axios.post(urlBackend+'/uploadShape4', dataform).then(res2 => {
                console.log(res2)
                if (res2.data.error){
                    that.handleAlert("error","CSV can be displayed in wrong projection .")
                }else{
                    console.log(res2)
                    let res = res2.data;
                    console.log(res)
                    result.push({name:name,data:res,color:['get','color'],id:data.length+name,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'geojson',text:false,border:"none",hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], ['get','color'], "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                    LayerOptions.push({name:name,data:'none',data2:"none",id:data.length+1});
                    UserOptions.push({name:name,data:'none',data2:"none",id:20+data.length+1});
                    userdataname.push({index:2+userdataname.length,name:name,type:"GeojsonLayer"});
                    data.push({name:name,data:res,color:['get','color'],id:data.length+name,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'geojson',text:false,border:"none",hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                    presetdata.push({name:name,data:res,color:"#FF00FF",id:data.length+name,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'geojson',text:false,border:"none",hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                    dataname.push({index:dataname.length,name:name,type:"GeojsonLayer"});
                    data2.push({name:name+"Text",enable:false,data:urlMartin+'/public.basinscenter/{z}/{x}/{y}.pbf',color:"#00218B",id:data2[data2.length-1].id+"geo",beforeid:false,visibility:"none",sourcelayer:'public.basinscenter',opacity:0.8,type:'symbol',layoutname:'OriginalName'})
                    that.setState({imageDisplay:'none',inc:that.state.inc++});
                    that.handleAlert("success","CSV Successfully uploaded.")
                    that.refreshResult()
                }

            }).catch((error) => {
                let geojson = {
                    "type": "FeatureCollection",
                    "features": [
                    ]
                }
                const wkt = require('wkt');
                Papa.parse(this.state.geojsonFile, {
                    header: true,
                    complete: function (results) {
                        console.log("Finished:", results.data);
                        for (let i = 0; i < results.data.length; i++) {
                            if (results.data[i].geometry !== "" && results.data[i].geometry !== undefined) {
                                geojson.features.push({
                                    "type": "Feature",
                                    "properties": results.data[i],
                                    "geometry": wkt.parse(results.data[i].geometry)
                                })

                            }
                        }
                        console.log("Finished:", geojson);
                        name = tempname;
                        result.push({name:name,data:geojson,color:['get','color'],id:data.length+name,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'geojson',text:false,border:"none",hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                        LayerOptions.push({name:name,data:'none',data2:"none",id:data.length+1});
                        UserOptions.push({name:name,data:'none',data2:"none",id:20+data.length+1});
                        userdataname.push({index:2+userdataname.length,name:name,type:"GeojsonLayer"});
                        data.push({name:name,data:geojson,color:['get','color'],id:data.length+name,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'geojson',text:false,border:"none",hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                        presetdata.push({name:name,data:geojson,color:"#FF00FF",id:data.length+name,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'geojson',text:false,border:"none",hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                        dataname.push({index:dataname.length,name:name,type:"GeojsonLayer"});
                        data2.push({name:name+"Text",data:urlMartin+'/public.basinscenter/{z}/{x}/{y}.pbf',color:"#00218B",id:data2[data2.length-1].id+"geo",beforeid:false,visibility:"none",sourcelayer:'public.basinscenter',opacity:0.8,type:'symbol',layoutname:'OriginalName'})
                        that.setState({imageDisplay:'none',inc:this.state.inc++});
                        that.handleAlert("error","CSV can be displayed in wrong projection .")
                        that.refreshResult()
                    }
                })
            });

        }
        else if(this.state.type ==="geojson") {
            const dataform = new FormData();
            dataform.append('file',this.state.geojsonFile);
            var reader = new FileReader();
            let name = this.state.geojsonFile.name;
            let tempname = name;
            let newname = true;
            let g = 1;
            while (newname){
                for (let j = 0 ; j< result.length;j++){
                    if ( tempname === result[j].name){
                        tempname = name+"("+g+")";
                        j = 0;
                        g++;
                    }
                    if ( j === result.length-1)
                        newname = false;
                }
            }
            name = tempname;
            let that = this;
            reader.readAsText(this.state.geojsonFile);
            console.log(this.state.geojsonFile)
            reader.onload = function() {
                console.log(reader.result)
                let temp = reader.result;
                temp = temp.replace(/(\r\n|\n|\r)/gm, "");
                let res = JSON.parse(temp);
                //let res2 =res;
                // uploadShape3
                axios.post(urlBackend+'/uploadShape4', dataform).then(res2 => {
                    console.log(res2)
                    if (res2.data.error !== undefined){
                        that.handleAlert("error","GeoJson can't be displayed .")
                    }
                    else{
                        console.log(res2)
                        let res = res2.data;
                        console.log(res)
                        result.push({name:name,data:res,color:['get','color'],id:data.length+name,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'geojson',text:false,border:"none",hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], ['get','color'], "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                        LayerOptions.push({name:name,data:'none',data2:"none",id:data.length+1});
                        UserOptions.push({name:name,data:'none',data2:"none",id:20+data.length+1});
                        userdataname.push({index:2+userdataname.length,name:name,type:"GeojsonLayer"});
                        data.push({name:name,data:res,color:['get','color'],id:data.length+name,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'geojson',text:false,border:"none",hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                        presetdata.push({name:name,data:res,color:"#FF00FF",id:data.length+name,beforeid:data.length+2,visibility:"visible",sourcelayer:"none",opacity:0.8,type:'geojson',text:false,border:"none",hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
                        dataname.push({index:dataname.length,name:name,type:"GeojsonLayer"});
                        data2.push({name:name+"Text",data:urlMartin+'/public.basinscenter/{z}/{x}/{y}.pbf',color:"#00218B",id:data2[data2.length-1].id+"geo",beforeid:false,visibility:"none",sourcelayer:'public.basinscenter',opacity:0.8,type:'symbol',layoutname:'OriginalName'})
                        that.setState({imageDisplay:'none',inc:that.state.inc++});
                        that.handleAlert("success","Geojson Successfully uploaded.")
                        that.refreshResult()
                    }
                }).catch((error) => {
                    console.log(error)
                    that.handleAlert("error","GeoJson can't be displayed.")
                });

                /*

                 */
            };
        }
        else {
            console.log(this.state.type,this.state)
            this.handleAlert("error","Error while uploading Geojson .")
            this.setState({imageerror:"Upload Error.Not Supported file type. (upload GeoTiff,Zip,Geojson",type:''})
        }

    };
    fitLayer=(name)=> {

        for (let i = 0 ; i<result.length; i++){
            if (result[i].visibility==="visible" && result[i].name===name){
            setTimeout(() => {
            let layers = [result[i].id.toString()];
            let features= []
                //map.queryRenderedFeatures({layers:layers});
                var bbox = turf.bbox({
                    type: 'FeatureCollection',
                    features: features
                });
                if (bbox[0] < -180 || !Number.isFinite(bbox[0]))
                    bbox[0] =-180
                if (bbox[1] < -90 || !Number.isFinite(bbox[1]))
                    bbox[1] =-90
                if (bbox[2] > 180 || !Number.isFinite(bbox[2]))
                    bbox[2] = 180
                if (bbox[3] > 90 || !Number.isFinite(bbox[3]))
                    bbox[3] =90
                    this.setState({flyCount:this.state.flyCount+1,
                        flyCoordinates:features,
                        flyType:"bbox"})
            //map.fitBounds(bbox, {padding: 20});
            },
            400);
            }
        }
    };
    fitGeoJsonLayer=(name)=> {
        for (let i = 0 ; i<result.length; i++){
            if (result[i].visibility==="visible" && result[i].name===name){
                setTimeout(() => {
                        let features= result[i].data;
                        var bbox = turf.bbox(features);
                        if (bbox[0] < -180 || !Number.isFinite(bbox[0]))
                            bbox[0] =-180
                        if (bbox[1] < -90 || !Number.isFinite(bbox[1]))
                            bbox[1] =-90
                        if (bbox[2] > 180 || !Number.isFinite(bbox[2]))
                            bbox[2] = 180
                        if (bbox[3] > 90 || !Number.isFinite(bbox[3]))
                            bbox[3] =90
                        //map.fitBounds(bbox, {padding: 20});
                        this.setState({flyCount:this.state.flyCount+1,
                            flyCoordinates:{
                                type: 'FeatureCollection',
                                features: features
                            },
                            flyType:"bbox"})
                    },
                    400);
            }
        }
    };
    updateGeoRefPoint=(array,map)=>{
        let points = array;
        var myGeoJSON = {};
        myGeoJSON.type = "FeatureCollection";
        myGeoJSON.features = [];

        for (let i = 0;i<points.length;i++){
            myGeoJSON.features.push({
                'type': 'Feature',
                'geometry': {
                    'type': 'Point',
                    'coordinates': [points[i].x,points[i].y]
                },
                'properties': {
                    'title': i
                }
            })
        }
        map.getSource('geoRefPoints').setData(myGeoJSON);
        map.setLayoutProperty(
            'geoRefPoints',
            'visibility',
            'visible'
        );
        map.setLayoutProperty(
            'geoRefSymbol',
            'visibility',
            'visible'
        );
        map.moveLayer('geoRefSymbol', null)
        map.moveLayer('geoRefPoints', null)
        this.setState({georefPoints:points})
    }
    resetGeoRefPoint=()=>{
        this.setState({georefPoints:[]})
    }
    deleteGeoRefPoint=(index)=>{
        this.setState({drawState:false,georefMode:false,georef_delete_index:index,georef_delete:true})
    }
    deleteGeoRefReset=()=>{
        console.log("reset delete")
        this.setState({drawState:false,georefMode:false,georef_delete_index:null,georef_delete:false})
    }
    updateGeoRefMapPoints=(index,value,type)=>{
        let points = this.state.georefPoints;
        console.log("delete points")
        if ( index <= points.length){
            console.log(points[index])
        }
        this.setState({drawState:false,georefMode:false})
    }
    updateGeoRefMapPointsReset=(index,value,type)=>{
        console.log("reset delete")
        let points = this.state.georefPoints;
        if ( index <= points.length){
            points.splice(index, 1);
        }
        this.setState({drawState:false,georefMode:false,georefPoints:points})
    }
    geoRefToLayer=(name,blobUrl,blob,bbox,opacity)=>{
        let coord = [meters2degress(bbox[0][0],bbox[0][1]),meters2degress(bbox[1][0],bbox[1][1]),meters2degress(bbox[2][0],bbox[2][1]),meters2degress(bbox[3][0],bbox[3][1])];
        let newname = true;
        let g = 1;
        while (newname){
            for (let j = 0 ; j< result.length;j++){
                if ( name === result[j].name){
                    name = name+"("+g+")";
                    j = 0;
                    g++;
                }
                if ( j === result.length-1)
                    newname = false;
            }
        }
        index++;
        result.unshift({name:name,data:blobUrl,color:"#FF00FF",id:data.length+1,beforeid:data.length+2,visibility:"visible",sourcelayer:blob,opacity:opacity,type:'image',text:false,border:coord,hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
        LayerOptions.unshift({name:name,data:'none',data2:"none",id:data.length+1});
        UserOptions.unshift({name:name,data:'none',data2:"none",id:20+data.length+1});
        userdataname.unshift({index:2+userdataname.length,name:name,type:"ImageLayer"});
        data.unshift({name:name,data:blobUrl,color:"#FF00FF",id:data.length+1,beforeid:data.length+2,visibility:"visible",sourcelayer:blob,opacity:opacity,type:'image',text:false,border:coord,hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
        presetdata.unshift({name:name,data:blobUrl,color:"#FF00FF",id:data.length+1,beforeid:data.length+2,visibility:"visible",sourcelayer:blob,opacity:opacity,type:'image',text:false,border:coord,hover:true,paint:{'fill-color': ['case', ['boolean', ['feature-state', 'hover'], false], "#000000", "transparent"], 'fill-opacity': 0.8, 'fill-outline-color':"#000000",},transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF"});
        dataname.unshift({index:dataname.length,name:name,type:"ImageLayer"});
        data2.unshift({name:name+"Text",data:urlMartin+'/public.basinscenter/{z}/{x}/{y}.pbf',color:"#00218B",id:data2[data2.length-1].id+"tif",beforeid:false,visibility:"none",sourcelayer:'public.basinscenter',opacity:opacity,type:'symbol',layoutname:'OriginalName'})
        this.setState({imageDisplay:'none',inc:this.state.inc++});
        this.handleAlert("success","GeoTiff Successfully uploaded.")
    }

    previewGeoRef=(url,bbox,opacity,map)=>{
        let coord = [meters2degress(bbox[0][0],bbox[0][1]),meters2degress(bbox[1][0],bbox[1][1]),meters2degress(bbox[2][0],bbox[2][1]),meters2degress(bbox[3][0],bbox[3][1])];
        /*if (map.getSource('georefPreview') !== undefined){
            map.removeLayer('georefPreview')
            map.removeSource('georefPreview')
        }
        map.addSource('georefPreview', {
            'type': 'image',
            'url': url,
            'coordinates': coord
        });
        map.addLayer({
            id: 'georefPreview',
            'type': 'raster',
            'source': 'georefPreview',
            'paint': {
                'raster-fade-duration': 0,
                "raster-opacity" : Number.parseFloat(opacity)
            }
        });
        map.moveLayer('geoRefPoints', null)
        map.moveLayer('geoRefSymbol', null)
        map.setLayoutProperty(
            'georefPreview',
            'visibility',
            'visible'
        );

         */
        this.setState({
            georefURL:url,
            georefBbox:coord,
            georefOpacity:Number.parseFloat(opacity)
        })
    };
    onGeoRefClose=(map)=>{
        if (this.state.georefDisplay==="block"){
            this.setState({georefDisplay:"none",georefClear:true,
                georefMode:false,})
        }
        else
            this.setState({georefDisplay:"block",georefClear:false,georefMode:false})
    }
    addGeoRerPoint=()=>{
        this.setState({drawState:true,georefMode:true,georefClear:false})


    };
    getGeoRefPoints=(points) =>{
        console.log(points)
        let georefPoints = [];
        for (let i = 0 ; i < points.length ; i++){
            georefPoints.push({x:points[i][0],y:points[i][1]})

        }
        this.setState({drawState:false,georefMode:false,georefPoints:georefPoints})
    }
    deleteLayerFromMap = (value) =>{
        console.log("before",result,value)
        result = arrayRemove(result,value);
        LayerOptions= arrayRemove(LayerOptions,value);
        data= arrayRemove(data,value);
        presetdata= arrayRemove(presetdata,value);
        dataname= arrayRemove(dataname,value);
        UserOptions= arrayRemove(UserOptions,value);
        userdataname= arrayRemove(userdataname,value);
        data2= arrayRemove(data2,value+"Text");
        console.log(result)
    };

    radiusEmissionsDelete=()=>{

        this.setState({emissionCenter:null})
    }
    radiusEmissions=(polygon)=>{
        //const map = this.reactMap.getMap();
        //this.radiusEmissionsDelete()
        this.setState({emissionCenter:polygon})



    }
    user_id_change=() =>{
        var user_id;
        if (Cookies.get("id") !== undefined){
            if (only_my === false)
                user_id = "?user_id="+  Cookies.get("id");
            else
                user_id = "?user_id="+  Cookies.get("id") + "&only_my=true"  ;               }
        else {
            user_id = "?user_id=0";
            this.disableOnLogoutResult()

        }
        this.setState({user_id:user_id})
    }
    areaMeasuring=(triger)=>{
        console.log("area",this.state.areaActive,this.state.drawState);
        const map = this.reactMap.getMap();
        var area = [];
        var symbol = "";
        var symbolCoordinates = [0,0];
        let firstiteration = true;
        let areaKeyDown = false;
        let prevAreaKeyDown = true;
        let that= this;
        function onClick(e) {
            let areaBuffer=[];
            if ( prevAreaKeyDown === areaKeyDown){
                area.push([e.lngLat.lng,e.lngLat.lat])
                if ( area.length >2) {
                    areaBuffer = area.slice();
                    areaBuffer.push(areaBuffer[0]);
                    const { stringify } = require('wkt');
                    let wktarea = {
                        'type': 'Polygon',
                        'coordinates': [areaBuffer]
                    };
                    areaBuffer = [];
                    let coordinates = wktarea.coordinates.slice();
                    var polygon2 = turf.polygon(coordinates);
                    //console.log(polygon2)
                    if (wktarea.type === "Polygon"){
                        //console.log(e.features[0].geometry.coordinates,e)
                        for (let i = 0 ; i < coordinates[0].length;i++){
                            coordinates[0][i] = coordinates[0][i][0] + " " + coordinates[0][i][1];
                        }
                        let coordinates2 = coordinates;
                        let res3 = [];
                        for (let i = 0;i < coordinates2[0].length;i++) {
                            let res = coordinates2[0][i].split(" ");
                            res3.push([parseFloat(res[0]),parseFloat(res[1])]);
                        }
                        //console.log(res3)
                        var polygon = turf.polygon([res3]);
                        var areaPolygon = turf.area(polygon);
                        var centroid = turf.centroid(polygon);
                        //console.log(centroid);
                        symbol=Math.round((Math.round((areaPolygon + Number.EPSILON) * 100) / 100)/1000000)+" km²";
                        symbolCoordinates =centroid.geometry.coordinates.slice();
                        //console.log(symbol,symbolCoordinates);
                        //message = (Math.round((area + Number.EPSILON) * 100) / 100)+" square meters";
                        //display = true;

                        //this.setState({drawState:true,area:(Math.round((area + Number.EPSILON) * 100) / 100)+" square meters",calcDisplay:"block",redactDisplay:"block"})
                    }
                }

            }
            console.log(e,area,prevAreaKeyDown , areaKeyDown)
            if ( area.length > 2) {
                //console.log("area 2+",map.getSource('area'),map.getLayer('area'))
                map.getSource('area').setData({
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Polygon',
                        'coordinates': [area]
                    }
                });
                map.getSource('areaSymbol').setData({
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': symbolCoordinates
                    },
                    'properties': {
                        'title': symbol
                    }
                });
                map.setLayoutProperty(
                    'area',
                    'visibility',
                    'visible'
                );
                map.setLayoutProperty(
                    'areaSymbol',
                    'visibility',
                    'visible'
                );
                map.setLayoutProperty(
                    'areapoint',
                    'visibility',
                    'none'
                );
                map.setLayoutProperty(
                    'arealine',
                    'visibility',
                    'none'
                );
                map.moveLayer('area', null)
                map.moveLayer('areaSymbol', null)
            }
            else if  ( area.length === 2){
                console.log(area)
                symbol = "";
                symbolCoordinates = [0,0];
                map.getSource('area').setData({
                    'type': 'Feature',
                    'geometry': {
                        'type': 'LineString',
                        'coordinates': area
                    }
                });
                map.setLayoutProperty(
                    'arealine',
                    'visibility',
                    'visible'
                );
                map.setLayoutProperty(
                    'areapoint',
                    'visibility',
                    'none'
                );
                map.setLayoutProperty(
                    'areaSymbol',
                    'visibility',
                    'none'
                );
                map.moveLayer('arealine', null)
            }
            else if  ( area.length === 1){
                symbol = "";
                symbolCoordinates = [0,0];
                console.log(area)
                map.getSource('area').setData({
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': area[0]
                    }
                });
                map.setLayoutProperty(
                    'areapoint',
                    'visibility',
                    'visible'
                );
                map.setLayoutProperty(
                    'areaSymbol',
                    'visibility',
                    'none'
                );
                map.moveLayer('areapoint', null)
            }

                };
        function click(e) {
            console.log("click",e.originalEvent.altKey)
            if (!firstiteration )
                prevAreaKeyDown = areaKeyDown;
            else
                firstiteration=false;
            console.log("click",e.lngLat.lng,e.lngLat.lat);
            areaKeyDown = true;
            if (that.state.areaActive)
            onClick(e);

        }

            if (this.state.areaActive){


            var canvas = map.getCanvasContainer();
            canvas.addEventListener('keydown', function(e) {
                if (e.key === "Escape") {
                    this.radiusEmissionsDelete()
                    map.setLayoutProperty('search_point', 'visibility', 'none');
                    area = [];
                }
            });
            map.on('click',click);
            map.on('touchstart',click);
        }
        else{
            map.off('click', click);
            map.off('touchstart',click);
                map.setLayoutProperty(
                    'area',
                    'visibility',
                    'none'
                );
                map.setLayoutProperty(
                    'areaSymbol',
                    'visibility',
                    'none'
                );
                map.getSource('areaSymbol').setData({
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Point',
                        'coordinates': []
                    },
                    'properties': {
                        'title': ''
                    }
                });
                area = [];
                symbol = "";
                symbolCoordinates = [0,0];
        }

    };
    draw_graticule({deg,opacity,width,color},index) {
        let one_minute = deg
        const map = this.reactMap.getMap();
        const graticule = {
            type: 'FeatureCollection',
            features: []
        };
        if (deg !== undefined && deg!==null && deg !==0){
            for (let lng = -180; lng <= 180; lng += one_minute) {
                graticule.features.push({
                    type: 'Feature',
                    geometry: {type: 'LineString', coordinates: [[lng, -90], [lng, 90]]},
                    properties: {value: lng}
                });
            }
            for (let lat = -80; lat <= 80; lat +=one_minute) {
                graticule.features.push({
                    type: 'Feature',
                    geometry: {type: 'LineString', coordinates: [[-180, lat], [180, lat]]},
                    properties: {value: lat}
                });
            }
            console.log(deg,index,'graticule'+index,map.getSource('graticule'+index))
            map.getSource('graticule'+index).setData(graticule)
            map.setPaintProperty('graticule'+index, 'line-color', color);
            map.setPaintProperty('graticule'+index, 'line-opacity', opacity);
            map.setPaintProperty('graticule'+index, 'line-width', width);

        }else{
            map.getSource('graticule'+index).setData(graticule)
        }

    }
    LoadUpdate(mode,title, active = false) {
        console.log(mode,title)
        if (!mode)
            return
        for (let i = 0; i < result.length; i++) {
            if (result[i].name.toLowerCase() === title) {
                if (active === false) {
                    result[i].visibility = 'visible';
                    for (let j = 0; j < data2.length; j++) {
                        if (data2[j].name.toLowerCase() === title + "text") {
                            data2[j].enable = true;
                        }
                    }
                }
                else {
                    result[i].visibility = 'none';
                    for (let j = 0; j < data2.length; j++) {
                        if (data2[j].name.toLowerCase() === title + "Text") {
                            data2[j].enable = false;
                        }
                    }
                }
            }
        }
        for (let i=result.length-1;i>=0;i--){
            result[i].id = i+result[i].name;
            result[i].beforeid = null;
            if (i<result.length-2){
                result[i].beforeid = result[i+1].id
            }

            if (i<result.length-2 && result[i].visibility === "visible"){
                for (let j = i+1; j <result.length ;j++){
                    if (result[j].visibility === "visible" ) {
                        result[i].beforeid =j+result[j].name;
                        j=result.length;
                    }
                }
            }
        }
        this.setState({inc:this.state.inc++})

    }
    getLibraryPublication(){
        let publicationQuary = {type:"get_publications",select_type:"get_publications_all",select_params:{user_id:Cookies.get('id')}}
        let that = this;
        axios.post(urlBackend+'/postQuery', {query:{type:"get_publications",select_type:"get_publications_count",select_params:{user_id:Cookies.get('id')}}})
            .then((result) => {
                console.log(result)
                axios.post(urlBackend+'/postQuery', {query:publicationQuary})
                    .then((res) => {
                        console.log(res.data)
                        that.setState({LibraryDisplay:"block",publication :res.data,publicationCount:result.data[0].count})
                        //publicationCount
                    }).catch((error) => {
                    console.log(error)
                });
            }).catch((error) => {
            console.log(error)
        });
    }
    componentDidMount(){
        if (Cookies.get("id") !== undefined){
            this.setState({auth:true,username:Cookies.get("username"),id:Cookies.get("id")});}
        browser =navigator.saysWho;
        //Работа с основной mapbox gl
        var that = this;
        //const map = this.reactMap.getMap();
        var popup = new mapboxgl.Popup({
            closeOnClick: false
        });
        var start;
        /*const tb = (window.tb = new Threebox(
            map,
            map.getCanvas().getContext('webgl'),
            {
                defaultLights: true
            }
        ));

         */

        this.authhandle();

        localStorage.setItem('Data1', JSON.stringify(data));
        localStorage.setItem('Data2', JSON.stringify(data2));
        localStorage.setItem('presetData',JSON.stringify(presetdata));
        localStorage.setItem('ver',ver);


    }
    render() {
        let timer;
        if (result.length === 0) {
                    result = presetdata.slice();
                    result=result.reverse();
                }
        for (let i=result.length-1;i>=0;i--){
                    result[i].id = result[i].name;
                    result[i].beforeid = null;
                    if (i<result.length-2){
                        result[i].beforeid = result[i+1].id
                    }

                    if (i<result.length-2 && result[i].visibility === "visible"){

                            for (let j = i+1; j <result.length ;j++){
                                if (result[j].visibility === "visible" ) {
                                    result[i].beforeid =j+result[j].name;

                                    j=result.length;
                                }
                            }

                    }
                }
        console.log(result)
        var user_id;
        if (Cookies.get("id") !== undefined){
            console.log("change postfix", only_my)
            if (only_my === false)
                user_id = "?user_id="+  Cookies.get("id");
            else
                user_id = "?user_id="+  Cookies.get("id") + "&only_my=true"  ;               }
        else {
            user_id = "?user_id=0";
        }
        let top = this.state.y;
        let left = this.state.x;
        let search_css = "map-overlay"
        let display = this.state.searchDisplay;
        if ( isMobile) {
            search_css = "map-overlay-mobile"
            top = "30%";
            left = "40%";
            if ( display !== "none" && display !== "block"){
                display = "none"
            }
        }
        let guide="block";
        if(Cookies.get("guide")!==undefined)
            guide = "none";
        return (
            <Router>
            <LoadingScreen close={this.onLoadingClose} loading={this.state.loading} />
            <div id='main'  onClick={()=>{
                if (guide === "block"){
                    guide = "none";
                    Cookies.set("guide",true);
                    this.setState({inc:this.state.inc++})
                }

            }}>

                <AppMenu searchclickhandle={this.searchclickhandle}
                         searchblurhandle={this.searchblurhandle}
                         georefhandle={this.onGeoRefClose}
                         landing={false}
                         lineshandle={this.lineshandle}
                         mobileOsmSearch={this.searchblurhandle}
                         searchblur2handle={this.searchblur2handle}
                         searchfocushandle={this.searchfocushandle}
                         searchinputhandle={this.searchinputhandle}
                         layershandle={this.layershandle}
                         searchhandle={this.searchhandle}
                         abouthandle={this.handlerAbout}
                         filterhandle={this.filterhandle}
                         loginhandle={this.loginhandle}
                         libraryhandle={this.getLibraryPublication}
                         registerhandle={this.registerhandle}
                         authhandle={this.authhandle}
                         onLayerChange={this.onLayerChange}
                         onLayerChange2={this.onLayerChange2}
                         imageclickhandle={this.imagehandle}
                         shapeclickhandle={this.shapehandle}
                         kernclickhandle={this.kernhandle}
                         wikiclickhandle={this.wikihandle}
                         helpclickhandle={this.helphandle}
                         analogsclickhandle={this.analogshandle}
                         model3dsclickhandle={this.model3dhandle}
                         reservesclickhandle={this.reserveshandle}
                         loaderhandle={this.loaderhandle}
                         profilehandle={() => {
                             this.setState({profileDisplay:"block",profileName: Cookies.get("username"), profileEmail: Cookies.get("email")})
                         }}
                         lasClickHandle={this.lashandle}
                         languagehandle={this.handlerLanguage}
                         langArray={this.state.languageArray}
                         lang={this.state.language}
                         auth={this.state.auth}
                         username = {this.state.username}
                />
                <UpdateGeometry display={this.state.redactDisplay}
                           coord={this.state.redactionCoord}
                           id={this.state.id}
                           name={this.state.redactionName}
                           myObj={this.state.myObject}
                           qc_id={this.state.clickID}
                           source={this.state.redactionSourceLayer}
                           objID={this.state.objID}
                           reset={this.drawReset}
                           layerchange={this.onLayerChange2}
                />
                {(Cookies.get("guide")===undefined )&&(<WelcomeGuide display={guide} update={() => {this.setState({inc:this.state.inc++})}}/>)}
                <Warning browser={browser} update={() => {this.setState({inc:this.state.inc++})}} />
                {(this.state.alertDisplay)&&(<AlertMessage close={this.handleAlertClose} display={this.state.alertDisplay} state={this.state.alertState} value={this.state.alertValue} update={() => {this.setState({inc:this.state.inc++})}}/>)}
                <Routes>
                    <Route path="/activation/success/:user" render={(prop)=>{
                    let user = prop.match.params.user;
                    return (
                        <Activation state={"success"} value={user} update={() => {this.setState({inc:this.state.inc++})}}/>
                    )
                }}/>
                    <Route path="/activation/error/:error" render={(prop)=>{
                        if (!this.state.routerAboutTrigger)
                        {
                            this.setState({routerAboutTrigger:true})}
                        return (
                            <Activation state={"error"} value={prop.match.params.error} update={() => {this.setState({inc:this.state.inc++})}}/>
                        )
                    }
                    }/>
                    <Route path="/info/:layer/:name/:id"
                           element={<LoadFeatureOnStart onMapFly={this.onMapFly} update={this.LoadUpdate} setRouterInfosTrigger={setRouterInfosTrigger} routerInfosTrigger={routerInfosTrigger} data2={data2} setData2={setData2} result={result} setResult={setResult} onClick={this.onClick}  />}
                           />
                    <Route path="/layers/:layer"
                           element={<LoadLayerOnStart  update={this.LoadUpdate} setRouterInfosTrigger={setRouterInfosTrigger} routerInfosTrigger={routerInfosTrigger} data2={data2} setData2={setData2} result={result} setResult={setResult} onClick={this.onClick}  />}
                    />
                    <Route path="/"
                           element={<LoadOnStart  update={this.LoadUpdate} setRouterInfosTrigger={setRouterInfosTrigger} routerInfosTrigger={routerInfosTrigger} data2={data2} setData2={setData2} result={result} setResult={setResult} onClick={this.onClick}  />}
                    />
                    <Route path="/tools/:instrument"
                           element={<LoadInstrumentOnStart handleAlert={this.handleAlert} reserveshandle={this.reserveshandle} model3dhandle={this.model3dhandle} analoghandle={this.analogshandle} kernhandle={this.kernhandle} libraryhandle={this.getLibraryPublication} georefhandle={this.onGeoRefClose} lashandle={this.lashandle} />}
                    />
                    <Route path="/login"
                           element={<LoadLoginOnStart loginhandle={()=>{this.setState({loginDisplay:"block"})}}  />}
                    />
                    <Route path="/layermenu"
                           element={<LoadLayerMenuOnStart layermenuhandle={this.layershandle}  />}
                    />
                    <Route path="/profile"
                           element={<LoadProfileOnStart handleAlert={this.handleAlert}  profilehandle={() => {
                               this.setState({profileDisplay:"block",profileName: Cookies.get("username"), profileEmail: Cookies.get("email")})
                           }}  />}
                    />

                </Routes>
                <CookieConsent style={{ background: "#3F51B5",zIndex:999999 }}>This website uses cookies to enhance the user experience. <a style={{color:"yellow"}} href={"https://mygeomap.ru/docs/cookies.pdf"} target="_blank">{"Document"}</a></CookieConsent>
                <FilterItemList onRowClick={(proxy)=>{
                    console.log(proxy)
                    this.onClick(proxy);
                }} result={result} close={() => this.setState({filterPropsDisplay:"none",filterPropsLayer:""})}
                                display = {this.state.filterPropsDisplay}
                                layer={this.state.filterPropsLayer}
                                filterPropsList={this.state.filterPropsList} />
                <Singin handleSingin={this.handlerSingin} close={()=>{this.setState({loginDisplay:"none"})}} display={this.state.loginDisplay} refresh={this.onLayerChange}/>
                <div id="drop-area" style={{display:"none",position:"absolute",zIndex:"9999",opacity:"10%",width:"100%",height: window.innerHeight-66}}><div style={{position:"absolute",top:(($(window).height()-this.state.bindHeight)/2)+"px" ,left:(($(window).width()-this.state.bindWidth)/4)+"px" ,width:this.state.bindWidth+"px" ,height:this.state.bindHeight+"px" }}><canvas width={this.state.bindWidth} height={this.state.bindHeight} id="main-canvas"/> <div style={{display:"none"}}><canvas width={this.state.bindWidth} height={this.state.bindHeight} id="myCanvas"/></div></div> </div>

                    {/*(Cookies.get("id") === "1" || Cookies.get("id") === "43") &&(
                    <div className={"route"} style={{display:this.state.routeDisplay}}>
                        <br/>
                        <button onClick={()=>{
                            this.setState({routeMenu:trace1Days})
                        }}>11.2021</button>
                        <button onClick={()=>{
                            this.setState({routeMenu:trace2Days})
                        }}>12.2021</button>
                        <button onClick={()=>{
                            this.setState({routeMenu:trace3Days})
                        }}>01.2022</button>
                        <br/>
                        {(this.state.routeMenu.length !== null)&&(
                            this.state.routeMenu.map((date)=>{
                                    return (
                                        <button onClick={()=>{
                                            const map = this.reactMap.getMap();
                                            if (map.getSource('trace') !== undefined){
                                                map.removeLayer('trace')
                                                map.removeSource('trace')
                                            }
                                            let trace = import('./images/json/'+date).then((trace)=>{
                                                console.log(trace)
                                                let tempArray = []
                                                for (let h = 0 ; h < trace.files.length;h++){
                                                    tempArray.push([trace.files[h]['Адреса остановок'][0],trace.files[h]['Адреса остановок'][1]])
                                                }
                                                let data = { "type": "FeatureCollection",
                                                    "features": [
                                                        { "type": "Feature",
                                                            "geometry": {
                                                                "type": "LineString",
                                                                "coordinates": tempArray
                                                            }
                                                        },
                                                    ]
                                                }
                                                console.log(data)
                                                const coordinates = data.features[0].geometry.coordinates;
                                                data.features[0].geometry.coordinates = [coordinates[0]];
                                                map.addSource('trace', { type: 'geojson', data: data });
                                                map.addLayer({
                                                    'id': 'trace',
                                                    'type': 'line',
                                                    'source': 'trace',
                                                    'paint': {
                                                        'line-color': '#ff0708',
                                                        'line-opacity': 0.75,
                                                        'line-width': 5
                                                    }
                                                });
                                                map.jumpTo({ 'center': coordinates[0], 'zoom': 7 });
                                                map.setPitch(30);
                                                let i = 0;
                                                const timer = setInterval(() => {
                                                    if (i < coordinates.length) {
                                                        data.features[0].geometry.coordinates.push(coordinates[i]);
                                                        map.getSource('trace').setData(data);
                                                        map.panTo(coordinates[i]);
                                                        i++;
                                                    } else {
                                                        window.clearInterval(timer);
                                                    }
                                                }, 350);
                                            })
                                            this.setState({routeDisplay:"none"})
                                        }}>{date}</button>
                                    )
                                })
                        )}
                        <br/>
                        <button onClick={()=>{
                            const map = this.reactMap.getMap();
                            if (map.getSource('trace') !== undefined){
                                map.removeLayer('trace')
                                map.removeSource('trace')
                            }
                            this.setState({routeMenu:[]})
                        }}>Reset</button>
                        <button onClick={()=>{
                            this.setState({routeDisplay:"none"})
                        }}>close</button>
                    </div>
                    )*/}


                    {/*<div className="lasso" style={{display:this.state.lassoDisplay}}>
                        <Button basic size={'mini'} onClick={this.onLassoListClose} icon><i className="close icon"/></Button>
                        <Label basic size={"small"}>{"Features selected from lasso tool."}</Label>
                        <Button basic size={'mini'} onClick={this.onLassoListFilter} icon>{"Filter Layers"}</Button>
                        <div style={{}}>
                        <List animated={true} selection={true} celled={true}  >
                            {this.state.lassoList.map(({name,id,source,sourceLayer,coordinates,feature }) => {
                                //console.log(this.state.featuresList)
                                return(
                                    <List.Item content={[name,id,source,sourceLayer,coordinates,feature]} className="child-list-item"
                                               as="a"
                                               onClick={this.onLassoListClick}
                                               key={id}
                                               onMouseLeave={()=>{
                                                   clearTimeout(timer);
                                               }}
                                               onMouseEnter={() => {
                                                   timer = setTimeout(() => {
                                                       // return the timeoutID
                                                       const hoveredID = id;
                                                       const hoveredSourceLayer = source;
                                                       const hoveredSourceID = sourceLayer+"test";
                                                       if (hoveredID !== this.state.hoveredID) {
                                                           this.setState({ hoveredID,hoveredSourceLayer,hoveredSourceID });
                                                       }

                                                   }, 500);

                                               }}
                                    >
                                        <List.Content floated='left'>
                                            {name}
                                        </List.Content>
                                    </List.Item>
                                )})}
                        </List>
                        </div>
                    </div>*/}

                {(this.state.AnalogDisplay === "block")&&(
                    <FloatWindow close={this.analogshandle} Component={"Analog"} onMapReserves={this.handleMapReserves} layerchange={this.onLayerChange} Filters={Filters} data={this.state.AnalogData} categories={this.state.AnalogCategories} dict={this.state.AnalogDict} dict_names={this.state.AnalogDictNames} />
                )}
                {(this.state.Model3dDisplay === "block")&&(
                    <FloatWindow close={this.model3dhandle} Component={"Geo3D"} display={this.state.Model3dDisplay} url={this.state.url3d} layerchange={this.onLayerChange} />
                )}
                <Profile display={this.state.profileDisplay} result={result} lassostate={this.onLassoState} interation={this.state.inc} infoclick={this.onClick} itemclick={this.itemSelection} create={this.onProfileChange} close={() => {this.setState({profileDisplay:"none"})}} error={""} email={this.state.profileEmail} name={this.state.profileName} />
                <Routes>
                    <Route path="/view/:lat/:lng/:zoom" render={(prop)=>{
                        console.log(prop)
                        if (!this.state.routerViewportTrigger && prop.match.params.lat!=="infos"){
                            if (prop.match.params.lat !== undefined && prop.match.params.lng !== undefined && prop.match.params.zoom !== undefined)
                                this.setState({viewport:{
                                        latitude: prop.match.params.lat,
                                        longitude: prop.match.params.lng,
                                        zoom: prop.match.params.zoom
                                    },routerViewportTrigger:true})
                        }
                    }}/>
                    <Route path="/infos/:id/:layer" render={(prop)=>{
                        if (!routerInfosTrigger){
                            routerInfosTrigger=true;
                            console.log(prop.match.params.id,prop.match.params.layer);
                            let id = prop.match.params.id;
                            let layer = prop.match.params.layer;
                            let layerID = '';
                            for (let h = 0 ; h < result.length; h++){
                                if (result[h].name !==undefined)
                                    if (result[h].name.toLowerCase() === layer){
                                        layerID = result[h].id;
                                    }
                            }
                            let quary = 'select user_id as user,(select description from '+layer+'_infos where '+layer+'_id = '+id+' and view_state IS null limit 1) as desc, original_name as OriginalName,'+layer+'_id as qc_id,\''+layer+'\' as sourcetable,\''+layerID+'\' as sourceid, ST_AsGeoJSON(wkb_geometry) as geom from '+ layer+
                                ' where '+layer+'_id='+id;
                            axios.post(urlBackend+'/layerid', {query:quary})
                                .then((res) => {
                                    console.log(res);
                                    function waitForMap(that){
                                        console.log("WAIT FOR MAP" , that, this,)
                                        if (typeof that !== "undefined")
                                            console.log(typeof that.reactMap,that.reactMap !== null, that.reactMap)
                                        if(typeof that !== "undefined" && that.reactMap !== null){
                                            console.log("WAIT FOR MAP END" )
                                            let time = 1000;
                                            res.data[0].coordinates = JSON.parse(res.data[0].geom).coordinates;
                                            res.data[0].id = res.data[0].qc_id;
                                            res.data[0].source = res.data[0].sourceid;
                                            res.data[0].geometry = JSON.parse(res.data[0].geom);
                                            res.data[0].type = res.data[0].geometry.type;
                                            res.data[0].sourceLayer = 'public.' + res.data[0].sourcetable;
                                            const hoveredID = res.data[0].id
                                            const hoveredSourceLayer = res.data[0].source
                                            const hoveredSourceID = res.data[0].sourceLayer +"test"
                                            console.log(hoveredID,hoveredSourceLayer,hoveredSourceID)
                                            let proxy = {};
                                            proxy.features = [res.data[0]];
                                            proxy.point = {x:500,y:250};
                                            proxy.features[0].properties= {OriginalName:res.data[0].originalname};
                                            if (Cookies.get("id") !== undefined) {
                                                time = 3000;
                                                that.onClick(proxy);
                                            }
                                            that.setState({infosDesc:res.data[0].desc,routerInfosTrigger:true,hoveredID,hoveredSourceLayer,hoveredSourceID})
                                            for ( let i = 0 ; i < result.length;i++){
                                                if(result[i].name !==undefined)
                                                    if (result[i].name.toLowerCase() === layer) {
                                                        if (result[i].visibility !== 'visible') {
                                                            result[i].visibility = 'visible'
                                                        }
                                                    }
                                            }
                                            console.log(that.reactMap)
                                            const map = that.reactMap.getMap();
                                            map.zoomTo(1, { duration: 500 });
                                            if( res.data[0].geometry.type === "Point")
                                            {
                                                map.flyTo({
                                                    center: [
                                                        Number(res.data[0].geometry.coordinates[0]) ,
                                                        Number(res.data[0].geometry.coordinates[1])
                                                    ],
                                                    zoom: 11,
                                                    essential: true
                                                });
                                            }
                                            else {
                                                let bbox = turf.bbox(res.data[0].geometry);
                                                map.fitBounds(bbox, {padding: 250})
                                            }
                                            if (Cookies.get("id")=== undefined){
                                                setTimeout(() => {
                                                        that.onClick(proxy);
                                                    },
                                                    501);
                                            }
                                        }
                                        else{
                                            setTimeout(waitForMap(that), 100);
                                        }
                                    }
                                    waitForMap(this);
                                }).catch((error) => {
                                console.log(error)
                            });
                        }
                    }}/>
                    <Route path="/about/:display" render={()=>{if (!this.state.routerAboutTrigger){AboutDisplay("block");this.setState({routerAboutTrigger:true})}}}/>
                </Routes>
                {(this.state.georefDisplay === "block")&&(
                    <FloatWindow
                                 Component={"Georef"}
                                 alert={this.handleAlert}
                                 reset={this.resetGeoRefPoint}
                                 mapPoints={this.state.georefPoints}
                                 close={this.onGeoRefClose}
                                 toLayer={this.geoRefToLayer}
                                 preview={this.previewGeoRef}
                                 updatePoint={this.updateGeoRefMapPoints}
                                 deletePoint={this.deleteGeoRefPoint}
                                 addMapPoint={this.addGeoRerPoint}
                                 loadingopen={this.onLoadingOpen}
                                 loadingclose={this.onLoadingClose}
                                 display={this.state.georefDisplay}
                    />
                )}
                <LayerMenu setMinGradient={setMinGradient} setMaxGradient={setMaxGradient}
                    user_id_change={this.user_id_change} setDataname={setDataname} setLayerOptions={setLayerOptions} interation={this.state.inc} setResult={setResult} LayerDisplay={this.state.layerDisplay} loaderhandle={this.loaderhandle} openhelp={()=>{this.setState({helpDisplay:"block"})}} loaderhandle2={this.tiffloaderhandle} layerchange3={this.onMobile} layerchange={this.onLayerChange} layerchange2={this.onLayerChange2} popupregister={this.onLayerChange2} onFeaturestable={this.onFeaturestable} fitGeoJsonLayer={this.fitGeoJsonLayer} fitlayer={this.fitLayer} image={this.state.imageDisplay} onlayermove={this.onLayerMove} result={result} colorGradient1={colorGradient1} colorGradient2={colorGradient2} TextData={TextData} LegendLayer={LegendLayer} data2={data2}
                           presetdata={presetdata} data={data} LayerOptions={LayerOptions} dataname={dataname} UserOptions={UserOptions} userdataname={userdataname}
                           geodataname={geodataname} Filters={Filters} FilterLayerData={FilterLayerData} minGradient={minGradient} maxGradient={maxGradient}
                           setinteraction={this.setInteractionLayers} histogramValue2={histogramValue2} histogramValue={histogramValue} maxWinValue={maxWinValue} maxAuctionValue={maxAuctionValue}
                           infdataname={infdataname} ecodataname={ecodataname} regdataname={regdataname} userdataname={userdataname} deleteLayerFromMap={this.deleteLayerFromMap} onMapReserves={this.handleMapReserves} layoutdataname={layoutdataname} />

                {(AboutDisplay() === "block")&&( <WelcomeMessage display = {AboutDisplay()} abouthandle={this.handlerAbout} closepopup={this.handleCloseWelcome}/>)}
                {(this.state.tiffLoaderDisplay=== "block")&&(<GeoTiffLoader alert={this.handleAlert} word={publication_type_word} display={this.state.tiffLoaderDisplay} close={this.tiffloaderdisplay} name={this.state.tiffLoaderName} mapType={map_type_word} layerlist={result} />)}
                {(this.state.kernDisplay === "block")&&(<Kern close={this.kernhandle} onCapillaryClick={this.onCapillaryClick} display= {this.state.kernDisplay}/>)}
                {console.log(this.state.LibraryDisplay)}
                {(this.state.LibraryDisplay === "block" )&&(<LibraryTool count={this.state.publicationCount} close={()=>this.setState({LibraryDisplay:"none",publication:[]})} publications={this.state.publication} />)}
                {(this.state.helpDisplay === "block")&&(
                    <Help update={()=>{this.setState({inc:this.state.inc+1})}} closehelp={this.handleCloseHelp} display= {this.state.helpDisplay} />
                )}
                <div className={search_css} style={{display:display}}>
                    <List animated={true} selection={true} celled={true}  >
                        {this.state.list.map(({name,id,source,sourceLayer,coordinates,feature,center }) => {
                            let Optional = null;
                            if (source.replace(/[0-9]/g, '')==="Licenses") {
                                console.log("Licenses!!!!!!!!!!!")
                                Optional = feature.optional;
                            }
                            console.log(feature)
                            console.log(feature.optional,source.replace(/[0-9]/g, ''));
                            return(
                                <List.Item content={[name,id,source,sourceLayer,coordinates,feature,center]} className="child-list-item" as="a"  onClick={this.itemSelection} key={id}>
                                    <List.Content  floated='left'>
                                        <div id="my_div" onMouseEnter={() => {

                                            let layerName =  source.replace(/[0-9]/g, '');
                                            console.log(layerName);
                                            /*for ( let i = 0 ; i < result.length;i++){
                                                if ( result[i].name === layerName){
                                                    if ( result[i].visibility==="visible"){
                                                        const hoveredID = id;
                                                        const hoveredSourceLayer = source;
                                                        const hoveredSourceID = sourceLayer+"test";
                                                        console.log(hoveredID,hoveredSourceLayer,hoveredSourceID);
                                                        if (hoveredID !== this.state.hoveredID) {
                                                            this.setState({ hoveredID,hoveredSourceLayer,hoveredSourceID });
                                                        }
                                                    }
                                                }
                                            }

                                             */

                                        }}
                                        >
                                            {name}<br/><i>{source.replace(/[0-9]/g, '')}</i> {(Optional!== null)&&(<i>{Optional}</i>)}</div>
                                    </List.Content>
                                    <List.Content floated='right'>
                                        <Checkbox
                                            onChange={this.checkboxhandle}
                                            value={feature}
                                        />
                                    </List.Content>
                                </List.Item>
                            )})}
                    </List>
                </div>
                <div className="image"  style={{display:this.state.imageDisplay }}>
                    <div style={{display: '-webkit-box'}}>

                        {//<p>For correct display use geotiff with ESPG:3857 projection,Zip with .shp and .dbf or geojson.For csv geom column should be named geometry. </p> <input type="file" id={this.state.imageVisible} name="file" onChange={this.onImageChange}/>\
                        }

                        <Dropzone onDrop={this.onImageChange}
                        >
                            {({getRootProps, getInputProps}) => (
                                <section>
                                    <div style={{height:"70px",width:"200px",borderRadius: "15px",padding: "10px",border:"1px solid black"}} {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        Drag'n'drop files
                                        or click to select
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                        <div>
                            <Popup style={{width:300,zIndex:999999999,textAlign:"left"}} position='top center' trigger={<Icon circular floated='left'  name={"info"}/>}  flowing>
                                {i18n.t("Поддерживаются следующие форматы:")}<br/>
                                {i18n.t("geojson, shp (как zip архив),")}<br/>
                                {i18n.t("kml, kmz, gpx, geotiff,")}<br/>
                                {i18n.t("jpeg с геопривязкой (как zip архив), ")}<br/>
                                {i18n.t("csv таблицу с полем геометрии как geom в формате WKT")}<br/>
                                {i18n.t(" и EPSG:4326")}<br/>
                            </Popup>
                        </div>
                        <br/>

                    </div>
                    {(this.state.filename !== undefined)&&(<>{"Выбранный файл: "+this.state.filename}</>)}

                </div>
                <div className="oil" style={{opacity:"100%"}}>Цена на нефть :<br/><br/>{(typeof WTI === "string")&&(<span>{WTI.toString()}</span>)}<br/><br/>{(typeof XBR === "string")&&(<span>{XBR.toString()}</span>)}</div>

                {(this.state.mapReservesDisplay === "block")&&(
                    <MapReserves update={()=>{this.setState({inc:this.state.inc+1})}} ids={this.state.mapReservesIds} close={this.handleCloseMapReserves} display= {this.state.mapReservesDisplay} />
                )}
                {(this.state.reservesDisplay=== "block")&&(<React.Suspense fallback="loading"><ReservesTranslate close={this.reserveshandle} display={this.state.reservesDisplay} /></React.Suspense>)}
                {(this.state.wikiDisplay === "block")&&(<WikimapiaApi displayApiResult={this.displayApiResult} getcenter={this.mapgetcenter} center={this.state.currentCenter} update={()=>{this.setState({inc:this.state.inc+1})}} display= {this.state.wikiDisplay}/>)}
                {(this.state.loaderDisplay === "block")&&(<LoaderToDB groupPermArray={this.state.groupPermArray} alert={this.handleAlert}
                                                                      groupPermArrayBasic={this.state.groupPermArrayBasic} loadingclose={this.onLoadingClose} loadingopen={this.onLoadingOpen} result={result} list = {this.state.loaderProp} resultlist={this.state.loaderResultList} display = {this.state.loaderDisplay} loaderhandle={this.loaderdisplay} />)}
                <FeaturePopup x={this.state.x} y={this.state.y} display = {this.state.popupDisplay} guest={this.onGuestInfos} id={this.state.clickID} layer={this.state.infoLayer} description={this.state.infosDesc} deletehandle={this.deletehandle} handler = {this.handlerPopup} text = {this.state.textPopup} closepopup={this.handleClosePopup} infospopup={this.handleInfosPopup} />
                {(this.state.infosFeature.length > 0)&&(
                    <React.Suspense fallback="loading">
                        <FeatureInfos zoomin={this.zoomin} emissions={this.radiusEmissions} emissionsDelete={this.radiusEmissionsDelete} update_date={this.state.updateDate} avgWinValue={avgWinValue} maxWinValue={maxWinValue} model3infos={this.model3dhandleinfos} result={result} LayerOptions={LayerOptions} UserOptions={UserOptions} userdataname={userdataname} data={data} presetdata={presetdata} dataname={dataname} data2={data2} loadingclose={this.onLoadingClose} loadingopen={this.onLoadingOpen} alert={this.handleAlert} lasHandler={this.lasHandler} author_id={this.state.infosAuthorID} perm={this.state.infosPerm} onGraphClick={this.onGraphClick} infosredraw={this.handleInfosPopup} userobject={this.state.infosUserObject} userstate = {this.state.userActive} display = {this.state.infosDisplay} close={this.handleCloseInfos} maxWinValue={maxWinValue} avgWinValue={avgWinValue} layerupdate={this.onLayerChange2}  infos={this.state.infosFeature} name={this.state.infosName} desc={this.state.infosDesc} deletehandle={this.deletehandle} handler = {this.handlerPopup} username = {this.state.infosEditorName}/>
                    </React.Suspense>)}

                {(this.state.lasDisplay === "block")&&(<LasTool close={()=>this.setState({lasDisplay:'none'})} lasURL={this.state.lasURL} display={this.state.lasDisplay}/>)}
                <MyGeoMap
                    geom_data={this.state.dataForRedact} disableOnLogoutResult={this.disableOnLogoutResult}
                    deleteGeoRefReset={this.deleteGeoRefReset} onRedactChange={this.onRedactChange}
                    georef_delete={this.state.georef_delete} georef_delete_index={this.state.georef_delete_index}
                    linesDisplay={this.state.linesDisplay} emissionCenter={this.state.emissionCenter} emission_remove={this.radiusEmissionsDelete}
                    onFeaturestableComplete={this.onFeaturestableComplete} filterPropsLayer={this.state.filterPropsLayer} filterPropsDisplay={this.state.filterPropsDisplay}
                    georefURL={this.state.georefURL} georefBbox={this.state.georefBbox}  georefOpacity={this.state.georefOpacity}
                    georefMode={this.state.georefMode} georefClear={this.state.georefClear} getGeoRefPoints={this.getGeoRefPoints}
                    flyCount={this.state.flyCount} flyCoordinates={this.state.flyCoordinates} flyType={this.state.flyType} onFly={this.onMapFly}
                    onFeatureListClick={this.onFeatureListClick}    onFeatureListClose={this.onFeatureListClose}
                    updateGeometry={this.updateGeometry} updateGeometryReset={this.updategeometryReset2}
                    georefPoints={this.state.georefPoints}
                          user_id={this.state.user_id} onClickGeoJson={this.onClickGeoJson} interactionLayersID={this.setInteractionLayersID}
                          interactionLayer={this.state.interactionLayers} onClick={this.onClick} onLeave={this.onLeave} onHover={this.onHover}
                          hoveredID={this.state.hoveredID} hoveredSourceLayer={this.state.hoveredSourceLayer} hoveredSourceID={this.state.hoveredSourceID}
                          handleAlert={this.handleAlert} data2={data2} Filters={Filters} result={result} inc={this.state.inc}>

                </MyGeoMap>

            </div>
            </Router>
        );
    }
}
document.body.style.margin = 0;
const container  = document.getElementById("app");
console.log(container.hasChildNodes())
if (container.hasChildNodes()) {
    // Если в контейнере уже есть дочерние узлы, используем hydrateRoot
    hydrateRoot(container, <App filter={FilterDisplay()} />);
} else {
    // Если нет, создаем новый корневой узел и рендерим приложение
    const root = createRoot(container);
    root.render(<App filter={FilterDisplay()} />);
}
