import axios from "axios";
import Cookies from "js-cookie";

var config = require('./config');
const hostMartin = config.HOSTMartin;
const hostBackend = config.HOSTBackend;
const portBackend = config.PORTBackend;
const portMartin = config.PORTMartin;
const regURL = config.regURL;
const protocolMartin = config.ProtocolMartin;
const protocolBackend = config.ProtocolBackend;
const prefixMartin = config.PrefixMartin;
const prefixBackend = config.PrefixBackend;
export const viewer3d = config.viewer3dApi;
//const geoReferenceUrl = config.georefReproject;
export const georefApi = config.georefApi;
const ver = "0.5.1";
export var tags_word;export var basin_class_word;export var basin_subtype_word;export var basin_type_word;export var basin_fluid_word;export var field_class_word;export var signal_source_word;export var seismic_type_word;export var domain_word;export var field_type_word;export var well_type_word;export var well_stock_word;export var well_status_word;export var object_type_word;export var outcrop_word;export var licenses_type_word;export var development_stage_word;export var confidence_degree_word;export var licenses_status_word;export var location_word;export var discovery_method_word;export var protected_areas_word;export var protected_areas_profile_word;export var terrain_word;export var basin_age_word;export var map_type_word;export var publication_type_word;export var age_from_word;export var age_to_word ;
//;
export var world_source_storage;export var world_source_status;export var world_source_reservoir;export var world_source_formation;export var domain_word2;export var seismic_type_word2;export var signal_source_word2;export var basin_fluid_word2;export var field_class_word2;export var field_type_word2;export var object_type_word2;export var outcrop_word2;export var confidence_degree_word2;export var licenses_type_word2;export var licenses_status_word2;export var location_word2;export var terrain_word2;export var protected_areas_word2;export var protected_areas_profile_word2;export var discovery_method_word2;export var basin_age_word2;export var licensecolor ;
export var only_my = false
export function change_only_my (state) {
    only_my = state;
}
var authorized = false;
if (Cookies.get("id") !== undefined)
    authorized = true;
else
    authorized = false;
export var urlMartin=protocolMartin+'://'+hostMartin+':'+portMartin+prefixMartin;

if (portMartin=== "" || portMartin === undefined){
    urlMartin=protocolMartin+'://'+hostMartin+prefixMartin;
}
else{
    urlMartin=protocolMartin+'://'+hostMartin+':'+portMartin+prefixMartin;
}
export var urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
if (portBackend === "" || portBackend === undefined){
    urlBackend=protocolBackend+'://'+hostBackend+prefixBackend;
}
else{
    urlBackend=protocolBackend+'://'+hostBackend+':'+portBackend+prefixBackend;
}
export async function requast(quary){
    return axios.post(urlBackend+'/postQuery', {query:quary}).then(response => {response.data.push({key:"",text:"------",value:""}); return response.data})
}
export async function requast2(quary){
    return axios.post(urlBackend+'/postQuery', {query:quary}).then(response => {return response.data})
}
if (localStorage.getItem('ver') !== ver) {
    localStorage.removeItem('Data1');
    localStorage.removeItem('Data2');
    localStorage.removeItem('presetData');
    localStorage.clear();
}
export var data = (localStorage.getItem('Data1') === null) ?
    [
        {name:"Basins",data:urlMartin+'/public.basinstest/{z}/{x}/{y}.pbf',color:['get','color'],id:1,beforeid:2,visibility:"none",sourcelayer:'public.basinstest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null ,transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF",popup:true},
        {name:"GeologicMap",data:urlMartin+'/public.geologicmaptest/{z}/{x}/{y}.pbf',color:['get','color'],id:2,beforeid:3,visibility:"none",sourcelayer:'public.geologicmaptest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.geologicmap',layers:[],colordefault:['get','color'],popup:true},
        {name:"TectonicMap",data:urlMartin+'/public.tectonicmaptest/{z}/{x}/{y}.pbf',color:"#D2691E",id:3,beforeid:4,visibility:"none",sourcelayer:'public.tectonicmaptest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.tectonicmap',layers:[],colordefault:"#D2691E",popup:true},
        {name:"SubBasins",data:urlMartin+'/public.subbasinstest/{z}/{x}/{y}.pbf',color:"#FFFFFF",id:4,beforeid:5,visibility:"none",sourcelayer:'public.subbasinstest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.subbasins',layers:[],colordefault:"#FFFFFF",popup:true},
        {name:"Regions",data:urlMartin+'/public.regionstest/{z}/{x}/{y}.pbf',color:"#00218B",id:5,beforeid:6,visibility:"none",sourcelayer:'public.regionstest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:true,source:'public.regions',layers:[],colordefault:"#00218B",popup:true},
        {name:"Licenses",data:urlMartin+'/public.licensestest/{z}/{x}/{y}.pbf',color:['get','color'],id:6,beforeid:7,visibility:"none",sourcelayer:'public.licensestest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.licenses',layers:[],colordefault:['get','color'],popup:true},
        {name:"Fields",data:urlMartin+'/public.fieldstest/{z}/{x}/{y}.pbf',color:['get','color'],id:7,beforeid:8,visibility:"none",sourcelayer:'public.fieldstest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.fields',layers:[],colordefault:['get','color'],popup:true},
        {name:"GeologicObjects",data:urlMartin+'/public.geoobjecttest/{z}/{x}/{y}.pbf',color:"#00218B",id:8,beforeid:9,visibility:"none",sourcelayer:'public.geoobjecttest',opacity:0.8,type:'circle',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.geo_objects',layers:[],colordefault:"#00218B",popup:true},
        {name:"Seismics",data:urlMartin+'/public.seismicstest/{z}/{x}/{y}.pbf',color:"#000000",id:7,beforeid:8,visibility:"none",sourcelayer:'public.seismicstest',opacity:0.8,type:'line',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.seismics',layers:[],colordefault:"#000000",popup:true},
        {name:"Wells",data:urlMartin+'/public.wellstest/{z}/{x}/{y}.pbf',color:"#000000",id:8,beforeid:9,visibility:"none",sourcelayer:'public.wellstest',opacity:0.8,type:'circle',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.wells',layers:[],colordefault:"#000000",popup:true},
        {name:"ProtectedAreas",data:urlMartin+'/public.protectedareastest/{z}/{x}/{y}.pbf',color:"#cddcad",id:9,beforeid:10,visibility:"none",sourcelayer:'public.protectedareastest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.protectedareastest',layers:[],colordefault:"#cddcad",popup:true},
        {name:"Info",data:'https://openinframap.org/tiles/{z}/{x}/{y}.pbf',color:['get','color'],id:9,beforeid:10,visibility:"none",sourcelayer:'public.test',opacity:0.8,type:'multilayer',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.b',layers:
                [{idlayer: "power_line",color:"#ff8c00",size:1,type:"line"},
                    {idlayer: "power_tower",color:"#ff8c00",size:1,type:"circle"},
                    {idlayer: "power_substation",color:"#cd6600",size:1,type:"line"},
                    {idlayer: "power_substation_point",color:"#cd6600",size:1,type:"circle"},
                    {idlayer: "power_plant",color:"#c9713b",size:1,type:"line"},
                    {idlayer: "power_plant_point",color:"#c9713b",size:1,type:"circle"},
                    {idlayer: "power_generator",color:"#446b75",size:1,type:"circle"},
                    {idlayer: "power_generator_area",color:"#446b75",size:1,type:"area"},
                    {idlayer: "power_heatmap_solar",color:"#593872",size:0.5,type:"circle"},
                    {idlayer: "power_transformer",color:"#ba9bac",size:1,type:"circle"},
                    {idlayer: "power_compensator",color:"#c99e66",size:1,type:"circle"},
                    {idlayer: "power_switch",color:"#5b6c60",size:1,type:"circle"},
                    {idlayer: "telecoms_communication_line",color:"#bf91ff",size:1,type:"line"},
                    {idlayer: "telecoms_data_center",color:"#bf91ff",size:1,type:"area"},
                    {idlayer: "telecoms_mast",color:"#9874cc",size:1,type:"circle"},
                    {idlayer: "petroleum_pipeline",color:"#006E00",size:2,type:"line"},
                    {idlayer: "petroleum_well",color:"#313036",size:2,type:"circle"},
                    {idlayer: "petroleum_site",color:"#eee6b8",size:2,type:"circle"},
                    {idlayer: "water_pipeline",color:"#00ceff",size:2,type:"line"}]},
        {name:"GoogleSat",data:'https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',color:"#FFFFFF",id:9,beforeid:10,visibility:"none",sourcelayer:'public.i',opacity:0.8,type:'raster',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.i',layers:[],colordefault:"",popup:true},
        {name:"GoogleHyb",data:'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',color:"#FFFFFF",id:9,beforeid:10,visibility:"none",sourcelayer:'public.i',opacity:0.8,type:'raster',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.i',layers:[],colordefault:"",popup:true},
        {name:"BingAerial",data:'https://ecn.t3.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=1',color:"#FFFFFF",id:9,beforeid:10,visibility:"none",sourcelayer:'public.i',opacity:0.8,type:'raster',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.i',layers:[],colordefault:"",popup:true},
        {name:"ArcGISTopo",data:'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',color:"#FFFFFF",id:9,beforeid:10,visibility:"none",sourcelayer:'public.i',opacity:0.8,type:'raster',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.i',layers:[],colordefault:"",popup:true},
    ]
    :
    JSON.parse(localStorage.getItem('Data1'));
export var data2 =(localStorage.getItem('Data2') === null) ?
     [
        {name:"BasinsText",data:urlMartin+'/public.mvtbasinstext/{z}/{x}/{y}.pbf',color:"#00218B",id:20,beforeid:false,visibility:"visible",sourcelayer:'public.mvtbasinstext',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
        {name:"GeologicMapText",data:urlMartin+'/public.mvtgeologicmaptext/{z}/{x}/{y}.pbf',color:"#00218B",id:21,beforeid:false,visibility:"visible",sourcelayer:'public.mvtgeologicmaptext',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
        {name:"TectonicMapText",data:urlMartin+'/public.mvttectonicmaptext/{z}/{x}/{y}.pbf',color:"#00218B",id:22,beforeid:false,visibility:"visible",sourcelayer:'public.mvttectonicmaptext',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
        {name:"SubBasinsText",data:urlMartin+'/public.mvtsubbasinstext/{z}/{x}/{y}.pbf',color:"#00218B",id:23,beforeid:false,visibility:"visible",sourcelayer:'public.mvtsubbasinstext',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
        {name:"RegionsText",data:urlMartin+'/public.regionscenter/{z}/{x}/{y}.pbf',color:"#00218B",id:24,beforeid:false,visibility:"visible",sourcelayer:'public.regionscenter',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
        {name:"LicensesText",data:urlMartin+'/public.mvtlicensestext/{z}/{x}/{y}.pbf',color:"#00218B",id:25,beforeid:false,visibility:"visible",sourcelayer:'public.mvtlicensestext',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
        {name:"SeismicsText",data:urlMartin+'/public.mvtseismicstext/{z}/{x}/{y}.pbf',color:"#00218B",id:35,beforeid:false,visibility:"visible",sourcelayer:'public.mvtseismicstext',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
        {name:"WellsText",data:urlMartin+'/public.mvtwellstext/{z}/{x}/{y}.pbf',color:"#00218B",id:33,beforeid:false,visibility:"visible",sourcelayer:'public.mvtwellstext',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
        {name:"ProtectedAreasText",data:urlMartin+'/public.mvtprotectedareastext/{z}/{x}/{y}.pbf',color:"#00218b",id:34,beforeid:35,visibility:"visible",sourcelayer:'public.mvtprotectedareastext',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
        {name:"FieldsText",data:urlMartin+'/public.mvtfieldstext/{z}/{x}/{y}.pbf',color:"#00218B",id:26,beforeid:false,visibility:"visible",sourcelayer:'public.mvtfieldstext',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
        {name:"GeologicObjectsText",data:urlMartin+'/public.mvtgeologicobjectstext/{z}/{x}/{y}.pbf',color:"#00218B",id:27,beforeid:false,visibility:"visible",sourcelayer:'public.mvtgeologicobjectstext',opacity:0.4,type:'symbol',layoutname:'object_name',enable:false},
        {name:"InfoText",data:urlMartin+'/public.regionscenter/{z}/{x}/{y}.pbf',color:"#00218B",id:28,beforeid:false,visibility:"none",sourcelayer:'public.regionscenter',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
        {name:"GoogleSatText",data:urlMartin+'/public.regionscenter/{z}/{x}/{y}.pbf',color:"#00218B",id:29,beforeid:false,visibility:"none",sourcelayer:'public.regionscenter',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
        {name:"GoogleHybText",data:urlMartin+'/public.regionscenter/{z}/{x}/{y}.pbf',color:"#00218B",id:30,beforeid:false,visibility:"none",sourcelayer:'public.regionscenter',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
        {name:"BingAerialText",data:urlMartin+'/public.regionscenter/{z}/{x}/{y}.pbf',color:"#00218B",id:31,beforeid:false,visibility:"none",sourcelayer:'public.regionscenter',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
        {name:"ArcGISTopoText",data:urlMartin+'/public.regionscenter/{z}/{x}/{y}.pbf',color:"#00218B",id:32,beforeid:false,visibility:"none",sourcelayer:'public.regionscenter',opacity:0.4,type:'symbol',layoutname:'OriginalName',enable:false},
    ]
    :
    JSON.parse(localStorage.getItem('Data2'));
export var presetdata = (localStorage.getItem('presetData') === null) ?
     [
        {name:"GeologicObjects",data:urlMartin+'/public.geoobjecttest/{z}/{x}/{y}.pbf',color:"#00218B",id:0,beforeid:1,visibility:"none",sourcelayer:'public.geoobjecttest',opacity:0.8,type:'circle',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.geo_objects',layers:[],colordefault:"#00218B",popup:true},
        {name:"Info",data:'https://openinframap.org/tiles/{z}/{x}/{y}.pbf',color:['get','color'],id:1,beforeid:2,visibility:"none",sourcelayer:'public.test',opacity:0.8,type:'multilayer',text:false,border:"#000000",hover:false,paint:null,transparent:false,layers:
                [{idlayer: "power_line",color:"#ff8c00",size:1,type:"line"},
                    {idlayer: "power_tower",color:"#ff8c00",size:1,type:"circle"},
                    {idlayer: "power_substation",color:"#cd6600",size:1,type:"line"},
                    {idlayer: "power_substation_point",color:"#cd6600",size:1,type:"circle"},
                    {idlayer: "power_plant",color:"#c9713b",size:1,type:"line"},
                    {idlayer: "power_plant_point",color:"#c9713b",size:1,type:"circle"},
                    {idlayer: "power_generator",color:"#446b75",size:1,type:"circle"},
                    {idlayer: "power_generator_area",color:"#446b75",size:1,type:"area"},
                    {idlayer: "power_heatmap_solar",color:"#593872",size:0.5,type:"circle"},
                    {idlayer: "power_transformer",color:"#ba9bac",size:1,type:"circle"},
                    {idlayer: "power_compensator",color:"#c99e66",size:1,type:"circle"},
                    {idlayer: "power_switch",color:"#5b6c60",size:1,type:"circle"},
                    {idlayer: "telecoms_communication_line",color:"#bf91ff",size:1,type:"line"},
                    {idlayer: "telecoms_data_center",color:"#bf91ff",size:1,type:"area"},
                    {idlayer: "telecoms_mast",color:"#9874cc",size:1,type:"circle"},
                    {idlayer: "petroleum_pipeline",color:"#006E00",size:2,type:"line"},
                    {idlayer: "petroleum_well",color:"#313036",size:2,type:"circle"},
                    {idlayer: "petroleum_site",color:"#eee6b8",size:2,type:"circle"},
                    {idlayer: "water_pipeline",color:"#00ceff",size:2,type:"line"}]},
        {name:"Seismics",data:urlMartin+'/public.seismicstest/{z}/{x}/{y}.pbf',color:"#000000",id:7,beforeid:8,visibility:"none",sourcelayer:'public.seismicstest',opacity:0.8,type:'line',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.wells',layers:[],colordefault:"#000000",popup:true},
        {name:"Wells",data:urlMartin+'/public.wellstest/{z}/{x}/{y}.pbf',color:"#000000",id:7,beforeid:8,visibility:"none",sourcelayer:'public.wellstest',opacity:0.8,type:'circle',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.wells',layers:[],colordefault:"#000000",popup:true},
        {name:"Fields",data:urlMartin+'/public.fieldstest/{z}/{x}/{y}.pbf',color:['get','color'],id:3,beforeid:4,visibility:"none",sourcelayer:'public.fieldstest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.fields',layers:[],colordefault:['get','color'],popup:true},
        {name:"Licenses",data:urlMartin+'/public.licensestest/{z}/{x}/{y}.pbf',color:['get','color'],id:2,beforeid:3,visibility:"none",sourcelayer:'public.licensestest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.licenses',layers:[],colordefault:['get','color'],popup:true},
        {name:"TectonicMap",data:urlMartin+'/public.tectonicmaptest/{z}/{x}/{y}.pbf',color:"#D2691E",id:3,beforeid:4,visibility:"none",sourcelayer:'public.tectonicmaptest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.tectonicmap',layers:[],colordefault:"#D2691E",popup:true},
        {name:"SubBasins",data:urlMartin+'/public.subbasinstest/{z}/{x}/{y}.pbf',color:"#FFFFFF",id:4,beforeid:5,visibility:"none",sourcelayer:'public.subbasinstest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.subbasins',layers:[],colordefault:"#FFFFFF",popup:true},
        {name:"Basins",data:urlMartin+'/public.basinstest/{z}/{x}/{y}.pbf',color:['get','color'],id:5,beforeid:6,visibility:"none",sourcelayer:'public.basinstest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.basins',layers:[],colordefault:"#FFFFFF",popup:true},
        {name:"Regions",data:urlMartin+'/public.regionstest/{z}/{x}/{y}.pbf',color:"#00218B",id:6,beforeid:7,visibility:"none",sourcelayer:'public.regionstest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:true,source:'public.regions',layers:[],colordefault:"#00218B",popup:true},
        {name:"GeologicMap",data:urlMartin+'/public.geologicmaptest/{z}/{x}/{y}.pbf',color:['get','color'],id:7,beforeid:8,visibility:"none",sourcelayer:'public.geologicmaptest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.geologicmaptest',layers:[],colordefault:['get','color'],popup:true},
        {name:"ProtectedAreas",data:urlMartin+'/public.protectedareastest/{z}/{x}/{y}.pbf',color:"#cddcad",id:8,beforeid:9,visibility:"none",sourcelayer:'public.protectedareastest',opacity:0.8,type:'fill',text:false,border:"#000000",hover:false,paint:null,transparent:false,source:'public.protectedareastest',layers:[],colordefault:"#cddcad",popup:true},
        {name:"GoogleSat",data:'https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',color:"#FFFFFF",id:9,beforeid:10,visibility:"none",sourcelayer:'public.i',opacity:0.8,type:'raster',text:false,border:"#000000",hover:true,paint:null,transparent:false,source:'public.i',layers:[],colordefault:"",popup:true},
        {name:"GoogleHyb",data:'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',color:"#FFFFFF",id:9,beforeid:10,visibility:"none",sourcelayer:'public.i',opacity:0.8,type:'raster',text:false,border:"#000000",hover:true,paint:null,transparent:false,source:'public.i',layers:[],colordefault:"",popup:true},
        {name:"BingAerial",data:'https://ecn.t3.tiles.virtualearth.net/tiles/a{quadkey}.jpeg?g=1',color:"#FFFFFF",id:9,beforeid:10,visibility:"none",sourcelayer:'public.i',opacity:0.8,type:'raster',text:false,border:"#000000",hover:true,paint:null,source:'public.i',transparent:false,layers:[],colordefault:"",popup:true},
        {name:"ArcGISTopo",data:'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',color:"#FFFFFF",id:9,beforeid:10,visibility:"none",sourcelayer:'public.i',opacity:0.8,type:'raster',text:false,border:"#000000",hover:true,paint:null,source:'public.i',transparent:false,layers:[],colordefault:"",popup:true},
    ]
:
     JSON.parse(localStorage.getItem('presetData'));
const nodes = [{
    value: 'Платформ',
    label: 'Платформ',
    children: [
        { value: 'Внутриплатформенный (интракратонный)', label: 'Внутриплатформенный (интракратонный)',children:[
                {value:"Синеклизный",label:"Синеклизный"}, {value:"Рифтовый (Внутриплатформенный)",label:"Рифтовый (Внутриплатформенный)"}] },
        { value: 'Окраинно-платформенный (перикратонный)', label: 'Окраинно-платформенный (перикратонный)' ,children:[
                {value:"Перикратонно-орогенный",label:"Перикратонно-орогенный"}, {value:"Перикратонный",label:"Перикратонный"}] },
        { value: 'Пассивных окраин (перикратонно-океанический)', label: 'Пассивных окраин (перикратонно-океанический)' ,children:[
                {value:"Периокеанический",label:"Периокеанический"}, {value:"Рифтовый (Пассивных окраин)",label:"Рифтовый (Пассивных окраин)"}] },
    ],
},
    {
        value: 'Подвижных поясов',
        label: 'Подвижных поясов',
        children: [
            { value: 'Островодужный', label: 'Островодужный' ,children:[
                    {value:"Преддуговой",label:"Преддуговой"}, {value:"Междуговой",label:"Междуговой"}, {value:"Задуговой",label:"Задуговой"}] },
            { value: 'Орогенный', label: 'Орогенный' ,children:[
                    {value:"Межконтинентально-орогенный",label:"Межконтинентально-орогенный"}, {value:"Периконтенентально-океанический орогенный",label:"Периконтенентально-океанический орогенный"}, {value:"Внутриконтенентально-орогенный",label:"Внутриконтенентально-орогенный"}, {value:"Периконтинентально-Орогенный",label:"Периконтинентально-Орогенный"}, {value:"Окраинно-континентально-орогенный",label:"Окраинно-континентально-орогенный"}] },
        ],
    }];
export var age_word = [];
export var age_period = [];
export var age_period2 = [];
export var age_word_from = [];
export var age_word_to = [];
export var co2_emissions_min = null;
export var co2_emissions_max = null;
export var infra_emissions_min = 0;
export var infra_emissions_max = 96578767;

export const GuestText = ['OriginalName'];
export const ProtectedAreasText = ['OriginalName','Тип']
export const WellsText = ['OriginalName'];
export const SeismicsText = ['OriginalName'];
export const BasinsText = ['OriginalName','qc_id','Площадь' ,'Классификация','Тип','Подтип','Класс','Возраст','Разведанные_ресурсы_нефти','Разведанные_ресурсы_конденсата', 'Разведанные_ресурсы_газа', 'Неразведанные_ресурсы_нефти', 'Неразведанные_ресурсы_конденсата', 'Неразведанные_ресурсы_газа'];
export const SubBasinsText = ['OriginalName','Площадь', 'Классификация_по_полезным_ископае', 'Lithology',  'Main_reservoir', 'Main_source_rock', 'Разведанные_ресурсы_нефти',  'Разведанные_ресурсы_конденсата', 'Разведанные_ресурсы_газа', 'Неразведанные_ресурсы_нефти',  'Неразведанные_ресурсы_конденсата', 'Неразведанные_ресурсы_газа'];
export const FieldsText = ["OriginalName","Год","Класс","Location","Тип","Terrain","ShutdownYear","StartProductionYear","PlanedStartProductionYear","DiscoveryMethod","MaxElevation","MaxWaterDepth"];
export const LicensesText = ['Имя_лицензии','Лицензионный_номер','Год','Источник','Степень_уверенности', 'Тип_лицензии', 'Статус_лицензии','Лицензионные_обязательства', 'Дата_выдачи', 'Дата_окончания','Оператор','Платеж_победителя','Начальная_цена_аукциона'];
export const GeologicMapText = ['Geo_Index', 'Geo_compos'];
export const TectonicMapText = ['Структура', 'Порядок','Морфогенетический_тип','Тип'];
export const GeoObjectText = ['object_name','object_type','geo_index', 'outcrop'];
export const WellsFilter = [{name:'OriginalName',type:"text",display:"none",nulldisplay:"none"},{name:'FilterByGeometry',type:"geometry",display:"none",nulldisplay:"none"}];
export const SeismicsFilter = [{name:'OriginalName',type:"text",display:"none",nulldisplay:"none"},{name:'domain',type:"word",word:domain_word2,display:"none",nulldisplay:"none"},{name:'seismic_type',word:seismic_type_word2,type:"word",display:"none",nulldisplay:"none"},{name:'signal_source',word:signal_source_word2,type:"word",display:"none",nulldisplay:"none"},{name:'field_work_year',type:"number",display:"none",nulldisplay:"none"},{name:'processing_year',type:"number",display:"none",nulldisplay:"none"},{name:'FilterByGeometry',type:"geometry",display:"none",nulldisplay:"none"}];
export const BasinsFilter = [{name:'OriginalName',type:"text",display:"none",nulldisplay:"none"},{name:'Год',type:"number",display:"none",nulldisplay:"none"} ,{name:'Классификация',type:"word",word:nodes,display:"none",nulldisplay:"none"},{name:'Возраст',type:"word",word:basin_age_word2,display:"none",nulldisplay:"none"},{name:'Стратиграфия',type:"chrono",word:age_word,display:"none",nulldisplay:"none"},{name:'Тип полезных ископаемых',type:"word",word:age_word,display:"none",nulldisplay:"none"}];
export const SubBasinsFilter = [{name:'OriginalName',type:"text",display:"none",nulldisplay:"none"},{name:'Год',type:"number",display:"none",nulldisplay:"none"} ,{name:'Классификация',type:"word",word:nodes,display:"none",nulldisplay:"none"},{name:'Возраст',type:"word",word:basin_age_word2,display:"none",nulldisplay:"none"},{name:'Стратиграфия',type:"chrono",word:age_word,display:"none",nulldisplay:"none"},{name:'Тип полезных ископаемых',type:"word",word:age_word,display:"none",nulldisplay:"none"},{name:'FilterByGeometry',type:"geometry",display:"none",nulldisplay:"none"}];
export const FieldsFilter = [{name:'OriginalName',type:"text",display:"none",nulldisplay:"none"},{name:'Год открытия',type:"number",display:"none",nulldisplay:"none"},{name:'Классификация',type:"word",word:field_class_word2,display:"none",nulldisplay:"none"},{name:'Расположение',type:"word",word:location_word2,display:"none",nulldisplay:"none"} ,{name:'Тип',type:"word",word:field_type_word2,display:"none",nulldisplay:"none"},{name:'Местность',type:"word",word:terrain_word2,display:"none",nulldisplay:"none"},{name:'Год закрытия',type:"number",display:"none",nulldisplay:"none"},{name:'Год начала производства',type:"number",display:"none",nulldisplay:"none"},{name:'Планируемый год начала производства',type:"number",display:"none",nulldisplay:"none"},{name:'Метод открытия',type:"word",word:discovery_method_word2,display:"none",nulldisplay:"none"},{name:'Максимальная высота',type:"number",display:"none",nulldisplay:"none"},{name:'Максимальная глубина воды',type:"number",display:"none",nulldisplay:"none"},{name:'FilterByAge',type:"age",display:"none",nulldisplay:"none"},{name:'FilterByReserves',type:"reserves",display:"none",nulldisplay:"none"},{name:'FilterByGeometry',type:"geometry",display:"none",nulldisplay:"none"}];
export const LicensesFilter = [{name:'OriginalName',type:"text",display:"none",nulldisplay:"none"},{name:'Лицензионный номер',type:"text",display:"none",nulldisplay:"none"},{name:'Тип лицензии',type:"word",word:licenses_type_word2,display:"none",nulldisplay:"none"} ,{name:'Статус лицензии',type:"word",word:licenses_status_word2,display:"none",nulldisplay:"none"},{name:'Дата выдачи лицензии',type:"date",display:"none",nulldisplay:"none"},{name:'Дата окончания лицензи',type:"date",display:"none",nulldisplay:"none"},{name:'Оператор',type:"text",display:"none",nulldisplay:"none"},{name:"Источник",type:"text",display:"none",nulldisplay:"none"},{name:'Степень Уверенности',type:"word",word:discovery_method_word2,display:"none",nulldisplay:"none"},{name:'Лицензионные обязательства',type:"text",display:"none",nulldisplay:"none"},{name:'Год',type:"number",display:"none",nulldisplay:"none"},{name:'Платеж победителя',type:"number",display:"none",nulldisplay:"none"},{name:'Начальная цена аукциона',type:"number",display:"none",nulldisplay:"none"},{name:'FilterByGeometry',type:"geometry",display:"none",nulldisplay:"none"}];
export const GeologicMapFilter = [{name:'OriginalName',type:"text",display:"none",nulldisplay:"none"},{name:'Compas',type:"text",display:"none",nulldisplay:"none"},{name:'Стратиграфия',type:"chrono",word:age_word,display:"none",nulldisplay:"none"},{name:'FilterByGeometry',type:"geometry",display:"none",nulldisplay:"none"}];
export const GeologicObjectsFilter = [{name:'OriginalName',type:"text",display:"none",nulldisplay:"none"},{name:'ObjectType',type:"word",word:object_type_word2,display:"none",nulldisplay:"none"},{name:'Outcrop',type:"word",word:outcrop_word2,display:"none",nulldisplay:"none"} ,{name:'GeoPosition',type:"text",display:"none",nulldisplay:"none"},{name:'Reference',type:"text",display:"none",nulldisplay:"none"},{name:'Стратиграфия',type:"chrono",word:age_word,display:"none",nulldisplay:"none"},{name:'Straton',type:"text",display:"none",nulldisplay:"none"},{name:'ShortDesc',type:"text",display:"none",nulldisplay:"none"},{name:'FilterByGeometry',type:"geometry",display:"none",nulldisplay:"none"}];
export const TectonicMapFilter = [{name:'OriginalName'},{name:'FilterByGeometry',type:"geometry",display:"none",nulldisplay:"none"},{name:'FilterByGeometry',type:"geometry",display:"none",nulldisplay:"none"}];
export const ProtectedAreasFilter = [{name:'OriginalName',type:"text",display:"none",nulldisplay:"none"},{name:'ProtectedAreasType',type:"word",word:protected_areas_word2,display:"none",nulldisplay:"none"},{name:'ProtectedAreasProfile',type:"word",word:protected_areas_profile_word2,display:"none",nulldisplay:"none"},{name:'FilterByGeometry',type:"geometry",display:"none",nulldisplay:"none"}];
export const RegionsFilter = [{name:'OriginalName',type:"text",display:"none",nulldisplay:"none"},{name:'Административный округ',type:"text",display:"none",nulldisplay:"none"}];
export const BasinsFilterData = ['OriginalName','Год','Классификация','Возраст','Стратиграфия','Тип полезных ископаемых'];
export const WellsFilterData = ['OriginalName','FilterByGeometry'];
export const SeismicsFilterData = ['OriginalName','domain','seismic_type','signal_source','field_work_year','processing_year','FilterByGeometry'];
export const ProtectedAreasData = ['OriginalName','ProtectedAreasType','ProtectedAreasProfile','FilterByGeometry']
export const FieldsFilterData = ['OriginalName','Год открытия','Классификация','Расположение','Тип','Местность','Год закрытия','Год начала производства','Планируемый год начала производства','Метод открытия',"Максимальная высота","Максимальная глубина воды","FilterByAge","FilterByReserves",'FilterByGeometry'];
export const LicensesFilterData = ['OriginalName','Лицензионный номер','Тип лицензии','Статус лицензии','Дата выдачи лицензии','Дата окончания лицензи','Оператор',"Источник","Степень Уверенности","Лицензионные обязательства","Год","Платеж победителя","Начальная цена аукциона",'FilterByGeometry'];
export const GeologicMapFilterData = ['OriginalName','Compas','Стратиграфия','FilterByGeometry'];
export const TectonicMapFilterData = ['OriginalName','FilterByGeometry'];
export const GeologicObjectsFilterData = ['OriginalName','ObjectType','Outcrop','GeoPosition','Reference','Стратиграфия','Straton','ShortDesc','FilterByGeometry'];
export const RegionsFilterData = ['OriginalName','Административный округ'];
export const SubBasinsFilterData = ['OriginalName','Год','Классификация','Возраст','Стратиграфия','Тип полезных ископаемых','FilterByGeometry'];
export const LegendLayer = [{name:"Basins",data:false},{name:"SubBasins",data:false},{name:"Fields",data:false},{name:"Licenses",data:false},{name:"GeologicObjects",data:false},{name:"ProtectedAreas",data:false}];
export const FilterLayerData = [{name:"Basins",data:BasinsFilter,list:BasinsFilterData},{name:"GeologicMap",data:GeologicMapFilter,list:GeologicMapFilterData},{name:"TectonicMap",data:TectonicMapFilter,list:TectonicMapFilterData},{name:"SubBasins",data:SubBasinsFilter,list:SubBasinsFilterData},{name:"Regions",data:RegionsFilter,list:RegionsFilterData},{name:"GeologicObjects",data:GeologicObjectsFilter,list:GeologicObjectsFilterData},{name:"Licenses",data:LicensesFilter,list:LicensesFilterData},{name:"Fields",data:FieldsFilter,list:FieldsFilterData},{name:"ProtectedAreas",data:ProtectedAreasFilter,list:ProtectedAreasData},{name:"Seismics",data:SeismicsFilter,list:SeismicsFilterData}]
export var Filters = [{name:"Basins",switch:false,filters:[{name:"OriginalName",filter:null,notnull:null,DBname:"OriginalName"},{name:"Год",filter:null,notnull:null,DBname:"Год"},{name:"Класс",filter:null,notnull:null,DBname:"Класс"},{name:"Возраст",filter:null,notnull:null,DBname:"Возраст"},{name:"Стратиграфия",filter:null,notnull:null,DBname:"Начало"},{name:"Тип полезных ископаемых",filter:null,notnull:null,DBname:"Fluid"}]},
    {name:"SubBasins",switch:false,filters:[{name:"OriginalName",filter:null,notnull:null,DBname:"OriginalName"},{name:"Год",filter:null,notnull:null,DBname:"Год"},{name:"Класс",filter:null,notnull:null,DBname:"Класс"},{name:"Возраст",filter:null,notnull:null,DBname:"Возраст"},{name:"Стратиграфия",filter:null,notnull:null,DBname:"Начало"},{name:"Тип полезных ископаемых",filter:null,notnull:null,DBname:"Fluid"},{name:"FilterByGeometry",filter:null,notnull:null,DBname:"id"}]},
    {name:"Regions",switch:false,filters:[{name:"OriginalName",filter:null,notnull:null,DBname:"OriginalName"},{name:"Административный округ",filter:null,notnull:null,DBname:"АдминистративныйОкруг"}]},
    {name:"Fields",switch:false,filters:[{name:"OriginalName",filter:null,notnull:null,DBname:"OriginalName"},{name:"Год открытия",filter:null,notnull:null,DBname:"Год"},{name:"Класс",filter:null,notnull:null,DBname:"Класс"},{name:"Расположение",filter:null,notnull:null,DBname:"Location"},{name:"Тип",filter:null,notnull:null,DBname:"Тип"},{name:"Местность",filter:null,notnull:null,DBname:"Terrain"},{name:"Год закрытия",filter:null,notnull:null,DBname:"ShutdownYear"},{name:"Год начала производства",filter:null,notnull:null,DBname:"StartProductionYear"},{name:"Планируемый год начала производства",filter:null,notnull:null,DBname:"PlanedStartProductionYear"},{name:"Метод открытия",filter:null,notnull:null,DBname:"DiscoveryMethod"},{name:"Максимальная высота",filter:null,notnull:null,DBname:"MaxElevation"},{name:"Максимальная глубина воды",filter:null,notnull:null,DBname:"MaxWaterDepth"},{name:"FilterByAge",filter:null,notnull:null,DBname:"id"},{name:"FilterByReserves",filter:null,notnull:null,DBname:"id"},{name:"FilterByGeometry",filter:null,notnull:null,DBname:"id"}]},
    {name:"Licenses",switch:false,filters:[{name:"OriginalName",filter:null,notnull:null,DBname:"OriginalName"},{name:"Лицензионный номер",filter:null,notnull:null,DBname:"ЛицензионныйНомер"},{name:"Тип лицензии",filter:null,notnull:null,DBname:"Тип лицензии"},{name:"Статус лицензии",filter:["Действующая","Аукцион","Планируемый аукцион","Инвестиционные возможности"],notnull:null,DBname:"Статус лицензии"},{name:"Дата выдачи лицензии",filter:null,notnull:null,DBname:"IssueDate"},{name:"Дата окончания лицензи",filter:null,notnull:null,DBname:"ExparyYear"},{name:"Оператор",filter:null,notnull:null,DBname:"Оператор"},{name:"Источник",filter:null,notnull:null,DBname:"Источник"},{name:"Степень Уверенности",filter:null,notnull:null,DBname:"Степень_Уверенности"},{name:"Лицензионные обязательства",filter:null,notnull:null,DBname:"Лицензионные обязательства"},{name:"Год",filter:null,notnull:null,DBname:"Год"},{name:"Платеж победителя",filter:null,notnull:null,DBname:"paid_value"},{name:"Начальная цена аукциона",filter:null,notnull:null,DBname:"auction_value"},{name:"FilterByGeometry",filter:null,notnull:null,DBname:"id"}]},
    {name:"GeologicObjects",switch:false,filters:[{name:"OriginalName",filter:null,notnull:null,DBname:"OriginalName"},{name:"ObjectType",filter:null,notnull:null,DBname:"ObjectType"},{name:"Outcrop",filter:null,notnull:null,DBname:"outcrop"},{name:"GeoPosition",filter:null,notnull:null,DBname:"GeoPosition"},{name:"Reference",filter:null,notnull:null,DBname:"Reference"},{name:"Стратиграфия",filter:null,notnull:null,DBname:"Начало"},{name:"Straton",filter:null,notnull:null,DBname:"Straton"},{name:"ShortDesc",filter:null,notnull:null,DBname:"ShortDesc"},{name:"FilterByGeometry",filter:null,notnull:null,DBname:"id"}]},
    {name:"TectonicMap",switch:false,filters:[{name:"OriginalName",filter:null,notnull:null,DBname:"OriginalName"},{name:"FilterByGeometry",filter:null,notnull:null,DBname:"id"}]},
    {name:"GeologicMap",switch:false,filters:[{name:"OriginalName",filter:null,notnull:null,DBname:"OriginalName"},{name:'Compas',filter:null,notnull:null,DBname:"Compas"},{name:'Стратиграфия',filter:null,notnull:null,DBname:"Начало"},{name:"FilterByGeometry",filter:null,notnull:null,DBname:"id"}]},
    {name:"Wells",switch:false,filters:[{name:"OriginalName",filter:null,notnull:null,DBname:"OriginalName"},{name:"FilterByGeometry",filter:null,notnull:null,DBname:"id"}]},
    {name:"Seismics",switch:false,filters:[{name:"OriginalName",filter:null,notnull:null,DBname:"OriginalName"},{name:"domain",filter:null,notnull:null,DBname:"domain"},{name:"seismic_type",filter:null,notnull:null,DBname:"seismic_type"},{name:"signal_source",filter:null,notnull:null,DBname:"signal_source"},{name:"field_work_year",filter:null,notnull:null,DBname:"field_work_year"},{name:"processing_year",filter:null,notnull:null,DBname:"processing_year"},{name:"FilterByGeometry",filter:null,notnull:null,DBname:"id"}]},
    {name:"ProtectedAreas",switch:false,filters:[{name:"OriginalName",filter:null,notnull:null,DBname:"OriginalName"},{name:"ProtectedAreasType",filter:null,notnull:null,DBname:"protectedareastype"},{name:"ProtectedAreasProfile",filter:null,notnull:null,DBname:"protectedareasprofile"},{name:"FilterByGeometry",filter:null,notnull:null,DBname:"id"}]}];
export var result = [];
export const TextData = authorized ?
    [{name:"Wells",data:WellsText},{name:"Seismics",data:SeismicsText},{name:"Basins",data:BasinsText},{name:"GeologicMap",data:GeologicMapText},{name:"TectonicMap",data:TectonicMapText},{name:"SubBasins",data:SubBasinsText},{name:"Regions",data:BasinsText},{name:"GeologicObjects",data:GeoObjectText},{name:"Licenses",data:LicensesText},{name:"Fields",data:FieldsText},{name:"ProtectedAreas",data:ProtectedAreasText}]
    :
    [{name:"Wells",data:WellsText},{name:"Seismics",data:SeismicsText},{name:"Basins",data:GuestText},{name:"GeologicMap",data:GuestText},{name:"TectonicMap",data:GuestText},{name:"SubBasins",data:GuestText},{name:"Regions",data:GuestText},{name:"GeologicObjects",data:GuestText},{name:"Licenses",data:GuestText},{name:"Fields",data:GuestText}];
//const TextData = [{name:"Basins",data:BasinsText},{name:"GeologicMap",data:GeologicMapText},{name:"TectonicMap",data:TectonicMapText},{name:"SunBasins",data:SubBasinsText},{name:"Regions",data:BasinsText},{name:"GeologicObjects",data:GeoObjectText},{name:"Licenses",data:LicensesText},{name:"Fields",data:FieldsText}];
//const FilterData = [{name:"ProtectedAreas",data:ProtectedAreasText},{name:"Wells",data:WellsText},{name:"Basins",data:Basins},{name:"GeologicMap",data:GeologicMap},{name:"TectonicMap",data:TectonicMap},{name:"SunBasins",data:SubBasins},{name:"Regions",data:Regions},{name:"Regions",data:Regions},{name:"Licenses",data:Licenses},{name:"Fields",data:Fields}];
export var LayerOptions=[{name:"Basins",data:'none',data2:"none",id:0},{name:"GeologicMap",data:'none',data2:"none",id:1},{name:"TectonicMap",data:'none',data2:"none",id:2},{name:"SubBasins",data:'none',data2:"none",id:3},{name:"Regions",data:'none',data2:"none",id:4},{name:"Licenses",data:'none',data2:"none",id:5},{name:"Fields",data:'none',data2:"none",id:6},{name:"GeologicObjects",data:'none',data2:"none",id:7},{name:"Wells",data:'none',data2:"none",id:8},{name:"ProtectedAreas",data:'none',data2:"none",id:9},{name:"Seismics",data:"none",data2:"none",id:10}];
export var ExploreOptions=[{name:"Wells",data:'none',data2:"none",id:13},{name:"Seismics",data:"none",data2:"none",id:15},{name:"Fields",data:'none',data2:"none",id:0},{name:"Licenses",data:'none',data2:"none",id:5},{name:"SubBasins",data:'none',data2:"none",id:3},{name:"Basins",data:'none',data2:"none",id:1}];
export var GeoOptions=[{name:"GeologicObjects",data:'none',data2:"none",id:1},{name:"TectonicMap",data:'none',data2:"none",id:4},{name:"GeologicMap",data:'none',data2:"none",id:8}];
export var InfraOptions=[{name:"Info",data:'none',data2:"none",id:9},{name:"Google",data:'none',data2:"none",id:10}];
export var AdminOptions=[{name:"Regions",data:'none',data2:"none",id:4}];
export var EcoOptions=[{name:"ProtectedAreas",data:"none",data2:"none",id:14}];
export var UserOptions=[];
export var LayoutOptions=[{name:"GoogleSat",data:'none',id:10},{name:"GoogleHyb",data:'none',id:11},{name:"BingAerial",data:'none',id:12},{name:"ArcGISTopo",data:'none',id:13}];
export var name ;
export var update_dict=  axios.post(urlBackend+'/postQuery', {query:{type:"check_dict",select_type:"check_dict"}}).then(result => {
    console.log(result.data[0].relfilenode,localStorage.getItem('Dict_ver'));
    update_dict= result.data[0].relfilenode
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('tags_word')=== null)
        tags_word =requast({type:"get_publications_tags",select_type:"dropdown"}).then(result => {console.log(tags_word);localStorage.setItem('tags_word', JSON.stringify(result));tags_word= result});
    else
        tags_word =JSON.parse(localStorage.getItem('tags_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('basin_class_word')=== null)
        basin_class_word =requast({type:"basin_class",select_type:"dropdown"}).then(result => {console.log(basin_class_word);localStorage.setItem('basin_class_word', JSON.stringify(result));basin_class_word= result});
    else
        basin_class_word =JSON.parse(localStorage.getItem('basin_class_word'));
    console.log(basin_class_word)
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('basin_subtype_word')=== null)
        basin_subtype_word = requast({type:"basin_subtype",select_type:"dropdown"}).then(result => {localStorage.setItem('basin_subtype_word', JSON.stringify(result));basin_subtype_word= result});
    else
        basin_subtype_word =JSON.parse(localStorage.getItem('basin_subtype_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('basin_type_word')=== null)
        basin_type_word = requast({type:"basin_type",select_type:"dropdown"}).then(result => {localStorage.setItem('basin_type_word', JSON.stringify(result));basin_type_word= result});
    else
        basin_type_word =JSON.parse(localStorage.getItem('basin_type_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('basin_fluid_word')=== null)
        basin_fluid_word = requast({type:"basin_fluid_type",select_type:"dropdown"}).then(result => {localStorage.setItem('basin_fluid_word', JSON.stringify(result));basin_fluid_word= result});
    else
        basin_fluid_word =JSON.parse(localStorage.getItem('basin_fluid_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('field_class_word')=== null)
        field_class_word = requast({type:"universal_dict",select_type:"dropdown",select_params:"field_class"}).then(result => {localStorage.setItem('field_class_word', JSON.stringify(result));field_class_word= result});
    else
        field_class_word =JSON.parse(localStorage.getItem('field_class_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('signal_source_word')=== null)
        signal_source_word = requast( {type:"universal_dict",select_type:"dropdown",select_params:'seismic_signal_source'}).then(result => {localStorage.setItem('signal_source_word', JSON.stringify(result));signal_source_word= result});
    else
        signal_source_word =JSON.parse(localStorage.getItem('signal_source_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('seismic_type_word')=== null)
        seismic_type_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'seismic_type'}).then(result => {localStorage.setItem('seismic_type_word', JSON.stringify(result));seismic_type_word= result});
    else
        seismic_type_word =JSON.parse(localStorage.getItem('seismic_type_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('domain_word ')=== null)
        domain_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'seismic_domain'}).then(result => {localStorage.setItem('domain_word', JSON.stringify(result));domain_word= result});
    else
        domain_word  =JSON.parse(localStorage.getItem('domain_word '));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('field_type_word')=== null)
        field_type_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'field_type'}).then(result => {localStorage.setItem('field_type_word', JSON.stringify(result));field_type_word= result});
    else
        field_type_word =JSON.parse(localStorage.getItem('field_type_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('well_type_word')=== null)
        well_type_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'well_type'}).then(result => {localStorage.setItem('well_type_word', JSON.stringify(result));well_type_word= result});
    else
        well_type_word =JSON.parse(localStorage.getItem('well_type_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('well_stock_word')=== null)
        well_stock_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'well_stock'}).then(result => {localStorage.setItem('well_stock_word', JSON.stringify(result));well_stock_word= result});
    else
        well_stock_word =JSON.parse(localStorage.getItem('well_stock_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('well_status_word')=== null)
        well_status_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'well_status'}).then(result => {localStorage.setItem('well_status_word', JSON.stringify(result));well_status_word= result});
    else
        well_status_word =JSON.parse(localStorage.getItem('well_status_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('object_type_word')=== null)
        object_type_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'object_type'}).then(result => {localStorage.setItem('object_type_word', JSON.stringify(result));object_type_word= result});
    else
        object_type_word =JSON.parse(localStorage.getItem('object_type_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('outcrop_word')=== null)
        outcrop_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'outcrop'}).then(result => {localStorage.setItem('outcrop_word', JSON.stringify(result));outcrop_word= result});
    else
        outcrop_word =JSON.parse(localStorage.getItem('outcrop_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('licenses_type_word')=== null)
        licenses_type_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'license_type'}).then(result => {localStorage.setItem('licenses_type_word', JSON.stringify(result));licenses_type_word= result});
    else
        licenses_type_word =JSON.parse(localStorage.getItem('licenses_type_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('development_stage_word')=== null)
        development_stage_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'development_stage'}).then(result => {localStorage.setItem('development_stage_word', JSON.stringify(result));development_stage_word= result});
    else
        development_stage_word =JSON.parse(localStorage.getItem('development_stage_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('confidence_degree_word')=== null)
        confidence_degree_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'confidence_degree'}).then(result => {localStorage.setItem('confidence_degree_word', JSON.stringify(result));confidence_degree_word= result});
    else
        confidence_degree_word =JSON.parse(localStorage.getItem('confidence_degree_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('licenses_status_word')=== null)
        licenses_status_word = requast({ type:"universal_dict",select_type:"dropdown",select_params:'license_status'}).then(result => {localStorage.setItem('licenses_status_word', JSON.stringify(result));licenses_status_word= result});
    else
        licenses_status_word =JSON.parse(localStorage.getItem('licenses_status_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('location_word')=== null)
        location_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'field_location'}).then(result => {localStorage.setItem('location_word', JSON.stringify(result));location_word= result});
    else
        location_word =JSON.parse(localStorage.getItem('location_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('discovery_method_word')=== null)
        discovery_method_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'discovery_method'}).then(result => {localStorage.setItem('discovery_method_word', JSON.stringify(result));discovery_method_word= result});
    else
        discovery_method_word =JSON.parse(localStorage.getItem('discovery_method_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('protected_areas_word')=== null)
        protected_areas_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'protected_area'}).then(result => {localStorage.setItem('protected_areas_word', JSON.stringify(result));protected_areas_word= result});
    else
        protected_areas_word =JSON.parse(localStorage.getItem('protected_areas_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('protected_areas_profile_word')=== null)
        protected_areas_profile_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'protected_area_profile'}).then(result => {localStorage.setItem('protected_areas_profile_word', JSON.stringify(result));protected_areas_profile_word= result});
    else
        protected_areas_profile_word =JSON.parse(localStorage.getItem('protected_areas_profile_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('terrain_word')=== null)
        terrain_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'field_terrain'}).then(result => {localStorage.setItem('terrain_word', JSON.stringify(result));terrain_word= result});
    else
        terrain_word =JSON.parse(localStorage.getItem('terrain_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('basin_age_word')=== null)
        basin_age_word = requast({type:"basin_age",select_type:"dropdown"}).then(result => {localStorage.setItem('basin_age_word', JSON.stringify(result));console.log(result);basin_age_word= result});
    else
        basin_age_word =JSON.parse(localStorage.getItem('basin_age_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('map_type_word')=== null)
        map_type_word = requast({type:"universal_dict",select_type:"dropdown",select_params:'map_type'}).then(result => {localStorage.setItem('map_type_word', JSON.stringify(result));map_type_word= result});
    else
        map_type_word =JSON.parse(localStorage.getItem('map_type_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('publication_type_word')=== null)
        publication_type_word = requast({type:"universal_dict",select_type:"dropdown2",select_params:'publication_type'}).then(result => {localStorage.setItem('publication_type_word', JSON.stringify(result));publication_type_word= result});
    else
        publication_type_word =JSON.parse(localStorage.getItem('publication_type_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('age_from_word')=== null)
        age_from_word = requast({type:"chrono_strat",select_type:"dropdown",select_params:'age_from'}).then(result => {localStorage.setItem('age_from_word', JSON.stringify(result));age_from_word= result});
    else
        age_from_word =JSON.parse(localStorage.getItem('age_from_word'));
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('age_to_word')=== null)
        age_to_word = requast({type:"chrono_strat",select_type:"dropdown",select_params:'age_to'}).then(result => {localStorage.setItem('age_to_word', JSON.stringify(result));age_to_word= result});
    else
        age_to_word =JSON.parse(localStorage.getItem('age_to_word'));
    //
    //
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('domain_word2')=== null)
        domain_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'seismic_domain'}).then(result => {localStorage.setItem('domain_word2', JSON.stringify(result));domain_word2= result;SeismicsFilter[1].word = domain_word2 ;});
    else{
        domain_word2 =JSON.parse(localStorage.getItem('domain_word2'));
        SeismicsFilter[1].word =JSON.parse(localStorage.getItem('domain_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('seismic_type_word2')=== null)
        seismic_type_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'seismic_type'}).then(result => {localStorage.setItem('seismic_type_word2', JSON.stringify(result));seismic_type_word2= result;SeismicsFilter[2].word = seismic_type_word2 ;});
    else{
        seismic_type_word2 =JSON.parse(localStorage.getItem('seismic_type_word2'));
        SeismicsFilter[2].word =JSON.parse(localStorage.getItem('seismic_type_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('signal_source_word2')=== null)
        signal_source_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'seismic_signal_source'}).then(result => {localStorage.setItem('signal_source_word2', JSON.stringify(result));signal_source_word2= result;SeismicsFilter[3].word = signal_source_word2 ;});
    else{
        signal_source_word2 =JSON.parse(localStorage.getItem('signal_source_word2'));
        SeismicsFilter[3].word = JSON.parse(localStorage.getItem('signal_source_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('basin_fluid_word2')=== null)
        basin_fluid_word2 = requast2({type:"basin_fluid_type",select_type:"filter"}).then(result => {localStorage.setItem('basin_fluid_word2', JSON.stringify(result));basin_fluid_word2= result;BasinsFilter[5].word =basin_fluid_word2;SubBasinsFilter[5].word =basin_fluid_word2;});
    else{
        basin_fluid_word2 =JSON.parse(localStorage.getItem('basin_fluid_word2'));
        BasinsFilter[5].word =JSON.parse(localStorage.getItem('basin_fluid_word2'));
        SubBasinsFilter[5].word =JSON.parse(localStorage.getItem('basin_fluid_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('field_class_word2')=== null)
        field_class_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'field_class'}).then(result => {localStorage.setItem('field_class_word2', JSON.stringify(result));field_class_word2= result;FieldsFilter[2].word =field_class_word2;});
    else{
        field_class_word2 =JSON.parse(localStorage.getItem('field_class_word2'));
        FieldsFilter[2].word =JSON.parse(localStorage.getItem('field_class_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('field_type_word2')=== null)
        field_type_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'field_type'}).then(result => { console.log(result);localStorage.setItem('field_type_word2', JSON.stringify(result));field_type_word2= result;FieldsFilter[4].word =field_type_word2;});
    else{
        field_type_word2 =JSON.parse(localStorage.getItem('field_type_word2'));
        FieldsFilter[4].word = JSON.parse(localStorage.getItem('field_type_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('object_type_word2')=== null)
        object_type_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'object_type'}).then(result => {localStorage.setItem('object_type_word2', JSON.stringify(result));object_type_word2= result;GeologicObjectsFilter[1].word =object_type_word2;});
    else{
        object_type_word2 =JSON.parse(localStorage.getItem('object_type_word2'));
        GeologicObjectsFilter[1].word =JSON.parse(localStorage.getItem('object_type_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('outcrop_word2')=== null)
        outcrop_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'outcrop'}).then(result => {localStorage.setItem('outcrop_word2', JSON.stringify(result));outcrop_word2= result;GeologicObjectsFilter[2].word =outcrop_word2;});
    else{
        outcrop_word2 =JSON.parse(localStorage.getItem('outcrop_word2'));
        GeologicObjectsFilter[2].word =JSON.parse(localStorage.getItem('outcrop_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('confidence_degree_word2')=== null)
        confidence_degree_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'confidence_degree'}).then(result => {localStorage.setItem('confidence_degree_word2', JSON.stringify(result));confidence_degree_word2= result;LicensesFilter[8].word =confidence_degree_word2;});
    else{
        confidence_degree_word2 =JSON.parse(localStorage.getItem('confidence_degree_word2'));
        LicensesFilter[8].word =JSON.parse(localStorage.getItem('object_type_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('licenses_type_word2')=== null)
        licenses_type_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'license_type'}).then(result => {console.log(result);localStorage.setItem('licenses_type_word2', JSON.stringify(result));licenses_type_word2= result;LicensesFilter[2].word =licenses_type_word2;});
    else{
        licenses_type_word2 =JSON.parse(localStorage.getItem('licenses_type_word2'));
        console.log(licenses_type_word2);
        LicensesFilter[2].word =JSON.parse(localStorage.getItem('licenses_type_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('licenses_status_word2')=== null)
        var licenses_status_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'license_status'}).then(result => {localStorage.setItem('licenses_status_word2', JSON.stringify(result));licenses_status_word2= result;LicensesFilter[3].word =licenses_status_word2;});
    else{
        licenses_status_word2 =JSON.parse(localStorage.getItem('licenses_status_word2'));
        LicensesFilter[3].word =JSON.parse(localStorage.getItem('licenses_status_word2'));
    }

    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('location_word2')=== null)
        location_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'field_location'}).then(result => {localStorage.setItem('location_word2', JSON.stringify(result));location_word2= result;FieldsFilter[3].word =location_word2;});
    else{
        location_word2 =JSON.parse(localStorage.getItem('location_word2'));
        FieldsFilter[3].word =JSON.parse(localStorage.getItem('location_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('terrain_word2')=== null)
        terrain_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'field_terrain'}).then(result => {localStorage.setItem('terrain_word2', JSON.stringify(result));terrain_word2= result;FieldsFilter[5].word =terrain_word2;});
    else{
        terrain_word2 =JSON.parse(localStorage.getItem('terrain_word2'));
        FieldsFilter[5].word =JSON.parse(localStorage.getItem('terrain_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('protected_areas_word2')=== null)
        protected_areas_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'protected_area'}).then(result => {localStorage.setItem('protected_areas_word2', JSON.stringify(result));protected_areas_word2= result;ProtectedAreasFilter[1].word =protected_areas_word2;});
    else{
        protected_areas_word2 =JSON.parse(localStorage.getItem('protected_areas_word2'));
        ProtectedAreasFilter[1].word =JSON.parse(localStorage.getItem('protected_areas_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('protected_areas_profile_word2')=== null)
        protected_areas_profile_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'protected_area_profile_profile'}).then(result => {localStorage.setItem('protected_areas_profile_word2', JSON.stringify(result));protected_areas_profile_word2= result;ProtectedAreasFilter[2].word =protected_areas_profile_word2;});
    else{
        protected_areas_profile_word2 =JSON.parse(localStorage.getItem('protected_areas_profile_word2'));
        ProtectedAreasFilter[2].word =JSON.parse(localStorage.getItem('protected_areas_profile_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('discovery_method_word2')=== null)
        discovery_method_word2 = requast2({type:"universal_dict",select_type:"filter",select_params:'discovery_method'}).then(result => {localStorage.setItem('discovery_method_word2', JSON.stringify(result));discovery_method_word2= result;FieldsFilter[9].word =discovery_method_word2;});
    else{
        discovery_method_word2 =JSON.parse(localStorage.getItem('discovery_method_word2'));
        FieldsFilter[9].word =JSON.parse(localStorage.getItem('discovery_method_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('basin_age_word2')=== null)
        basin_age_word2 = requast2({type:"basin_age",select_type:"filter"}).then(result => {localStorage.setItem('basin_age_word2', JSON.stringify(result));basin_age_word2= result;BasinsFilter[3].word =basin_age_word2;SubBasinsFilter[3].word =basin_age_word2;});
    else{
        basin_age_word2 =JSON.parse(localStorage.getItem('basin_age_word2'));
        BasinsFilter[3].word =JSON.parse(localStorage.getItem('basin_age_word2'));
        SubBasinsFilter[3].word =JSON.parse(localStorage.getItem('basin_age_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('field_type_word2')=== null)
        field_type_word2 = requast2({type:"universal_dict",select_type:"color",select_params:'field_type'}).then(result => {result.push({name:null,color:null});localStorage.setItem('field_type_word2', JSON.stringify(result));field_type_word2= result;LegendLayer[2].data = field_type_word2 ;});
    else{
        field_type_word2 =JSON.parse(localStorage.getItem('field_type_word2'));
        FieldsFilter[4].word =JSON.parse(localStorage.getItem('field_type_word2'));
        LegendLayer[2].data =JSON.parse(localStorage.getItem('field_type_word2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('licensecolor')=== null)
        licensecolor = requast2({type:"universal_dict",select_type:"color",select_params:'license_status'}).then(result => {result.push({name:null,color:null});localStorage.setItem('licensecolor', JSON.stringify(result));licensecolor= result;LegendLayer[3].data = licensecolor ;});
    else{
        licensecolor =JSON.parse(localStorage.getItem('licensecolor'));
        LegendLayer[3].data =JSON.parse(localStorage.getItem('licensecolor'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('world_source_storage2')=== null)
        world_source_storage = requast2({type:"universal_dict",select_type:"dropdown",select_params:'storage_type'}).then(result => {localStorage.setItem('world_source_storage2', JSON.stringify(result));world_source_storage= result});
    else{
        world_source_storage =JSON.parse(localStorage.getItem('world_source_storage2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('world_source_status2')=== null)
        world_source_status = requast2({type:"universal_dict",select_type:"dropdown",select_params:'world_sources_status'}).then(result => {localStorage.setItem('world_source_status2', JSON.stringify(result));world_source_status= result});
    else{
        world_source_status =JSON.parse(localStorage.getItem('world_source_status2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('world_source_formation2')=== null)
        world_source_formation = requast2({type:"universal_dict",select_type:"dropdown",select_params:'world_source_formation'}).then(result => {localStorage.setItem('world_source_formation2', JSON.stringify(result));world_source_formation= result});
    else{
        world_source_formation =JSON.parse(localStorage.getItem('world_source_formation2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver')&&localStorage.getItem('world_source_reservoir2')=== null)
        world_source_reservoir = requast2({type:"litho_type",select_type:"dropdown",select_params:'world_source_reservoir'}).then(result => {localStorage.setItem('world_source_reservoir2', JSON.stringify(result));world_source_reservoir= result});
    else{
        world_source_reservoir =JSON.parse(localStorage.getItem('world_source_reservoir2'));
    }
    if ( update_dict !== localStorage.getItem('Dict_ver'))
        localStorage.setItem('Dict_ver', update_dict)
    if (Cookies.get("id") !== undefined)
        axios.post(urlBackend+'/postQuery', {query:{type:"get_Groups_Permission",select_type:"get_Groups_Permission_Without_Object",select_params:{user_id:Cookies.get("id")}}})
            .then((res) => {
                let groupArray = [];
                for (let i = 0;i< res.data[0].get_permission_groups.length;i++){
                    groupArray.push(res.data[0].get_permission_groups[i].name);
                }
                Cookies.set("groups", groupArray);
            }).catch((error) => {
            console.log(error)
        });
    axios.post(urlBackend+'/postQuery', {query:{type:"get_co2_infra_emissions_values",select_type:"get_values"}})
        .then((res) => {
            console.log(res.data);
            infra_emissions_min = res.data[0].min_value;
            infra_emissions_max = res.data[0].max_value;
        }).catch((error) => {
        console.log(error)
    });
    axios.post(urlBackend+'/postQuery', {query:{type:"get_co2_emissions_values",select_type:"get_values"}})
        .then((res) => {
            console.log(res.data);
            co2_emissions_min = res.data[0].min_value;
            co2_emissions_max = res.data[0].max_value;
        }).catch((error) => {
        console.log(error)
    });
    axios.post(urlBackend+'/postQuery', {query:{type:"chrono_strat",select_type:"filter",select_params:'age_from'}})
        .then((res) => {
            age_word = res.data;
            let temp = [];
            for (let i = 0 ; i < res.data.length;i++){
                temp.push({key:i,text:res.data[i].object_index,value:res.data[i].age_from + " " +res.data[i].object_index})
                age_period.push({key:i,text:res.data[i].object_index,value:res.data[i].age_to + " " +res.data[i].object_index});
                age_period2.push({key:res.data[i].object_index,text:res.data[i].object_index,value:res.data[i].object_index});
            }
            BasinsFilter[4].word = temp;
            GeologicMapFilter[2].word = temp;
            GeologicObjectsFilter[5].word = temp;
            FieldsFilter[12].word = temp;
            age_word_from = temp;
            age_word_to = age_period;
            console.log(temp,age_period,age_word)
        }).catch((error) => {
        console.log(error)
    });
});
export const graticule = {
    type: 'FeatureCollection',
    features: []
};
