import React, {createRef, useEffect, useRef, useState} from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import maplibregl, {AttributionControl} from 'maplibre-gl';
import '../Styles/MyGeoMap.css'
import 'maplibre-gl/dist/maplibre-gl.css';
import MeasuresControl from 'maplibre-gl-measures';
import styles from "../styles2.json";
import {position} from "plotly.js/src/plots/cartesian/layout_attributes";
import {RulerControl} from "mapbox-gl-controls";
import {Button, Label, List, Popup} from "semantic-ui-react";
import {co2_emissions_max, co2_emissions_min, graticule, urlBackend,infra_emissions_min,infra_emissions_max} from "../VariableInitialize";
import {Graticule} from "./MyGeoMapGraticule"
import Area from "../Interface/area2.png";
import * as turf from "@turf/turf";
import CoordinateNet from "../Interface/coord2.png";
import Lasso from "../Interface/lasso.png";
import {stringify} from "wkt";
import axios from "axios";
import patternlines from "../images/lines.png";
import MyGeoMapImage from "./MyGeoMapImage";
import MyGeoMapLayer from "./MyGeoMapLayer"
import MyGeoMapSource from "./MyGeoMapSource";
import MyGeoMapFilter from "./MyGeoMapFilter";
import MyGeoMapFlyTo from "./MyGeoMapFlyTo";
import MyGeoMapFeatureState from "./MyGeoMapFeatureState";
import Cookies from "js-cookie";
import mapboxgl from "mapbox-gl";
import {isMobile} from "react-device-detect";
import {getUniqueFeatures} from "../functions";
import {Image} from "@urbica/react-map-gl";
import mgu from "../Interface/mgu.png";
import triangle from "../Interface/triangle.png";
import rosneft from "../Interface/rosneft.png";
import gazprom from "../Interface/gazprom.png";
import i18n from "../i18n";
import PointCreate from "./PointCreate";
import {MyGeoMapPointCreate} from "./MyGeoMapPointCreate";
import MyGeoMapGeoReference from "./MyGeoMapGeoReference";
import MyGeoMapGeoReferencePreview from "./MyGeoMapGeoReferencePreview";
import MyGeoMapAreaMeansurement from "./MyGeoMapAreaMeansurement";
import MyGeoMapLasso from "./MyGeoMapLasso";
import MyGeoMapLinesTable from "./MyGeoMapLinesTable";
import MyGeoMapLines from "./MyGeoMapLines";
import MyGeoMapClear from "./MyGeoMapClear";
import MyGeoMapPieLayer from "./MyGeoMapPieLayer";
const MAPBOX_TOKEN = '29401193d04a48ad85fc942a38f09631'; //

var timer;



export default function MyGeoMap(props) {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const map_popup = useRef(null)
    const draw = useRef(null)
    const interaction = useRef(true)
    const [result,setResult] = useState(props.result)
    const [data2,setData2] = useState(props.data2)
    const [lng,setLng] = useState(97);
    const [lat,setLat] = useState(67);
    const [zoom,setZoom] = useState(2.3);
    const [AreaActive, setAreaActive] = useState(false)
    const [DrawState, setDrawState] = useState(true)
    const [CoordinateNetActive, setCoordinateNetActive] = useState(false)
    const [lassoActive,setLassoActive] = useState(false)
    const [lassoDisplay,setLassoDisplay] = useState(false)
    const [lassoList, setLassoList] = useState([])
    const [lassoTableDisplay,setLassoTableDisplay] = useState("none")
    const [lassoFilter,setLassoFilter] = useState([])
    const [pointDisplay,setPointDisplay] = useState("none")
    const [mapMenuDisplay,setMapMenuDisplay] = useState('none')
    const [mapMenuSearchDisplay,setMapMenuSearchDisplay] = useState('none')
    const [mapMenuSearchResult,setMapMenuSearchResult] = useState({})
    const [mapMenuPosition,setMapMenuPosition] = useState({x:0,y:0})
    const [mapMenuPoint,setMapMenuPoint] = useState({lat:0,lng:0})
    const [top,setTop] = useState(0);
    const [left,setLeft] = useState(0);
    const [featuresList,setFeaturesList] = useState([])
    const [featuresDisplay,setFeaturesDisplay] = useState("none")
    const [stateList,setStateList] = useState([])
    const [stateID,setStateID] = useState(null);
    const [stateSource,setStateSource] = useState(null);
    const [stateLayer,setStateLayer] = useState(null);
    const [interactionLayer,setInteractionLayer] = useState([])
    const [linesDisplay,setLinesDisplay] = useState(false)
    const [filterPropsLayer,setFilterPropsLayer] =useState("")
    const [filter,setFilter] = useState([])
    const [flyCount,setFlyCount] = useState(0);
    const [flyCoordinates,setFlyCoordinates] = useState([]);
    const [flyType,setFlyType] = useState("");
    const [georef_delete,setGeorefDelete] = useState(false);
    const [georef_delete_index, setGeorefDeleteIndex] = useState(null);
    const [georef_state, setGeorefState] = useState(false);
    const [georef_opacity,setGeoRefOpacity] = useState(1)
    const [georef_bbox,setGeoRefBbox] = useState([])
    const [co2_center,setCo2Center] = useState([]);
    const [geomData,setGeomData] = useState(null)
    const [lineActive,setLineActive] = useState(false)
    const [lineActiveTrigger, setLineActiveTrigger] = useState(0)
    const [linesLength,setLinesLength] = useState(0)
    const [lines,setLines] = useState(null)
    const [georef_url,setGeoRefUrl] = useState("")
    const [clear, setClear] = useState(false);
    const [points, setPoints] = useState([]);
    const [user_id, setUser_id] = useState("?user_id="+  (Cookies.get("id")?Cookies.get("id"):0))
    const [API_KEY] = useState(MAPBOX_TOKEN);
    var element = document.getElementsByClassName('mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_line')
    var element2 = document.getElementsByClassName('mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon')
    var element3 = document.getElementsByClassName('mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_point')
    if (element[0])
        element[0].addEventListener('click', function() {
            console.log("button click")
            draw.current.deleteAll()
        }, false)
    if (element2[0])
        element2[0].addEventListener('click', function() {
            console.log("button click")
            draw.current.deleteAll()
        }, false)
    if (element3[0])
        element3[0].addEventListener('click', function() {
            console.log("button click")
            draw.current.deleteAll()
    }, false)

    console.log(element,element2,element3)
    useEffect(() => {
        if (map.current) return; // stops map from intializing more than once

        map.current = new maplibregl.Map({
            container: mapContainer.current,
            style: styles,
            center: [lng, lat],
            zoom: zoom,
            attributionControl: {
                compact: true,
                customAttribution:" <a href=\"https://mygeomap.ru/docs/user_agreement.pdf\" target=\"_blank\">Пользовательское Соглашение </a> | "
            }
        });
        // Add navigation control

        map.current.addControl(new maplibregl.NavigationControl(), 'top-right');
        // Add Draw
        draw.current = new MapboxDraw();
        map.current.addControl(draw.current, 'top-right');
        map.current.on('draw.modechange', (e,data)=>{
            console.log(e,e.mode,data,map.current.getStyle().layers)
            if (e.mode === 'simple_select'){
                setDrawState(false)
            }else {
                setDrawState(true)
                if (e.mode === 'draw_point')
                    setPointDisplay("block")
                else
                    setPointDisplay("none")
                if ( e.mode === 'draw_line_string' || e.mode === 'draw_polygon' || e.mode === 'draw_point') {
                    console.log("delete previus geom", draw.current.getAll())
                    let features = draw.current.getAll()
                    if (features.features.length > 1)
                        draw.current.deleteAll()
                }

            }
        });
        map.current.on('draw.selectionchange', (e,data)=>{
            console.log(map.current.getStyle().layers)
            if (e.features.length === 1){
                props.updateGeometry(e.features[0])
                setDrawState(true)
            }else{
                props.updateGeometryReset()
                setDrawState(false)
            }
        });
        map.current.on('draw.update', (e,data)=>{

            console.log(e,data,map.current.getStyle().layers)
            props.onRedactChange(e,data)
        })
        map.current.on('draw.create',(e,data)=>{
            console.log(e,data,map.current.getStyle().layers)
            props.onRedactChange(e,data)
        })
        //mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_line
        //mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon
        //mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_point

        //Add Geolocation
        map.current.addControl(
            new maplibregl.GeolocateControl({
                positionOptions: {
                    enableHighAccuracy: true
                },
                trackUserLocation: true
            })
        );
        // Add Scale
        map.current.addControl( new maplibregl.ScaleControl({
            maxWidth: 80,
            unit: 'metric'
        }), 'bottom-right');
        //Add Fullscreen
        map.current.addControl(new maplibregl.FullscreenControl());
        // Add Ruler
        map.current.addControl(new RulerControl(), 'bottom-right');
        // mouse position
        map.current.on('mousemove', (e) => {
            document.getElementById('latlngnew').innerHTML =
                "Lng: "+e.lngLat.lng.toFixed(5)+" Lat: "+e.lngLat.lat.toFixed(5) ;
        });
        //zoom on shift+click
        map.current.on('click',(e) =>{
            if (e.originalEvent.shiftKey ){
                FlyOnShift(e.lngLat.lng,e.lngLat.lat)
            }
        })
        // on Esc
        var canvas = map.current.getCanvasContainer();
        canvas.addEventListener('keydown', function(e) {
            if (e.key === "Escape") {
                //this.radiusEmissionsDelete()
                props.emission_remove();
            }
        });
        //context menu

        map.current.on("contextmenu", (e) => {
            console.log(e,"contextmenu")
            e.preventDefault()
            setMapMenuDisplay("block")
            setMapMenuSearchDisplay('none')
            setMapMenuPosition({ x: e.originalEvent.pageX-10, y: e.originalEvent.pageY-80})
            setMapMenuPoint({lat:e.lngLat.lat,lng:e.lngLat.lng})
            //that.setState({mapMenuDisplay:"block",
            //    mapMenuSearchDisplay:"none",
            //    mapMenuPosition:,mapMenuPoint:,})
            console.log(e.lngLat.lat,e.lngLat.lng)
        });
        //set interaction
        map_popup.current = new maplibregl.Popup({closeOnClick: false})
        //area measure source layer

        // user agreement
        console.log("basic map interactions")
        setBaseAreaSourceLayer(map,result,DrawState,setDrawState,props.onClick,LayerOrder,setFeaturesList,setFeaturesDisplay,setTop,setLeft,georef_state)
    }, [API_KEY, props.onFly, lng, lat, zoom, DrawState, props.onClick, result, props, georef_state, setBaseAreaSourceLayer, FlyOnShift]);
    useEffect(() => {

        setCo2Center(props.emissionCenter)
    }, [props.emissionCenter]);
    useEffect(() => {
        console.log(co2_center,map.current,{
            type: 'FeatureCollection',
            features: co2_center,
        })
        if (!map.current)
            return
        //'id': 'emissions-radius-fill',
        if (map.current.getSource('emissions-radius')){
            if (co2_center === null){
                console.log("Delete Emission Data")
                map.current.setLayoutProperty("emissions-radius-fill", 'visibility', 'none');
            } else {
                console.log("New Emission Data")
                map.current.getSource('emissions-radius').setData({
                    type: 'FeatureCollection',
                    features: [co2_center],
                });
                map.current.setLayoutProperty("emissions-radius-fill", 'visibility', 'visible');
            }
        }
        //'source': 'emissions-radius',
    }, [co2_center]);
    useEffect(() => {
        console.log(props.Filters)
        setFilter(props.Filters)

    }, [props.Filters]);
    useEffect(() => {
        setGeorefDelete(props.georef_delete)
        setGeorefDeleteIndex(props.georef_delete_index)
        console.log(props.georef_delete, props.georef_delete_index)
    }, [props.georef_delete, props.georef_delete_index]);
    useEffect(() => {
        console.log(props.georefPoints,points)
        let changed = false;
        let newPoints = []
        for (let i = 0 ; i < points.length; i++){
            if ( props.georefPoints[i].x !== points[i][0] || props.georefPoints[i].y !== points[i][1]){
                changed = true
            }
            newPoints.push([props.georefPoints[i].x,props.georefPoints[i].y])
        }
        if(changed)
            setPoints(newPoints)
    }, [points, props.georefPoints]);
    useEffect(() => {
        setLinesDisplay(props.linesDisplay)

    }, [props.linesDisplay]);
    useEffect(() => {
        console.log("table",props.filterPropsLayer,props.filterPropsDisplay === "none",props.inc)
        if (props.filterPropsLayer && filterPropsLayer !== props.filterPropsLayer && props.filterPropsDisplay === "none"){
            console.log("complete")
            props.onFeaturestableComplete(getFilterPropsList(props.filterPropsLayer))
            setFilterPropsLayer(props.filterPropsLayer)
        }
    }, [props.filterPropsLayer, props.filterPropsDisplay, filterPropsLayer]);
    useEffect(() => {
        setGeoRefUrl(props.georefURL)
        setGeoRefOpacity(props.georefOpacity)
        setGeoRefBbox(props.georefBbox)
    }, [props.georefURL,props.georefBbox,props.georefOpacity]);
    useEffect(() => {
        if(props.flyCount>0){
            setFlyCount(props.flyCount)
            setFlyCoordinates(props.flyCoordinates)
            setFlyType(props.flyType)
        }
    }, [props.flyCoordinates, props.flyCount, props.flyType]);
    useEffect(() => {
        console.log(user_id)
        setUser_id(props.user_id)
    }, [props.user_id, user_id]);

    useEffect(() => {
        console.log(props.result,props.data2)
        setResult(props.result)
        setData2(props.data2)
        setUser_id(props.user_id)
    },[JSON.stringify(props.result),JSON.stringify(props.data2),props.user_id])
    useEffect(() => {

        setGeorefState(props.georefMode)
        setDrawState(props.georefMode)
        interaction.current = props.georefMode;
    }, [props.georefMode]);
    useEffect(() => {
        if (props.georefClear && !clear)
            setClear(true)
    }, [props.georefClear]);
    useEffect(() => {
        console.log(props.hoveredID,props.hoveredSourceLayer,props.hoveredSourceID)
        setStateID(props.hoveredID);
        setStateLayer(props.hoveredSourceLayer);
        setStateSource(props.hoveredSourceID)
        if ( props.hoveredID && props.hoveredSourceID && props.hoveredSourceLayer){
            map.current.on('click', props.hoveredSourceID, (e) => {
                if (props.hoveredID) {
                    map.current.setFeatureState(
                        {source: props.hoveredSourceLayer, id: props.hoveredID},
                        {hover: true}
                    );
                }
            });
            map.current.on('mouseleave', props.hoveredSourceID, () => {
                if (props.hoveredID) {
                    map.current.setFeatureState(
                        {source: props.hoveredSourceLayer, id: props.hoveredID},
                        {hover: false}
                    );
                }
                setStateID(null);
                setStateLayer(null);
                setStateSource(null)
            });
        }


    },[props.hoveredID, props.hoveredSourceLayer, props.hoveredSourceID])

    useEffect(() => {
        console.log(props.interactionLayer)
        let layers = []
        for (let i = 0 ; i < props.interactionLayer.length ; i++){
            for (let j = 0 ; j < result.length;j++){
                console.log(props.interactionLayer[i],result[j].name)
                if ( props.interactionLayer[i].toLowerCase() === result[j].name.toLowerCase())
                    layers.push(result[j].id)
            }
        }
        console.log(layers,props.interactionLayer)
        for (let i = 0 ; i < layers.length;i++){
            console.log("create map event ",layers[i])
            map.current.on('click',layers[i],(e)=>{
                var bbox = [
                    [e.point.x - 1, e.point.y - 1],
                    [e.point.x + 1, e.point.y + 1]
                ];
                let features = map.current.queryRenderedFeatures(bbox, {layers: [layers[i]]});
                features = getUniqueFeatures(features, 'qc_id');
                console.log(features[0].id,features[0].source)
                props.interactionLayersID(features[0].id,features[0].source)
            })
        }
    }, [props.interactionLayer, props.interactionLayer.length,props.interactionLayersID, result]);
    useEffect(() => {
        console.log(props.geom_data,geomData,props.geom_data === geomData)
        if (props.geom_data === geomData)
            return
        if (props.geom_data === null)
        {
            draw.current.deleteAll()
            return
        }
        console.log("not same geom")
        setGeomData(props.geom_data)
        draw.current.deleteAll()
        draw.current.set(props.geom_data);
        console.log("geom set")
    }, [geomData, props.geom_data]);
    useEffect(() => {
        setFilter(props.Filters)
    }, [lassoFilter, props.Filters]);
    useEffect(() => {

        console.log(AreaActive)
        if (map.current) return;
        map.current.setLayoutProperty(
            'area',
            'visibility',
            'none'
        );
        map.current.setLayoutProperty(
            'areaSymbol',
            'visibility',
            'none'
        );
        map.current.setLayoutProperty(
            'areapoint',
            'visibility',
            'none'
        );
        map.current.setLayoutProperty(
            'arealine',
            'visibility',
            'none'
        );

    }, [AreaActive]);
    function onGeoRefDelete () {
        setGeorefDelete(false)
        setGeorefDeleteIndex(null)
    }
    function FlyOnShift(x,y) {
        console.log(props.georefMode,interaction.current)
        if ( interaction.current)
            return
        //console.log(georef_state2,x,y,"after",props.georefMode)
        props.onFly({center: [
                Number(x) ,
                Number(y)
            ]},"fly")
    }
    function onClickProxy(proxy,onClick) {
        console.log(props.georefMode)

        if ( interaction.current)
            return
        console.log(proxy,"after",props.georefMode,georef_state)
        onClick(proxy)


    }
    function addToDraw(x,y,trigger = false) {
        console.log(x,y,draw.current,trigger)
        if (trigger)
            map.current.flyTo({
                center: [
                    y ,
                    x
                ],
                zoom: 9,
                essential: true // this animation is considered essential with respect to prefers-reduced-motion
            });
        console.log({
            "type": "FeatureCollection",
            "features": [
                {
                    "type": "Feature",
                    "geometry": { type: 'Point', coordinates: [y,x] },
                    "properties": {"prop0": "value0"}
                }]
        })
        draw.current.deleteAll()
        draw.current.set({
            type: 'FeatureCollection',
            features: [{
                type: 'Feature',
                properties: {},
                id: 'example-id',
                geometry: { type: 'Point', coordinates: [y,x] }
            }]
        });

        console.log("point created")
    }
    function getFilterPropsList(layerID) {
        console.log("process")
        if (!map || !layerID)
            return
        let FilterPropsList = []
        let features2 = map.current.queryRenderedFeatures({layers: [layerID.toString()]});
        if (features2.length > 0){
            for ( let p = 0 ; p< features2.length ; p++){
                let props = features2[p].properties;
                delete props.ExpiryDate;delete props.IssueDate;delete props.color;delete props.color0;delete props.color1;delete props.color2;delete props.color3;delete props.color4;delete props.color5;delete props.color6;delete props.color7;delete props.color8;delete props.color9;delete props.color10;delete props.Описание;
                FilterPropsList.push(features2[p].properties)
            }

        }
        console.log("result",FilterPropsList)
        return FilterPropsList;
    }
    function setBaseAreaSourceLayer (map,result,drawState,setDrawState,onClick,LayerOrder,setFeaturesList,setFeaturesDisplay,setTop,setLeft) {
        let lasso = [];
        let lassoKeyDown = false;
        let prevLassoKeyDown;
        map.current.once('load', async() => {

            map.current.addSource('lines', {
                'type': 'vector',
                'url': 'https://openinframap.org/map.json'
            });
            map.current.addSource('graticule1', {
                type: 'geojson',
                data: graticule
            });
            map.current.addSource('graticule2', {
                type: 'geojson',
                data: graticule
            });
            map.current.addSource('georef_points', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [],
                },
            });
            map.current.addSource('emissions-radius', {
                'type': 'geojson',
                'data': []
            });
            map.current.addLayer(
                {
                    'id': 'emissions-radius-fill',
                    //'type': 'fill',
                    'type': 'line',
                    'source': 'emissions-radius',
                    'layout': {'visibility': 'visible',},
                    'paint': {
                        //'fill-outline-color':"#000000",
                        //'fill-color':'transparent',
                        'line-color': '#000',
                        'line-width': 2

                    }
                }
            );
            map.current.addLayer({
                id: 'georef_points',
                type: 'circle',
                source: 'georef_points',
                paint: {
                    'circle-radius': 5,
                    'circle-color': 'transparent',
                    'circle-stroke-width':0.6,
                    'circle-stroke-color':'#ea0606'
                },
            });
            map.current.addLayer({
                id: 'georef_sybmol',
                type: 'symbol',
                source: 'georef_points',
                'layout': {
                    'text-field': ['get', 'title'],
                    'text-font': [
                        'Open Sans Semibold',
                        'Arial Unicode MS Bold'
                    ],
                    'text-size':9,
                    'text-anchor': 'bottom'
                }
            });


            map.current.addLayer({
                id: 'graticule1',
                type: 'line',
                source: 'graticule1',
                paint: {
                    'line-color': '#050505',
                    'line-opacity': 0.75,
                    'line-width': 0.3
                }
            });

            map.current.addLayer({
                id: 'graticule2',
                type: 'line',
                source: 'graticule2',
                paint: {
                    'line-color': '#1f1f1f',
                    'line-opacity': 0.5,
                    'line-width': 0.1
                }
            });
            let before = null;
            for (let i = result.length-1 ; i > 0;i--) {
                if (result[i].name !== "Info") {
                    before = result[i].id;
                }
            }
            for (let i = 0 ; i < result.length;i++) {
                if (result[i].name === "Info") {
                    let length = result[i].layers.length;
                    if (length !== undefined)
                        for (let j = 0; j < length; j++) {
                            if (result[i].layers[j].type === "line"){
                                map.current.addLayer({
                                        'id': result[i].layers[j].idlayer,
                                        'beforeid':before,
                                        'type': 'line',
                                        'source': 'lines',
                                        'source-layer': result[i].layers[j].idlayer,
                                        'paint': {
                                            'line-width': result[i].layers[j].size,
                                            'line-color': result[i].layers[j].color
                                        },
                                        'layout': {
                                            'visibility': result[i].visibility,
                                        }
                                    }
                                );}
                            else if (result[i].layers[j].type === "area"){
                                map.current.addLayer({
                                        'id': result[i].layers[j].idlayer,
                                        'beforeid':before,
                                        'type': 'fill',
                                        'source': 'lines',
                                        'source-layer': result[i].layers[j].idlayer,
                                        'paint': {
                                            'fill-color': result[i].layers[j].color
                                        },
                                        'layout': {
                                            'visibility': result[i].visibility,
                                        }
                                    }
                                );
                            }
                            else
                                map.current.addLayer({
                                        'id': result[i].layers[j].idlayer,
                                        'beforeid':before,
                                        'type': 'circle',
                                        'source': 'lines',
                                        'source-layer': result[i].layers[j].idlayer,
                                        'paint': {
                                            'circle-radius': result[i].layers[j].size*2,
                                            'circle-color': result[i].layers[j].color
                                        },
                                        'layout': {
                                            'visibility': result[i].visibility,
                                        }
                                    }
                                );
                        }
                }
            }
            var popup = new mapboxgl.Popup({
                closeOnClick: false
            });
            let layer = [];
            let layerArray = [];
            let layerID;
            for (let i = 0 ; i<result.length; i++){
                if (result[i].name !== "Info" || result[i].name === "Basins" || result[i].name === "ProtectedAreas" || result[i].name === "SubBasins" || result[i].name === "Fields" || result[i].name === "Regions" || result[i].name === "GeologicMap" || result[i].name === "Licenses" || result[i].name === "TectonicMap" || result[i].name === "co2_emissions" || result[i].name === "infra_obj" || result[i].name === "co2_world_sources" ) {
                    layer.push(result[i].id);
                    layerArray.push(result[i].id);
                }
                map.current.on('mousemove', result[i].id.toString(), function(e) {
                    if (result[i].popup && !drawState){
                        map.current.getCanvas().style.cursor = 'pointer';
                        if ( e.features.length > 0){
                            var coordinates = e.lngLat;
                            var description = e.features[0].properties.OriginalName;
                            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                            }
                            popup.setLngLat(e.lngLat).setHTML(description).addTo(map.current);
                        }
                    }} );
                map.current.on('mouseleave', result[i].id.toString(), function(e) {
                    map.current.getCanvas().style.cursor = '';
                    popup.remove();
                });


                if(result[i].name ==="GeologicObjects"){
                    layerID = result[i].id.toString()
                }
            }
            var f = function(e) {
                if (e.originalEvent.ctrlKey) {
                    console.log(e.features[0].geometry);
                    //that.handlerHoverClick(e.features[0].id,e.features[0].source,e.features[0].sourceLayer,e.features[0].geometry)
                }
            };
            for (let i =0;i<layer.length;i++){
                map.current.on('click', layer[i], f);
                console.log("touch")
                if (isMobile) {
                    console.log("mobile")
                    map.current.on('touchstart', layer[i], function (e) {
                        console.log("touch start")
                        let startx = e.lngLat.lat;
                        let starty = e.lngLat.lng;
                        map.current.on('touchend', layer[i], function (e) {
                            console.log("touch end")
                            let endx = e.lngLat.lat;
                            let endy = e.lngLat.lng;
                            if (startx === endx && starty === endy) {
                                console.log(map.current.getZoom())
                                var bbox = [
                                    [e.point.x - map.current.getZoom()+15, e.point.y - map.current.getZoom()+15],
                                    [e.point.x + map.current.getZoom()+15, e.point.y + map.current.getZoom()+15]
                                ];
                                let layer = [];
                                layerArray = []
                                var features;
                                for (let i = 0; i < result.length; i++) {
                                    if (result[i].name === "Basins" || result[i].name === "GeologicObjects"   || result[i].name === "ProtectedAreas" || result[i].name === "SubBasins" || result[i].name === "Fields" || result[i].name === "Regions" || result[i].name === "GeologicMap" || result[i].name === "Licenses" || result[i].name === "Seismics" || result[i].name === "TectonicMap" || result[i].name === "Wells" || result[i].name === "MSU_Branches"  || result[i].name === "co2_emissions" || result[i].name === "infra_obj" || result[i].name === "co2_world_sources") {
                                        if (result[i].visibility === 'visible') {
                                            layer.push(result[i].id);
                                            layerArray.push(result[i].id);

                                        }
                                    }
                                }
                                console.log(layer,layerArray)
                                features = map.current.queryRenderedFeatures(bbox, {layers: layer});
                                features = getUniqueFeatures(features, 'qc_id');
                                console.log(layer,layerArray,bbox,features)
                                let array = [];
                                for (let i = 0; i < features.length; i++) {
                                    let string = features[i].properties.OriginalName + " " + features[i].sourceLayer.substring(7, features[i].sourceLayer.length).replace('test', '') + " ";
                                    array.push({
                                        name: string,
                                        id: features[i].qc_id,
                                        source: features[i].source,
                                        sourceLayer: features[i].sourceLayer.substring(7, features[i].sourceLayer.length).replace('test', ''),
                                        coordinates: features[i].geometry.coordinates.slice(),
                                        feature: features[i]
                                    });
                                }
                                console.log(drawState)
                                if (array.length === 1 && !interaction.current ) {
                                    console.log(e);
                                    let proxy = {};
                                    proxy.features = [array[0].feature];
                                    proxy.point = {x: e.point.x, y: e.point.y};
                                    onClickProxy(proxy,onClick);
                                }
                                if (array.length > 1 && !interaction.current) {
                                    setFeaturesDisplay("block")
                                    setLeft(e.point.x)
                                    setTop(e.point.y)
                                    console.log(array)
                                    setFeaturesList(array)
                                    /*that.setState({
                                        featuresList: array,
                                        featuresDisplay: "block",
                                        x: e.point.x,
                                        y: e.point.y
                                    }); */
                                }
                            }
                        });
                    });
                }
            }
            let start ;
            map.current.on('click', function(e) {
                console.log(e.originalEvent)
                if (e.originalEvent.shiftKey) {
                    start = e.lngLat.wrap();
                }

                var bbox = [
                    [e.point.x - 1, e.point.y - 1],
                    [e.point.x + 1, e.point.y + 1]
                ];
                let layer=[];
                layerArray =[];
                let features_to_nearest
                var featurestemp = [];
                var features = [];
                for (let i = 0 ; i<result.length; i++){
                    if ( result[i].name === "Basins" || result[i].name === "GeologicObjects"  ||  result[i].name === "ProtectedAreas" || result[i].name === "SubBasins" || result[i].name === "Fields" || result[i].name === "Regions" || result[i].name === "GeologicMap" || result[i].name === "Licenses" || result[i].name === "Seismics" || result[i].name === "TectonicMap" || result[i].name === "Wells" || result[i].name === "MSU_Branches" || result[i].name === "co2_emissions" || result[i].name === "infra_obj" || result[i].name === "co2_world_sources"){
                        if ( result[i].visibility === 'visible'){
                            layer.push(result[i].id);
                            layerArray.push(result[i].id);
                        }
                    }
                }
                console.log(layer,result)
                if (e.originalEvent.ctrlKey) {
                    if (map.current.getSource("nearestPoints") !== undefined){
                        map.current.removeLayer("nearestPoints")
                        map.current.removeSource("nearestPoints")
                    }

                    var bbox_to_nearest = [
                        [e.point.x - 40, e.point.y - 40],
                        [e.point.x + 40, e.point.y + 40]
                    ];
                    let delta = null ;
                    let nearest_point = [e.lngLat.lng,e.lngLat.lat];
                    console.log([e.lngLat.lng,e.lngLat.lat],e)
                    features_to_nearest = map.current.queryRenderedFeatures(bbox_to_nearest, {layers: layer});
                    features_to_nearest = getUniqueFeatures(features_to_nearest, 'qc_id');
                    console.log(features_to_nearest)
                    for ( let i = 0 ; i < features_to_nearest.length ; i++) {
                        console.log(features_to_nearest[i].geometry)
                        for ( let j = 0 ; j < features_to_nearest[i].geometry.coordinates[0].length;j++){
                            let point = features_to_nearest[i].geometry.coordinates[0][j]
                            var from = turf.point([e.lngLat.lng,e.lngLat.lat]);
                            var to = turf.point(point);

                            var distance = turf.distance(from, to);
                            console.log(distance,delta,point)
                            if (delta === null){
                                delta = distance;
                            }
                            else if ( distance < delta){
                                delta = distance
                                nearest_point = point
                            }

                        }

                    }
                    console.log(delta,nearest_point)
                    map.current.addSource('nearestPoints', {
                        'type': 'geojson',
                        'data': {
                            'type': 'Feature',
                            'geometry': {
                                'type': 'Point',
                                'coordinates': nearest_point
                            },
                            'properties': {
                                'title': ''
                            }
                        }
                    });
                    map.current.addLayer({
                        'id': 'nearestPoints',
                        'type': 'circle',
                        'source': 'nearestPoints',
                        'layout': {},
                        'paint': {
                            'circle-radius': 5,
                            'circle-color': 'transparent',
                            'circle-stroke-width':0.6,
                            'circle-stroke-color':'#ea0606'
                        }
                    });
                }
                console.log(layer)
                features = map.current.queryRenderedFeatures(bbox, {layers: layer});
                features = getUniqueFeatures(features, 'qc_id');
                console.log(features)
                let array =[];
                for ( let i = 0;i <features.length;i++){
                    let string = features[i].properties.OriginalName;
                    array.push({
                        name: string,
                        id: features[i].id,
                        source: features[i].source,
                        sourceLayer: features[i].sourceLayer.substring(7,features[i].sourceLayer.length).replace('test',''),
                        coordinates: features[i].geometry.coordinates.slice(),
                        feature:features[i]
                    });
                    array.sort((a, b) => {
                        let optional1 = 1;
                        let optional2 = 2;
                        if ( a.feature.source.includes("License"))
                            optional1 = a.feature.properties["Статус лицензии"]
                        if ( b.feature.source.includes("License"))
                            optional2 = b.feature.properties["Статус лицензии"]
                        if (optional1 === "Действующая")
                            return -1
                        else if (optional2 === "Действующая")
                            return 1
                        return 0
                    })
                }
                setDrawState((drawState=false) => {
                    console.log(drawState,array)
                    if (array.length ===1 && !interaction.current  ){
                        console.log(e);
                        let proxy = {};
                        proxy.features = [array[0].feature];
                        proxy.point = {x:e.point.x,y:e.point.y};
                        if (!e.originalEvent.ctrlKey)
                            onClickProxy(proxy,onClick);
                    }
                    if (array.length >1 &&  !interaction.current) {
                        setFeaturesDisplay("block")
                        setLeft(e.point.x)
                        setTop(e.point.y)
                        console.log(array)
                        setFeaturesList(array)
                        //that.setState({featuresList:array,featuresDisplay:"block",x:e.point.x,y:e.point.y});
                    }
                })
            });
        });

    }
    function onLassoListClick(event,data)  {
        let proxy = {};
        proxy.features = [data.content[5]];
        proxy.point = {x:100,y:100};
        proxy.features[0].properties= {OriginalName:data.content[0]};
        onClickProxy(proxy,props.onClick);
    }

    function onLassoComplete(polygon) {
        console.log("Лассо создано",polygon)
        console.log(polygon[0])
        let closedPolygon = [...polygon,...[polygon[0]]]
        console.log(closedPolygon)
        const { stringify } = require('wkt');
        let wktlasso = stringify({
            'type': 'Polygon',
            'coordinates': [closedPolygon]
        })
        console.log(wktlasso)
        let visiblelayers = [];
        for(let i = 0 ; i < props.result.length;i++){
            if (props.result[i].visibility === 'visible' && props.result[i].name !== "Info" && props.result[i].type !== "geojson" && props.result[i].type !== "image")
                visiblelayers.push({source:props.result[i].source.substring(7,props.result[i].sourcelayer.length),id:props.result[i].id})
        }
        let query = '';
        if (visiblelayers.length > 0){
            if (visiblelayers[0].source === "wells" || visiblelayers[0].source === "geo_objects"){
                query = "select original_name as OriginalName,"+visiblelayers[0].source+"_id as qc_id,'"+visiblelayers[0].source+"' as sourcetable,'"+visiblelayers[0].id+"' as sourceid, ST_AsGeoJSON(ST_Buffer(wkb_geometry ,0.01, 'quad_segs=8')) as geom ,view_state \n from "+visiblelayers[0].source+" as s where st_intersects(s.wkb_geometry, ST_SetSRID(st_makevalid((ST_GeomFromText('"+wktlasso+"'))),4326) ) \n";

            }
            else
                query = "select original_name as OriginalName,"+visiblelayers[0].source+"_id as qc_id,'"+visiblelayers[0].source+"' as sourcetable,'"+visiblelayers[0].id+"' as sourceid, ST_AsGeoJSON(wkb_geometry) as geom ,view_state \n from "+visiblelayers[0].source+" as s where st_intersects(s.wkb_geometry, ST_SetSRID(st_makevalid((ST_GeomFromText('"+wktlasso+"'))),4326) ) \n";
            for (let i = 1 ; i < visiblelayers.length ; i++){
                console.log(visiblelayers[i].source)
                if (visiblelayers[i].source === "wells" || visiblelayers[i].source === "geo_objects"){
                    query = query + "union\n" +
                        "select original_name as OriginalName,"+visiblelayers[i].source+"_id as qc_id,'"+visiblelayers[i].source+"' as sourcetable,'"+visiblelayers[i].id+"' as sourceid, ST_AsGeoJSON(ST_Buffer(wkb_geometry ,0.01, 'quad_segs=8')) as geom ,view_state \n from "+visiblelayers[i].source+" as s where st_intersects(s.wkb_geometry, ST_SetSRID(st_makevalid((ST_GeomFromText('"+wktlasso+"'))),4326) ) \n"
                }
                else
                    query = query + "union\n" +
                        "select original_name as OriginalName,"+visiblelayers[i].source+"_id as qc_id,'"+visiblelayers[i].source+"' as sourcetable,'"+visiblelayers[i].id+"' as sourceid, ST_AsGeoJSON(wkb_geometry) as geom ,view_state \n from "+visiblelayers[i].source+" as s where st_intersects(s.wkb_geometry, ST_SetSRID(st_makevalid((ST_GeomFromText('"+wktlasso+"'))),4326) ) \n"
            }
            query = 'select * from (\n'+ query+') as u \n where (view_state is NULL OR view_state = \'USER\') AND (st_area(st_intersection(ST_GeomFromGeoJSON(geom), ST_SetSRID(st_makevalid((ST_GeomFromText(\''+wktlasso+'\'))),4326) ))/st_area(ST_GeomFromGeoJSON(geom) )) > .5';
            console.log(query)
            axios.post(urlBackend+'/layerid', {query:query})
                .then((res) => {
                    console.log("RESPONCE",res);
                    let array = [];
                    if (res.data.length >0) {
                        for ( let i = 0;i <res.data.length;i++){
                            res.data[i].sourceLayer = 'public.'+res.data[i].sourcetable;
                            res.data[i].coordinates = JSON.parse(res.data[i].geom).coordinates;
                            res.data[i].id = res.data[i].qc_id;
                            res.data[i].source =res.data[i].sourceid;
                            res.data[i].geometry = JSON.parse(res.data[i].geom);
                            res.data[i].type = res.data[i].geometry.type;
                            let string = res.data[i].originalname+" "+res.data[i].sourceLayer+"";
                            array.push({
                                name: string,
                                id: res.data[i].qc_id,
                                source: res.data[i].sourceid,
                                sourceLayer: res.data[i].sourceLayer,
                                coordinates: res.data[i].coordinates,
                                feature:res.data[i]
                            });
                        }
                        setLassoList(array)
                        setLassoTableDisplay("block")
                        //that.setState({lassoList:array,lassoDisplay:"block",x:e.point.x,y:e.point.y});
                    }
                }).catch((error) => {
                console.log(error,query)
            });
        }
    }
    function areaMeasuring(map,AreaActive,DrawState){
        console.log("area",AreaActive,DrawState);

        var area = [];
        var symbol = "";
        var symbolCoordinates = [0,0];
        let firstiteration = true;
        let areaKeyDown = false;
        let prevAreaKeyDown = true;
        function onClick(e) {
            setAreaActive(AreaActive => {
                if (AreaActive){
                    let areaBuffer=[];
                    if ( prevAreaKeyDown === areaKeyDown){
                        area.push([e.lngLat.lng,e.lngLat.lat])
                        if ( area.length >2) {
                            areaBuffer = area.slice();
                            areaBuffer.push(areaBuffer[0]);
                            const { stringify } = require('wkt');
                            let wktarea = {
                                'type': 'Polygon',
                                'coordinates': [areaBuffer]
                            };
                            areaBuffer = [];
                            let coordinates = wktarea.coordinates.slice();
                            var polygon2 = turf.polygon(coordinates);
                            //console.log(polygon2)
                            if (wktarea.type === "Polygon"){
                                //console.log(e.features[0].geometry.coordinates,e)
                                for (let i = 0 ; i < coordinates[0].length;i++){
                                    coordinates[0][i] = coordinates[0][i][0] + " " + coordinates[0][i][1];
                                }
                                let coordinates2 = coordinates;
                                let res3 = [];
                                for (let i = 0;i < coordinates2[0].length;i++) {
                                    let res = coordinates2[0][i].split(" ");
                                    res3.push([parseFloat(res[0]),parseFloat(res[1])]);
                                }
                                //console.log(res3)
                                var polygon = turf.polygon([res3]);
                                var areaPolygon = turf.area(polygon);
                                var centroid = turf.centroid(polygon);
                                //console.log(centroid);
                                symbol=Math.round((Math.round((areaPolygon + Number.EPSILON) * 100) / 100)/1000000)+" km²";
                                symbolCoordinates =centroid.geometry.coordinates.slice();
                                //console.log(symbol,symbolCoordinates);
                                //message = (Math.round((area + Number.EPSILON) * 100) / 100)+" square meters";
                                //display = true;

                                //this.setState({drawState:true,area:(Math.round((area + Number.EPSILON) * 100) / 100)+" square meters",calcDisplay:"block",redactDisplay:"block"})
                            }
                        }

                    }
                    console.log(e,area,prevAreaKeyDown , areaKeyDown)
                    if ( area.length > 2) {
                        //console.log("area 2+",map.getSource('area'),map.getLayer('area'))
                        map.current.getSource('area').setData({
                            'type': 'Feature',
                            'geometry': {
                                'type': 'Polygon',
                                'coordinates': [area]
                            }
                        });
                        map.current.getSource('areaSymbol').setData({
                            'type': 'Feature',
                            'geometry': {
                                'type': 'Point',
                                'coordinates': symbolCoordinates
                            },
                            'properties': {
                                'title': symbol
                            }
                        });
                        map.current.setLayoutProperty(
                            'area',
                            'visibility',
                            'visible'
                        );
                        map.current.setLayoutProperty(
                            'areaSymbol',
                            'visibility',
                            'visible'
                        );
                        map.current.setLayoutProperty(
                            'areapoint',
                            'visibility',
                            'none'
                        );
                        map.current.setLayoutProperty(
                            'arealine',
                            'visibility',
                            'none'
                        );
                        map.current.moveLayer('area', null)
                        map.current.moveLayer('areaSymbol', null)
                    }
                    else if  ( area.length === 2){
                        console.log(area)
                        symbol = "";
                        symbolCoordinates = [0,0];
                        map.current.getSource('area').setData({
                            'type': 'Feature',
                            'geometry': {
                                'type': 'LineString',
                                'coordinates': area
                            }
                        });
                        map.current.setLayoutProperty(
                            'arealine',
                            'visibility',
                            'visible'
                        );
                        map.current.setLayoutProperty(
                            'areapoint',
                            'visibility',
                            'none'
                        );
                        map.current.setLayoutProperty(
                            'areaSymbol',
                            'visibility',
                            'none'
                        );
                        map.current.moveLayer('arealine', null)
                    }
                    else if  ( area.length === 1){
                        symbol = "";
                        symbolCoordinates = [0,0];
                        console.log(area)
                        map.current.getSource('area').setData({
                            'type': 'Feature',
                            'geometry': {
                                'type': 'Point',
                                'coordinates': area[0]
                            }
                        });
                        map.current.setLayoutProperty(
                            'areapoint',
                            'visibility',
                            'visible'
                        );
                        map.current.setLayoutProperty(
                            'areaSymbol',
                            'visibility',
                            'none'
                        );
                        map.current.moveLayer('areapoint', null)
                    }
                }
            })
            console.log(AreaActive)

        }
        function click(e) {
            console.log("click",e.originalEvent.altKey,AreaActive)
            if (!firstiteration )
                prevAreaKeyDown = areaKeyDown;
            else
                firstiteration=false;
            console.log("click",e.lngLat.lng,e.lngLat.lat);
            areaKeyDown = true;
            if (AreaActive)
                onClick(e);

        }
        console.log(AreaActive)
        if (AreaActive){
            var canvas = map.current.getCanvasContainer();
            canvas.addEventListener('keydown', function(e) {
                if (e.key === "Escape") {
                    //this.radiusEmissionsDelete()
                    props.emission_remove();
                    map.current.setLayoutProperty('search_point', 'visibility', 'none');
                    area = [];
                }
            });
            map.current.on('click',click);
            map.current.on('touchstart',click);
        }
        else{
            console.log("off click")
            map.current.off('click', click);
            map.current.off('touchstart',click);
            map.current.setLayoutProperty(
                'area',
                'visibility',
                'none'
            );
            map.current.setLayoutProperty(
                'areaSymbol',
                'visibility',
                'none'
            );
            map.current.getSource('areaSymbol').setData({
                'type': 'Feature',
                'geometry': {
                    'type': 'Point',
                    'coordinates': []
                },
                'properties': {
                    'title': ''
                }
            });
            area = [];
            symbol = "";
            symbolCoordinates = [0,0];
        }

    };
    function LayerOrder() {
        console.log("function")
        if (map.current){
            console.log("function activate")
            if(typeof map.current.getLayer('power_line') !== 'undefined') {map.current.moveLayer('power_line',null)}
            if(typeof map.current.getLayer('power_tower') !== 'undefined') {map.current.moveLayer('power_tower', null)}
            if(typeof map.current.getLayer('power_substation') !== 'undefined') {map.current.moveLayer('power_substation', null)}
            if(typeof map.current.getLayer('power_substation_point') !== 'undefined') {map.current.moveLayer('power_substation_point',null)}
            if(typeof map.current.getLayer('power_plant') !== 'undefined') {map.current.moveLayer('power_plant', null)}
            if(typeof map.current.getLayer('power_plant_point') !== 'undefined') {map.current.moveLayer('power_plant_point',null)}
            if(typeof map.current.getLayer('power_generator') !== 'undefined') {map.current.moveLayer('power_generator',null)}
            if(typeof map.current.getLayer('power_generator_area') !== 'undefined') {map.current.moveLayer('power_generator_area',null)}
            if(typeof map.current.getLayer('power_heatmap_solar') !== 'undefined') {map.current.moveLayer('power_heatmap_solar', null)}
            if(typeof map.current.getLayer('power_transformer') !== 'undefined') {map.current.moveLayer('power_transformer', null)}
            if(typeof map.current.getLayer('power_compensator') !== 'undefined') {map.current.moveLayer('power_compensator', null)}
            if(typeof map.current.getLayer('power_switch') !== 'undefined') {map.current.moveLayer('power_switch', null)}
            if(typeof map.current.getLayer('telecoms_communication_line') !== 'undefined') {map.current.moveLayer('telecoms_communication_line',null)}
            if(typeof map.current.getLayer('telecoms_data_center') !== 'undefined') {map.current.moveLayer('telecoms_data_center', null)}
            if(typeof map.current.getLayer('telecoms_mast') !== 'undefined') {map.current.moveLayer('telecoms_mast', null)}
            if(typeof map.current.getLayer('petroleum_pipeline') !== 'undefined') {map.current.moveLayer('petroleum_pipeline', null)}
            if(typeof map.current.getLayer('petroleum_well') !== 'undefined') {map.current.moveLayer('petroleum_well', null)}
            if(typeof map.current.getLayer('petroleum_site') !== 'undefined') {map.current.moveLayer('petroleum_site', null)}
            if(typeof map.current.getLayer('water_pipeline') !== 'undefined') {map.current.moveLayer('water_pipeline',null)}
            if(typeof map.current.getLayer('gl-draw-polygon-fill-inactive.cold') !== 'undefined') {map.current.moveLayer('gl-draw-polygon-fill-inactive.cold',null)}
            if(typeof map.current.getLayer('gl-draw-polygon-fill-active.cold') !== 'undefined') {map.current.moveLayer('gl-draw-polygon-fill-active.cold', null)}
            if(typeof map.current.getLayer('gl-draw-polygon-midpoint.cold') !== 'undefined') {map.current.moveLayer('gl-draw-polygon-midpoint.cold', null)}
            if(typeof map.current.getLayer('gl-draw-polygon-stroke-inactive.cold') !== 'undefined') {map.current.moveLayer('gl-draw-polygon-stroke-inactive.cold',null)}
            if(typeof map.current.getLayer('gl-draw-polygon-stroke-active.cold') !== 'undefined') {map.current.moveLayer('gl-draw-polygon-stroke-active.cold',null)}
            if(typeof map.current.getLayer('gl-draw-line-inactive.cold') !== 'undefined') {map.current.moveLayer('gl-draw-line-inactive.cold',null)}
            if(typeof map.current.getLayer('gl-draw-line-active.cold') !== 'undefined') {map.current.moveLayer('gl-draw-line-active.cold', null)}
            if(typeof map.current.getLayer('gl-draw-polygon-and-line-vertex-stroke-inactive.cold') !== 'undefined') {map.current.moveLayer('gl-draw-polygon-and-line-vertex-stroke-inactive.cold', null)}
            if(typeof map.current.getLayer('gl-draw-polygon-and-line-vertex-inactive.cold') !== 'undefined') {map.current.moveLayer('gl-draw-polygon-and-line-vertex-inactive.cold', null)}
            if(typeof map.current.getLayer('gl-draw-point-point-stroke-inactive.cold') !== 'undefined') {map.current.moveLayer('gl-draw-point-point-stroke-inactive.cold', null)}
            if(typeof map.current.getLayer('gl-draw-point-inactive.cold') !== 'undefined') {map.current.moveLayer('gl-draw-point-inactive.cold', null)}
            if(typeof map.current.getLayer('gl-draw-point-stroke-active.cold') !== 'undefined') {map.current.moveLayer('gl-draw-point-stroke-active.cold', null)}
            if(typeof map.current.getLayer('gl-draw-point-active.cold') !== 'undefined') {map.current.moveLayer('gl-draw-point-active.cold', null)}
            if(typeof map.current.getLayer('gl-draw-polygon-fill-static.cold') !== 'undefined') {map.current.moveLayer('gl-draw-polygon-fill-static.cold',null)}
            if(typeof map.current.getLayer('gl-draw-polygon-stroke-static.cold') !== 'undefined') {map.current.moveLayer('gl-draw-polygon-stroke-static.cold', null)}
            if(typeof map.current.getLayer('gl-draw-line-static.cold') !== 'undefined') {map.current.moveLayer('gl-draw-line-static.cold', null)}
            if(typeof map.current.getLayer('gl-draw-point-static.cold') !== 'undefined') {map.current.moveLayer('gl-draw-point-static.cold', null)}
            if(typeof map.current.getLayer('gl-draw-polygon-fill-inactive.hot') !== 'undefined') {map.current.moveLayer('gl-draw-polygon-fill-inactive.hot',null)}
            if(typeof map.current.getLayer('gl-draw-polygon-fill-active.hot') !== 'undefined') {map.current.moveLayer('gl-draw-polygon-fill-active.hot', null)}
            if(typeof map.current.getLayer('gl-draw-polygon-midpoint.hot') !== 'undefined') {map.current.moveLayer('gl-draw-polygon-midpoint.hot', null)}
            if(typeof map.current.getLayer('gl-draw-polygon-stroke-inactive.hot') !== 'undefined') {map.current.moveLayer('gl-draw-polygon-stroke-inactive.hot',null)}
            if(typeof map.current.getLayer('gl-draw-polygon-stroke-active.hot') !== 'undefined') {map.current.moveLayer('gl-draw-polygon-stroke-active.hot',  null)}
            if(typeof map.current.getLayer('gl-draw-line-inactive.hot') !== 'undefined') {map.current.moveLayer('gl-draw-line-inactive.hot', null)}
            if(typeof map.current.getLayer('gl-draw-line-active.hot') !== 'undefined') {map.current.moveLayer('gl-draw-line-active.hot', null)}
            if(typeof map.current.getLayer('gl-draw-polygon-and-line-vertex-stroke-inactive.hot') !== 'undefined') {map.current.moveLayer('gl-draw-polygon-and-line-vertex-stroke-inactive.hot',null)}
            if(typeof map.current.getLayer('gl-draw-polygon-and-line-vertex-inactive.hot') !== 'undefined') {map.current.moveLayer('gl-draw-polygon-and-line-vertex-inactive.hot', null)}
            if(typeof map.current.getLayer('gl-draw-point-point-stroke-inactive.hot') !== 'undefined') {map.current.moveLayer('gl-draw-point-point-stroke-inactive.hot', null)}
            if(typeof map.current.getLayer('gl-draw-point-inactive.hot') !== 'undefined') {map.current.moveLayer('gl-draw-point-inactive.hot', null)}
            if(typeof map.current.getLayer('gl-draw-point-stroke-active.ho') !== 'undefined') {map.current.moveLayer('gl-draw-point-stroke-active.hot', null)}
            if(typeof map.current.getLayer('gl-draw-point-active.hot') !== 'undefined') {map.current.moveLayer('gl-draw-point-active.hot', null)}
            if(typeof map.current.getLayer('gl-draw-polygon-fill-static.hot') !== 'undefined') {map.current.moveLayer('gl-draw-polygon-fill-static.hot',null)}
            if(typeof map.current.getLayer('gl-draw-polygon-stroke-static.hot') !== 'undefined') {map.current.moveLayer('gl-draw-polygon-stroke-static.hot',null)}
            if(typeof map.current.getLayer('gl-draw-line-static.hot') !== 'undefined') {map.current.moveLayer('gl-draw-line-static.hot', null)}
            if(typeof map.current.getLayer('gl-draw-point-static.hot') !== 'undefined') {map.current.moveLayer('gl-draw-point-static.hot',null)}
        }
    }
    const handlePointsUpdate = (updatedPoints) => {
        setPoints(updatedPoints);
        setGeorefState(false)
        setDrawState(false)
        props.getGeoRefPoints(updatedPoints)
        // Automatically reset clear to false after points are updated
        clearGeoref()
    };
    const handlePointsDelete = (index) => {
        console.log(points)
        props.getGeoRefPoints(points)
        // Automatically reset clear to false after points are updated
        clearGeoref()
    };
    function clearGeoref(){
        if (clear) {
            setClear(false);
            setGeoRefBbox([])
            setGeoRefOpacity(1)
            setGeoRefUrl("")
        }
    }
    function onLassoListFilter() {
        //filter
        let arrayID =[]
        if (lassoFilter.length > 0 ){
            console.log('lasso filter reset')
            setLassoFilter([])
        }
        else{
            for (let i =0 ; i <lassoList.length;i++){
                arrayID.push(lassoList[i].id)
            }
            console.log(arrayID)
            setLassoFilter(arrayID)

        }
    }
    function addLine() {
        console.log("line add function")
        console.log(lineActive)
        setLineActiveTrigger(1)
        setLineActive(true)
    }

    function onLassoListClose() {
        setLassoFilter([])
        setLassoList([])
        setLassoDisplay(false)
        setLassoTableDisplay("none")
    }
    function draw_graticule({deg,opacity,width,color},index) {
        let one_minute = deg
        const graticule = {
            type: 'FeatureCollection',
            features: []
        };
        if (deg !== undefined && deg!==null && deg !==0){
            for (let lng = -180; lng <= 180; lng += one_minute) {
                graticule.features.push({
                    type: 'Feature',
                    geometry: {type: 'LineString', coordinates: [[lng, -90], [lng, 90]]},
                    properties: {value: lng}
                });
            }
            for (let lat = -80; lat <= 80; lat +=one_minute) {
                graticule.features.push({
                    type: 'Feature',
                    geometry: {type: 'LineString', coordinates: [[-180, lat], [180, lat]]},
                    properties: {value: lat}
                });
            }
            console.log(deg,index,'graticule'+index,map.current.getSource('graticule'+index))
            map.current.getSource('graticule'+index).setData(graticule)
            map.current.setPaintProperty('graticule'+index, 'line-color', color);
            map.current.setPaintProperty('graticule'+index, 'line-opacity', opacity);
            map.current.setPaintProperty('graticule'+index, 'line-width', width);

        }else{
            map.current.getSource('graticule'+index).setData(graticule)
        }

    }

    return (
        <div className="myGeoMap-wrap">
            <div ref={mapContainer} className="myGeoMap" >
                <div className={"latlngnew"} id={"latlngnew"} ></div>
                <button style={{border: "none",boxShadow:"#767676 0px 0px 4px",zIndex:"1"}} className={"net-icon"}>
                    <Popup
                        trigger={<img onClick={()=>{setCoordinateNetActive(!CoordinateNetActive)}} src={CoordinateNet} />}
                        //content='Active.\n To draw hold Alt button and move cursor.'
                        position='right center'
                        size='mini'
                    >
                        Сoordinate grid
                    </Popup>
                </button>
                <MyGeoMapPointCreate display={pointDisplay} create={addToDraw} close={() => {setPointDisplay("none")}} error={""}/>
                <div className="features-choose" style={{display:featuresDisplay,top:top,zIndex:1,
                    left:left}}>
                    <Button basic size={'mini'} onClick={()=>{setFeaturesDisplay("none")}} icon><i className="close icon"/></Button>
                    <p>Choose features from list</p>
                    <List animated={true} selection={true} celled={true}  >
                        {featuresList.map(({name,id,source,sourceLayer,coordinates,feature }) => {
                            var optional  = "";
                            if ( feature.source.includes("License"))
                                optional = feature.properties["Статус лицензии"]
                            return(
                                <List.Item content={[name,id,source,sourceLayer,coordinates,feature]} className="child-list-item"
                                           as="a"
                                           onClick={props.onFeatureListClick}
                                           key={id}>
                                    <List.Content floated='left'>
                                        {name + " "}  <i>{sourceLayer + " " +optional}</i>
                                    </List.Content>
                                </List.Item>
                            )})}
                    </List>
                </div>
                {(CoordinateNetActive)&&(
                    <Graticule close={()=>{setCoordinateNetActive(false)}} draw_graticule={draw_graticule}/>
                )}
                {(lassoActive) &&(

                    <button style={{border: "2px solid #000000",boxShadow:"#767676 0px 0px 4px"}} className={"lasso-icon2"}>
                        <Popup
                            trigger={<img onClick={()=>{setLassoActive(false)}} src={Lasso} />}
                            //content='Active.\n To draw hold Alt button and move cursor.'
                            position='right center'
                            size='mini'
                        >
                            Active.<br/> To draw hold Alt button and move cursor.
                        </Popup>
                    </button>


                )}
                {(!lassoActive) &&(
                    <button style={{border: "none",boxShadow:"#767676 0px 0px 4px"}} className={"lasso-icon2"}>
                        <Popup
                            trigger={<img onClick={()=>{setLassoActive(true)}} src={Lasso} />}
                            //content='Inactive.\n To draw hold Alt button and move cursor.'
                            position='right center'
                            size='mini'
                        >
                            Inactive.<br/> To draw hold Alt button and move cursor.
                        </Popup>
                    </button>


                )}
                {(AreaActive) &&(
                    <>

                        <button style={{border: "2px solid #000000",boxShadow:"#767676 0px 0px 4px"}} className={"area-icon"}>
                            <Popup
                                trigger={<img onClick={()=>{setAreaActive(false);setDrawState(false);
                                        //areaMeasuring(map,AreaActive,DrawState)
                                }} src={Area} />}
                                //content='Active.\n To draw hold Alt button and move cursor.'
                                position='right center'
                                size='mini'
                            >
                                Active.<br/> To draw click on map.
                            </Popup>
                        </button>
                    </>
                )}
                {(!AreaActive) &&(
                    <>
                        <button style={{border: "none",boxShadow:"#767676 0px 0px 4px"}} className={"area-icon"}>
                            <Popup
                                trigger={<img onClick={()=>{setAreaActive(true);setDrawState(true);
                                    //areaMeasuring(map,AreaActive,DrawState)

                                }} src={Area} />}
                                //content='Inactive.\n To draw hold Alt button and move cursor.'
                                position='right center'
                                size='mini'
                            >
                                Inactive.<br/> To draw click on map.
                            </Popup>
                        </button>
                    </>

                )}
                <MyGeoMapAreaMeansurement area_active={AreaActive} map={map.current} />
                <MyGeoMapLasso map={map.current} onLassoComplete={onLassoComplete} lasso_state={lassoActive} />
                {console.log(lassoTableDisplay,lassoDisplay,lassoList)}
                {(lassoTableDisplay === "block")&&(<div className="lasso" style={{display:lassoTableDisplay}}>
                    <Button basic size={'mini'} onClick={onLassoListClose} icon><i className="close icon"/></Button>
                    <Label basic size={"small"}>{"Features selected from lasso tool."}</Label>
                    <Button basic size={'mini'} onClick={onLassoListFilter} icon>{"Filter Layers"}</Button>
                    <div style={{}}>
                        <List animated={true} selection={true} celled={true}  >
                            {lassoList.map(({name,id,source,sourceLayer,coordinates,feature }) => {
                                //console.log(this.state.featuresList)
                                return(
                                    <List.Item content={[name,id,source,sourceLayer,coordinates,feature]} className="child-list-item"
                                               as="a"
                                               onClick={onLassoListClick}
                                               key={id}
                                               onMouseLeave={()=>{
                                                   //clearTimeout(timer);
                                               }}
                                               onMouseEnter={() => {
                                                   /*timer = setTimeout(() => {
                                                       // return the timeoutID
                                                       const hoveredID = id;
                                                       const hoveredSourceLayer = source;
                                                       const hoveredSourceID = sourceLayer+"test";
                                                       if (hoveredID !== this.state.hoveredID) {
                                                           this.setState({ hoveredID,hoveredSourceLayer,hoveredSourceID });
                                                       }

                                                   }, 500);

                                                    */

                                               }}
                                    >
                                        <List.Content floated='left'>
                                            {name}
                                        </List.Content>
                                    </List.Item>
                                )})}
                        </List>
                    </div>
                </div>)}

                {(mapMenuDisplay==="block")&&(
                    <div style={{top:"calc("+ mapMenuPosition.y+"px )", left: mapMenuPosition.x,zIndex:"1"
                    }} className="geocodingContextMenu" onMouseLeave={()=>{
                        setMapMenuDisplay('none')
                    }}>

                        <div className={"menuButton"}  style={{border: '1px solid black', backgroundColor: 'rgba(255, 255, 255, 0.85)'}} onClick={()=>{
                            axios.get('https://nominatim.openstreetmap.org/reverse?format=json&lat='+mapMenuPoint.lat+'&lon='+mapMenuPoint.lng+'&zoom='+(Number.parseInt(zoom)+2))
                                .then((res)=>{
                                    console.log(res)
                                    let that = this;
                                    setTimeout(function() {
                                        setMapMenuDisplay("none")
                                        setMapMenuSearchDisplay("block")
                                        setMapMenuSearchResult(res.data)
                                    }, 100);

                                }).catch((err)=>{
                                console.log(err)
                            })
                            //https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=-34.44076&lon=-58.70521
                            //mapMenuPoint:{lat:e.lngLat.lat,lng:e.lngLat.lng
                        }}>{i18n.t('Search near point')}</div>
                        <div className={"menuButton"} style={{border: '1px solid black', backgroundColor: 'rgba(255, 255, 255, 0.85)'}} onClick={()=>{
                            console.log(document.getElementById('latlngnew').innerHTML)
                            let string = document.getElementById('latlngnew').innerHTML;
                            let array = string.split(' ')
                            console.log(array,array[1],array[3])
                            let lat = Number.parseFloat(array[1])
                            let lng = Number.parseFloat(array[3])
                            let lat1 = lng
                            let lng1 = lat
                            navigator.clipboard.writeText(lng1+' , '+lat1)
                            setTimeout(function() {
                                setMapMenuDisplay("none")
                            }, 100);
                        }}>{i18n.t('Copy Coordinates ')} {i18n.t(' to clipboard ')}</div>
                        <div className={"menuButton"} style={{border: '1px solid black', backgroundColor: 'rgba(255, 255, 255, 0.85)'}} onClick={()=>{
                            console.log(document.getElementById('latlngnew').innerHTML)
                            let string = document.getElementById('latlngnew').innerHTML;
                            let array = string.split(' ')
                            console.log(array,array[1],array[3])
                            let lat = Number.parseFloat(array[1])
                            let lng = Number.parseFloat(array[3])
                            addToDraw(lng,lat)
                            //this.onPointCreate(lng,lat,false)
                            setTimeout(function() {
                                setMapMenuDisplay("none")
                            }, 100);
                        }}>{i18n.t('Create Point ')}</div>
                    </div>
                )}
                {(mapMenuSearchDisplay==="block")&&(
                    <div style={{top: mapMenuPosition.y, left: mapMenuPosition.x
                    }} className="geocodingContextMenuSearch" onMouseLeave={()=>{setMapMenuSearchDisplay("none")}}
                         onClick={()=>{setMapMenuSearchDisplay("none")}}>
                        <pre>{JSON.stringify(mapMenuSearchResult, null, '\t')}</pre>
                    </div>
                )}
                {result.slice(0).reverse().map(({name , data , color , id , beforeid , visibility , sourcelayer , opacity , type , text, border,hover,paint,transparent,layers }) => {
                    //console.log(name,id,type,visibility)
                        if (type === 'multilayer') {
                        } else if (type === "raster") {
                            return (
                                <MyGeoMapSource
                                    map={map}
                                    id={id.toString()}
                                    visibility={visibility}
                                    type="raster"
                                    tileSize={256}
                                    tiles={[data]}
                                />)
                        } else if (type === 'image') {
                            console.log(name,id,type)
                            return (
                                <MyGeoMapSource
                                    map={map}
                                    visibility={visibility}
                                    id={id.toString()}
                                    type="image"
                                    url={data}
                                    coordinates={border}
                                />
                            )
                        } else if (type === 'geojson') {
                            return (
                                <MyGeoMapSource
                                    map={map}
                                    id={ id.toString()}
                                    type='geojson'
                                    data={data}
                                />
                            )
                        }
                        else if (type === 'wms') {
                            return (
                                <MyGeoMapSource
                                    map={map}
                                    id={id.toString()}
                                    type='raster'
                                    tiles={[data]}
                                />
                            )
                        }else if (name === "Basins") {
                            return (
                                <MyGeoMapSource map={map} id={id.toString()} type="vector" tiles={[data + props.user_id]} maxzoom={30}
                                        minzoom={0} maxTileCacheSize={0} promoteId='qc_id'/>
                            )
                        } else if (type !== 'geojson')
                            //console.log(id.toString())
                        return (
                            <>

                                <MyGeoMapSource map={map} id={id.toString()} type="vector" tiles={[data + props.user_id]} maxzoom={30}
                                        minzoom={0} maxTileCacheSize={0} promoteId='qc_id'/>
                            </>

                        )

                })}
                {data2.slice(0).reverse().map(({name, data,color,id,beforeid,visibility,sourcelayer,opacity, type,layoutname ,enable }) => {
                    if ( type !== "heatmap" && type !== "bubble") {
                        return (
                            <MyGeoMapSource map={map} id={id} type="vector" tiles={[data + props.user_id]}
                                    maxzoom={30} minzoom={0}/>
                        )
                    }
                })}
                <MyGeoMapImage  name={"pattern"} image={patternlines} map={map}/>
                <MyGeoMapImage name={"mgu"} image={mgu} map={map}/>
                <MyGeoMapImage name={"triangle"} image={triangle} map={map}/>
                <MyGeoMapImage name={"rosneft"} image={rosneft} map={map}/>
                <MyGeoMapImage name={"gazprom"} image={gazprom} map={map}/>

                {result.slice(0).reverse().map(({name , data , color , id , beforeid , visibility , sourcelayer , opacity , type , text, border,hover,paint,transparent,layers,colordefault,popup }) => {

                    if (map.current){

                        map.current.on('load', () => {
                            console.log("load",name,id)
                        })
                        if (transparent === true)
                            color = 'transparent';
                        if (type === 'fill') {
                            console.log(name,visibility,type)
                            return (
                                <span>
                                        <MyGeoMapLayer
                                            map={map}
                                            id={id.toString()}
                                            before={beforeid}
                                            type={type}
                                            popup={popup}
                                            visibility={visibility}
                                            map_popup={map_popup}
                                            source={id.toString()}
                                            sourceLayer={sourcelayer.toString()}
                                            paint={{
                                                'fill-color': [
                                                    'case',
                                                    ['boolean', ['feature-state', 'hover'], false],
                                                    "#FFFF14",
                                                    color
                                                ],
                                                //'fill-pattern': 'pattern',
                                                'fill-opacity': opacity,
                                                'fill-outline-color': [
                                                    'case',
                                                    ['boolean', ['feature-state', 'hover'], false],
                                                    "#FF0606",
                                                    border
                                                ]

                                            }}
                                            layout={{
                                                'visibility': visibility,
                                            }}
                                            onHover={props.onHover}
                                            onLeave={props.onLeave}
                                            //onClick={props.onClick}
                                        />
                                    </span>
                            );
                        }
                        else if (type === 'heatmap'){
                            return(
                                <MyGeoMapLayer id={id.toString()}
                                               map={map}
                                       before={beforeid}
                                       type={type}
                                               visibility={visibility}
                                       source={id.toString()}
                                       sourceLayer={sourcelayer.toString()}
                                       paint={paint}
                                       layout={{
                                           'visibility': visibility,
                                       }}
                                       onHover={props.onHover}
                                       onLeave={props.onLeave}
                                    // filter={layers}
                                />
                            )
                        }
                        else if (type === 'bubble'){
                            return(
                                <MyGeoMapLayer id={id.toString()}
                                               map={map}
                                       before={beforeid}
                                       type={'circle'}
                                               visibility={visibility}
                                       source={id.toString()}
                                       sourceLayer={sourcelayer.toString()}
                                       paint={paint}
                                       layout={{
                                           'visibility': visibility,
                                       }}
                                       onHover={props.onHover}
                                       onLeave={props.onLeave}
                                    // filter={layers}
                                />
                            )
                        }
                        else if (type === 'wms'){
                            return(
                                <MyGeoMapLayer id={id.toString()}
                                               map={map}
                                       before={beforeid}
                                               visibility={visibility}
                                       type={'raster'}
                                       source={id.toString()}
                                       paint={{}}
                                       layout={{
                                           'visibility': visibility,
                                       }}
                                       onHover={props.onHover}
                                       onLeave={props.onLeave}
                                    // filter={layers}
                                />
                            )
                        }
                        else if (type === 'line'){
                            if(Cookies.get("id") !== undefined) {
                                return (
                                    <MyGeoMapLayer id={id.toString()}
                                                   map={map}
                                           before={beforeid}
                                           type={type}
                                                   visibility={visibility}
                                                   popup={popup}
                                                   map_popup={map_popup}
                                           source={id.toString()}
                                           sourceLayer={sourcelayer.toString()}
                                           paint={{
                                               'line-width':
                                                   {
                                                       'base': 0.35,
                                                       'stops':
                                                           [
                                                               [0, 0.35],
                                                               [2, 0.7],
                                                               [4, 1],
                                                               [5, 1.35],
                                                               [6, 1.7],
                                                               [8, 2]
                                                           ]
                                                   },
                                               'line-color': [
                                                   'case',
                                                   ['boolean', ['feature-state', 'hover'], false],
                                                   "#FF0606",
                                                   color
                                               ],
                                               'line-opacity': opacity,
                                           }}
                                           layout={{
                                               'visibility': visibility,
                                           }}
                                           onHover={props.onHover}
                                           onLeave={props.onLeave}
                                    />
                                )
                            }
                        }
                        else if (type ==='image'){
                            //console.log(result)
                            return(
                                <MyGeoMapLayer id={id.toString()}
                                               map={map}
                                               visibility={visibility}
                                       before={beforeid}
                                       type="raster"
                                       source={id.toString()}
                                       paint={{'raster-opacity': opacity }}
                                       layout={{'visibility': visibility}}
                                />
                            )
                        }
                        else if (type === 'geojson'){
                            return(
                                <span>
                                    <MyGeoMapLayer id={id.toString()}
                                                   map={map}
                                           before={beforeid}
                                           type="fill"
                                                   visibility={visibility}
                                           layertype={'geojson'}
                                           source={id.toString()}
                                           paint={{
                                               'fill-color': color,
                                               //'fill-pattern': 'pattern',
                                               'fill-outline-color':"#000000",
                                               'fill-opacity': opacity,
                                           }}
                                           layout={{
                                               'visibility': visibility,
                                           }}
                                           filter={['==', '$type', 'Polygon']}
                                           onClick={props.onClickGeoJson}
                                    />
                                    <MyGeoMapLayer id={"00"+id.toString()}
                                                   before={beforeid}
                                                   map={map}
                                                   visibility={visibility}
                                                   type='line'
                                                   layertype={'geojson'}
                                                   source={id.toString()}
                                                   paint={{
                                                       'line-width': [
                                                           'interpolate',
                                                           ['linear'],
                                                           ['zoom'],
                                                           1,
                                                           2,
                                                           30,
                                                           20
                                                       ],
                                                       'line-color': color,
                                                       'line-opacity':opacity,
                                                   }}
                                                   layout={{
                                                       'visibility': visibility,
                                                   }}
                                                   filter={['==', '$type', 'LineString']}
                                        //onClick={props.onClickGeoJson}
                                    />
                                    <MyGeoMapLayer id={"0"+id.toString()}
                                                   map={map}
                                           before={beforeid}
                                                   visibility={visibility}
                                           type="circle"
                                                   layertype={'geojson'}
                                           source={id.toString()}
                                           paint={{
                                               'circle-radius': [
                                                   'interpolate',
                                                   ['linear'],
                                                   ['zoom'],
                                                   1,
                                                   2,
                                                   30,
                                                   20
                                               ],
                                               'circle-color': [
                                                   'case',
                                                   ['boolean', ['feature-state', 'hover'], false],
                                                   "#FFFF14",
                                                   color
                                               ],
                                               'circle-opacity':opacity,
                                               'circle-stroke-width':0.3,
                                               'circle-stroke-color': [
                                                   'case',
                                                   ['boolean', ['feature-state', 'hover'], false],
                                                   "#FF0606",
                                                   color
                                               ],
                                               'circle-stroke-opacity':opacity,
                                           }}
                                           layout={{
                                               'visibility': visibility,
                                           }}
                                           filter={['==', '$type', 'Point']}
                                           onClick={props.onClickGeoJson}
                                    />

                            </span>
                            )
                        }
                        else if (type === 'multilayer') {
                            return(
                            <MyGeoMapLayer map={map} id={id.toString()} visibility={visibility} layers={layers} before={beforeid} type="multilayer" source={id.toString()} layout={{
                                'visibility': visibility,
                            }}/>)
                        }
                        else if(type === "raster"){
                            return(
                                <MyGeoMapLayer map={map} visibility={visibility} id={id.toString()} before={beforeid} type="raster" source={id.toString()} layout={{
                                    'visibility': visibility,
                                }}/>
                            )
                        }
                        else if (type !== 'geojson'){
                            if ( name === "Wells" || name === "Seismics" ){
                                console.log(Cookies.get("id"),sourcelayer.toString(),id.toString())
                                if(Cookies.get("id") !== undefined){
                                    return(
                                        <MyGeoMapLayer
                                            map={map}
                                            id={id.toString()}
                                            visibility={visibility}
                                            before={beforeid}
                                            type={type}
                                            popup={popup}
                                            map_popup={map_popup}
                                            source={id.toString()}
                                            sourceLayer={sourcelayer.toString()}
                                            paint={{
                                                'circle-radius': [
                                                    'interpolate',
                                                    ['linear'],
                                                    ['zoom'],
                                                    1,
                                                    1.5,
                                                    30,
                                                    20
                                                ],
                                                'circle-color': [
                                                    'case',
                                                    ['boolean', ['feature-state', 'hover'], false],
                                                    "#FFFF14",
                                                    color
                                                ],
                                                'circle-opacity':opacity,
                                                'circle-stroke-width':0.3,
                                                'circle-stroke-color': [
                                                    'case',
                                                    ['boolean', ['feature-state', 'hover'], false],
                                                    "#FF0606",
                                                    border
                                                ],
                                                'circle-stroke-opacity':opacity,
                                            }}
                                            layout={{
                                                'visibility': visibility,
                                            }}
                                            //filter={['==', '$type', 'Point']}
                                            onHover={props.onHover}
                                            onLeave={props.onLeave}
                                            onClick={props.onClick}
                                        />
                                    )
                                }
                            }
                            else if(name === "co2_emissions" ) {
                                if (Cookies.get("groups") !== undefined)
                                    if ((Cookies.get("groups").includes('PH_CO2') || Cookies.get("groups").includes("Пользователи МГУ"))){
                                        console.log(Cookies.get("groups"))
                                        console.log( Cookies.get("groups").includes('Анонимные пользователи'))
                                        console.log("emission DiSPLAY" ,co2_emissions_max ,co2_emissions_min)
                                        let interpolate = [
                                            'interpolate',
                                            ["exponential", 0.999999957],
                                            ['get', 'emissions_value'],
                                            Number.parseInt(co2_emissions_min),
                                            ['get', 'zoom_min'],
                                            Number.parseInt(co2_emissions_max),
                                            ['get', 'zoom_max']
                                        ]
                                        console.log(interpolate);
                                        if(Cookies.get("id") !== undefined && Cookies.get("groups").includes('PH_CO2') ) {
                                            return (
                                                <>
                                                    <MyGeoMapLayer
                                                        map={map}
                                                        id={id.toString() + "_icons"}
                                                        before={beforeid}
                                                        type={"symbol"}
                                                        visibility={visibility}
                                                        source={id.toString()}
                                                        sourceLayer={sourcelayer.toString()}
                                                        layout={{
                                                            'icon-image': ['get', 'icon'],
                                                            'icon-size': [
                                                                'interpolate',
                                                                ['linear'],
                                                                ['zoom'],
                                                                1,
                                                                0.28,
                                                                30,
                                                                1
                                                            ],
                                                            'visibility': visibility,
                                                            'icon-allow-overlap': true
                                                        }}
                                                        //filter={['has', 'icon']}
                                                        onHover={props.onHover}
                                                        onLeave={props.onLeave}
                                                        onClick={props.onClick}
                                                    />
                                                    <MyGeoMapLayer
                                                        map={map}
                                                        id={id.toString()}
                                                        before={beforeid}
                                                        type={type}
                                                        popup={popup}
                                                        map_popup={map_popup}
                                                        visibility={visibility}
                                                        source={id.toString()}
                                                        sourceLayer={sourcelayer.toString()}
                                                        paint={{
                                                            'circle-radius': interpolate,
                                                            'circle-color': color,
                                                            'circle-opacity': 0.65,
                                                            'circle-stroke-opacity': 0.5,
                                                        }}
                                                        layout={{
                                                            'visibility': visibility,
                                                        }}
                                                        //filter={['!has', 'icon']}
                                                        onHover={props.onHover}
                                                        onLeave={props.onLeave}
                                                        onClick={props.onClick}
                                                    />
                                                </>
                                            )
                                        }else if(Cookies.get("id") !== undefined && Cookies.get("groups").includes('Пользователи МГУ') ) {
                                            return (
                                                <>
                                                    <MyGeoMapLayer
                                                        map={map}
                                                        id={id.toString()}
                                                        before={beforeid}
                                                        type={type}
                                                        popup={popup}
                                                        map_popup={map_popup}
                                                        visibility={visibility}
                                                        source={id.toString()}
                                                        sourceLayer={sourcelayer.toString()}
                                                        paint={{
                                                            'circle-radius': interpolate,
                                                            'circle-color': color,
                                                            'circle-opacity': 0.65,
                                                            'circle-stroke-opacity': 0.5,
                                                        }}
                                                        layout={{
                                                            'visibility': visibility,
                                                        }}
                                                        //filter={['!has', 'icon']}
                                                        onHover={props.onHover}
                                                        onLeave={props.onLeave}
                                                        onClick={props.onClick}
                                                    />
                                                </>
                                            )
                                        }
                                    }
                            }else if(name === "infra_obj" ) {
                                        let interpolate = [
                                            'interpolate',
                                            ["exponential", 0.999999307],
                                            ['get', 'emissions_value'],
                                            Number.parseInt(infra_emissions_min),
                                            ['get', 'zoom_min'],
                                            Number.parseInt(infra_emissions_max),
                                            ['get', 'zoom_max']
                                        ]
                                        console.log(interpolate);
                                        if(Cookies.get("id") !== undefined && Cookies.get("groups").includes('PH_CO2') ) {
                                            return (
                                                <>
                                                    <MyGeoMapLayer
                                                        map={map}
                                                        id={id.toString() + "_icons"}
                                                        before={beforeid}
                                                        type={"symbol"}
                                                        visibility={visibility}
                                                        source={id.toString()}
                                                        sourceLayer={sourcelayer.toString()}
                                                        layout={{
                                                            'icon-image': ['get', 'icon'],
                                                            'icon-size': [
                                                                'interpolate',
                                                                ['linear'],
                                                                ['zoom'],
                                                                1,
                                                                0.28,
                                                                30,
                                                                1
                                                            ],
                                                            'visibility': visibility,
                                                            'icon-allow-overlap': true
                                                        }}
                                                        //filter={['has', 'icon']}
                                                        onHover={props.onHover}
                                                        onLeave={props.onLeave}
                                                        onClick={props.onClick}
                                                    />
                                                    <MyGeoMapLayer
                                                        map={map}
                                                        id={id.toString()}
                                                        before={beforeid}
                                                        type={type}
                                                        popup={popup}
                                                        map_popup={map_popup}
                                                        visibility={visibility}
                                                        source={id.toString()}
                                                        sourceLayer={sourcelayer.toString()}
                                                        paint={{
                                                            'circle-radius': interpolate,
                                                            'circle-color': color,
                                                            'circle-opacity': 0.65,
                                                            'circle-stroke-opacity': 0.5,
                                                        }}
                                                        layout={{
                                                            'visibility': visibility,
                                                        }}
                                                        //filter={['!has', 'icon']}
                                                        onHover={props.onHover}
                                                        onLeave={props.onLeave}
                                                        onClick={props.onClick}
                                                    />
                                                </>
                                            )
                                        }else {
                                            console.log(Cookies.get("id"))
                                            if(Cookies.get("id") === undefined ){
                                                visibility ="none"
                                            }
                                            return (
                                                <>
                                                    <MyGeoMapLayer
                                                        map={map}
                                                        id={id.toString()}
                                                        before={beforeid}
                                                        type={type}
                                                        popup={popup}
                                                        map_popup={map_popup}
                                                        visibility={visibility}
                                                        source={id.toString()}
                                                        sourceLayer={sourcelayer.toString()}
                                                        paint={{
                                                            'circle-radius': interpolate,
                                                            'circle-color': color,
                                                            'circle-opacity': 0.65,
                                                            'circle-stroke-opacity': 0.5,
                                                        }}
                                                        layout={{
                                                            'visibility': visibility,
                                                        }}
                                                        //filter={['!has', 'icon']}
                                                        onHover={props.onHover}
                                                        onLeave={props.onLeave}
                                                        onClick={props.onClick}
                                                    />
                                                    {/*<MyGeoMapPieLayer
                                                        map={map}
                                                        id={id.toString()}
                                                        before={beforeid}
                                                        type={type}
                                                        popup={popup}
                                                        min={Number.parseInt(infra_emissions_min)}
                                                        max={Number.parseInt(infra_emissions_max)}
                                                        map_popup={map_popup}
                                                        visibility={visibility}
                                                        source={id.toString()}
                                                        sourceLayer={sourcelayer.toString()}
                                                        paint={{
                                                            'circle-radius': interpolate,
                                                            'circle-color': color,
                                                            'circle-opacity': 0.65,
                                                            'circle-stroke-opacity': 0.5,
                                                        }}
                                                        layout={{
                                                            'visibility': visibility,
                                                        }}
                                                        //filter={['!has', 'icon']}
                                                        onHover={props.onHover}
                                                        onLeave={props.onLeave}
                                                        onClick={props.onClick}
                                                    />
                                                    */}
                                                </>
                                            )
                                        }
                            }
                            else if(name === "MSU_Branches") {

                                return(
                                    <>
                                        <MyGeoMapLayer
                                            map={map}
                                            id={id.toString()}
                                            before={beforeid}
                                            type={"symbol"}
                                            visibility={visibility}
                                            source={id.toString()}
                                            sourceLayer={sourcelayer.toString()}
                                            layout={{
                                                'icon-image': 'mgu',
                                                'icon-size': 0.6,
                                                'visibility': visibility,
                                            }}
                                            //filter={['==', '$type', 'Point']}
                                            onHover={props.onHover}
                                            onLeave={props.onLeave}
                                            onClick={props.onClick}
                                        />
                                    </>
                                )
                            }
                            else if(name === "co2_world_sources") {

                                return(
                                    <>
                                        <MyGeoMapLayer
                                            map={map}
                                            id={id.toString()}
                                            before={beforeid}
                                            type={"symbol"}
                                            visibility={visibility}
                                            source={id.toString()}
                                            sourceLayer={sourcelayer.toString()}
                                            paint={{
                                                "text-color": color,
                                                "text-halo-width": 1,
                                                "text-halo-color": "black"
                                            }}
                                            layout={{
                                                'text-field': [
                                                    'format',
                                                    ['upcase', '▲'],
                                                    // △
                                                    { 'font-scale': opacity },
                                                ],
                                                'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                                                'text-size':24,
                                                'visibility': visibility,
                                                'text-ignore-placement':true,
                                                'icon-ignore-placement':true,
                                                'text-allow-overlap':true,
                                                'icon-allow-overlap':true
                                            }}
                                            //filter={['==', '$type', 'Point']}
                                            onHover={props.onHover}
                                            onLeave={props.onLeave}
                                            onClick={props.onClick}
                                        />
                                    </>
                                )
                            }
                            else
                                return(
                                    <span>
                                <MyGeoMapLayer
                                    map={map}
                                    id={id.toString()}
                                    before={beforeid}
                                    type={type}
                                    visibility={visibility}
                                    source={id.toString()}
                                    sourceLayer={sourcelayer.toString()}
                                    paint={{
                                        'circle-radius': [
                                            'interpolate',
                                            ['linear'],
                                            ['zoom'],
                                            1,
                                            2,
                                            30,
                                            20
                                        ],
                                        'circle-color': [
                                            'case',
                                            ['boolean', ['feature-state', 'hover'], false],
                                            "#FFFF14",
                                            color
                                        ],

                                        'circle-opacity':opacity,
                                        'circle-stroke-width':0.3,
                                        'circle-stroke-color': [
                                            'case',
                                            ['boolean', ['feature-state', 'hover'], false],
                                            "#FF0606",
                                            border
                                        ],
                                        'circle-stroke-opacity':opacity,
                                    }}
                                    layout={{
                                        'visibility': visibility,
                                    }}
                                    //filter={['==', '$type', 'Point']}
                                    onHover={props.onHover}
                                    onLeave={props.onLeave}
                                    onClick={props.onClick}
                                />
                            </span>
                                )}
                    }
                })}

                {data2.slice(0).reverse().map(({name, data,color,id,beforeid,visibility,sourcelayer,opacity, type,layoutname ,enable}) => {
                    //console.log(name,id)

                    if (map.current) {
                        //console.log(enable,name,data2)
                            if (visibility === 'visible' && !enable)
                                visibility = 'none'

                            if (type !== "heatmap" && type !== "bubble") {
                                if (name === "WellsText" || name === "SeismicsText") {
                                    if (Cookies.get("id") !== undefined) {
                                        console.log("display text")
                                        return (
                                            <MyGeoMapLayer
                                                map={map}
                                                id={id.toString()}
                                                type='symbol'
                                                visibility={visibility}
                                                enable={enable}
                                                source={id.toString()}
                                                sourceLayer={sourcelayer.toString()}
                                                layout={{
                                                    'visibility': visibility,
                                                    'symbol-sort-key': id,
                                                    'text-field': [
                                                        'format',
                                                        ['upcase', ['get', layoutname]],
                                                        {'font-scale': opacity},
                                                    ],
                                                    'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                                                    'text-offset': [0, -0.3],
                                                    'text-anchor': 'bottom-left',
                                                    'text-justify': 'left'
                                                }}
                                                onHover={props.onHover}
                                                onLeave={props.onLeave}
                                            />
                                        )
                                    }
                                }
                                //console.log(name,id,"enabled")
                                else if (name === "MSU_BranchesText") {
                                    if (Cookies.get("id") !== undefined) {
                                        console.log("display text")
                                        return (
                                            <MyGeoMapLayer
                                                map={map}
                                                id={id.toString()}
                                                type='symbol'
                                                visibility={visibility}
                                                enable={enable}
                                                source={id.toString()}
                                                sourceLayer={sourcelayer.toString()}
                                                layout={{
                                                    'visibility': visibility,
                                                    'symbol-sort-key': id,
                                                    'text-field': [
                                                        'format',
                                                        ['upcase', ['get', layoutname]],
                                                        {'font-scale': opacity},
                                                    ],
                                                    'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                                                    'text-offset': [0, -1],
                                                    'text-anchor': 'top',
                                                    'text-justify': 'center',
                                                    'text-ignore-placement': true
                                                }}
                                                onHover={props.onHover}
                                                onLeave={props.onLeave}
                                            />
                                        )
                                    }
                                } else if (name === "co2_world_sourcesText") {
                                    if (Cookies.get("id") !== undefined) {
                                        console.log("display text", name, sourcelayer)
                                        return (
                                            <MyGeoMapLayer
                                                map={map}
                                                id={id.toString()}
                                                type='symbol'
                                                visibility={visibility}
                                                enable={enable}
                                                source={id.toString()}
                                                sourceLayer={sourcelayer.toString()}
                                                layout={{
                                                    'visibility': visibility,
                                                    'symbol-sort-key': id,
                                                    'text-field': [
                                                        'format',
                                                        ['upcase', ['get', layoutname]],
                                                        {'font-scale': opacity},
                                                    ],
                                                    'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                                                    'text-offset': [0, -0.62],
                                                    'text-anchor': 'top',

                                                    'text-justify': 'center',

                                                }}
                                                onHover={props.onHover}
                                                onLeave={props.onLeave}
                                            />
                                        )
                                    }
                                } else if (name === "co2_emissionsText") {
                                    console.log("co2_emissionsText")
                                    if (Cookies.get("id") !== undefined && Cookies.get("groups") !== undefined) {
                                        console.log("display text")
                                        if ((Cookies.get("groups").includes('PH_CO2') || Cookies.get("groups").includes("Пользователи МГУ")))
                                            return (
                                                <MyGeoMapLayer
                                                    map={map}
                                                    id={id.toString()}
                                                    type='symbol'
                                                    visibility={visibility}
                                                    enable={enable}
                                                    source={id.toString()}
                                                    sourceLayer={sourcelayer.toString()}
                                                    layout={{
                                                        'visibility': visibility,
                                                        'symbol-sort-key': id,
                                                        'text-field': [
                                                            'format',
                                                            ['upcase', ['get', layoutname]],
                                                            {'font-scale': opacity},
                                                        ],
                                                        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                                                        'text-offset': [0, -1],
                                                        'text-anchor': 'top',
                                                        'text-justify': 'center',
                                                    }}
                                                    onHover={props.onHover}
                                                    onLeave={props.onLeave}
                                                />
                                            )
                                    }
                                } else if (name === "infra_objText") {

                                    if (Cookies.get("id") !== undefined ) {
                                            return (
                                                <MyGeoMapLayer
                                                    map={map}
                                                    id={id.toString()}
                                                    type='symbol'
                                                    visibility={visibility}
                                                    enable={enable}
                                                    source={id.toString()}
                                                    sourceLayer={sourcelayer.toString()}
                                                    layout={{
                                                        'visibility': visibility,
                                                        'symbol-sort-key': id,
                                                        'text-field': [
                                                            'format',
                                                            ['upcase', ['get', layoutname]],
                                                            {'font-scale': opacity},
                                                        ],
                                                        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                                                        'text-offset': [0, -1],
                                                        'text-anchor': 'top',
                                                        'text-justify': 'center',
                                                    }}
                                                    onHover={props.onHover}
                                                    onLeave={props.onLeave}
                                                />
                                            )
                                    }
                                } else {
                                    //console.log(visibility, id, layoutname, sourcelayer, opacity)
                                    return (
                                        <MyGeoMapLayer
                                            map={map}
                                            id={id.toString()}
                                            type='symbol'
                                            visibility={visibility}
                                            enable={enable}
                                            source={id.toString()}
                                            sourceLayer={sourcelayer.toString()}
                                            layout={{
                                                'visibility': visibility,
                                                'symbol-sort-key': id,
                                                'text-field': [
                                                    'format',
                                                    ['upcase', ['get', layoutname]],
                                                    {'font-scale': opacity},
                                                ],
                                                'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                                                'text-offset': [0, 0.4],
                                                'text-anchor': 'center',
                                                'text-justify': 'center'
                                            }}
                                            onHover={props.onHover}
                                            onLeave={props.onLeave}
                                        />
                                    )
                                }
                            }
                        }
                })}
                {(flyCount>0)&&(<MyGeoMapFlyTo map={map} coordinates={flyCoordinates} type={flyType} />)}
                {(linesDisplay)&&(
                    <MyGeoMapLinesTable addLine={addLine} lineLength={linesLength} lines={lines}
                                        close={()=>setLinesDisplay(false)}
                    />
                )}
                {console.log(lineActive)}
                {(map.current && lineActiveTrigger > 0)&&(
                    <MyGeoMapLines active={lineActive} map={map.current}  reset={false} onResetComplete={()=>console.log("function")}
                                   onDeactivate={(length,lines)=>{setLineActive(false);setLinesLength(length);setLines(lines)}}
                    />
                )}
                {(map && georef_url && georef_url !== "")&&(
                    <MyGeoMapGeoReferencePreview
                        map={map} url={georef_url} clear={clear} bbox={georef_bbox} opacity={georef_opacity} onClearComplete={clearGeoref}
                    />
                )}
                <MyGeoMapGeoReference
                    mapRef={map}
                    georef_state={props.georefMode}
                    clear={clear}
                    georef_delete={georef_delete}
                    georef_delete_index={georef_delete_index}
                    onGeoRefDelete={onGeoRefDelete}
                    onPointsDelete={props.deleteGeoRefReset}
                    onPointsUpdate={handlePointsUpdate}
                />
                {map && stateList.map(({id,source,layer}) => (
                    <MyGeoMapFeatureState id={id} layer={layer} source={source} map={map} />
                ))}
                {filter.map((filter)=>{
                    return(
                        <MyGeoMapFilter map={map} Filters={filter} lassoFilter={lassoFilter} result={result} data2={data2} handleAlert={()=>{console.log("alert")}} />
                    )
                })}
                {console.log(map , result , data2)}
                {(map && result && data2) && (
                    <>
                        {console.log("clear initiated",Cookies.get("id"))}
                        <MyGeoMapClear map={map} result={result} data2={data2} user_id={Cookies.get("id")} />
                    </>
                )}
            </div>
        </div>
    )
}
export function ProvideMap(props) {
    const {myGeoMap} ={}
    console.log(myGeoMap);
    useEffect(() => {
        console.log(myGeoMap)
        myGeoMap &&  myGeoMap.flyTo({center: [-122.4, 37.8]});

    }, [myGeoMap])

    return <></>;
}