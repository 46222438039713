import React from 'react';
import {Button, Label, Dropdown, Input, Popup, Message, Grid, Table, Icon} from 'semantic-ui-react'
import { isMobile} from "react-device-detect";
import '../Styles/Reserves.css'
import Plot from '../../node_modules/react-plotly.js/react-plotly';
import axios from "axios";
import Cookies from 'js-cookie'
import {urlBackend} from "../VariableInitialize";
import KernTab from "./KernTab";
import ReservesOil from "./ReservesOil";
import ReservesGas  from "./ReservesGas";
import ReservesSuccessProbability from "./ReservesSuccessProbability";
import {Helmet} from "react-helmet";
import {ReservesGraphPreview} from "./ReservesGraphPreview";
const config = require('../config');
const reservesApi = config.reservesApi;
class Reserves extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paramsOil:[
                {name:"GRV",name_en:"Gross rock volume",name_ru:"Общий объем породы",popup:"Общий объем породы",distribution:null,values:{}},
                {name:"NPV",name_en:"Net pore volume",name_ru:"Чистый объем пор",popup:"Чистый объем пор",distribution:null,values:{}},
                {name:"HCPV",name_en:"Hydrocarbon pore volume",name_ru:"Поровый объем углеводородов",popup:"Поровый объем углеводородов",distribution:null,values:{}},
                {name:"s",name_en:"Areas",name_ru:"Площадь*",popup:"Площадь",distribution:null,values:{}},
                {name:"h",name_en:"Total thickness",name_ru:"Общая мощность",popup:"Общая мощность",distribution:null,values:{}},
                {name:"ntg",name_en:"Reservoir thickness",name_ru:"Коэффициент песчанности",popup:"Коэффициент песчанности",distribution:null,values:{}},
                {name:"hef",name_en:"Reservoir thickness",name_ru:"Эффективная мощность*",popup:"Эффективная мощность",distribution:null,values:{}},
                {name:"poro",name_en:"Porosity",name_ru:"Пористость*",popup:"Пористость",distribution:null,values:{}},
                {name:"sw",name_en:"Water saturation",name_ru:"Водонасыщенность*",popup:"Водонасыщенность",distribution:null,values:{}},
                {name:"fvf",name_en:"Formation volume factor",name_ru:"Объемный коэффициент*",popup:"Объемный коэффициент",distribution:null,values:{}},
                {name:"ef",name_en:"Expansion factor",name_ru:"Коэффициент расширения",popup:"Коэффициент расширения",distribution:null,values:{}},
                {name:"geomf",name_en:"Geometric correction factor",name_ru:"Геометрический фактор",popup:"Геометрический фактор",distribution:null,values:{}},
                {name:"top_depth",name_en:"Top depth",name_ru:"Кровля пласта",popup:"Кровля пласта",distribution:null,values:{}},
                {name:"bot_depth",name_en:"Bottom depth",name_ru:"Подошва пласта",popup:"Подошва пласта",distribution:null,values:{}},
                {name:"owc",name_en:"Oil-water contact",name_ru:"ВНК",popup:"ВНК",distribution:null,values:{}},
                {name:"gwc",name_en:"Gas-water contact",name_ru:"ГНК",popup:"ГНК",distribution:null,values:{}},
                {name:"dens",name_en:"Oil density",name_ru:"Плотность нефти",popup:"Плотность нефти",distribution:null,values:{}},
                {name:"Kper",name_en:"Conversion Factor",name_ru:"Пересчетный коэффициен",popup:"Пересчетный коэффициен",distribution:null,values:{}},
            ],
            paramsOilDropdownBasic:["GRV","NPV","HCPV","s","h","ntg","hef","poro","sw","fvf","ef","geomf","top_depth","bot_depth","owc","gwc"],
            paramsOilDropdown:["GRV","NPV","HCPV","s","h","ntg","hef","poro","sw","fvf","ef","geomf","top_depth","bot_depth","owc","gwc"],
            paramsOilSelected:[],
            unitsOilList:["Баррель","Тонны","М³"],
            unitsOil:null,
            selectedParams:[],
            // GRV*NPV*HCPV*S*geomf*H*NtG*Hef*poro*(1-So)*(1/fvf)*Kper*dens*bbl
            params: [
                {name:"s",name_en:"Areas",name_ru:"Площадь*",popup:"Площадь",distribution:null,values:{}},
                {name:"h",name_en:"Total thickness",name_ru:"Общая мощность",popup:"Общая мощность",distribution:null,values:{}},
                {name:"ntg",name_en:"Reservoir thickness",name_ru:"Коэффициент песчанности",popup:"Коэффициент песчанности",distribution:null,values:{}},
                {name:"hef",name_en:"Reservoir thickness",name_ru:"Эффективная мощность*",popup:"Эффективная мощность",distribution:null,values:{}},
                {name:"poro",name_en:"Porosity",name_ru:"Пористость*",popup:"Пористость",distribution:null,values:{}},
                {name:"sw",name_en:"Water saturation",name_ru:"Водонасыщенность*",popup:"Водонасыщенность",distribution:null,values:{}},
                {name:"fvf",name_en:"Formation volume factor",name_ru:"Объемный коэффициент*",popup:"Объемный коэффициент",distribution:null,values:{}},
                {name:"ef",name_en:"Expansion factor",name_ru:"Коэффициент расширения",popup:"Коэффициент расширения",distribution:null,values:{}},
                {name:"den",name_en:"Oil density",name_ru:"Плотность нефти",popup:"Плотность нефти",distribution:null,values:{}},
                {name:"geomf",name_en:"Geometric correction factor",name_ru:"Геометрический фактор",popup:"Геометрический фактор",distribution:null,values:{}},
                {name:"top_depth",name_en:"Top depth",name_ru:"Кровля пласта",popup:"Кровля пласта",distribution:null,values:{}},
                {name:"bot_depth",name_en:"Bottom depth",name_ru:"Подошва пласта",popup:"Подошва пласта",distribution:null,values:{}},
                {name:"owc",name_en:"Oil-water contact",name_ru:"ВНК",popup:"ВНК",distribution:null,values:{}},
                {name:"gwc",name_en:"Gas-water contact",name_ru:"ГНК",popup:"ГНК",distribution:null,values:{}}
            ],
            send:"",
            samples:null,
            result:"",
            popupParams: {},
            popupDisplay:false,
            popupPosition:null,
            popupDistribution:null,
            popupName:"",
            popupVariable:"",
            graphTable:[],
            graphData:null,
            graphLayout:null,
            graphResultData:null,
            graphResultLayout:null,
            graphResultCumulativeData:null,
            graphResultCumulativeLayout:null,
            graphResultTestData:null,
            graphResultTestLayout:null,
            submitError:false,
            submitErrorMassage:null,
            colors:["green", "yellow", "red", "orange", "purple", "teal", "crimson", "gold", 'Brown', 'Lavender', "DarkKhaki", 'Indigo', 'olive', 'Salmon', "black"],
            distribution :[
                {name:"uniform",values:["left", "right"]},
                {name:"normal",values:["mean", "std"]},
                {name:"beta",values:["a", "b", "left", "right"]},
                {name:"lognormal",values:["mean", "std", "shift"]},
                {name:"triangular",values:["left", "right","mode"]},
                {name:"truncated normal",values:["mean", "std", "left", "right"]}],
            distributionDropdown :["uniform","normal","beta","lognormal","triangular","truncated normal",null],
            colorstabs:{'active':'#3F51B5','inactive':'#7197D2'},
            tabs:[{name:'Old',status:'Old'},{name:'Oil',status:'Oil'},{name:'Gas',status:'Gas'}],
            activeTab:"Old",
            probability:1,
        }
        this.onSend = this.onSend.bind(this);
        this.resetGraph = this.resetGraph.bind(this);
        this.probabilityChange = this.probabilityChange.bind(this)
        this.setPreview = this.setPreview.bind(this)
    };
    setPreview (reset,values,name,distribution,popup,clientX,clientY) {
        if (!reset)
            this.setState({popupParams:values,
                popupDisplay:true,
                popupVariable:name,
                popupDistribution:distribution,
                popupName:popup,
                popupPosition:{x:clientX,y:clientY},})
        else
            this.setState({popupParams:{},
                popupDistribution:null,
                popupDisplay:false,
                popupVariable:'',
                popupName:'',
                popupPosition:null,})
    }
    probabilityChange (probability) {
        console.log(probability)
        this.setState({probability:probability})
    }
    resetGraph () {
        this.setState({graphData:null,
            graphLayout:null,
            graphResultData:null,
            graphResultLayout:null,
            graphResultCumulativeData:null,
            graphResultCumulativeLayout:null,})
    }
    onSend () {
        const { t } = this.props;
        console.log(this.state.samples)
        let samples = this.state.samples;
        if (this.state.samples === undefined || this.state.samples === null || isNaN(this.state.samples)) {
            samples = 10000;
        }
        //
        let newJsonFormat = {
            "name": "Model",
            "seed": null,
            "num_samples": samples,
            "config": {
                "region_name": {
                    "composition": "Composition",
                    "inputs": {},
                    "results": {
                        "s * hef * poro * (1-sw) * (1/fvf)": {
                            "equation": "s * hef * poro * (1-sw) * (1/fvf)",
                            "probability":this.state.probability,
                        }
                    }
                }
            }
        }
        let params = JSON.parse(JSON.stringify(this.state.params));
        for (let i = 0; i < params.length; i++) {
            console.log(params[i].distribution)
            if ((params[i].name === "s" || params[i].name === "hef"|| params[i].name === "poro"|| params[i].name === "sw"|| params[i].name === "fvf")&&(params[i].distribution === null || params[i].distribution === undefined)) {
                this.setState({submitError:true,submitErrorMassage:"Required parameter(*) is empty. Please correct and try again"})
                return;
            }
            else if (params[i].distribution === null || params[i].distribution === undefined) {
                console.log(params[i])
                params.splice(i, 1);
                i--;
            }
            else {
                let valuesArray = Object.values(params[i].values)
                for (let j = 0; j < valuesArray.length; j++) {
                    if (valuesArray[j] === undefined || valuesArray[j] === null) {
                        this.setState({submitError:true,submitErrorMassage:"An fields is empty or has invalid value. Please correct and try again"})
                        return;
                    }
                }
                console.log(params[i].distribution,valuesArray[1] , valuesArray[0])
                if (params[i].distribution === 'uniform' || params[i].distribution === 'triangular') {
                    if (valuesArray[0] > valuesArray[1]){
                        this.setState({submitError:true,submitErrorMassage:"Right cant be less then Left. " + params[i].name_ru})
                        return;
                    }

                }
                if (params[i].distribution === 'beta'|| params[i].distribution === 'truncated normal'){
                    if (valuesArray[2] > valuesArray[3]){
                        this.setState({submitError:true,submitErrorMassage:"Right cant be less then Left. " + params[i].name_ru})
                        return;
                    }

                }
                if (params[i].distribution === 'normal'){
                    console.log(valuesArray[0],valuesArray[1],valuesArray[0]-(3*valuesArray[1]),(valuesArray[0]-(3*valuesArray[1]))>0)
                    if ((valuesArray[0]-(3*valuesArray[1]))<0){
                        this.setState({submitError:true,submitErrorMassage:"Error in params for normal distribution. " + params[i].name_ru})
                        return;
                    }

                }
                newJsonFormat.config.region_name.inputs[params[i].popup] = {
                    "variable":params[i].name,
                    "distribution": {
                        "name": params[i].distribution,
                        "params": params[i].values
                    }
                }
            }
        }
        console.log(params)
        let results = {data:[]};
        let graphics = [];
        let layout = {};
        let result_graph = [];
        let result_layout = {};
        let cumulative_data = [];
        let cumulative_layout = {};
        console.log(params)
        console.log(results)
        console.log(newJsonFormat)
        axios.post(reservesApi+'/getGraphData',newJsonFormat,{ headers: { "Content-Type": "application/json" } })
            .then(result => {
                console.log(result.data)
                console.log(result.data.region_name)
                console.log(Object.keys(result.data.region_name))
                let keys = Object.keys(result.data.region_name);
                let data = result.data.region_name;
                layout = {
                    height: 700,
                    width: 1200,
                    grid: {rows: Math.ceil(Math.sqrt(params.length+1)), columns: Math.round(Math.sqrt(params.length+1)), pattern: 'independent'},
                    yaxis: {
                        title: {
                            text: t('frequency'),
                            standoff: 0
                        }
                    },
                    xaxis:{
                        title: {
                            text:t(keys[0]),
                            standoff:8
                        }
                    }
                };
                result_layout = {
                    height: 700,
                    width: 1200,
                    barmode: "overlay",
                    yaxis: {
                        title: {
                            text: t('frequency'),
                            standoff: 0
                        }
                    },
                    xaxis:{
                        title: {
                            text:t('result')+ " : " +t(keys[keys.length-1]),
                            standoff:8
                        }
                    }
                };
                cumulative_layout  = {
                    height: 700,
                    width: 1200,
                    barmode: "overlay",
                    yaxis: {
                        title: {
                            text: t('frequency'),
                            standoff: 0
                        }
                    },
                    xaxis:{
                        title: {
                            text:t('result')+ " : " +t(keys[keys.length-1]),
                            standoff:8
                        }
                    }
                };
                for (let i = 0 ; i<keys.length-2; i++){
                    console.log(data[keys[i]].values)
                    let trace1 = {
                        x: data[keys[i]].values,
                        xaxis: "x"+(i+1),
                        yaxis: "y"+(i+1),
                        type: "histogram",
                        histnorm: "probability density",
                        opacity: 0.8,
                        name:t(keys[i]),
                        marker: {
                            color: this.state.colors[i],
                        },
                    };
                    graphics.push(trace1);
                    if (i>0){
                        layout['yaxis'+(i+1)] = {
                            title:{
                                text:t('frequency'),
                                standoff:0
                            }
                        }
                        layout['xaxis'+(i+1)] ={
                            title: {
                                text:t(keys[i]),
                                standoff:8
                            }
                        }
                    }
                }
                let trace1 = {
                    x: data[keys[keys.length-2]].values,
                    xaxis: "x",
                    yaxis: "y",
                    type: "histogram",
                    histnorm: "probability density",
                    opacity: 0.8,
                    name:t(keys[keys.length-1]),
                    marker: {
                        color: this.state.colors[keys.length-1],
                    },
                };
                let probability_array =JSON.parse(JSON.stringify(data[keys[keys.length-1]].values))
                for ( let j = 0 ; j < probability_array.length;j++){
                    if ( probability_array[j] === 0)
                        probability_array[j] = -999;
                }
                let trace1_probability = {}
                if (this.state.probability < 1)
                    trace1_probability = {
                        x: probability_array,
                        xaxis: "x",
                        yaxis: "y",
                        type: "histogram",
                        histnorm: "probability density",
                        opacity: 0.8,
                        name:t(keys[keys.length-1])+"_Success_Probability",
                        marker: {
                            color: "green",
                        },
                    };
                result_graph.push(trace1,trace1_probability)
                let trace2 = {
                    x: data[keys[keys.length-2]].values,
                    xaxis: "x",
                    yaxis: "y",
                    type: "histogram",
                    histnorm: "probability density",
                    cumulative: { enabled: true, direction: "decreasing" },
                    opacity: 0.8,
                    name:t(keys[keys.length-1]),
                    marker: {
                        color: this.state.colors[keys.length],
                    },
                };
                let table = []
                table.push({"Name":t(keys[keys.length-1]),"P10":Number.parseFloat(data[keys[keys.length-2]].stats.P10[0]).toFixed(2),"P50":Number.parseFloat(data[keys[keys.length-2]].stats.P50[0]).toFixed(2),"P90":Number.parseFloat(data[keys[keys.length-2]].stats.P90[0]).toFixed(2)})
                table.push({"Name":t(keys[keys.length-1]+"_Success_Probability"),"P10":(data[keys[keys.length-1]].stats ? Number.parseFloat(data[keys[keys.length-1]].stats.P10[0]).toFixed(2) : "Not availible yet"), "P50":(data[keys[keys.length-1]].stats ? Number.parseFloat(data[keys[keys.length-1]].stats.P50[0]).toFixed(2) : "Not availible yet"), "P90":(data[keys[keys.length-1]].stats ? Number.parseFloat(data[keys[keys.length-1]].stats.P90[0]).toFixed(2) : "Not availible yet")})

                let trace2P10dot = {
                    x: [Math.min.apply(Math, data[keys[keys.length-2]].values),data[keys[keys.length-2]].stats.P10[0],data[keys[keys.length-2]].stats.P10[0]],
                    y: [data[keys[keys.length-2]].stats.P10[1]/100,data[keys[keys.length-2]].stats.P10[1]/100,0],
                    mode: 'lines',
                    name: 'P10',
                    legendgroup: 'markers1',
                    showlegend:false,
                    line: {
                        color: 'rgb(0,0,0)',
                        dash: 'dot',
                        width: 2
                    }
                };
                //result_probability
                let trace2P50dot = {
                    x: [Math.min.apply(Math, data[keys[keys.length-2]].values),data[keys[keys.length-2]].stats.P50[0],data[keys[keys.length-2]].stats.P50[0]],
                    y: [data[keys[keys.length-2]].stats.P50[1]/100,data[keys[keys.length-2]].stats.P50[1]/100,0],
                    mode: 'lines',
                    name: 'P50',
                    legendgroup: 'markers1',
                    showlegend:false,
                    line: {
                        color: 'rgb(0,0,0)',
                        dash: 'dot',
                        width: 2
                    }
                };
                let trace2P90dot = {
                    x: [Math.min.apply(Math, data[keys[keys.length-2]].values),data[keys[keys.length-2]].stats.P90[0],data[keys[keys.length-2]].stats.P90[0]],
                    y: [data[keys[keys.length-2]].stats.P90[1]/100,data[keys[keys.length-2]].stats.P90[1]/100,0],
                    mode: 'lines',
                    name: 'P90',
                    legendgroup: 'markers1',
                    showlegend:false,
                    line: {
                        color: 'rgb(0,0,0)',
                        dash: 'dot',
                        width: 2
                    }
                };
                var trace3 = {
                    x: [data[keys[keys.length-2]].stats.P10[0], data[keys[keys.length-2]].stats.P50[0], data[keys[keys.length-2]].stats.P90[0]],
                    y: [data[keys[keys.length-2]].stats.P10[1]/100, data[keys[keys.length-2]].stats.P50[1]/100, data[keys[keys.length-2]].stats.P90[1]/100],
                    mode: 'markers+text',
                    type: 'scatter',
                    name: 'markers P10,P50,P90',
                    legendgroup: 'markers1',
                    text: ['P10 '+parseFloat(data[keys[keys.length-2]].stats.P10[0]).toFixed(2), 'P50 '+parseFloat(data[keys[keys.length-2]].stats.P50[0]).toFixed(2), 'P90 '+parseFloat(data[keys[keys.length-2]].stats.P90[0]).toFixed(2)],
                    textposition: 'top center',
                    textfont: {
                        family:  'Raleway, sans-serif'
                    },
                    marker: { size: 12 }
                };
                let trace4Probability = {}
                if (this.state.probability < 1)
                    trace4Probability = {
                        x: data[keys[keys.length-1]].values,
                        xaxis: "x",
                        yaxis: "y",
                        type: "histogram",
                        histnorm: "probability density",
                        connectgaps: false,
                        cumulative: { enabled: true, direction: "decreasing" },
                        opacity: 0.8,
                        name:t(keys[keys.length-1] + " probability"),
                        marker: {
                            color: this.state.colors[keys.length+1],
                        },
                    };

                let nullArray  = []
                let valueArray = []
                for ( let j = 0 ; j < data[keys[keys.length-1]].values.length;j++){
                    if ( data[keys[keys.length-1]].values[j] === 0 ){
                        nullArray.push(data[keys[keys.length-1]].values[j])
                    }else{
                        valueArray.push(data[keys[keys.length-1]].values[j])
                    }
                }
                result_layout.xaxis.range = [0,Math.max(...data[keys[keys.length-2]].values)];
                console.log(valueArray,nullArray,Math.min(...valueArray))
                cumulative_layout.xaxis.range = [Math.min(...valueArray),Math.max(...data[keys[keys.length-2]].values)];

                cumulative_data.push(trace2,trace2P10dot,trace2P50dot,trace2P90dot,trace3,trace4Probability)
                if (data[keys[keys.length-1]].stats){
                    let trace4P10dot = {
                        x: [Math.min.apply(Math, data[keys[keys.length-1]].values),data[keys[keys.length-1]].stats.P10[0],data[keys[keys.length-1]].stats.P10[0]],
                        y: [data[keys[keys.length-1]].stats.P10[1]/100,data[keys[keys.length-1]].stats.P10[1]/100,0],
                        mode: 'lines',
                        name: 'P10_SP',
                        legendgroup: 'markers2',
                        showlegend:false,
                        line: {
                            color: 'rgb(0,0,0)',
                            dash: 'dot',
                            width: 2
                        }
                    };
                    //result_probability
                    let trace4P50dot = {
                        x: [Math.min.apply(Math, data[keys[keys.length-1]].values),data[keys[keys.length-1]].stats.P50[0],data[keys[keys.length-1]].stats.P50[0]],
                        y: [data[keys[keys.length-1]].stats.P50[1]/100,data[keys[keys.length-1]].stats.P50[1]/100,0],
                        mode: 'lines',
                        name: 'P50_SP',
                        legendgroup: 'markers2',
                        showlegend:false,
                        line: {
                            color: 'rgb(0,0,0)',
                            dash: 'dot',
                            width: 2
                        }
                    };
                    let trace4P90dot = {
                        x: [Math.min.apply(Math, data[keys[keys.length-1]].values),data[keys[keys.length-1]].stats.P90[0],data[keys[keys.length-1]].stats.P90[0]],
                        y: [data[keys[keys.length-1]].stats.P90[1]/100,data[keys[keys.length-1]].stats.P90[1]/100,0],
                        mode: 'lines',
                        name: 'P90_SP',
                        legendgroup: 'markers2',
                        showlegend:false,
                        line: {
                            color: 'rgb(0,0,0)',
                            dash: 'dot',
                            width: 2
                        }
                    };
                    let trace5 = {
                        x: [data[keys[keys.length-1]].stats.P10[0], data[keys[keys.length-1]].stats.P50[0], data[keys[keys.length-1]].stats.P90[0]],
                        y: [data[keys[keys.length-1]].stats.P10[1]/100, data[keys[keys.length-1]].stats.P50[1]/100, data[keys[keys.length-1]].stats.P90[1]/100],
                        mode: 'markers+text',
                        type: 'scatter',
                        legendgroup: 'markers2',
                        name: 'markers SP P10,P50,P90',
                        text: ['P10 '+parseFloat(data[keys[keys.length-1]].stats.P10[0]).toFixed(2), 'P50 '+parseFloat(data[keys[keys.length-1]].stats.P50[0]).toFixed(2), 'P90 '+parseFloat(data[keys[keys.length-1]].stats.P90[0]).toFixed(2)],
                        textposition: 'top center',
                        textfont: {
                            family:  'Raleway, sans-serif'
                        },
                        marker: { size: 12 }
                    };
                    cumulative_data.push(trace4P10dot,trace4P50dot,trace4P90dot,trace5)
                }

                this.setState({graphTable:table, graphData:graphics, graphLayout:layout, graphResultData:result_graph, graphResultLayout:result_layout,graphResultCumulativeData:cumulative_data,
                    graphResultCumulativeLayout:cumulative_layout, submitError:false, submitErrorMassage:null,})
            })
            .catch(err => {console.log(err)})

    };
    onSetData = (graphics,layout,result_graph,result_layout,cumulative_data,cumulative_layout) => {
        console.log(graphics,layout,result_graph,result_layout,cumulative_data,cumulative_layout)
        this.setState({ graphData:graphics, graphLayout:layout, graphResultData:result_graph, graphResultLayout:result_layout,graphResultCumulativeData:cumulative_data,
            graphResultCumulativeLayout:cumulative_layout, submitError:false, submitErrorMassage:null,})
    }
    onDropdownChange (option, value, name) {
        let params = JSON.parse(JSON.stringify(this.state.params));
        let dist ;
        let index = 0;
        let list ={};
        for (let i = 0; i < params.length; i++) {
            if (params[i].name === name) {
                params[i].distribution = value.value;
                dist = value.value;
                index = i;
            }
        }
        console.log(index,dist,this.state.distribution)
        for (let i = 0 ; i < this.state.distribution.length;i++){
            console.log(dist,this.state.distribution[i].name)
            if (dist === this.state.distribution[i].name){
                let values = this.state.distribution[i].values;
                for (let j = 0; j < values.length;j++){
                    list[values[j]] = null;
                }
                params[index].values = list;
            }
        }
        console.log(params,list)
        this.setState({params:params})
    };
    render() {
        console.log("Reserves ")
        let width = "80%";
        if (isMobile) {
            console.log("IS MOBILE")
            width = "100%";
        }
        let samplesError = false;
        if(this.state.submitError&&(this.state.samples === undefined || this.state.samples ===null))
            samplesError = true;
        const { t } = this.props;
        return(
            <div className={"reserves"} style={{display:this.props.display,width:width}}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{"Подсчет запасов"}</title>
                    <meta name="description" content={"Подсчет запасов нефти и газа монте карло (вероятностный метод) расчет объема и геологических рисков поиска углеводородов"} />
                </Helmet>
                <Button basic size={'mini'} onClick={this.props.close} icon><i className="close icon"/></Button>
                <br />
                <br />
                <ReservesGraphPreview display={this.state.popupDisplay} variable={this.state.popupVariable} name={this.state.popupName} position={this.state.popupPosition} distribution={this.state.popupDistribution} params={this.state.popupParams} />
                {(Cookies.get("id") !== undefined)&&(
                    <>
                        {this.state.tabs.map(({name,status},index) =>{
                            //console.log(name,status)
                            return(
                                <Button style={{color:"white",backgroundColor:this.state.colorstabs[status]}}
                                        onClick={() => {
                                            //console.log(name,status)
                                            let tabs = this.state.tabs;
                                            for (let i = 0; i < tabs.length; i++)
                                                tabs[i].status = "inactive";
                                            tabs[index].status = "active"
                                            this.setState({activeTab:name, tabs:tabs})
                                        }}>{name}</Button>
                            )
                        })}
                        <br />
                        {(this.state.activeTab === "Oil")&&(<ReservesOil setPreview={this.setPreview} resetGraph={this.resetGraph} colors={this.state.colors} onSetData={this.onSetData} distributionDropdown={this.state.distributionDropdown} distribution={this.state.distribution} />)}
                        <br />
                        {(this.state.activeTab === "Gas")&&(<ReservesGas setPreview={this.setPreview} resetGraph={this.resetGraph} colors={this.state.colors} onSetData={this.onSetData} distributionDropdown={this.state.distributionDropdown} distribution={this.state.distribution} />)}
                        <br />
                    </>
                )}
                {(this.state.activeTab === "Old")&&(<>
                    {this.state.params.map(({name,name_en,name_ru,popup,distribution,values},index)=>{
                        console.log(popup,this.state.params)
                        return (
                            <>  <div >
                                    <Popup className={"popup"} content={name} trigger={<Label className={"size75label1"} >{t(name_ru)}</Label>}/>
                                    <Dropdown
                                        className={"size75dropdown"}
                                        placeholder={t('Select Distribution')}
                                        selection
                                        onChange={(option,value)=>this.onDropdownChange(option,value,name)}
                                        options= {this.state.distributionDropdown.map(data => ({key: data, text:t(data), value: data}))}
                                    />
                                    <div className={"size75div"}>
                                    {Object.keys(values).map((key,value) => {
                                        console.log(values[key],this.state.submitError,values[key] === undefined,values[key] === null,( values[key] === undefined || values[key] === null),(this.state.submitError && ( values[key] === undefined || values[key] === null)))
                                        let error = false;
                                        if (this.state.submitError && ( values[key] === undefined || values[key] === null || isNaN(values[key])))
                                            error = true;
                                        return (
                                            <div className={"size75div2"}><Label className={"size75label2"}>{key}</Label><Input key={name+key} id={name+key} className={"size75input"} value={this.state.params[index].values[key]} error={error} min="0" type={"number"} onChange={(e,input)=>{
                                                let params = this.state.params;
                                                if ( Number.parseFloat(input.value)>=0 || input.value === ''){
                                                    if (Number.parseFloat(input.value)>=1 &&(name==="ntg"||name==="poro"||name==="sw"||name==="geomf"||name==="den"||name==="ef") &&(key==='left'||key==='right'||key==='mode'||key==='mean')){
                                                        document.getElementById(name+key).value = 1;
                                                        params[index].values[key] = 1;
                                                        this.setState({params: params})
                                                    }
                                                    else{
                                                        params[index].values[key] = Number.parseFloat(input.value.replace('-', ''));
                                                        this.setState({params: params})
                                                    }
                                                }else
                                                    document.getElementById(name+key).value = document.getElementById(name+key).value.replace('-','') ;
                                            }} /></div>
                                        )
                                    })}
                                        <Icon name={"eye"} onMouseEnter={(e)=>{
                                            console.log("onMouseEnter",e.clientX,e.clientY)
                                            this.setState({popupParams:values,
                                                popupDisplay:true,
                                                popupVariable:name,
                                                popupDistribution:distribution,
                                                popupName:popup,
                                                popupPosition:{x:e.clientX,y:e.clientY},})
                                        }} onMouseLeave={(e)=>{console.log("onMouseLeave")
                                            this.setState({popupParams:{},
                                                popupDistribution:null,
                                                popupDisplay:false,
                                                popupVariable:'',
                                                popupName:'',
                                                popupPosition:null,})
                                        }} />
                                    </div>
                                </div>

                            </>
                        )
                    })}

                    {(this.state.submitError)&&(<><Message size={"small"} compact negative>
                        <Message.Header>{t("Error")}</Message.Header>
                        <p>{t(this.state.submitErrorMassage)}</p>
                    </Message><br/></>)}
                    <br/>
                    <ReservesSuccessProbability setProbability={this.probabilityChange} display={"block"}/>
                    <div className={"size75div3"}>
                        <Grid>
                            <Grid.Column width={8}>
                                <Table className={"ui very basic table"}>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell collapsing>
                                                <Label className={"size75label2"}>
                                                    {t("Samples")}
                                                </Label>
                                                <Input className={"size75input"} error={samplesError} type={"number"} min="0" value={this.state.samples}  onChange={(e,input)=>{
                                                    if (Number.parseFloat(input.value)>=0  || input.value === '')
                                                        this.setState({samples:Number.parseFloat(input.value)})
                                                }} style={{width:"100px"}} />
                                                <Button className={"size75Button"} onClick={this.onSend}>
                                                    {t("Send")}
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Grid.Column>
                        </Grid>
                    </div>
                </>)}
                {(this.state.graphData !== null)&&(<Plot
                    style={{marginLeft:'-50px'}}
                    data={this.state.graphData}
                    layout={ this.state.graphLayout }
                />)}
                <br/>
                {console.log(this.state.graphResultData,this.state.graphResultLayout)}
                {(this.state.graphResultData !== null)&&(<Plot
                    style={{marginLeft:'-50px'}}
                    data={this.state.graphResultData}
                    layout={ this.state.graphResultLayout }
                />)}
                {console.log(this.state.graphResultCumulativeData,this.state.graphResultCumulativeLayout)}
                {(this.state.graphResultCumulativeData !== null)&&(<Plot
                    style={{marginLeft:'-50px'}}
                    data={this.state.graphResultCumulativeData}
                    layout={ this.state.graphResultCumulativeLayout }
                />)}
                {(this.state.graphTable.length > 1)&&(<>
                    <table style={{width:"600px"}} id='publications'>
                        <tbody>
                        <tr key={"geotiff"}>
                            <th >{""}</th>
                            <th>{"P10"}</th>
                            <th>{"P50"}</th>
                            <th>{"P90"}</th>
                        </tr>
                        {this.state.graphTable.map(({Name,P10,P50,P90})=>{
                            return(
                                <tr>
                                    <td>{Name}</td>
                                    <td>{P10}</td>
                                    <td>{P50}</td>
                                    <td>{P90}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    <br/>
                    <br/>
                </>)}

            </div>
        )}}
export default Reserves;
