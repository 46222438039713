import React from "react";
import {Dropdown, Icon, Label, Input, Button} from "semantic-ui-react";
import Cookies from "js-cookie";
import axios from "axios";
import i18n from "../i18n";
import {MathMean} from "../functions"
import {urlBackend} from "../VariableInitialize";
import FeatureInfosReservesGroupByAge from "./FeatureInfosReservesGroupByAge";
import {shuffle,rainbow} from "../functions"
import {age_word_to,age_word_from,age_word} from "../VariableInitialize"
import GroupPermissionForObject from "./GroupPermissionForObject";
import Plot from "react-plotly.js";
import stats from "math-stats";
function intersect(a, b) {
    let t;
    if (b.length > a.length) {
        t = b; b = a; a = t; // indexOf to loop over shorter
    }
    return a.filter(function (e) {
        return b.indexOf(e) > -1;
    });
}

class ReservesFilterInterface extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ReservesDisplay:"none",
            ReservesData:null,
            RawData:[],
            axisType:['log','linear'],
            Xaxis:'linear',
            Yaxis:'linear',
            FiltersKeys:[],
            FilterData:[],
            Filters:[],
            FiltersConst:[],
            FiltersActive:[],
            FilteredData:[],
            ReservesProps:[],
            HistogramBins:10,
            ReservoirList:[],
            GraphTypesList:['BarChart','Histogram','BoxChart','BubbleChart','ParallelChart'],
            GraphLegend:null,
            GroupedBy:null,
            GroupedByArrays:[],
            GroupByNames:["All"],
            GroupByParams:["min", "max", "sum", "mean", "median"],
            GroupByParamsSelected:[],
            FiltersSelected:[],
            Reservoirs:[],
            GraphLegendOpen:false,
            ParamList:[],
            ParamList2:[],
            XParam:null,
            GraphType:null,
            YParam:null,
            sorted:false,
            XParamType:null,
            YParamType:null,
            ParamsRawList:[],
            ParamsDataList:[],
            Param:null,
            GraphData:[],
            GraphLayout:[],
            GraphExtra:[],
        }
        this.onGraphTypeSelection = this.onGraphTypeSelection.bind(this)
        this.onParamSelection = this.onParamSelection.bind(this)
        this.onFilterSelection = this.onFilterSelection.bind(this)
        this.onFilterData = this.onFilterData.bind(this)
        this.onXSelection = this.onXSelection.bind(this)
        this.onYSelection = this.onYSelection.bind(this)
        this.drawTable  = this.drawTable.bind(this)
        this.onGroupByChange = this.onGroupByChange.bind(this)
        this.onGroupByParamsChange = this.onGroupByParamsChange.bind(this)
        this.onGroupParamsChange = this.onGroupParamsChange.bind(this)
        this.onReset = this.onReset.bind(this)
        this.onBinsChange = this.onBinsChange.bind(this)
        this.onGroupByReset = this.onGroupByReset.bind(this)
        this.onDeleteFilter = this.onDeleteFilter.bind(this)
        this.onXReset =this.onXReset.bind(this)
        this.onYReset =this.onYReset.bind(this)
        this.onXaxis = this.onXaxis.bind(this)
        this.onYaxis = this.onYaxis.bind(this)
        this.onSort = this.onSort.bind(this)
    }
    onXReset () {
        this.setState({XParam:null,XParamType:null,})
    }
    onYReset () {
        this.setState({YParam:null,YParamType:null,})
    }
    onXaxis (e,data) {
        this.setState({Xaxis:data.value})
    }
    onYaxis (e,data) {
        this.setState({Yaxis:data.value})
    }
    onGroupByReset (e,data) {
        let filtered_data = this.state.FilteredData
        if (filtered_data.length === 0)
            filtered_data = this.state.FilterData;
        this.setState({GroupedBy:null,GroupedByArrays:[filtered_data],GroupByNames:["All"]})
    };
    onDeleteFilter (name) {
        console.log(name,this.state.FiltersActive)
        let array_after_delete  = this.state.FiltersActive.filter(x => x !== name)
        this.onFilterSelection(null,{value:array_after_delete})
    };
    onBinsChange (e,data) {
        console.log(e,data)
        if (data.value > 0)
            this.setState({HistogramBins:parseInt(data.value)})
    };
    onFilterSelection (e,data) {
        let filters = [...this.state.Filters]
        console.log(data.value,data.value.length)
        for (let j = 0 ; j < filters.length; j++){
            if (data.value.includes(filters[j].name) && filters[j].filter === undefined){
                if (filters[j].type === "integer" || filters[j].type === "age"){
                    filters[j].filter = {from:null, to:null}
                }else{
                    filters[j].filter = {values:null}
                }
            }
            else if (filters[j].filter !== undefined && !data.value.includes(filters[j].name)){
                console.log("delete filter for " , filters[j])
                delete filters[j].filter
            }
        }
        console.log(filters)
        if (data.value.length === 0){
            let table_result = this.drawTable(this.state.FilterData)
            console.log(data,table_result)
            this.setState({FilteredData:this.state.FilterData,ReservesData:table_result.tableRows,ReservesProps:table_result.keys,FiltersActive:[],Filters:this.state.FiltersConst})
        }
        else{
            this.setState({FiltersActive:data.value,Filters:filters})
            this.onFilterData()
        }

    }
    onSort(sortKey){
        //Publication
        let data = [...this.state.FilterData];
        data.sort((a,b) => {
            console.log(a[sortKey],b[sortKey])
            if (this.state.sorted) {
                if (!a[sortKey]) {
                    // Change this values if you want to put `null` values at the end of the array
                    return +1;
                }

                if (!b[sortKey]) {
                    // Change this values if you want to put `null` values at the end of the array
                    return -1;
                }
                return a[sortKey].toString().localeCompare(b[sortKey].toString());
            }else {
                if (!a[sortKey]) {
                    // Change this values if you want to put `null` values at the end of the array
                    return -1;
                }

                if (!b[sortKey]) {
                    // Change this values if you want to put `null` values at the end of the array
                    return +1;
                }
                return b[sortKey].toString().localeCompare(a[sortKey].toString());
            }
        })
        let table_result = this.drawTable(data)
        this.setState({ReservesData:table_result.tableRows,sorted:!this.state.sorted})
    }
    onFilterData () {
        console.log("Filters active " + this.state.FiltersActive.length)
        let data = [...this.state.FilterData];
        for (let h = 0; h < this.state.Filters.length; h++){
            if ( this.state.Filters[h].filter !== undefined){
                if (this.state.Filters[h].type === 'integer'){
                    if (this.state.Filters[h].filter.from !== null){
                        for ( let i = 0; i < data.length; i++){
                            if (Number.parseFloat(data[i][this.state.Filters[h].name]) < this.state.Filters[h].filter.from){
                                data.splice(i, 1);
                                i--;
                            }
                        }
                    }
                    if (this.state.Filters[h].filter.to !== null){
                        for ( let i = 0; i < data.length; i++){
                            if (Number.parseFloat(data[i][this.state.Filters[h].name]) > this.state.Filters[h].filter.to){
                                data.splice(i, 1);
                                i--;
                            }
                        }
                    }
                }else if(this.state.Filters[h].type === 'age'){
                    if (this.state.Filters[h].filter.from !== null){
                        for ( let i = 0; i < data.length; i++) {
                            if (data[i][this.state.Filters[h].name+"_start"] !== null) {
                                console.log(data[i][this.state.Filters[h].name+"_start"])
                                let result = age_word.filter(obj => {
                                    console.log(obj,data[i][this.state.Filters[h].name+"_start"])
                                    return obj.object_index === data[i][this.state.Filters[h].name+"_start"]
                                })
                                console.log(data[i][this.state.Filters[h].name], result, Number.parseFloat(result[0]),this.state.Filters[h].filter.from,)
                                if (Number.parseFloat(result[0].age_from) < this.state.Filters[h].filter.from) {
                                    data.splice(i, 1);
                                    i--;
                                }
                            }
                        }
                    }
                    if (this.state.Filters[h].filter.to !== null){
                        for ( let i = 0; i < data.length; i++){
                            if (data[i][this.state.Filters[h].name+"_end"] !== null) {
                                console.log(data[i][this.state.Filters[h].name+"_end"])
                                let result = age_word.filter(obj => {
                                    return obj.object_index === data[i][this.state.Filters[h].name+"_end"]
                                })
                                console.log(data[i][this.state.Filters[h].name+"_end"],result[0], Number.parseFloat(result[0]),this.state.Filters[h].filter.from,Number.parseFloat(result[0]) < this.state.Filters[h].filter.from)
                                if (Number.parseFloat(result[0].age_ti) > this.state.Filters[h].filter.to){
                                    data.splice(i, 1);
                                    i--;
                                }
                            }
                        }
                    }
                }else{
                    console.log("dictionary")
                    for ( let i = 0; i < data.length; i++){
                        if ( this.state.Filters[h].filter.values !== null){
                            console.log(Array.isArray(data[i][this.state.Filters[h].name]))
                            if ( Array.isArray(data[i][this.state.Filters[h].name])){
                                console.log(data[i][this.state.Filters[h].name],data[i].facies,data[i][this.state.Filters[h].name].length,intersect(data[i][this.state.Filters[h].name], this.state.Filters[h].filter.values).length)

                                if ( intersect(data[i][this.state.Filters[h].name], this.state.Filters[h].filter.values).length === 0 ){
                                    console.log( "array filter")
                                    data.splice(i, 1);
                                    i--;
                                }
                            }else
                            if ( !this.state.Filters[h].filter.values.includes(data[i][this.state.Filters[h].name])){
                                data.splice(i, 1);
                                i--;
                            }
                        }

                    }
                }
                console.log(data)
                let table_result = this.drawTable(data)
                console.log(data,table_result)
                this.setState({FilteredData:data,ReservesData:table_result.tableRows,ReservesProps:table_result.keys,GroupedByArrays:[data]})

            }
        }
    }
    drawTable (table_data) {
        const keys = [...new Set(table_data.flatMap(obj => Object.keys(obj)))];
        const tableRows = table_data.map(obj => (
            <tr style={{maxHeight:"100px"}} key={obj.id}>
                {keys.map(key => {
                    if (obj[key] !== undefined && obj[key] !== null) {
                        //console.log(obj[key])
                        if(obj[key].length>100)
                            return(
                                <td style={{maxHeight:"100px"}} key={key}>{obj[key].substring(0, 100) || ''}</td>
                            )
                        else if (Array.isArray(obj[key])){
                            return(
                                <td style={{maxHeight:"100px"}} key={key}>{obj[key].join(', ') || ''}</td>
                            )
                        }
                        else
                            return(
                                <td style={{maxHeight:"100px"}} key={key}>{obj[key] || ''}</td>
                            )
                    }
                    else
                        return(
                            <td style={{maxHeight:"100px"}} key={key}>{obj[key] || ''}</td>
                        )
                })}
            </tr>
        ));
        return({keys: keys,tableRows: tableRows});
    }

    onGraphTypeSelection (e,data) {
        this.setState({GraphType:data.value,XParam:null,YParam:null,GraphExtra:[]})
    }
    onXSelection (e,data) {
        console.log(data.value,this.state)
        let filter = this.searchInFilters(data.value)
        if (filter !== null)
            this.setState({XParam:data.value,XParamType:filter.type})
        else
            this.setState({XParam:data.value})
    }
    onYSelection (e,data) {
        console.log(data.value,this.state)
        let filter = this.searchInFilters(data.value)
        if (filter !== null)
            this.setState({YParam:data.value,YParamType:filter.type})
        else
            this.setState({YParam:data.value})
    }
    searchInFilters (name) {
        for (let i= 0 ; i < this.state.FiltersConst.length ; i++){
            if ( this.state.FiltersConst[i].name === name) {
                return this.state.FiltersConst[i]
            }
        }
        return null;
    }

    onParamSelection (e,data,single) {

        let x = this.state.XParam
        let y = this.state.YParam
        let xData = [];
        let yData = []
        let legend = null;
        let graphDatas = [];
        console.log(x,y)
        let layout = {
            font:{
                family: 'Raleway, sans-serif'
            },
            xaxis: {
                title: {
                    text: x,
                }
            },
            yaxis: {
                title: {
                    text: y,
                }
            }
        }
        console.log(layout)
        if (y !== null)
            layout.yaxis= {
                title: {text: y,}}
        if (x === 'depth')
            layout.xaxis.autorange= 'reversed';
        if (y === 'depth')
            layout.yaxis.autorange= 'reversed';
        for (let j = 0 ; j < this.state.GroupedByArrays.length ;j++){
            let params = [];
            let colors = []
            let ids = [];
            console.log(this.state.GroupedByArrays[j],x,y,this.state.GroupByNames[j],this.state.GroupByNames)
            let name = this.state.GroupByNames[j]
            if (name.length > 30)
                name = name.substring(0, 30)
            for ( let i = 0 ; i < this.state.GroupedByArrays[j].length ; i++ ){
                console.log(this.state.GroupedByArrays[j][i],x)
                ids.push(this.state.GroupedByArrays[j][i]['name']) ;
                if ( Array.isArray(x)){
                    let obj = {}
                    for(let l = 0 ; l < x.length;l++){
                        if (this.state.GroupedByArrays[j][i][x[l]] === undefined)
                            obj[x[l]] = 0;
                        else
                            obj[x[l]] = this.state.GroupedByArrays[j][i][x[l]]
                    }
                    params.push(obj)
                }
                else
                    params.push(this.state.GroupedByArrays[j][i][x])
                colors.push(i)
            }

            let paramsY = [];
            for ( let i = 0 ; i < this.state.GroupedByArrays[j].length ; i++ ){
                paramsY.push(this.state.GroupedByArrays[j][i][y])
            }
            console.log(params,paramsY)
            let valueList = params;
            let nameList = [];
            if ( params.length > 0 ){
                for ( let i = 0 ; i < params.length;i++){
                    console.log(params[i])
                    if ( params[i] !== undefined && params[i] !== null ){
                        if (params[i].name !== undefined && params[i].name !== null)
                            nameList.push(name + params[i].name)
                        else
                            nameList.push(name + params[i])
                    }
                    else
                        nameList.push(name )

                }
            }

            console.log(valueList,nameList)
            let valueListY = paramsY
            let nameListY = [];
            if ( paramsY.length > 0 ){
                for ( let i = 0 ; i < paramsY.length;i++){
                    nameListY.push(name +params[i])
                }
            }
            let intersects_index = [];
            if (  y !== null){
                console.log(y , "both")
                for (let i = 0; i < valueList.length; i++) {
                    let v = valueList[i];
                    let p = valueListY[i]
                    if ( Array.isArray(v)){
                        if (v[0] !== undefined && v[0] !== null && p !== null && p !== undefined) {
                            intersects_index.push(i);
                        }
                    }else if (Array.isArray(p)){
                        if (v !== undefined && v !== null && p[0] !== null && p[0] !== undefined) {
                            intersects_index.push(i);
                        }
                    }else{
                        if (v !== undefined && v !== null && p !== null && p !== undefined) {
                            intersects_index.push(i);
                        }
                    }
                }
                valueList = intersects_index.map(i => valueList[i])
                valueListY = intersects_index.map(i => valueListY[i])
            }else{
                console.log("only x")
                valueList = valueList.filter(function( element ) {
                    return element !== undefined && element !== null;
                });
            }
            console.log(valueList,valueListY)
            xData = [...xData,...valueList]
            if ( y !== null)
                yData = [...yData,...valueListY]
            if ( this.state.GroupByParamsSelected.length !== 0 && this.state.GroupByParamsSelected!== null && (this.state.GraphType === "BubbleChart" || this.state.GraphType === "BarChart")){
                let list = [];
                let listY = [];
                let namesList = [];
                let name_list = []
                console.log(this.state.GroupByParamsSelected,stats)
                for ( let k = 0 ; k < this.state.GroupByParamsSelected.length;k++){
                    //"min", "max", "sum", "mean", "median", "mod"
                    if ( this.state.GroupByParamsSelected[k] === 'min'){
                        list.push(Math.min(...valueList))
                        console.log(Math.min(...valueList),valueList)
                        if (this.state.GraphType === "BubbleChart")
                            listY.push(Math.max(...valueListY))
                        namesList.push("min")
                    }else if (this.state.GroupByParamsSelected[k] === 'max'){
                        list.push(Math.max(...valueList))
                        console.log(Math.max(...valueList),valueList)
                        if (this.state.GraphType === "BubbleChart")
                            listY.push(Math.max(...valueListY))
                        namesList.push("max")
                    }else if (this.state.GroupByParamsSelected[k] === 'sum'){
                        list.push(valueList.reduce((a,b)=>Number.parseFloat(a)+Number.parseFloat(b)))
                        console.log(valueList.reduce((a,b)=>Number.parseFloat(a)+Number.parseFloat(b)),valueList)
                        if (this.state.GraphType === "BubbleChart")
                            listY.push(valueListY.reduce((a,b)=>a+b))
                        namesList.push("sum")
                    }else if (this.state.GroupByParamsSelected[k] === 'mean'){
                        list.push(stats.mean(valueList))
                        console.log(stats.mean(valueList),valueList)
                        if (this.state.GraphType === "BubbleChart")
                            listY.push(stats.mean(valueListY))
                        namesList.push("mean")
                    }else if (this.state.GroupByParamsSelected[k] === 'median'){
                        list.push(stats.median(valueList))
                        console.log(stats.median(valueList),valueList)
                        if (this.state.GraphType === "BubbleChart")
                            listY.push(stats.median(valueListY))
                        namesList.push("median")
                    }else if (this.state.GroupByParamsSelected[k] === 'mod'){
                        console.log(stats.mode(valueList))
                        list.push(stats.mode(valueList)[0])
                        console.log(stats.median(valueList),valueList)
                        if (this.state.GraphType === "BubbleChart")
                            listY.push(stats.mode(valueListY)[0])
                        namesList.push("mod")
                    }
                }
                console.log(list,nameList)
                valueList = list;
                if (this.state.GraphType === "BubbleChart")
                    valueListY = listY;
                nameList = namesList;
            }
            let dataGraph;
            if (this.state.GraphType === 'BarChart'){
                layout.yaxis.type = this.state.Xaxis;
                layout.width = 600
                layout.height = 500
                if (this.state.GroupByParamsSelected.length === 0)
                    dataGraph =
                        {
                            y: valueList,
                            x:ids,
                            type: 'bar',
                            name: name
                        }
                else
                    dataGraph =
                        {
                            x: nameList,
                            y: valueList,
                            type: 'bar',
                            name: name
                        }
            }

            else if (this.state.GraphType === "ParallelChart"){
                let colors_parallel = [];
                let colorscale = [];
                let legend_color = [];
                layout.xaxis.type = this.state.Xaxis;
                for(let m = 0 ; m < colors.length ; m++){
                    colors_parallel.push(Number.parseFloat(((m)/colors.length).toFixed(2)));
                    let color = "#" + ((1 << 24) * Math.random() | 0).toString(16).padStart(6, "0");
                    colorscale.push([Number.parseFloat(((m)/colors.length).toFixed(2)),color])
                    legend_color.push({legends:ids[m],color:color})
                }
                legend = legend_color
                console.log(colors,colorscale,colors_parallel)
                dataGraph =
                    {
                        type: 'parcoords',
                        pad: [80,80,80,80],
                        ids:ids,
                        line: {
                            color: colors_parallel,
                            colorscale: [
                                ...colorscale,...[[
                                    1,
                                    "black"
                                ]]
                            ]
                        },

                        dimensions: [

                        ]
                    }
                for ( let l = 0 ; l < x.length; l++){
                    let list =[];
                    for ( let k = 0 ; k < valueList.length ;k++){
                        list.push(valueList[k][x[l]])
                    }
                    dataGraph.dimensions.push({
                        label: x[l],
                        values: list
                    },)
                }
                layout.width = 600
                layout.height = 500
            }
            else if (this.state.GraphType === "Histogram"){
                layout.yaxis.type = this.state.Xaxis;
                console.log(name)
                dataGraph =
                    {
                        x: valueList,
                        type: 'histogram',
                        name: name,
                        nbinsx:this.state.HistogramBins
                    }
                layout.barmode= "overlay";
                layout.width = 600
                layout.height = 500
            }
            else if ( this.state.GraphType === "BoxChart"){
                console.log(valueList,valueListY)
                if (Array.isArray(valueList[0]))
                    for (let k = 0 ; k < valueList.length; k++){
                        valueList[k] =valueList[k].toString()
                    }
                if (Array.isArray(valueListY[0]))
                    for (let k = 0 ; k < valueList.length; k++){
                        valueListY[k] =valueListY[k].toString()
                    }
                if ( this.state.XParamType !== 'word')
                    layout.xaxis.type = this.state.Xaxis;
                if ( this.state.YParamType !== 'word')
                    layout.yaxis.type = this.state.Yaxis;
                layout.width = 600
                layout.height = 500
                dataGraph =
                    {
                        y: valueListY,
                        x: valueList,
                        type: 'box',
                        name: name
                    }
            }

            else if ( this.state.GraphType === "BubbleChart"){
                layout.xaxis.type = this.state.Xaxis;
                layout.yaxis.type = this.state.Yaxis;
                layout.width = 600
                layout.height = 500
                dataGraph =
                    {
                        x: valueList,
                        y: valueListY,
                        mode: 'markers',
                        name: name
                    }
            }
            graphDatas.push(dataGraph)
        }
        let extra_data = []
        if (this.state.GraphType !== "ParallelChart"){
            //xData,yData,GraphExtra
            if ( y !== null){
                if (this.state.XParamType !== "word") {
                    extra_data.push({name:x,min:Math.min(...xData),max:Math.max(...xData),mean:MathMean(xData)})
                }
                if (this.state.YParamType !== "word"){
                    extra_data.push({name:y,min:Math.min(...yData),max:Math.max(...yData),mean:MathMean(yData)})
                }
            }else{
                console.log(Math.min(...xData),Math.max(...xData),MathMean(xData),xData)
                extra_data.push({name:x,min:Math.min(...xData),max:Math.max(...xData),mean:MathMean(xData)})
            }
        }

        console.log(graphDatas)
        if (single)
            this.setState({Params:[data.value],GraphData:[graphDatas],GraphLayout:[layout],GraphLegend:[legend],GraphExtra:[extra_data]})
        else{
            let params = this.state.Params
            params.push(data.value)
            let graphDataResult = this.state.GraphData
            graphDataResult.push(graphDatas)
            let graphLayoutResult = this.state.GraphLayout;
            graphLayoutResult.push(layout);
            let graphLegendResult = this.state.GraphLegend
            graphLegendResult.push(legend)
            let graphExtraResult = this.state.GraphExtra
            graphExtraResult.push(extra_data)
            console.log(graphLayoutResult,graphDataResult,params,graphLegendResult,graphExtraResult)
            this.setState({Params:params,GraphData:graphDataResult,GraphLayout:graphLayoutResult,GraphLegend:graphLegendResult,GraphExtra:graphExtraResult})

        }

    }
    onGroupByChange (e,data) {
        console.log(data.value,this.state.Filters)
        let dict = [];
        for (let i = 0 ; i < this.state.Filters.length; i++){
            if ( this.state.Filters[i].name === data.value){
                dict = this.state.Filters[i].dict
            }
        }
        let filteredData = this.state.FilteredData;
        if ( filteredData.length === 0)
            filteredData = this.state.FilterData;
        this.setState({ReservoirList:dict,XParam:null,
            GraphType:null,
            YParam:null,
            XParamType:null,
            YParamType:null,GroupedBy:data.value,GroupedByArrays:[filteredData]});
    };
    onReset () {
        this.setState({
            FiltersActive:[],
            FilteredData:[],
            GraphType:null,
            GroupedBy:null,
            GroupByNames:["All"],
            GroupByParamsSelected:[],
            Reservoirs:[],
            XParam:null,
            YParam:null,
            ParamsRawList:[],
            ParamsDataList:[],
            Param:null,
            GraphData:[],
            GraphLayout:[],
        })
    };
    onGroupByParamsChange (e,data,mode) {
        let filteredData = this.state.FilteredData;
        let names = data.value;
        if ( filteredData.length === 0)
            filteredData = this.state.FilterData;
        let groupedArray = [];
        if ( data.value.length === 0)
            groupedArray = [filteredData];
        else if (this.state.GroupedBy === "geo_inde" ) {
            console.log("geo_index", data.value,filteredData)
        } else {
            if (this.state.GroupedBy === "geo_index" )
                names = [];
            for ( let j = 0 ; j<data.value.length ;j++){
                let array = [];
                if (mode === "age"){
                    names.push(data.value[j].name_from + " " +data.value[j].name_to)
                    for (let i = 0 ; i<filteredData.length;i++){

                        console.log(filteredData[i].geo_index_start,filteredData[i].geo_index_end)
                        if (filteredData[i].geo_index_start !== null && filteredData[i].geo_index_end !==null ){
                            let start = age_word.filter(obj => {
                                //console.log(obj.object_index,filteredData[i].geo_index_start)
                                return obj.object_index === filteredData[i].geo_index_start
                            })
                            let end = age_word.filter(obj => {
                                //console.log(obj.object_index,filteredData[i].geo_index_end)
                                return obj.object_index === filteredData[i].geo_index_end
                            })
                            console.log(start,end)
                            // console.log(start[0].age_from >= data.value[j].from,start.age_from , data.value[j].from,end.age_to <= data.value[j].to,end.age_to , data.value[j].to)
                            if ( start[0].age_from >= data.value[j].from && end[0].age_to <= data.value[j].to){
                                array.push(filteredData[i])
                            }
                        }

                    }
                }else{
                    for (let i = 0 ; i<filteredData.length;i++){
                        console.log(filteredData[i][this.state.GroupedBy],data.value[j])
                        if ( Array.isArray(filteredData[i][this.state.GroupedBy])){
                            if ( filteredData[i][this.state.GroupedBy].includes(data.value[j])){
                                array.push(filteredData[i])
                            }
                        }else{
                            if ( filteredData[i][this.state.GroupedBy] === data.value[j]){
                                array.push(filteredData[i])
                            }
                        }

                    }
                }
                groupedArray.push(array)
            }
        }
        console.log(groupedArray,this.state.Filters)
        let filters = [...this.state.Filters]
        console.log(filters);
        let params = []
        let params2 =[]
        for ( let i = 0; i < filters.length;i++){
            console.log(filters[i],filters[i].name !== "name" )
            if (filters[i].name !== "name" && filters[i].name !== "comment" &&	filters[i].name !== "set_year" &&	filters[i].name !== "source_authors" &&	filters[i].name !== "source_year" &&	filters[i].name !== "source_name"){
                params.push(filters[i].name)
            }
            if (filters[i].type === "integer"){
                params2.push(filters[i].name)
            }
        }
        this.setState({GroupedByArrays:groupedArray,ParamList:params,ParamList2:params2,GroupByNames:names});
    };
    onGroupParamsChange (e,data){
        this.setState({GroupByParamsSelected:data.value})
    }
    componentDidMount() {
        console.log("filter inteface mounted ",this.props.object_id)
        const isNumeric = (num) => (typeof(num) === 'number' || typeof(num) === "string" && num.trim() !== '') && !isNaN(num);
        let query = {type:'get_Reserves',select_type:'get_params_set_multiple_reserves',select_params:{object_id:this.props.object_id}}
        axios.post(urlBackend+'/postQuery', {query:query})
            .then((result) => {
                console.log(result.data);
                let table_data = [];
                let list = []
                let availableParamList = [];
                for ( let i = 0; i < result.data.length ; i++){
                    let object = {name:result.data[i].name,fields_name:result.data[i].fields_name,geo_index_start:result.data[i].geo_index_start,geo_index_end:result.data[i].geo_index_end,comment:result.data[i].f_hcd_param_set_comment,set_year:result.data[i].f_hcd_param_set_year,types:result.data[i].type,facies:result.data[i].facies[0].facies_ids,deposition_system:result.data[i].facies[0].deposition_system_ids,deposition_environment:result.data[i].facies[0].deposition_enviroment_ids,depth:result.data[i].depth,gisdepth:result.data[i].gisdepth}
                    let param = {name:result.data[i].name,fields_name:result.data[i].fields_name,geo_index_start:result.data[i].geo_index_start,geo_index_end:result.data[i].geo_index_end,comment:result.data[i].f_hcd_param_set_comment,set_year:result.data[i].f_hcd_param_set_year,types:result.data[i].type,facies:result.data[i].facies[0].facies_ids,deposition_system:result.data[i].facies[0].deposition_system_ids,deposition_environment:result.data[i].facies[0].deposition_enviroment_ids,depth:result.data[i].depth,gisdepth:result.data[i].gisdepth}
                    list.push(result.data[i].name)
                    for ( let j = 0; j < result.data[i].params.length; j++ ){
                        object[result.data[i].params[j].param_name] = result.data[i].params[j].value ;
                        if (!object.hasOwnProperty("source_authors"))
                            object["source_authors"] = result.data[i].params[j].source_authors;
                        if (!object.hasOwnProperty("source_year"))
                            object["source_year"] = result.data[i].params[j].source_year;
                        if (!object.hasOwnProperty("source_name"))
                            object["source_name"] =result.data[i].params[j].source_name;
                        param[result.data[i].params[j].param_name] = result.data[i].params[j].value;
                        if (!param.hasOwnProperty("source_authors"))
                            param["source_authors"] = result.data[i].params[j].source_authors;
                        if (!param.hasOwnProperty("source_year"))
                            param["source_year"] = result.data[i].params[j].source_year;
                        if (!param.hasOwnProperty("source_name"))
                            param["source_name"] =result.data[i].params[j].source_name;
                    }
                    //console.log(result.data[i].facies,result.data[i].facies[0].facies_ids,result.data[i].facies[0].deposition_system_ids,result.data[i].facies[0].deposition_enviroment_ids)
                    let reserves = result.data[i].reserves.filter((v,i,a)=>a.findIndex(v2=>(JSON.stringify(v2) === JSON.stringify(v)))===i)
                    //console.log(reserves)
                    let total_reserves = {"Нефтяное":null,"Газовое":null,"Конденсат":null,"Общий":null}


                    let subreserves = reserves.reduce(function (r, a) {
                        r[a.type] = r[a.type] || [];
                        r[a.type].push(a);
                        return r;
                    }, Object.create(null));

                    let keys_reserves = Object.keys(subreserves);
                    for ( let j = 0 ; j < keys_reserves.length ; j++){
                        console.log(subreserves[keys_reserves[j]])
                        subreserves[keys_reserves[j]] = subreserves[keys_reserves[j]].reduce(function (r, a) {
                            r[a.category] = r[a.category] || [];
                            r[a.category].push(a);
                            return r;
                        }, Object.create(null));
                        let keys_category = Object.keys(subreserves[keys_reserves[j]])
                        for ( let k = 0 ; k < keys_category.length;k++){
                            if (subreserves[keys_reserves[j]][keys_category[k]].length > 0){
                                if (subreserves[keys_reserves[j]][keys_category[k]].length > 1){
                                    let sum = subreserves[keys_reserves[j]][keys_category[k]][0].value;
                                    for (let l = 1 ; l < subreserves[keys_reserves[j]][keys_category[k]].length; l++){
                                        sum = sum+ subreserves[keys_reserves[j]][keys_category[k]][0].value;
                                    }
                                    subreserves[keys_reserves[j]][keys_category[k]] = [{value:sum,type:keys_reserves[j],category:keys_category[k]}]
                                    object["Запасы " + keys_reserves[j] + " " +keys_category[k]] =sum/subreserves[keys_reserves[j]][keys_category[k]].length;
                                    param["Запасы " + keys_reserves[j] + " " +keys_category[k]] =sum/subreserves[keys_reserves[j]][keys_category[k]].length;

                                }else{
                                    object["Запасы " + keys_reserves[j] + " " +keys_category[k]] =subreserves[keys_reserves[j]][keys_category[k]][0].value;
                                    param["Запасы " + keys_reserves[j] + " " +keys_category[k]] =subreserves[keys_reserves[j]][keys_category[k]][0].value;
                                }
                            }
                        }
                        if (keys_category.includes('А+Б1+С1')){
                            if(keys_category.lenght > 1){
                                total_reserves[keys_reserves[j]] = 0;
                                for ( let k = 0 ; k < keys_category.length;k++){
                                    if ( keys_category[k] !== 'А+Б1+С1')
                                        total_reserves[keys_reserves[j]] = total_reserves[keys_reserves[j]] + subreserves[keys_reserves[j]][keys_category[k]][0].value
                                }
                            }else{
                                total_reserves[keys_reserves[j]] = 0;
                                for ( let k = 0 ; k < keys_category.length;k++){
                                    total_reserves[keys_reserves[j]] = total_reserves[keys_reserves[j]] + subreserves[keys_reserves[j]][keys_category[k]][0].value
                                }
                            }
                        }else{
                            if(keys_category.length > 0){
                                total_reserves[keys_reserves[j]] = 0;
                                for ( let k = 0 ; k < keys_category.length;k++){
                                    total_reserves[keys_reserves[j]] = total_reserves[keys_reserves[j]] + subreserves[keys_reserves[j]][keys_category[k]][0].value
                                }
                            }
                        }
                    }
                    total_reserves.Общий = total_reserves.Нефтяное + (total_reserves.Газовое * 0.0009) +total_reserves.Конденсат
                    object["Запасы Нефти"] =total_reserves.Нефтяное;
                    param["Запасы Нефти"] = total_reserves.Нефтяное;
                    object["Запасы Газа"] =total_reserves.Газовое;
                    param["Запасы Газа"] = total_reserves.Газовое;
                    object["Запасы Кондесата"] =total_reserves.Конденсат;
                    param["Запасы Кондесата"] = total_reserves.Конденсат;
                    object["Запасы Общие"] =total_reserves.Общий;
                    param["Запасы Общие"] = total_reserves.Общий;
                    //console.log(subreserves,total_reserves)
                    // console.log(keys_reserves)
                    table_data.push(object)
                    availableParamList.push(param)
                }
                const keys = [...new Set(table_data.flatMap(obj => Object.keys(obj)))];
                const tableRows = table_data.map(obj => (
                    <tr style={{maxHeight:"100px"}} key={obj.id}>
                        {keys.map(key => {
                            if (obj[key] !== undefined && obj[key] !== null) {
                                if(obj[key].length>100)
                                    return(
                                        <td style={{maxHeight:"100px"}} key={key}>{obj[key].substring(0, 100) || ''}</td>
                                    )
                                else if (Array.isArray(obj[key])){
                                    return(
                                        <td style={{maxHeight:"100px"}} key={key}>{obj[key].join(', ') || ''}</td>
                                    )
                                }
                                else
                                    return(
                                        <td style={{maxHeight:"100px"}} key={key}>{obj[key] || ''}</td>
                                    )
                            }
                            else
                                return(
                                    <td style={{maxHeight:"100px"}} key={key}>{obj[key] || ''}</td>
                                )
                        })}
                    </tr>
                ));

                let set = new Set(list)
                //console.log(table_data,set,result.data,keys,tableRows,availableParamList)
                let availableKeys = Object.keys(availableParamList[0])
                let objects = []
                for (let i = 1 ; i < availableParamList.length;i++){
                    availableKeys = [...availableKeys,...Object.keys(availableParamList[i])]
                }
                function removeItemOnce(arr, value, mode, value2) {
                    console.log(mode,value,value2,arr)
                    if (mode === null || mode === undefined || mode === 1){
                        console.log(mode,value)
                        var index = arr.indexOf(value);
                        if (index > -1) {
                            arr.splice(index, 1);
                        }
                        return arr;
                    }else{
                        console.log(mode,value,value2)
                        var index = arr.indexOf(value);
                        console.log(index,arr[index],arr)
                        if (index > -1) {
                            arr[index] = value2;
                        }
                        return arr;
                    }

                }
                let temp_keys_set = new Set(availableKeys)
                availableKeys = Array.from(temp_keys_set)
                availableKeys = removeItemOnce(availableKeys,'geo_index_end')
                availableKeys = removeItemOnce(availableKeys,'geo_index_start',2,'geo_index')
                console.log(availableKeys)
                for (let i = 0;i < availableKeys.length;i++){
                    let isNull = true;
                    let type = "integer"
                    let dict = []
                    //console.log(availableParamList,availableKeys)
                    for (let j=0 ; j< availableParamList.length; j++){
                        if (availableParamList[j][availableKeys[i]] !== null && (availableParamList[j][availableKeys[i]] !== undefined || availableKeys[i] ==='geo_index')){
                            if (Array.isArray(availableParamList[j][availableKeys[i]])){
                                for ( let k = 0 ; k < availableParamList[j][availableKeys[i]].length;k++){
                                    dict.push(availableParamList[j][availableKeys[i]][k])
                                }
                            }else
                                dict.push(availableParamList[j][availableKeys[i]])
                            isNull = false;
                            if (isNumeric(availableParamList[j][availableKeys[i]])){
                                //console.log('not null , type integer', availableKeys[i])
                            }
                            else if (availableKeys[i] === "geo_index"){
                                type = "age"
                                console.log('not null , type age', availableKeys[i],availableParamList[j][availableKeys[i]],availableParamList[j])
                            }
                            else{
                                type = "word"
                                // console.log('not null , type word', availableKeys[i],availableParamList[j][availableKeys[i]],availableParamList[j])
                            }
                        }
                    }
                    if (isNull){
                        for (let j=0 ; j< availableParamList.length; j++){
                            delete availableParamList[j][availableKeys[i]]
                        }
                    }else {
                        //console.log(availableKeys[i])
                        if ( type === "integer"){
                            let setDict = new Set(dict)
                            objects.push({name:availableKeys[i],dict:Array.from(setDict),type:type})
                        }
                        else if (type === "age") {
                            let setDict = new Set(dict)
                            let arrayDict = Array.from(setDict)
                            console.log(availableKeys[i],arrayDict)
                            if (availableKeys[i] === "geo_index"){
                                console.log("age!!!!!!!!!!")
                                //console.log(age_from_word,age_to_word)
                                objects.push({name:"geo_index",dict_age_from:age_word_from,dict_age_to:age_word_to,type:type})
                            }
                        }
                        else{
                            let setDict = new Set(dict)
                            objects.push({name:availableKeys[i],dict:Array.from(setDict),type:type})
                        }
                    }
                }
                let filters = [...objects]
                let params = []
                let params2 = []
                console.log(filters)
                for ( let i = 0; i < filters.length;i++){
                    console.log(filters[i],filters[i].name !== "name" )
                    if (filters[i].name !== "name" && filters[i].name !== "comment" &&	filters[i].name !== "set_year" &&	filters[i].name !== "source_authors" &&	filters[i].name !== "source_year" &&	filters[i].name !== "source_name"){
                        params.push(filters[i].name)
                    }
                    if (filters[i].type === "integer"){
                        params2.push(filters[i].name)
                    }
                }
                console.log(params,params2,objects,availableParamList,Object.keys(availableParamList[0]))
                this.setState({ReservesDisplay:"block",GroupByNames:["All"],ReservesData:tableRows,ReservesProps:keys,ParamList:params.sort(),ParamList2:params2.sort(),FiltersConst:objects,ReservoirList:Array.from(set).sort(),RawData:result.data,Filters:objects,GroupedByArrays:[availableParamList], FilterData:availableParamList,FiltersKeys:availableKeys.sort()});

            })
            .catch((err) => {console.log(err);})
    }
    render() {
        console.log("Filter interface rendered")
        let iconReserves='plus';
        if(this.state.ReservesDisplay !== "none")
            iconReserves='minus';
        return (
            <div>
                {(this.state.ReservesDisplay === "block")&&(
                    <div style={{display:this.state.ReservesDisplay}}>
                        {(this.state.ReservesData !== null)&&(
                            <>
                                <Label>{i18n.t("Choose Filter : ")}</Label>
                                <Dropdown  selection
                                           multiple
                                           style={{zIndex:999999999,top: '13px',overflow: 'inherit'}}
                                           search
                                           value={this.state.FiltersActive}
                                           onChange={this.onFilterSelection}
                                           placeholder={i18n.t('Filter')}
                                           options={this.state.FiltersKeys.map(data => ({key: data, text:i18n.t(data), value: data}))}/>
                                <br/>
                                {this.state.FiltersActive.map(name => {
                                    console.log(name,this.state.Filters)
                                    for (let i = 0 ; i < this.state.Filters.length ;i++) {
                                        if (this.state.Filters[i].name === name){
                                            if (this.state.Filters[i].type === "integer"){
                                                return (
                                                    <>
                                                        <Label>{i18n.t("Filter ")+i18n.t(name)+i18n.t(" : From ")}</Label>
                                                        <Input type={"number"} id={"from"+name} onChange={(e,data)=>{
                                                            console.log(data.value)
                                                            let filters = [...this.state.Filters];
                                                            if (data.value === '')
                                                                filters[i].filter.from = null;
                                                            else
                                                                filters[i].filter.from = data.value;
                                                            this.setState({Filters: filters})
                                                            this.onFilterData()
                                                        }}/>
                                                        <Label>{i18n.t(" To ")}</Label>
                                                        <Input type={"number"} id={"to"+name} onChange={(e,data)=>{
                                                            console.log(data.value)
                                                            let filters = [...this.state.Filters];
                                                            if (data.value === '')
                                                                filters[i].filter.to = null;
                                                            else
                                                                filters[i].filter.to = data.value;
                                                            this.setState({Filters: filters})
                                                            this.onFilterData()
                                                        }}/>
                                                        <Icon name={"close"} color={"red"} onClick={()=>this.onDeleteFilter(name)}/>
                                                        <br/>
                                                    </>
                                                )
                                            }else if (this.state.Filters[i].type === "age"){
                                                console.log(this.state.Filters[i])
                                                return (
                                                    <>
                                                        <Label>{i18n.t("Filter ")+i18n.t(name)+" : "}</Label>
                                                        <Dropdown  selection

                                                                   search
                                                                   style={{zIndex:999999998,top: '13px',overflow: 'inherit'}}
                                                                   onChange={(e,data)=>{
                                                                       console.log(data.value)
                                                                       let filters = [...this.state.Filters];
                                                                       if (data.value === '')
                                                                           filters[i].filter.from = null;
                                                                       else
                                                                           filters[i].filter.from = Number.parseFloat(data.value);
                                                                       this.setState({Filters: filters})
                                                                       this.onFilterData()
                                                                   }}
                                                                   id={"drop"+name}
                                                                   placeholder={i18n.t("from")}
                                                                   options={this.state.Filters[i].dict_age_from}/>
                                                        <Dropdown  selection
                                                                   search
                                                                   style={{zIndex:999999997,top: '13px',overflow: 'inherit'}}
                                                                   onChange={(e,data)=>{
                                                                       console.log(data.value)
                                                                       let filters = [...this.state.Filters];
                                                                       if (data.value === '')
                                                                           filters[i].filter.to = null;
                                                                       else
                                                                           filters[i].filter.to = Number.parseFloat(data.value);
                                                                       this.setState({Filters: filters})
                                                                       this.onFilterData()
                                                                   }}
                                                                   id={"drop"+name}
                                                                   placeholder={i18n.t("to")}
                                                                   options={this.state.Filters[i].dict_age_to}/>
                                                        <Icon name={"close"} color={"red"} onClick={()=>this.onDeleteFilter(name)}/>
                                                        <br/>
                                                    </>
                                                )
                                            }else{
                                                return (
                                                    <>
                                                        <Label>{i18n.t("Filter ")+i18n.t(name)+" : "}</Label>
                                                        <Dropdown  selection
                                                                   style={{zIndex:999999996,top: '13px',overflow: 'inherit'}}
                                                                   search
                                                                   onChange={(e,data)=>{
                                                                       console.log(data.value)
                                                                       let filters = [...this.state.Filters];
                                                                       console.log(i,filters,filters[i])
                                                                       if (data.value.length > 0)
                                                                           filters[i].filter.values = data.value;
                                                                       else
                                                                           filters[i].filter.values = null;
                                                                       this.setState({Filters: filters})
                                                                       this.onFilterData()
                                                                   }}
                                                                   multiple
                                                                   id={"drop"+name}
                                                                   placeholder={i18n.t(name)}
                                                                   options={this.state.Filters[i].dict.map(data => ({key: data, text:i18n.t(data), value: data}))}/>
                                                        <Icon name={"close"} color={"red"} onClick={()=>this.onDeleteFilter(name)}/>
                                                        <br/>
                                                    </>
                                                )
                                            }
                                        }
                                    }

                                })}
                                <Label>{"Group by : "}</Label>
                                <Dropdown  selection
                                           style={{zIndex:999999995,top: '3px',overflow: 'inherit'}}
                                           search
                                           onChange={this.onGroupByChange}
                                           value={this.state.GroupedBy}
                                           placeholder={i18n.t('Group by')}
                                           options={this.state.FiltersKeys.map(data => ({key: data, text:i18n.t(data), value: data}))}/>
                                <Icon name={"close"} color={"red"} onClick={this.onGroupByReset}/>

                                <br/>

                                {(this.state.GroupedBy !== null && this.state.GroupedBy !== 'geo_index')&&(
                                    <>
                                        <Label>{i18n.t("Select groups : ")}</Label>
                                        <Dropdown  selection
                                                   search
                                                   style={{zIndex:999999994,top: '13px',overflow: 'inherit'}}
                                                   multiple
                                                   onChange={this.onGroupByParamsChange}
                                                   placeholder={i18n.t('All')}
                                                   value={this.GroupByNames}
                                                   options={this.state.ReservoirList.map(data => {
                                                       console.log(data)
                                                       return({key: data, text:i18n.t(data), value: data})
                                                   })}/>
                                        <br/>
                                    </>
                                )}
                                {(this.state.GroupedBy !== null && (this.state.GroupedBy === 'geo_index'))&&(
                                    <>
                                        <FeatureInfosReservesGroupByAge display={"block"} handler={(groups)=>this.onGroupByParamsChange(null,{value:groups},"age")} />
                                    </>
                                )}
                                <Label>{i18n.t("Choose Graph Type: ")}</Label>
                                <Dropdown  selection
                                           onChange={this.onGraphTypeSelection}
                                           placeholder={i18n.t('Reservoir')}
                                           value={this.state.GraphType}
                                           options={this.state.GraphTypesList.map(data => ({key: data, text:data, value: data}))}/>
                                <br/>
                                {(this.state.GraphType === "BubbleChart" || this.state.GraphType === "BarChart") &&(
                                    <>
                                        <Label>{i18n.t("Choose Group Params: ")}</Label>
                                        <Dropdown  selection
                                                   style={{zIndex:999999993,top: '13px',overflow: 'inherit'}}
                                                   search
                                                   multiple
                                                   onChange={this.onGroupParamsChange}
                                                   placeholder={i18n.t('Reservoir')}
                                                   options={this.state.GroupByParams.map(data => ({key: data, text:i18n.t(data), value: data}))}/>
                                        <br/>
                                    </>
                                )}
                                {(this.state.GraphType !== null)&&(
                                    <>
                                        {(this.state.GraphType === "Histogram" || this.state.GraphType === "BarChart" )&&(
                                            <>
                                                <Label>{i18n.t("Choose X Param: ")}</Label>
                                                <Dropdown  selection
                                                           style={{zIndex:999999992,top: '13px',overflow: 'inherit'}}
                                                           search
                                                           onChange={this.onXSelection}
                                                           value={this.state.XParam}
                                                           placeholder={i18n.t('X')}
                                                           options={this.state.ParamList2.map(data => ({key: data, text:data, value: data}))}/>

                                            </>
                                        )}
                                        {(this.state.GraphType === "ParallelChart" )&&(
                                            <>
                                                <Label>{i18n.t("Choose X Param: ")}</Label>
                                                <Dropdown  selection
                                                           style={{zIndex:999999991,top: '13px',overflow: 'inherit'}}
                                                           search
                                                           onChange={this.onXSelection}
                                                           multiple
                                                           placeholder='X'
                                                           options={this.state.ParamList2.map(data => ({key: data, text:data, value: data}))}/>

                                            </>
                                        )}
                                        {(this.state.GraphType !== "Histogram" && this.state.GraphType !== "BarChart"  && this.state.GraphType !== 'ParallelChart')&&(
                                            <>
                                                {(this.state.YParamType !== "word")&&(
                                                    <>
                                                        <Label>{i18n.t("Choose X Param: ")}</Label>
                                                        <Dropdown  selection
                                                                   style={{zIndex:999999990,top: '13px',overflow: 'inherit'}}
                                                                   search
                                                                   onChange={this.onXSelection}
                                                                   value={this.state.XParam}
                                                                   placeholder='X'
                                                                   options={this.state.ParamList.map(data => ({key: data, text:data, value: data}))}/>
                                                        <Icon name={"close"} color={"red"} onClick={this.onXReset}/>
                                                    </>
                                                )}
                                                {(this.state.YParamType === "word")&&(
                                                    <>
                                                        <Label>{i18n.t("Choose X Param: ")}</Label>
                                                        <Dropdown  selection
                                                                   style={{zIndex:999999989,top: '13px',overflow: 'inherit'}}
                                                                   search
                                                                   onChange={this.onXSelection}
                                                                   value={this.state.XParam}
                                                                   placeholder='X'
                                                                   options={this.state.ParamList2.map(data => ({key: data, text:data, value: data}))}/>
                                                        <Icon name={"close"} color={"red"} onClick={this.onXReset}/>
                                                    </>
                                                )}
                                            </>
                                        )}
                                        {(this.state.GraphType === 'Histogram')&&(
                                            <><Input type="number" min="0" value={this.state.HistogramBins} onChange={this.onBinsChange}></Input></>
                                        )}
                                        <br/>
                                        <Label>{i18n.t("Choose X axis: ")}</Label>
                                        <Dropdown  selection
                                                   onChange={this.onXaxis}
                                                   value={this.state.Xaxis}
                                                   placeholder='X'
                                                   options={this.state.axisType.map(data => ({key: data, text:data, value: data}))}/>
                                        <br/>
                                        {(this.state.GraphType === 'BoxChart' || this.state.GraphType ==='BubbleChart')&&(
                                            <>
                                                {(this.state.XParamType !== "word")&&(
                                                    <>
                                                        <Label>{i18n.t("Choose Y Param: ")}</Label>
                                                        <Dropdown  selection
                                                                   style={{zIndex:999999988,top: '13px',overflow: 'inherit'}}
                                                                   search
                                                                   onChange={this.onYSelection}
                                                                   value={this.state.YParam}
                                                                   placeholder='Y'
                                                                   options={this.state.ParamList.map(data => ({key: data, text:data, value: data}))}/>
                                                        <Icon name={"close"} color={"red"} onClick={this.onYReset}/>
                                                        <br/>
                                                    </>
                                                )}
                                                {(this.state.XParamType === "word")&&(
                                                    <>
                                                        <Label>{i18n.t("Choose Y Param: ")}</Label>
                                                        <Dropdown  selection
                                                                   style={{zIndex:999999987,top: '13px',overflow: 'inherit'}}
                                                                   search
                                                                   onChange={this.onYSelection}
                                                                   value={this.state.YParam}
                                                                   placeholder='Y'
                                                                   options={this.state.ParamList2.map(data => ({key: data, text:data, value: data}))}/>
                                                        <Icon name={"close"} color={"red"} onClick={this.onYReset}/>
                                                        <br/>
                                                    </>
                                                )}
                                                <Label>{i18n.t("Choose Y axis: ")}</Label>
                                                <Dropdown  selection
                                                           onChange={this.onYaxis}
                                                           value={this.state.Yaxis}
                                                           placeholder='Y'
                                                           options={this.state.axisType.map(data => ({key: data, text:data, value: data}))}/>
                                                <br/>
                                            </>
                                        )}
                                        <Button onClick={(e,data)=>this.onParamSelection(e,data,true)}>{"Построить"}</Button><Button onClick={this.onReset}>{"Сброс"}</Button>{(this.state.GraphData.length > 0)&&(<Button onClick={(e,data)=>this.onParamSelection(e,data,false)}>{"Добавить"}</Button>)}
                                    </>)}
                                <br/>
                                <br/>
                                {console.log(this.state.GraphData,this.state.GraphLayout)}
                                {(this.state.GraphData.map((data,index)=>{
                                    console.log(index,data)
                                    return(
                                        <div style={{display:"flex"}}>
                                            {console.log(this.state.GraphLayout[index],this.state.GraphExtra[index])}
                                            <Plot
                                                style={{marginLeft:'-25px'}}
                                                onClick={(data,e)=>{
                                                    console.log('click',data,e)
                                                }}
                                                data={ data}
                                                layout={ this.state.GraphLayout[index] }
                                            />
                                            {(this.state.GraphType === "ParallelChart" )&&(<div style={{top:"50px",position:"relative",height:"400px",fontSize:"10px",display:"inline",overflowY:"scroll"}}>
                                                {this.state.GraphLegend[index].map(({legends,color},id)=>{
                                                    console.log(legends,color)
                                                    let name = legends;
                                                    if (name === undefined || name ===  null || name === '')
                                                        name = "None"
                                                    return (
                                                        <div style={{display:"flex"}}>
                                                            <br/>
                                                            <div style={ {width: '12px', height: '12px', background:color,display:'inline-block',position: "relative"} } /><div style={{display:'inline-block'}}>:{name+"  ."}</div>
                                                        </div>)

                                                })}
                                                <br/>
                                            </div>)}
                                            {(this.state.GraphType !== "ParallelChart"  &&  this.state.GraphExtra[index] !== null &&  this.state.GraphExtra[index] !== undefined)&&(<div>
                                                <br/>
                                                {console.log(this.state.GraphExtra)}
                                                {this.state.GraphExtra[index].map((item,id)=>{
                                                    console.log(item.mean)
                                                    return (<>
                                                        {item.name + " :   "+i18n.t("min")+" : " + item.min  +i18n.t(" max ")+": " + item.max +i18n.t(" mean")+" : " + ( Math.round(Number.parseFloat(item.mean) * 100) / 100 )}
                                                        <br/>
                                                    </>) ;

                                                })}
                                            </div>)}
                                            <br/>
                                        </div>

                                    )}))}
                                <div style={{maxHeight:"500px",overflow:"auto"}}>
                                    <table >
                                        <thead>
                                        <tr>
                                            {this.state.ReservesProps.map(key => (
                                                <th onClick={()=>this.onSort(key)} style={{position: "sticky" ,top: "0"}} key={key}>{key}</th>
                                            ))}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.ReservesData}
                                        </tbody>
                                    </table>
                                </div>

                            </>
                        )}
                    </div>
                )}
            </div>
        )
    }
}
export default ReservesFilterInterface